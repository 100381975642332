import { AnimationControls } from 'framer-motion';

import { ReceptorEntity } from './models';
import { Dim } from '../predicates/models';
import Receptor from '../renderers/Receptor';
import receptorPredicate from '../predicates/receptorPredicate';
import { INPUT_MODES } from '../constants/layout';

const receptorEntity = (
  windowDims: Dim,
  y: number,
  animates: Record<number, AnimationControls>,
  inputMode = INPUT_MODES.jog
): ReceptorEntity => ({
  ...receptorPredicate(windowDims, y, animates, inputMode),
  renderer: Receptor,
});

export default receptorEntity;
