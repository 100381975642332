import Transformer from './Transformer';
import {
  ACTIVE_COMP_ATTACK,
  ACTIVE_COMP_RELEASE,
  RAW,
  NATURAL,
  BALANCED,
  BASSY,
  HEAVY,
} from './constants';
import { Preset } from './models';
import { getDistortionCurve } from './utils';

export default Transformer;
export {
  ACTIVE_COMP_ATTACK,
  ACTIVE_COMP_RELEASE,
  RAW,
  NATURAL,
  BALANCED,
  BASSY,
  HEAVY,
  getDistortionCurve,
};
export type { Preset };
