import React, {
  useReducer,
  useMemo,
  PropsWithChildren,
  ReactElement,
  useLayoutEffect,
} from 'react';

import { createSafeContext } from '~/helpers/state';

import { Value, State } from './models';
import {
  loadAuthThunk,
  loginThunk,
  signupThunk,
  logoutThunk,
  updateAccountThunk,
  deleteAccountThunk,
} from './actions';
import { changePassword } from './services';
import reducer, { INITIAL_STATE } from './reducer';
import { getAxiosAuthHeader, isPreviouslyAuthed } from './utils';

const [useAuth, Provider] = createSafeContext<Value>();

type AuthProps = Record<never, never>;

function Auth({ children }: PropsWithChildren<AuthProps>): ReactElement {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  // Connect thunks
  const logout = logoutThunk(dispatch);
  const loadAuth = loadAuthThunk(dispatch);
  const login = loginThunk(dispatch);
  const signup = signupThunk(dispatch);
  const getAccessToken = getAxiosAuthHeader;
  const updateAccount = updateAccountThunk(dispatch);
  const deleteAccount = deleteAccountThunk(dispatch);

  // Set up value
  const value = useMemo(() => {
    return {
      state: state as State,
      logout,
      loadAuth,
      login,
      signup,
      getAccessToken,
      updateAccount,
      changePassword,
      deleteAccount,
    };
  }, [state]);

  // Refresh access if previously authed
  useLayoutEffect(() => {
    if (isPreviouslyAuthed()) loadAuth();
  });

  // TODO: add `useEffect` with `addEventListener` for log out, log in, and refresh sync. Check https://hasura.io/blog/best-practices-of-using-jwt-with-graphql/ for an example of how they did this.

  return <Provider value={value}>{children}</Provider>;
}

export default Auth;
export { useAuth };
