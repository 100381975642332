import React, {
  ReactElement,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from 'react';
import YouTube from 'react-youtube';
import { useForm } from 'react-hook-form';
import type YouTubePlayer from 'youtube-player';

import COLORS from '~/constants/colors';
import VARIANTS from '~/constants/variants';
import { FONT_SIZES } from '~/constants/typography';
import urls from '~/urls';
import FramedView from '~/components/FramedView';
import Divider from '~/components/flow/Divider';
import TextInput from '~/components/inputs/TextInput';
import NumberInput from '~/components/inputs/NumberInput';
import Button from '~/components/buttons/Button';
import Announcement from '~/components/Announcement';
import Code from '~/components/flow/Code';
import TextArea from '~/components/inputs/TextArea';
import Link from '~/components/flow/Link';
import { stopEventEffects } from '~/utils';
import Title from '~/components/titles/Title';
import Header from '~/components/titles/Header';
import Subtext from '~/components/titles/Subtext';

import { FALL_SPEED, TEMPO_NUMERATOR } from '../constants/mechanics';
import { SONGS } from '../constants/alpha';
import PHASES from '../constants/phases';
import {
  DEFAULT_META,
  DEFAULT_SONG,
  DEFAULT_RHYTHM,
} from '../constants/defaults';
import { getVideoId } from '../utils';
import { getVideoMeta } from '../services/youTube';
import { AnySettings, Rhythm, Song } from '../models';
import OptionSelect from '../components/OptionSelect';
import NoteVelocitySlider from '../components/NoteVelocitySlider';
import SongIntervalSlider from '../components/SongIntervalSlider';

interface StudioProps {
  phase: string;
  setPhase: Dispatch<SetStateAction<string>>;
  setPrevPhase: Dispatch<SetStateAction<string>>;
  setSettings: Dispatch<SetStateAction<AnySettings>>;
  setSong: Dispatch<SetStateAction<Song>>;
  videoPlayer: YouTubePlayer;
  playerState: number;
  setPlayerState: Dispatch<SetStateAction<number>>;
  newRhythm: Rhythm;
  setNewRhythm: Dispatch<SetStateAction<Rhythm>>;
}

function Studio({
  phase,
  setPhase,
  setPrevPhase,
  setSettings,
  setSong,
  videoPlayer,
  playerState,
  setPlayerState,
  newRhythm,
  setNewRhythm,
}: StudioProps): ReactElement {
  const [songIdx, setSongIdx] = useState(-1);
  const [songVolume] = useState(100);
  const [rhythmIdx, setRhythmIdx] = useState(0);
  const [velocity, setVelocity] = useState(FALL_SPEED.default);
  const [formSource, setFormSource] = useState(DEFAULT_SONG.source);
  const [formBpm, setFormBpm] = useState(DEFAULT_SONG.bpm);
  const [outputSong, setOutputSong] = useState(DEFAULT_SONG);
  const output = JSON.stringify({ ...outputSong, rhythms: [newRhythm] });
  const getStudioSource = (idx) => SONGS[idx]?.source ?? formSource;
  const newSongLabel = 'New song...';
  const studioRhythm = SONGS[songIdx]?.rhythms[rhythmIdx] ?? DEFAULT_RHYTHM;
  const studioSong = {
    title: SONGS[songIdx]?.title ?? newSongLabel,
    artist: SONGS[songIdx]?.artist ?? DEFAULT_SONG.artist,
    source: getStudioSource(songIdx),
    bpm: SONGS[songIdx]?.bpm ?? formBpm,
    rhythms: [studioRhythm],
  };
  const affectVideoPlayer = (source) => {
    const newVideoId = getVideoId(source);

    if (newVideoId) {
      videoPlayer?.cueVideoById(newVideoId);
      setPlayerState(YouTube.PlayerState.UNSTARTED);
    }
  };

  // Handle adding song
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: DEFAULT_META });
  const sourceOpts = { required: true, validate: (link) => !!getVideoId(link) };
  const bpmOpts = { required: true, min: 1 };
  const addSongButtonText = formSource ? 'Update' : 'Load';
  const onAddSong = (s) => {
    setFormSource(s.source);
    setFormBpm(Number(s.bpm));
    setOutputSong({ ...outputSong, ...s });
    affectVideoPlayer(s.source);
  };

  // Handle studio interactions
  const [duration, setDuration] = useState(0);
  const [playerStartTime, setPlayerStartTime] = useState(0);
  const [playerEndTime, setPlayerEndTime] = useState(duration);
  const videoStartTime = playerStartTime * 1000;
  const videoEndTime = playerEndTime * 1000;
  const handleSongChoice = (option) => {
    const newSongIdx = option.value;
    const newSource = getStudioSource(newSongIdx);

    setSongIdx(newSongIdx);
    setRhythmIdx(0);
    affectVideoPlayer(newSource);
  };
  const handleRhythmName = (event) => {
    stopEventEffects(event);
    setNewRhythm({ ...newRhythm, name: event.target.value });
  };
  const handleRhythmPromoText = (event) => {
    const tempRhythm = { ...newRhythm };

    stopEventEffects(event);
    if (!event.target.value) delete tempRhythm.promoText;
    else tempRhythm.promoText = event.target.value;
    setNewRhythm(tempRhythm);
  };
  const handleRhythmPromoLink = (event) => {
    const tempRhythm = { ...newRhythm };

    stopEventEffects(event);
    if (!event.target.value) delete tempRhythm.promoLink;
    else tempRhythm.promoLink = event.target.value;
    setNewRhythm(tempRhythm);
  };
  const handleInputEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.target.blur();
    }
  };

  // Handle starting game
  const handleGame = (nextPhase) => (event) => {
    stopEventEffects(event);
    if (videoPlayer) {
      setSettings({
        song: { ...outputSong, rhythms: [newRhythm] },
        velocity,
        videoStartTime,
        videoEndTime,
      });

      // Set new rhythm timings if empty
      if (songIdx >= 0 && newRhythm.timings.length === 0)
        setNewRhythm({ ...newRhythm, timings: studioRhythm.timings });

      // Needed here for mobile not allowing autoplay
      videoPlayer.setVolume(songVolume);
      videoPlayer.loadVideoById({
        videoId: getVideoId(outputSong.source),
        startSeconds: playerStartTime,
        endSeconds: playerEndTime,
      });
      setPrevPhase(PHASES.studio);
      setPhase(nextPhase);
    }
  };

  // Handle timing alignment
  const [precision, setPrecision] = useState(1);
  const precisions = new Map([
    [1 / 32, 'Thirty-Second'],
    [1 / 16, 'Sixteenth'],
    [1 / 8, 'Eighth'],
    [1 / 4, 'Quarter'],
    [1 / 3, 'Third'],
    [1 / 2, 'Half'],
    [1, 'Whole'],
    [2, 'Breve'],
    [3, 'Triple'],
    [4, 'Longa'],
    [8, 'Maxima'],
  ]);
  const roundTimings = (timings: number[]) => {
    const divisorTime = (TEMPO_NUMERATOR / outputSong.bpm) * precision;
    const alignTiming = (t) => Math.round(t / divisorTime) * divisorTime;
    const insideSong = (t) => t >= 0 && t <= duration * 1000;
    const outsideRange = (t) =>
      (t < videoStartTime || t > videoEndTime) && insideSong(t);
    const insideRange = (t) => !outsideRange(t) && insideSong(t);
    const compare = (a, b) => a - b;
    const alignedRange = timings.filter(insideRange).map(alignTiming);
    const merged = timings.filter(outsideRange).concat(alignedRange);

    return [...new Set(merged.sort(compare))];
  };
  const handleAlignment = (event) => {
    stopEventEffects(event);
    setNewRhythm({ ...newRhythm, timings: roundTimings(newRhythm.timings) });
  };

  // Interface relevant
  const disabledLoading = playerState <= YouTube.PlayerState.UNSTARTED;
  const disabledWaiting = songIdx < 0 && !formSource;
  const disabled = disabledLoading || disabledWaiting;
  const recordButtonText = 'Record';
  const reviewButtonText = 'Review';
  const alignButtonText = 'Align';

  // Styling
  const studioStyle = {
    display: phase === PHASES.studio ? 'block' : 'none',
  };
  const framedViewStyle = {
    width: 'min(45em, 82.5vw)',
  };
  const frameColors: [string, string] = [
    COLORS.waikawaGray,
    COLORS.waikawaGray,
  ];
  const levelStyle = {
    display: 'block',
    marginTop: '1em',
  };
  const readyLevelStyle = {
    ...levelStyle,
    display: disabledWaiting ? 'none' : 'block',
  };
  const actionLevelStyle = {
    ...levelStyle,
    fontSize: FONT_SIZES.action,
  };
  const levelRowSpaceStyle = {
    height: '0.25em',
  };
  const optionSelectStyle = {
    width: 'min(20em, 80vw)',
  };
  const textInputStyle = {
    width: 'min(320px, 75vw)',
  };
  const numberInputStyle = {
    width: 'min(314px, 73.4vw)',
  };
  const songIntervalLabelStyle = {
    margin: '0.25em 1em 0.25em',
    color: COLORS.dodgerBlue,
  };
  const buttonStyle = {
    width: '9em',
    margin: '0 0.25em 0.38em',
    boxShadow: `-0.1em 0.1em ${COLORS.black25}`,
    borderStyle: 'none',
    fontSize: FONT_SIZES.text,
  };
  const sliderStyle = {
    width: 'min(305px, 69vw)',
    minWidth: 0,
    margin: '0.25em 0.5em 0.5em 1em',
  };
  const textAreaStyle = {
    width: 'min(320px, 75vw)',
    height: '5em',
  };
  const outputStyle = {
    ...textAreaStyle,
    width: 'min(640px, 75vw)',
    height: '7.5em',
  };
  const alignOptionSelectStyle = {
    ...optionSelectStyle,
    width: 'min(9.5em, 80vw)',
    margin: '0 0.25em 0.38em',
  };
  const alignButtonStyle = {
    ...buttonStyle,
    margin: '0 0 0.38em 0.25em',
  };

  useEffect(() => {
    if (playerState === YouTube.PlayerState.CUED) {
      const newDuration = Math.floor(videoPlayer.getDuration());
      const studioSource = studioSong.source;
      const studioBpm = studioSong.bpm;

      getVideoMeta(studioSource, studioBpm, outputSong, setOutputSong);
      setDuration(newDuration);
      setPlayerStartTime(0);
      setPlayerEndTime(newDuration);
    }
  }, [playerState]);

  // Handle updating get ready
  useEffect(() => {
    if (phase === PHASES.studio) setSong(outputSong);
  }, [outputSong.source, outputSong.title, outputSong.bpm, phase]);

  return (
    <div style={studioStyle}>
      <FramedView accent colors={frameColors} style={framedViewStyle}>
        <Announcement header="Warning">
          There is no persistence. If you refresh or change pages, your work
          will be lost!
        </Announcement>
        <Title>Studio</Title>
        <br />
        Enter a YouTube link and BPM or select an already added song. You can
        then record a new rhythm or review what you&apos;ve made. To record, tap
        the pad or press enter when you want a note to hit the receptor bar.
        Focus on timing relative to the song not the positioning. The note
        highway here only communicates a relative sense of timing. If you
        don&apos;t like a part, adjust the start and end times then use the
        alignment feature or click record to overwrite what&apos;s inclusively
        between. You can review specific parts similarly.
        <Divider />
        <Header>Song Selection</Header>
        <div style={levelStyle}>
          <OptionSelect
            placeholder="Select a song..."
            value={{
              value: songIdx,
              label: studioSong.title,
            }}
            onChange={handleSongChoice}
            options={[
              { value: -1, label: newSongLabel },
              ...SONGS.map((s, idx) => ({
                value: idx,
                label: `${s.title} by ${s.artist}`,
              })),
            ]}
            style={optionSelectStyle}
          />
        </div>
        {songIdx < 0 && (
          <form style={levelStyle}>
            <div style={actionLevelStyle}>
              Enter a YouTube link
              <div style={levelRowSpaceStyle} />
              <TextInput
                {...register('source', sourceOpts)}
                style={textInputStyle}
              />
              {errors.source && (
                <Subtext color={COLORS.mandy}>
                  Valid YouTube link required!
                </Subtext>
              )}
            </div>
            <div style={actionLevelStyle}>
              What is the song&apos;s BPM?
              <div style={levelRowSpaceStyle} />
              <NumberInput
                min={1}
                {...register('bpm', bpmOpts)}
                style={numberInputStyle}
              />
              {errors.bpm && (
                <Subtext color={COLORS.mandy}>Valid BPM required!</Subtext>
              )}
            </div>
            <br />
            <Button
              variant={VARIANTS.warning}
              label={addSongButtonText}
              onClick={handleSubmit(onAddSong)}
              onTouchEnd={handleSubmit(onAddSong)}
              style={buttonStyle}
            />
          </form>
        )}
        {songIdx >= 0 && (
          <div style={levelStyle}>
            <div style={levelStyle}>
              Start with an existing rhythm?
              <div style={levelRowSpaceStyle} />
              <OptionSelect
                placeholder={
                  studioRhythm.name
                    ? 'Select a pattern...'
                    : 'No patterns available'
                }
                value={{
                  value: rhythmIdx,
                  label: studioRhythm.name,
                }}
                onChange={(option) => setRhythmIdx(option.value)}
                options={SONGS[songIdx].rhythms.map((r, idx) => ({
                  value: idx,
                  label: r.name,
                }))}
                isSearchable={false}
                controlShouldRenderValue={!!studioRhythm.name}
                disabled={!studioRhythm.name}
                style={{ width: 'min(20em, 80vw)' }}
              />
            </div>
            <div style={levelStyle}>
              Here are the selected rhythm&apos;s timings
              <div style={levelRowSpaceStyle} />
              <TextArea
                readOnly
                value={JSON.stringify(studioRhythm.timings)}
                style={textAreaStyle}
              />
            </div>
          </div>
        )}
        <div style={readyLevelStyle}>
          <Divider />
          <Header>Rhythm Recording</Header>
          {outputSong.title && outputSong.artist && (
            <Announcement variant={VARIANTS.warning}>
              The currently loaded song is{' '}
              <Code highlight={COLORS.white}>{outputSong.title}</Code> by{' '}
              <Code highlight={COLORS.white}>{outputSong.artist}</Code>!
            </Announcement>
          )}
          <div style={levelStyle}>
            What should we call your new rhythm?
            <div style={levelRowSpaceStyle} />
            <TextInput
              onBlur={handleRhythmName}
              onKeyDown={handleInputEnter}
              style={textInputStyle}
            />
          </div>
          <div style={levelStyle}>
            Which part of the song do you want to work with?
            <div style={levelRowSpaceStyle} />
            <Subtext style={songIntervalLabelStyle}>
              From <b>{playerStartTime}</b> seconds to <b>{playerEndTime}</b>{' '}
              seconds
            </Subtext>
            <SongIntervalSlider
              duration={duration}
              playerStartTime={playerStartTime}
              playerEndTime={playerEndTime}
              setPlayerStartTime={setPlayerStartTime}
              setPlayerEndTime={setPlayerEndTime}
              style={sliderStyle}
            />
          </div>
          <div style={levelStyle}>
            How fast do you want the notes to look?
            <div style={levelRowSpaceStyle} />
            <NoteVelocitySlider
              velocity={velocity}
              setVelocity={setVelocity}
              markLabel="Normal"
              markColor={COLORS.tundora}
              style={sliderStyle}
            />
          </div>
          <div style={levelStyle}>
            Feel free to add a shoutout!
            <div style={levelRowSpaceStyle} />
            <TextArea
              onBlur={handleRhythmPromoText}
              onKeyDown={handleInputEnter}
              maxLength={280}
              style={textAreaStyle}
            />
          </div>
          <div style={levelStyle}>
            Do you want your shoutout to also be a link?
            <div style={levelRowSpaceStyle} />
            <TextInput
              onBlur={handleRhythmPromoLink}
              onKeyDown={handleInputEnter}
              style={textInputStyle}
            />
            <br />
            <Subtext>Please keep things safe for work</Subtext>
          </div>
          <div style={levelStyle}>
            <Button
              label={recordButtonText}
              disabled={disabled}
              ignoreDisabledStyle
              onClick={handleGame(PHASES.record)}
              onTouchEnd={handleGame(PHASES.record)}
              style={buttonStyle}
            />
            <Button
              label={reviewButtonText}
              disabled={disabled}
              ignoreDisabledStyle
              onClick={handleGame(PHASES.review)}
              onTouchEnd={handleGame(PHASES.review)}
              style={buttonStyle}
            />
          </div>
        </div>
      </FramedView>
      <br />
      <FramedView accent colors={frameColors} style={framedViewStyle}>
        <Title>Submission</Title>
        <br />
        To add your rhythm to the game, join my{' '}
        <Link href={urls.discordInvite}>Discord</Link> and post what&apos;s in
        this last box to <Code>#japanese</Code>. Sorry it&apos;s a bit low-tech
        right now. I&apos;ll add a database if enough people are interested.
        <div style={levelStyle}>
          <TextArea
            readOnly
            value={disabledWaiting ? '' : output}
            style={outputStyle}
          />
        </div>
        <Divider />
        <Header>Adjust Alignment</Header>
        <Announcement variant={VARIANTS.warning}>
          Alignment works relative to the loaded song&apos;s BPM inclusively
          between the start and end times set above. This action irrecoverably
          mutates your work. Timings that overlap or exist outside the loaded
          song&apos;s duration will be removed.
        </Announcement>
        <div style={levelStyle}>
          To what fraction of a beat would you like to align your timings?
          <div style={levelRowSpaceStyle} />
          <OptionSelect
            placeholder="Select a precision..."
            value={{
              value: precision,
              label: precisions.get(precision),
            }}
            onChange={(option) => setPrecision(option.value)}
            options={Array.from(precisions).map(([v, l]) => ({
              value: v,
              label: l,
            }))}
            style={alignOptionSelectStyle}
          />
          <Button
            label={alignButtonText}
            variant={VARIANTS.warning}
            onClick={handleAlignment}
            onTouchEnd={handleAlignment}
            style={alignButtonStyle}
          />
        </div>
      </FramedView>
    </div>
  );
}

export default Studio;
