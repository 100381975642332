import React, { ReactElement, PropsWithChildren, CSSProperties } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';
import { Theme } from '~/models';
import VARIANTS from '~/constants/variants';

const THEME = {
  neutral: {
    default: {
      backgroundColor: COLORS.white,
    },
  },
  primary: {
    default: {
      backgroundColor: COLORS.dodgerBlue,
    },
  },
  secondary: {
    default: {
      backgroundColor: COLORS.white25,
    },
  },
  warning: {
    default: {
      backgroundColor: COLORS.kournikova,
    },
  },
  fail: {
    default: {
      backgroundColor: COLORS.mandy,
    },
  },
  success: {
    default: {
      backgroundColor: COLORS.wildWillow,
    },
  },
  disabled: {
    default: {
      backgroundColor: COLORS.alabaster,
    },
  },
} as Theme;

interface AnnouncementProps {
  variant?: VARIANTS;
  header?: string;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
}

function Announcement({
  variant = VARIANTS.fail,
  header,
  style,
  headerStyle,
  children,
}: PropsWithChildren<AnnouncementProps>): ReactElement {
  const bgColor = THEME[variant].default.backgroundColor;

  // Styling
  const announcementStyle = {
    padding: '1em',
    color: COLORS.black,
    backgroundColor: `${bgColor}88`,
    borderColor: bgColor,
    borderWidth: '0 0 0 0.5em',
    borderStyle: 'solid',
    fontSize: FONT_SIZES.text,
    fontWeight: FONT_WEIGHTS.normal,
    textAlign: 'left' as const,
    ...style,
  };
  const totalHeaderStyle = {
    margin: '0 0 0.5em',
    color: COLORS.black,
    fontSize: FONT_SIZES.action,
    fontWeight: FONT_WEIGHTS.bold,
    ...headerStyle,
  };

  return (
    <div style={announcementStyle}>
      {header && <h2 style={totalHeaderStyle}>{header}</h2>}
      {children}
    </div>
  );
}

export default Announcement;
