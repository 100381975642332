import YouTube from 'react-youtube';

import { PlaySystem } from './models';
import { Attempt } from '../models';
import {
  THRESHOLDS,
  POINTS,
  DAMAGE,
  INITIAL_HEALTH,
} from '../constants/mechanics';
import { INDEX_MAP } from '../constants/kana';
import PHASES from '../constants/phases';
import { TYPES } from '../constants/events';
import {
  INPUT_MODES,
  NOTE_KEYS,
  RECEPTOR_VARIANTS,
  FEEDBACK_VARIANTS,
} from '../constants/layout';

const playInputSystem: PlaySystem = (entities, { events }) => {
  const newEntities = entities;
  const {
    notes,
    receptor,
    setPhase,
    perfectFeedback,
    correctFeedback,
    metrics,
  } = entities;
  const { windowDims } = entities.physics;
  const { inputMode, homework } = entities.settings;
  const { youTubePlayerState, attemptableIdxs, cumCorrect, cumSpecial } =
    entities.state;
  const { startTime, videoTime } = entities.state;
  const { health } = entities.state.results;

  // Check game started and song still playing
  if (startTime < 0 || youTubePlayerState !== YouTube.PlayerState.PLAYING)
    return newEntities;

  // Loop through attemptable notes
  const noteKeys = Object.keys(notes);

  noteKeys.every((key) => {
    const note = notes[key];

    if (!note.attemptable) return true;

    // Calculate if player missed note completely
    const endY = receptor.getPos().y;
    const yDiff = note.getPos().y - endY;
    const thresholds = THRESHOLDS(windowDims);
    const missedTargetNote = yDiff > thresholds.miss;
    const attempt: Attempt = {
      rating: NOTE_KEYS.miss,
      correctTiming: note.timing,
      delta: 0,
      problem: homework.problems[attemptableIdxs[0]],
      answerIdx: attemptableIdxs[1],
      input: '',
      points: POINTS.miss,
      damage: DAMAGE.miss,
      special: note.theme === NOTE_KEYS.special,
    };
    let attemptRating = NOTE_KEYS.miss;

    // Handle input for timing and correctness
    const yDist = Math.abs(yDiff);
    const inKeyMode = inputMode === INPUT_MODES.key;
    const attemptedInput = inKeyMode
      ? events.find((event) => event.type === TYPES.onKeyDown)
      : events.find((event) => event.type === TYPES.onInputPadSend);
    const madeProperAttempt = yDist <= thresholds.miss && attemptedInput;
    const madePreAttempt =
      yDist > thresholds.miss && yDiff < 0 && attemptedInput;

    if (madeProperAttempt || madePreAttempt) {
      attempt.delta = videoTime - note.timing;
      attempt.input = (attemptedInput as CustomEvent).detail.character;

      // Check correctness depending on input mode
      const attemptWrong = inKeyMode
        ? attempt.input !== note.answer
        : INDEX_MAP[attempt.input].join('') !== INDEX_MAP[note.answer].join('');

      if (attemptWrong) attemptRating = NOTE_KEYS.wrong;
      else if (yDist <= thresholds.perfect) attemptRating = NOTE_KEYS.perfect;
      else if (yDist <= thresholds.good) attemptRating = NOTE_KEYS.good;
      else if (yDist <= thresholds.okay) attemptRating = NOTE_KEYS.okay;
      else if (yDist <= thresholds.bad) attemptRating = NOTE_KEYS.bad;
      else if (yDist <= thresholds.awful) attemptRating = NOTE_KEYS.awful;
      else if (madePreAttempt) attemptRating = NOTE_KEYS.pre;
    }

    // Update returned entities
    if (missedTargetNote || madeProperAttempt || madePreAttempt) {
      const nextCumCorrect =
        (attemptableIdxs[1] === 0 || cumCorrect) &&
        attemptRating !== NOTE_KEYS.wrong &&
        attemptRating !== NOTE_KEYS.miss;
      const nextCumSpecial =
        attempt.special || (cumSpecial && attemptableIdxs[1] > 0);

      attempt.rating = attemptRating;
      attempt.points = POINTS[attemptRating];
      attempt.damage = DAMAGE[attemptRating];
      newEntities.notes[key].attemptable = false;
      newEntities.notes[key].garbageTime = videoTime;
      newEntities.notes[key].state = attemptRating;
      newEntities.state.cumCorrect = nextCumCorrect;
      newEntities.state.cumSpecial = nextCumSpecial;

      // Update results
      const newHealth = Math.min(health - attempt.damage, INITIAL_HEALTH);

      newEntities.state.results.attempts.push(attempt);
      newEntities.state.results.points += attempt.points;
      newEntities.state.results.health = metrics.special
        ? Math.max(newHealth, 1)
        : newHealth;
      newEntities.state.results.damage +=
        attempt.damage > 0 ? attempt.damage : 0;
      newEntities.state.results[attempt.rating] += 1;
      if (attempt.rating !== NOTE_KEYS.miss) {
        newEntities.state.results.delta += attempt.delta;
        newEntities.state.results.deltaSquared += attempt.delta ** 2;
        newEntities.state.results.hits += 1;
      }

      // Update attemptable indices and input pad
      const makeSafe = (pIdx) => Math.min(pIdx, homework.problems.length - 1);
      const getAnswers = (pIdx) => homework.problems[makeSafe(pIdx)].answers;
      const nextAttemptableIdxs: [number, number] =
        attemptableIdxs[1] + 1 === getAnswers(attemptableIdxs[0]).length
          ? [attemptableIdxs[0] + 1, 0]
          : [attemptableIdxs[0], attemptableIdxs[1] + 1];
      const nextAnswers = getAnswers(nextAttemptableIdxs[0]);
      const problemsLeft = nextAttemptableIdxs[0] < homework.problems.length;
      const answersLeft = nextAttemptableIdxs[1] < nextAnswers.length;

      if (problemsLeft && answersLeft) {
        newEntities.state.attemptableIdxs = nextAttemptableIdxs;
        newEntities.inputPad.targetLabel = nextAnswers[nextAttemptableIdxs[1]];
      } else if (newEntities.inputPad.guidance)
        newEntities.inputPad.guidance = false; // Turn off guidance at end

      // Updates metrics
      newEntities.metrics.points = newEntities.state.results.points;
      newEntities.metrics.hearts = newEntities.state.results.health;

      // Update feedback
      if (attempt.rating !== NOTE_KEYS.wrong && !missedTargetNote) {
        newEntities.correctFeedback.stat += 1;
        correctFeedback.animate.start(FEEDBACK_VARIANTS.show);
      }
      if (attempt.rating === NOTE_KEYS.perfect) {
        newEntities.perfectFeedback.stat += 1;
        perfectFeedback.animate.start(FEEDBACK_VARIANTS.show);
      }

      // Animate receptor
      const nextProblemIsDiff = nextAttemptableIdxs[0] > attemptableIdxs[0];

      if (attemptRating === NOTE_KEYS.wrong)
        receptor.animates[note.xOffIdx].start(RECEPTOR_VARIANTS.wrong);
      else if (nextProblemIsDiff && nextCumCorrect) {
        if (nextCumSpecial) {
          newEntities.state.results.gotSpecial = true;
          receptor.animates[note.xOffIdx].start(RECEPTOR_VARIANTS.special);
          newEntities.metrics.special = true;
        } else receptor.animates[note.xOffIdx].start(RECEPTOR_VARIANTS.combo);
      }
    }

    return false;
  });

  // End game if no health
  if (newEntities.state.results.health <= 0) setPhase(PHASES.ending);

  return newEntities;
};

export default playInputSystem;
