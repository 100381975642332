import axios from 'axios';

import {
  getStorageItem,
  setStorageItem,
  removeStorageItem,
} from '~/helpers/storage';

import urls from './urls';
import {
  User,
  LoginInfo,
  SignupInfo,
  AccountInfo,
  PasswordInfo,
  FreshAuth,
} from './models';
import { STORAGE_KEYS } from './constants';

const config = { withCredentials: true };

export const revokeRefresh = async (): Promise<void> => {
  const refreshKey = getStorageItem(STORAGE_KEYS.refreshKey);

  if (refreshKey) {
    await axios.post(urls.revoke, { key: refreshKey }, config);
    removeStorageItem(STORAGE_KEYS.refreshKey);
  }
};

export const refreshAuth = async (): Promise<FreshAuth> => {
  const refreshKey = getStorageItem(STORAGE_KEYS.refreshKey);
  let response;

  if (refreshKey) {
    response = await axios.post(urls.refresh, { key: refreshKey }, config);
    setStorageItem(STORAGE_KEYS.refreshKey, response.data.refreshKey); // Should also synchronize refresh
  }

  return response?.data;
};

export const login = async (loginInfo: LoginInfo): Promise<FreshAuth> => {
  const response = await axios.post(urls.login, loginInfo, config);

  setStorageItem(STORAGE_KEYS.refreshKey, response.data.refreshKey); // Should also synchronize refresh

  return response.data;
};

export const changePassword = async (
  passwordInfo: PasswordInfo
): Promise<void> => {
  await axios.post(
    urls.changePassword,
    { ...passwordInfo, passwordConfirmation: passwordInfo.password },
    config
  );
};

export const createUser = async (signupInfo: SignupInfo): Promise<void> => {
  await axios.post(urls.register, signupInfo);
};

export const updateMe = async (accountInfo: AccountInfo): Promise<User> => {
  const response = await axios.put(urls.me, accountInfo, config);

  return response.data.user;
};

export const deleteMe = async (): Promise<void> => {
  await axios.delete(urls.me, config);
};
