import Matter from 'matter-js';

import { Dim, BarPredicate } from './models';
import { posOf } from '../utils';

const barPredicate = (
  windowDims: Dim,
  y: number,
  timing: number,
  fuzzy: boolean,
  state?: string
): BarPredicate => {
  const dims = { w: windowDims.w, h: 0 };
  const barStyle = {
    zIndex: 1,
  };
  const body = Matter.Bodies.rectangle(windowDims.w / 2, y, dims.w, dims.h, {
    type: 'bar',
  });

  return {
    dims,
    getPos: () => posOf(body),
    body,
    timing,
    garbageTime: -1,
    fuzzy,
    state,
    style: barStyle,
  };
};

export default barPredicate;
