import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import urls from '~/urls';
import FramedView from '~/components/FramedView';
import Title from '~/components/titles/Title';
import Divider from '~/components/flow/Divider';
import Announcement from '~/components/Announcement';
import Link from '~/components/flow/Link';
import PATHS from '~/constants/paths';
import Code from '~/components/flow/Code';
import { useAuth } from '~/providers/Auth';
import VARIANTS from '~/constants/variants';
import { getRelativeUrl } from '~/helpers/routing';

interface UnauthedProps {
  nested?: boolean;
  roleRequired?: boolean;
}

function Unauthed({
  nested = false,
  roleRequired = false,
}: UnauthedProps): ReactElement {
  const { state: authState } = useAuth();
  const navState = { from: getRelativeUrl() };

  // Styling
  const unauthedStyle = {
    width: 'min(45em, 82.5vw)',
  };
  const frameColors: [string, string] = [
    COLORS.waikawaGray,
    COLORS.waikawaGray,
  ];

  // Reduce repetition
  const Blurb = () => (
    <>
      You don&apos;t have permission to access this part of the app. If
      you&apos;d like access, please{' '}
      {!authState.isAuthed && (
        <Link to={PATHS.login} state={navState}>
          log in
        </Link>
      )}
      {!authState.isAuthed && roleRequired && ' and '}
      {roleRequired && (
        <>
          ask an admin in <Code>#music</Code> on our{' '}
          <Link href={urls.discordInvite}>Discord</Link> to grant you a stronger
          role. We appreciate any interest, but role requests may be denied till
          greater stability is achieved with our code&apos;s behavior
        </>
      )}
      .
    </>
  );

  return nested ? (
    <Blurb />
  ) : (
    <FramedView accent colors={frameColors} style={unauthedStyle}>
      <Announcement variant={VARIANTS.warning}>
        Parts of Beatbox Database are invite-only during beta
      </Announcement>
      <Title>Unauthorized</Title>
      <Divider />
      <Blurb />
    </FramedView>
  );
}

export default Unauthed;
