import { Dim } from '../../models';

export const getNoteDims = (gameDims: Dim): Dim => {
  const width = Math.min(gameDims.w / 6, 105);
  const height = Math.min(gameDims.h / 14.5, 75);
  const minDim = Math.min(width, height);

  return {
    w: minDim,
    h: minDim,
    b: Math.min(minDim / (width < height ? 35 : 25), 3),
  };
};

export const getThresholds = (gameDims: Dim): Record<string, number> => {
  const unit = getNoteDims(gameDims).w / 6;

  return {
    perfect: unit,
    right: unit * 3,
    miss: unit * 3,
  };
};

export const getYFromTrack = (gameDims: Dim, track: number): number =>
  (gameDims.h / 4) * (track + 1); // TODO: calculate based on number of sounds in chart instead of always dividing by 4
