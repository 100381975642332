import Matter from 'matter-js';
import { AnimationControls } from 'framer-motion';

import { Dim, ReceptorPredicate } from './models';
import { PANEL_DIMS, NOTE_DIMS, RECEPTOR_DIMS } from '../constants/layout';
import { posOf } from '../utils';

const receptorPredicate = (
  windowDims: Dim,
  y: number,
  animates: Record<number, AnimationControls>,
  inputMode: string,
  xOff = 0
): ReceptorPredicate => {
  const panelDims = PANEL_DIMS(windowDims).gym;
  const noteDims = NOTE_DIMS(windowDims);
  const receptorDims = RECEPTOR_DIMS(windowDims);
  const landingB = receptorDims.b;
  const dims = { w: windowDims.w, h: receptorDims.h };
  const body = Matter.Bodies.rectangle(
    windowDims.w / 2 + xOff,
    y,
    dims.w,
    dims.h,
    { type: 'receptor' }
  );
  const receptorStyle = {
    zIndex: 3,
  };

  Matter.Body.setStatic(body, true);

  return {
    dims,
    getPos: () => posOf(body),
    body,
    panelDims,
    noteDims,
    landingB,
    animates,
    inputMode,
    style: receptorStyle,
  };
};

export default receptorPredicate;
