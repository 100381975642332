import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import Button from '~/components/buttons/Button';

import { PRESETS, PRESET_LABELS } from '../../../constants/mastering';

interface PresetButtonsProps {
  mastering: string;
  cookingPreset: string;
  getCallback: (preset: string) => (event: Event) => Promise<void>;
}

function PresetButtons({
  mastering,
  cookingPreset,
  getCallback,
}: PresetButtonsProps): ReactElement {
  const buttonWidths = {
    [PRESETS.raw]: '3.2em',
    [PRESETS.natural]: '4.8em',
    [PRESETS.balanced]: '5.7em',
    [PRESETS.bassy]: '4.1em',
    [PRESETS.heavy]: '4.2em',
  };
  const presetColors = {
    [PRESETS.raw]: COLORS.jaffa,
    [PRESETS.natural]: COLORS.kournikova,
    [PRESETS.balanced]: COLORS.sushi,
    [PRESETS.bassy]: COLORS.malibu,
    [PRESETS.heavy]: COLORS.lilacBush,
  };
  const getKey = (idx) => `BeatboxSharePresetButtonsButton${idx}`;

  // Styling
  const presetButtonsStyle = {
    width: '21.5em',
    marginBottom: '-0.5em',
  };
  const getButtonStyle = (p) => {
    const fillColor = p === mastering ? presetColors[p] : COLORS.codGray;

    return {
      width: buttonWidths[p],
      padding: '0.5em',
      margin: '0 0.25em 0.5em',
      color: p === mastering ? COLORS.black : COLORS.white,
      backgroundColor: fillColor,
      borderColor: fillColor,
      fontSize: FONT_SIZES.subtext,
      cursor: p === mastering ? 'default' : 'pointer',
    };
  };
  const getButtonDisabledStyle = (p) => {
    const fillColor =
      p === mastering || p === cookingPreset ? presetColors[p] : COLORS.codGray;

    return {
      backgroundColor: fillColor,
      borderColor: fillColor,
      cursor: 'default',
    };
  };
  const getButtonDisabledLabelStyle = (p) => ({
    color: p === mastering || p === cookingPreset ? COLORS.black : COLORS.white,
  });

  return (
    <div style={presetButtonsStyle}>
      {Object.values(PRESETS).map((p, idx) => (
        <Button
          key={getKey(idx)}
          label={PRESET_LABELS[p]}
          loading={cookingPreset === p}
          disabled={cookingPreset !== ''}
          callback={getCallback(p)}
          style={getButtonStyle(p)}
          disabledStyle={getButtonDisabledStyle(p)}
          disabledLabelStyle={getButtonDisabledLabelStyle(p)}
        />
      ))}
    </div>
  );
}

export default PresetButtons;
