import urls from '~/urls';

export default {
  beatboxer: (handle) => `${urls.api}/beatbox/profiles/${handle}`,
  profiles: `${urls.api}/profiles`,
  profile: (profileId) => `${urls.api}/profiles/${profileId}`,
  subjects: `${urls.api}/subjects`,
  subject: (subjectId) => `${urls.api}/subjects/${subjectId}`,
  categories: `${urls.api}/categories`,
  category: (categoryId) => `${urls.api}/categories/${categoryId}`,
  techniques: `${urls.api}/techniques`,
  technique: (techniqueId) => `${urls.api}/techniques/${techniqueId}`,
  demos: `${urls.api}/demos`,
  demo: (demoId) => `${urls.api}/demos/${demoId}`,
  tutorials: `${urls.api}/tutorials`,
  tutorial: (tutorialId) => `${urls.api}/tutorials/${tutorialId}`,
  records: `${urls.api}/records`,
  record: (recordId) => `${urls.api}/records/${recordId}`,

  // External
  amCharts: 'https://www.amcharts.com',
  breathe: 'https://youtu.be/2wTDG1EggRg',
};
