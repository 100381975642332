import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { Beat, Nodes, Charts } from '../../models';
import getParser from '../../parsers/groups';
import Manager from './components/Manager';
import Content, { useContext } from './providers/Content';

interface ScriptProps {
  nodes?: Nodes;
  beat: Beat;
  setBeat: Dispatch<SetStateAction<Beat>>;
  setCharts: Dispatch<SetStateAction<Charts>>;
  disabled?: boolean;
  example?: Beat; // TODO: remove this in future versions
}

function Script({
  nodes,
  beat,
  setBeat,
  setCharts,
  disabled,
  example, // TODO: remove this in future versions
}: ScriptProps) {
  const { state, setNodes, setSentences, setDisabled, setPatterns, setGroups } =
    useContext();
  const { patterns, groups, muted } = state;
  const parser = nodes && getParser(nodes, beat.bpm);

  useEffect(() => {
    if (parser) {
      const layers = patterns.map((p, idx) => ({
        pattern: p,
        group: groups[idx],
      }));
      const notMutedLayers = layers.filter((l, idx) => !muted[idx]);
      const parsed = parser(layers);

      setSentences(parsed.sentences);
      // TODO: avoid double parsing
      setCharts(
        notMutedLayers.length < layers.length
          ? parser(notMutedLayers).charts
          : parsed.charts
      );
      if (parsed.accepted) setBeat({ ...beat, layers, nodes: parsed.nodes });
    } else setCharts({ [groups[0]]: [] }); // Needed for allowing group selection while loading
  }, [patterns, groups, muted]);
  useEffect(() => {
    if (nodes) {
      setNodes(nodes);
      if (!disabled) setDisabled(false);
    }
  }, [nodes]);

  // Example
  // TODO: remove in future versions
  useEffect(() => {
    if (example) {
      setPatterns(example.layers.map((l) => l.pattern));
      setGroups(example.layers.map((l) => l.group ?? 0));
    }
  }, [example]);

  return <Manager />;
}

export default (props: ScriptProps) => (
  <Content>
    <Script {...props} />
  </Content>
);
