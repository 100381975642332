import { AccountInfo } from '~/providers/Auth';

import { Vertex } from '../models';
import {
  CategoryInfo,
  TechniqueInfo,
  DemoInfo,
  TutorialInfo,
} from '../providers/BeatboxProvider';
import { RecordInfo } from '../providers/RecordProvider';
import { KINDS, STATES } from './content';

// Categories
export const DEFAULT_CATEGORY_LABEL = 'Lost';

export const DEFAULT_CATEGORY_INFO: CategoryInfo = {
  label: '',
  position: NaN,
};

// Techniques
export const DEFAULT_TECHNIQUE_INFO: TechniqueInfo = {
  name: '',
  description: '',
  kind: KINDS.sound,
  difficulty: undefined,
  credited_up_users: [],
  categories: [],
  parent_techniques: [],
};

// Demos
export const DEFAULT_DEMO_INFO: DemoInfo = {
  youtube: '',
  instagram: '',
  techniques: [],
};

// Tutorials
export const DEFAULT_TUTORIAL_INFO: TutorialInfo = {
  youtube: '',
  instagram: '',
  categories: [],
  techniques: [],
};

// Records
export const DEFAULT_RECORD_INFO: RecordInfo = {
  state: STATES.learning,
};

// Components
export const DEFAULT_LABEL_PREFIX = 'Standalone';

// Pages
export const DEFAULT_CATEGORY_VERTEX: Vertex = {
  id: 'c0',
  name: DEFAULT_CATEGORY_LABEL,
  children: [],
};

export const DEFAULT_ACCOUNT_INFO: AccountInfo = {
  onboarding: '',
};
