import React, { ReactElement } from 'react';
import { InstagramEmbed as ReactSocialMediaInstagramEmbed } from 'react-social-media-embed';

import { FONT_SIZES } from '~/constants/typography';

interface InstagramEmbedProps {
  url: string;
}

function InstagramEmbed({ url }: InstagramEmbedProps): ReactElement {
  const relativeWidth =
    document.body.clientWidth - 0.7 * parseInt(FONT_SIZES.text, 10) * 3;

  // Styling
  const instagramEmbedStyle = {
    marginBottom: '-0.5em',
  };

  return (
    <div style={instagramEmbedStyle}>
      <ReactSocialMediaInstagramEmbed
        url={url}
        width={Math.min(relativeWidth, 480)}
      />
    </div>
  );
}

export default InstagramEmbed;
