import React, { ReactElement, CSSProperties } from 'react';

interface GapProps {
  style?: CSSProperties;
}

function Gap({ style }: GapProps): ReactElement {
  const gapStyle = {
    height: '0.5em',
    ...style,
  };

  return <div style={gapStyle} />;
}

export default Gap;
