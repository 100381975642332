import React, { ReactElement, useState, CSSProperties } from 'react';
import { useForm } from 'react-hook-form';

import COLORS from '~/constants/colors';
import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';
import VARIANTS from '~/constants/variants';
import { stopEventEffects } from '~/utils';
import { getFormErrorHandler } from '~/helpers/journal';
import Button from '~/components/buttons/Button';
import Annotate from '~/components/Annotate';

import { DEFAULT_RECORD_INFO } from '../constants/defaults';
import { STATES } from '../constants/content';
import { useRecordContext } from '../providers/RecordProvider';
import { checkIdFormat, capitalizeFirstLetter } from '../utils';

interface RecordTogglerProps {
  techniqueId?: number;
  style?: CSSProperties;
}

function RecordToggler({
  techniqueId,
  style,
}: RecordTogglerProps): ReactElement {
  const [showAnnotate, setShowAnnotate] = useState(false);
  const [fail, setFail] = useState<string>();
  const { state: recordState, editRecord, deleteRecord } = useRecordContext();
  const toggleShowAnnotate = (event) => {
    stopEventEffects(event);
    setShowAnnotate(!showAnnotate);
  };
  const annotateCallback = () => setShowAnnotate(false);
  const record = recordState.records?.find(
    (r) => r.technique.id === techniqueId
  );
  const getKey = (idx) => `BeatboxDbAnnotateDiv${idx}`;

  // Form
  const defaultValues = DEFAULT_RECORD_INFO;
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues });
  const stateOpts = {
    required: true,
    validate: (value) => Object.values(STATES).includes(value),
  };
  const techniqueOpts = {
    required: true,
    validate: (value) => checkIdFormat(value),
  };

  register('state', stateOpts);
  register('technique', techniqueOpts);

  // Handlers
  const onSubmit = (recordInfo) => {
    return new Promise<void>((resolve) => {
      if (record)
        editRecord(record.id, recordInfo)
          .then(() => resolve())
          .catch(
            getFormErrorHandler(DEFAULT_RECORD_INFO, setError, setFail, resolve)
          );
    });
  };
  const getHandleValue = (state?) => (event) => {
    stopEventEffects(event);
    if (state) {
      setValue('state', state, { shouldValidate: true });
      setValue('technique', techniqueId, { shouldValidate: true });
      handleSubmit(onSubmit)();
    } else if (record)
      deleteRecord(record.id).catch(
        getFormErrorHandler(DEFAULT_RECORD_INFO, setError, setFail)
      );
    setShowAnnotate(false);
  };

  // Rendering
  const buttonLabel = 'Move';

  // Styling
  const recordTogglerStyle = {
    display: 'inline-block',
    position: 'relative' as const,
    ...style,
  };
  const buttonStyle = {
    width: '3.75em',
    lineHeight: 'calc(1em - 2px)',
    padding: '0.3em',
    borderRadius: '0.3em',
    fontSize: FONT_SIZES.text,
    boxShadow: undefined,
  };
  const activeButtonStyle = {
    ...buttonStyle,
    color: COLORS.waikawaGray,
    borderColor: COLORS.waikawaGray,
  };
  const contentStyle = {
    color: COLORS.white,
  };
  const getStateOptionStyle = (showBorder) => ({
    padding: '0.5em 0',
    ...(showBorder && { borderBottom: `1px dashed ${COLORS.white}` }),
    fontWeight: FONT_WEIGHTS.bold,
    cursor: 'pointer',
  });
  const discardStyle = {
    ...getStateOptionStyle(false),
    color: COLORS.waikawaGray,
    borderTop: `1px solid ${COLORS.white}`,
  };

  // Annotate props
  const annotateProps = {
    callback: annotateCallback,
    orientation: 'above' as const,
    containerWidth: '6em',
    containerHeight: '8.8em',
    containerBackgroundColor: COLORS.cornflower,
    contentStyle,
  };
  const errorAnnotateProps = {
    ...annotateProps,
    containerWidth: '6em',
    containerHeight: '3.5em',
    containerBackgroundColor: COLORS.mandy,
  };

  return (
    <div style={recordTogglerStyle}>
      <Button
        label={buttonLabel}
        variant={VARIANTS.secondary}
        onMouseUp={toggleShowAnnotate}
        onTouchEnd={toggleShowAnnotate}
        style={showAnnotate ? activeButtonStyle : buttonStyle}
      />
      <Annotate visible={showAnnotate} {...annotateProps}>
        {Object.values(STATES).map(
          (s, idx, { length }) =>
            s !== record?.state && (
              <div
                key={getKey(idx)}
                role="none"
                onMouseUp={getHandleValue(s)}
                onTouchEnd={getHandleValue(s)}
                style={getStateOptionStyle(
                  idx < length - (record?.state === STATES.archived ? 2 : 1)
                )}
              >
                {capitalizeFirstLetter(s)}
              </div>
            )
        )}
        <div
          role="none"
          onMouseUp={getHandleValue()}
          onTouchEnd={getHandleValue()}
          style={discardStyle}
        >
          Discard
        </div>
      </Annotate>
      <Annotate visible={!!(fail || errors.state)} {...errorAnnotateProps}>
        {fail || errors.state?.message || 'Please try again later'}
      </Annotate>
    </div>
  );
}

export default RecordToggler;
