import React, { ReactElement, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import HollowStudy from '~/components/backgrounds/HollowStudy';
import Brand from '~/components/titles/Brand';
import { FONT_SIZES } from '~/constants/typography';
import COLORS from '~/constants/colors';
import { useTitle } from '~/utils';

import PATHS, { BSH_PATH } from './constants/paths';
import { ListenControls } from './models';
import NavSwitchBoard from './components/NavSwitchBoard';
import NotFound from './pages/NotFound';
import Record from './pages/Record';
import Film from './pages/Film';
import Upload from './pages/Upload';
import Listen from './pages/Listen';
import About from './pages/About';

function BeatboxShare(): ReactElement {
  const [listenControls, setListenControls] = useState<ListenControls>({});
  const [automaticStart, setAutomaticStart] = useState(false);

  useTitle('Beatbox Share');

  // Styling
  const beatboxShareStyle = {
    width: '100%',
    fontSize: FONT_SIZES.text,
    textAlign: 'center' as const,
  };
  const backgroundStyle = {
    position: 'absolute' as const,
    minHeight: '45em',
    backgroundColor: COLORS.white,
    zIndex: -2,
  };
  const containerStyle = {
    display: 'flex',
    minWidth: 350,
    paddingBottom: '1em',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  };
  const topicStyle = {
    color: COLORS.ghost,
  };

  // Reduce repetition
  const RecordWithProps = (
    <Record
      setListenControls={setListenControls}
      automaticStart={automaticStart}
      setAutomaticStart={setAutomaticStart}
    />
  );

  return (
    <div style={beatboxShareStyle}>
      <HollowStudy style={backgroundStyle}>
        <div style={containerStyle}>
          <Brand
            name="beatbox"
            topic="Share"
            link={BSH_PATH || PATHS.home}
            topicStyle={topicStyle}
          />
          <NavSwitchBoard />
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route index element={RecordWithProps} />
            <Route path={PATHS.record} element={RecordWithProps} />
            <Route
              path={PATHS.film}
              element={
                <Film
                  setListenControls={setListenControls}
                  automaticStart={automaticStart}
                  setAutomaticStart={setAutomaticStart}
                />
              }
            />
            <Route
              path={PATHS.upload}
              element={
                <Upload
                  setListenControls={setListenControls}
                  automaticStart={automaticStart}
                  setAutomaticStart={setAutomaticStart}
                />
              }
            />
            <Route
              path={PATHS.listen}
              element={
                <Listen
                  listenControls={listenControls}
                  setListenControls={setListenControls}
                  setAutomaticStart={setAutomaticStart}
                />
              }
            />
            <Route path={PATHS.about} element={<About />} />
          </Routes>
        </div>
      </HollowStudy>
    </div>
  );
}

export default () => <BeatboxShare />;
