import React, { ReactElement, PropsWithChildren, CSSProperties } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';

interface TitleProps {
  color?: string;
  style?: CSSProperties;
}

function Title({
  color = COLORS.black,
  style,
  children,
}: PropsWithChildren<TitleProps>): ReactElement {
  const titleStyle = {
    margin: '0.35em 0 0',
    color,
    fontWeight: FONT_WEIGHTS.bold,
    fontSize: FONT_SIZES.title,
    textAlign: 'center' as const,
    overflow: 'hidden',
    ...style,
  };

  return <h1 style={titleStyle}>{children}</h1>;
}

export default Title;
