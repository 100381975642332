import React, { ReactElement } from 'react';
import { Body } from 'matter-js';

import { Dim, Pos } from './predicates/models';
import PHASES from './constants/phases';

export const getNewY = (
  endY: number,
  timing: number,
  elapsed: number,
  velocity: number,
  scale = -1
): number => {
  const startY = endY + scale * timing * velocity;
  const progress = elapsed * velocity;

  return startY - scale * progress;
};

export const isGamePhase = (phase?: string): boolean =>
  phase?.substring(0, 4) === PHASES.game;

export const posOf = (body: Body): Pos => ({
  x: body.position.x,
  y: body.position.y,
});

export const dimsOf = (body: Body): Dim => ({
  w: body.bounds.max.x - body.bounds.min.x,
  h: body.bounds.max.y - body.bounds.min.y,
});

export const getWindowDims = (): Dim => ({
  w: document.body.clientWidth,
  h: Math.max(
    window.innerHeight,
    (window.outerWidth / window.innerWidth) * window.innerHeight
  ),
});

export const getVideoId = (link: string): string => {
  const linkRegex =
    /(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;

  return link ? link.match(linkRegex)?.[7] ?? '' : '';
};

export const recursiveRenderer = (
  entities: Record<string, unknown>,
  window: Window & typeof globalThis
): ReactElement[] => {
  if (!entities || !window) return [];

  // Apply default renderer recursively
  const defaultRenderer = (entity, key) => {
    if (typeof entity.renderer === 'object')
      return (
        <entity.renderer.type key={key} window={window} predicate={entity} />
      );
    else if (typeof entity.renderer === 'function')
      return <entity.renderer key={key} window={window} predicate={entity} />;

    return undefined;
  };
  const recurser = (list, key = '0') => {
    const acc: ReactElement[] = [];

    if (list.length === 0) return [];
    list.forEach((element, idx) => {
      if (element && element.renderer) {
        const render = defaultRenderer(element, `entities_${key}_${idx}`);

        if (render) acc.push(render);
      } else if (Array.isArray(element))
        acc.push(...recurser(element, `${key}_${idx}`));
    });

    return acc;
  };
  const renders = recurser(Object.values(entities));

  return renders;
};
