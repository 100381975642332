import { ActionDispatch, ActionCreator } from '~/models';

export const ACTIONS = {
  setNodes: 'Beatblox/Script/content/setNodes',
  setPatterns: 'Beatblox/Script/content/setPatterns',
  setGroups: 'Beatblox/Script/content/setGroups',
  setSentences: 'Beatblox/Script/content/setSentences',
  setMuted: 'Beatblox/Script/content/setMuted',
  setDisabled: 'Beatblox/Script/content/setDisabled',
};

// Creators
const getSetGenericCreator =
  <T>(type: string): ActionCreator<T> =>
  (payload) => ({ type, payload });

// Thunks
export const setGenericThunk =
  <T>(dispatch: ActionDispatch, type: string) =>
  (payload: T): void => {
    const action = getSetGenericCreator(type)(payload);

    dispatch(action);
  };
