import React, { useState } from 'react';

import COLORS from '~/constants/colors';
import { FONTS, FONT_WEIGHTS } from '~/constants/typography';
import Annotate from '~/components/Annotate';
import { stopEventEffects } from '~/utils';

import { MARKS, DESCRIPTIONS } from '../../../constants/language';
import { Nodes } from '../../../models';
import Search from './Search';

interface HelpProps {
  nodes?: Nodes;
}

function Help({ nodes }: HelpProps) {
  const [markIdx, setMarkIdx] = useState<number>();
  const getMarkKey = (idx) => `BeatbloxWriteHelpMark${idx}`;
  const getContainerWidth = (description) =>
    `calc(${description.length}ch + 1em)`;

  // Handlers
  const callback = () => setMarkIdx(undefined);
  const getMarkHandler = (idx) => (event) => {
    stopEventEffects(event);
    setMarkIdx(markIdx === idx ? undefined : idx);
  };

  // Styling
  const helpStyle = {
    display: 'inline-block',
    width: '40ch',
  };
  const markStyle = {
    display: 'inline-block',
    position: 'relative' as const,
    margin: '0.05em',
    padding: '0.3em',
    border: `thin solid ${COLORS.gray}`,
    borderRadius: '0.4em',
    color: COLORS.tundora,
    fontFamily: FONTS.typewriter,
    whiteSpace: 'pre' as const,
    cursor: 'pointer',
  };
  const notationStyle = {
    padding: '0 0.2em',
    color: COLORS.tundora,
    backgroundColor: COLORS.alto,
    borderRadius: '0.2em',
    fontWeight: FONT_WEIGHTS.bold,
  };
  const annotateStyle = {
    zIndex: 5,
  };
  const contentStyle = {
    padding: '0.5em',
    color: COLORS.white,
  };

  return (
    <div style={helpStyle}>
      {Object.keys(DESCRIPTIONS).map((k, idx) => (
        <div
          key={getMarkKey(idx)}
          role="button"
          tabIndex={-1}
          onMouseUp={getMarkHandler(idx)}
          onTouchEnd={getMarkHandler(idx)}
          style={markStyle}
        >
          <span style={notationStyle}>{MARKS[k]}</span> {k}
          <Annotate
            visible={markIdx === idx}
            callback={callback}
            orientation="below"
            containerWidth={getContainerWidth(DESCRIPTIONS[k])}
            containerBackgroundColor={COLORS.tundora}
            style={annotateStyle}
            contentStyle={contentStyle}
          >
            {DESCRIPTIONS[k]}
          </Annotate>
        </div>
      ))}
      <Search nodes={nodes} />
    </div>
  );
}

export default Help;
