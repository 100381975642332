import { Nodes, Sentence, Chart, Layers } from '../models';
import { extractNodes } from '../utils';
import getPatternParser from './pattern';

type Parser = (layers: Layers) => {
  accepted: boolean;
  sentences: Sentence[];
  chart: Chart;
  nodes: Nodes;
};

const getParser = (nodes: Nodes, bpm: number): Parser => {
  const patternParser = getPatternParser(nodes, bpm);
  const parser: Parser = (layers) => {
    const layered = layers.reduce(
      (acc, l) => {
        const parsed = patternParser(l.pattern, acc.tracks);
        const { tracks, sentence, chart } = parsed;
        const accepted = !!l.pattern && !parsed.sentence.some((c) => !!c.error);

        return {
          accepted: acc.accepted && accepted,
          tracks,
          sentences: [...acc.sentences, sentence],
          chart: [...acc.chart, ...chart],
        };
      },
      {
        accepted: true,
        tracks: {},
        sentences: [] as Sentence[],
        chart: [] as Chart,
      }
    ); // Note `reduce` is sequential in JavaScript

    return {
      ...layered,
      accepted: layered.accepted && layers.length > 0,
      nodes: Object.values(extractNodes(layered.chart)),
    };
  };

  return parser;
};

export default getParser;
