import { Nodes, Sentence, Chart, Charts, Layers } from '../models';
import getLayersParser from './layers';

type Parser = (layers: Layers) => {
  accepted: boolean;
  sentences: Sentence[];
  chart: Chart;
  charts: Charts;
  nodes: Nodes;
};

const getParser = (nodes: Nodes, bpm: number): Parser => {
  const layersParser = getLayersParser(nodes, bpm);
  const parser: Parser = (layers) => {
    const groups = {};
    const idxs = {};

    layers.forEach((l, idx) => {
      const g = l.group ?? 0;

      groups[g] = [...(groups[g] ?? []), l];
      idxs[g] = [...(idxs[g] ?? []), idx];
    });

    const grouped = Object.keys(groups).reduce(
      (acc, g) => ({ ...acc, [g]: layersParser(groups[g]) }),
      {}
    );
    const sentences = Array(layers.length);
    const charts = {};
    const ns = {};
    let accepted = true;
    let chart = [] as Chart;

    Object.keys(groups).forEach((g) => {
      accepted = accepted && grouped[g].accepted;
      idxs[g].forEach((lIdx, idx) => {
        sentences[lIdx] = grouped[g].sentences[idx];
      });
      chart.push(...grouped[g].chart);
      charts[g] = grouped[g].chart;
      grouped[g].nodes.forEach((n) => {
        ns[n.id] = n;
      });
    });
    chart = chart.sort((a, b) => a.start - b.start);

    return { accepted, sentences, chart, charts, nodes: Object.values(ns) };
  };

  return parser;
};

export default getParser;
