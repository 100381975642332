import React, { ReactElement, useState, useEffect } from 'react';

import { FONT_SIZES } from '~/constants/typography';
import Gap from '~/components/flow/Gap';
import Break from '~/components/flow/Break';

import { Charts, Nodes } from '../../models';
import { BEAT } from '../../constants/defaults';
import { readNodes } from './services';
import Script from '../../components/Script';
import Controls from './components/Controls';
import Visualize from '../../components/Visualize';
import Help from './components/Help';
import Finish from './components/Finish';

function Write(): ReactElement {
  const [nodes, setNodes] = useState<Nodes>();
  const [beat, setBeat] = useState(BEAT);
  const [charts, setCharts] = useState<Charts>({});
  const [progress, setProgress] = useState(0);
  const state = nodes ? 'ready' : 'loading';

  useEffect(() => {
    (async () => setNodes(await readNodes()))();
  }, []);

  // Styling
  const safeWidth = '70ch';
  const writeStyle = {
    minWidth: safeWidth,
    fontSize: FONT_SIZES.text,
  };
  const containerStyle = {
    width: `max(${safeWidth}, 100vw)`,
    textAlign: 'center' as const,
  };

  // Example
  // TODO: remove this as well as props passed below in future versions
  const [example, setExample] = useState(BEAT);

  return (
    <div style={writeStyle}>
      <div style={containerStyle}>
        <Help nodes={nodes} />
        <Break />
        <Visualize
          beat={beat}
          charts={charts}
          state={state}
          progress={progress}
        />
        <Gap />
        <Controls
          beat={beat}
          setBeat={setBeat}
          charts={charts}
          setProgress={setProgress}
          example={example}
        />
        <Break />
        <Script
          nodes={nodes}
          beat={beat}
          setBeat={setBeat}
          setCharts={setCharts}
          example={example}
        />
        <Break />
        <Finish setBeat={setBeat} disabled={!nodes} setExample={setExample} />
      </div>
    </div>
  );
}

export default Write;
