import { AnimationControls } from 'framer-motion';

import COLORS from '~/constants/colors';

import { FeedbackEntity } from './models';
import { Dim, Pos } from '../predicates/models';
import Feedback from '../renderers/Feedback';
import feedbackPredicate from '../predicates/feedbackPredicate';

const feedbackEntity = (
  windowDims: Dim,
  pos: Pos,
  rating: string,
  animate: AnimationControls,
  ratingColor = COLORS.gold,
  statColor = COLORS.white
): FeedbackEntity => ({
  ...feedbackPredicate(
    windowDims,
    pos,
    rating,
    animate,
    ratingColor,
    statColor
  ),
  renderer: Feedback,
});

export default feedbackEntity;
