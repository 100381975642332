import PATHS from '~/constants/paths';

export const BEATBOXDB_PATH = PATHS.beatboxDb;

export default {
  home: '/',
  explore: 'explore',
  manage: 'manage',
  bounties: 'bounties',
  records: 'records',
  profile: '@:handle',
  search: 'search',
  about: 'about',
  unauthed: 'unauthed',
};

// Paths work relatively
export const EXPLORE_PATHS = {
  map: 'map',
  list: 'list',
  listCategory: ':categoryLabel',
  study: 'study',
  studyDifficulty: ':techniqueDifficulty',
  technique: 'techniques/:techniqueId',
  demo: 'demos/:demoId',
  tutorial: 'tutorials/:tutorialId',
};

export const MANAGE_PATHS = {
  categories: '/categories',
  category: ':categoryId',
  techniques: '/techniques',
  technique: ':techniqueId',
  demos: '/demos',
  demo: ':demoId',
  tutorials: '/tutorials',
  tutorial: ':tutorialId',
};

export const BOUNTIES_PATHS = {
  techniques: '/techniques',
  demos: '/demos',
  tutorials: '/tutorials',
};

export const RECORDS_PATHS = {
  learning: '/learning',
  grinding: '/grinding',
  satisfied: '/satisfied',
  archived: '/archived',
};
