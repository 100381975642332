import PATHS from '~/constants/paths';

export const BEATBLOX_PATH = PATHS.beatblox;

export default {
  home: '/',
  write: 'write',
  read: ':code',
  about: 'about',
};
