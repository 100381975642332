import urls from '~/urls';

export default {
  login: `${urls.auth}/local/login`,
  register: `${urls.auth}/local/register`,
  refresh: `${urls.auth}/refresh`,
  revoke: `${urls.auth}/revoke`,
  me: `${urls.auth}/me`,
  changePassword: `${urls.auth}/change-password`,
};
