import { isMobile } from 'react-device-detect';

import COLORS from '~/constants/colors';

// Input pad
export const INPUT_MODES = {
  key: 'key',
  jog: 'jog',
  gym: 'gym',
};

// Input pad
const jogPanelW = (wW) => Math.min(wW / (isMobile ? 4 : 6), 75);
const jogPanelH = (wH) => Math.min(wH / (isMobile ? 9 : 10), 75);
const compJogDims = (wDims) => jogPanelW(wDims.w) < jogPanelH(wDims.h);
const minJogDim = (wDims) => Math.min(jogPanelW(wDims.w), jogPanelH(wDims.h));
const gymPanelRatio = 1.4;
const gymPanelW = (wW) => Math.min(wW / (isMobile ? 5 : 6), 105);
const gymPanelH = (wH) => Math.min(wH / (isMobile ? 13 : 14.5), 75);
const compGymDims = (wDims) => gymPanelW(wDims.w) < gymPanelH(wDims.h);
const minGymDim = (wDims) => Math.min(gymPanelW(wDims.w), gymPanelH(wDims.h));
const panelB = (dim, d) => Math.min(dim / d, 3);
export const PANEL_DIMS = (wDims) => ({
  key: {
    h: minGymDim(wDims),
    b: panelB(minGymDim(wDims), compGymDims(wDims) ? 35 : 25),
    m: panelB(minGymDim(wDims), compGymDims(wDims) ? 35 : 25),
  },
  jog: {
    w: minJogDim(wDims),
    h: minJogDim(wDims),
    b: panelB(minJogDim(wDims), compJogDims(wDims) ? 35 : 25),
    m: panelB(minJogDim(wDims), compJogDims(wDims) ? 35 : 25),
  },
  gym: {
    w: compGymDims(wDims)
      ? gymPanelW(wDims.w)
      : gymPanelH(wDims.h) * gymPanelRatio,
    h: compGymDims(wDims)
      ? gymPanelW(wDims.w) / gymPanelRatio
      : gymPanelH(wDims.h),
    b: panelB(minGymDim(wDims), compGymDims(wDims) ? 35 : 25),
    m: panelB(minGymDim(wDims), compGymDims(wDims) ? 35 : 25),
  },
});

// Notes
export const NOTE_DIMS = (wDims) => ({
  w: minGymDim(wDims),
  h: minGymDim(wDims),
  b: panelB(minGymDim(wDims), compGymDims(wDims) ? 35 : 25),
});
export const NOTE_KEYS = {
  center: 'center',
  lateral: 'lateral',
  medial: 'medial',
  special: 'special',
  perfect: 'perfect',
  good: 'good',
  okay: 'okay',
  bad: 'bad',
  awful: 'awful',
  pre: 'pre',
  miss: 'miss',
  wrong: 'wrong',
};
export const THRESHOLD_COLORS = {
  perfect: COLORS.gold,
  good: COLORS.wildWillow,
  okay: COLORS.swampGreen,
  bad: COLORS.gothic,
  awful: COLORS.horizon,
  pre: COLORS.white,
  miss: COLORS.doveGray,
};
const getNoteBoxShadow = (wDims, c = COLORS.black25, d = 10) => {
  const matches = c.match(/\w\w/g) ?? [];
  const [r, g, b, a] = matches.map((v) => parseInt(v, 16));
  const color = `rgba(${r}, ${g}, ${b}, ${a ? a / 255 : 1})`;

  return `0 0 ${Math.round(NOTE_DIMS(wDims).w / d)}px 0 ${color}`;
};
export const NOTE_THEMES = (wDims) => ({
  center: {
    color: COLORS.black,
    backgroundImage: `radial-gradient(${COLORS.yellowGreen} 80%, ${COLORS.white} 80%)`,
    backgroundColor: COLORS.yellowGreen,
    boxShadow: getNoteBoxShadow(wDims),
  },
  lateral: {
    color: COLORS.black,
    backgroundImage: `linear-gradient(135deg, ${COLORS.white} 10%, ${COLORS.hotPink} 10%, ${COLORS.hotPink} 90%, ${COLORS.white} 90%)`,
    backgroundColor: COLORS.hotPink,
    boxShadow: getNoteBoxShadow(wDims),
  },
  medial: {
    color: COLORS.black,
    backgroundImage: `linear-gradient(225deg, ${COLORS.white} 10%, ${COLORS.malibu} 10%, ${COLORS.malibu} 90%, ${COLORS.white} 90%)`,
    backgroundColor: COLORS.malibu,
    boxShadow: getNoteBoxShadow(wDims),
  },
  special: {
    color: COLORS.white,
    backgroundImage: `radial-gradient(${COLORS.mineShaft} 60%, ${COLORS.codGray} 60%, ${COLORS.codGray} 80%, ${COLORS.black} 80%)`,
    backgroundColor: COLORS.codGray,
    boxShadow: getNoteBoxShadow(wDims, COLORS.white25),
  },
  // Threshold states
  perfect: {
    color: THRESHOLD_COLORS.perfect,
    backgroundImage: undefined,
    backgroundColor: THRESHOLD_COLORS.perfect,
    boxShadow: getNoteBoxShadow(wDims, THRESHOLD_COLORS.perfect, 4),
  },
  good: {
    color: THRESHOLD_COLORS.good,
    backgroundImage: undefined,
    backgroundColor: THRESHOLD_COLORS.good,
    boxShadow: getNoteBoxShadow(wDims, THRESHOLD_COLORS.good, 4),
  },
  okay: {
    color: THRESHOLD_COLORS.okay,
    backgroundImage: undefined,
    backgroundColor: THRESHOLD_COLORS.okay,
    boxShadow: getNoteBoxShadow(wDims, THRESHOLD_COLORS.okay, 4),
  },
  bad: {
    color: THRESHOLD_COLORS.bad,
    backgroundImage: undefined,
    backgroundColor: THRESHOLD_COLORS.bad,
    boxShadow: getNoteBoxShadow(wDims, THRESHOLD_COLORS.bad, 4),
  },
  awful: {
    color: THRESHOLD_COLORS.awful,
    backgroundImage: undefined,
    backgroundColor: THRESHOLD_COLORS.awful,
    boxShadow: getNoteBoxShadow(wDims, THRESHOLD_COLORS.awful, 4),
  },
  pre: {
    color: THRESHOLD_COLORS.pre,
    backgroundImage: undefined,
    backgroundColor: THRESHOLD_COLORS.pre,
    boxShadow: getNoteBoxShadow(wDims, THRESHOLD_COLORS.pre, 4),
  },
  // Error states
  miss: {
    color: THRESHOLD_COLORS.miss,
    backgroundImage: undefined,
    backgroundColor: COLORS.alto,
    boxShadow: getNoteBoxShadow(wDims),
  },
  wrong: {
    color: COLORS.peanut,
    backgroundImage: undefined,
    backgroundColor: COLORS.mandy,
    boxShadow: getNoteBoxShadow(wDims, COLORS.mandy, 4),
  },
});

// Receptor
export const RECEPTOR_DIMS = (wDims) => {
  const panelDims = PANEL_DIMS(wDims);
  const spacedHeight = NOTE_DIMS(wDims).h + 4 * panelDims.jog.b;

  return {
    w: 3 * spacedHeight,
    h: spacedHeight,
    b: 2 * panelDims.jog.b,
  };
};
export const LATERAL_OFFSET = (wDims) => {
  const panelDims = PANEL_DIMS(wDims);

  return panelDims.gym.w + 2 * (panelDims.gym.b + panelDims.gym.m);
};
export const RECEPTOR_VARIANTS = {
  reset: 'reset',
  wrong: 'wrong',
  combo: 'combo',
  special: 'special',
};

// Bridges
export const BRIDGE_WIDTH = (wDims) => NOTE_DIMS(wDims).w * 0.3;

// Bars
export const BAR_VARIANTS = {
  reset: 'reset',
  fadeIn: 'fadeIn',
  fadeOut: 'fadeOut',
};

// Feedback
export const FEEDBACK_VARIANTS = {
  reset: 'reset',
  show: 'show',
};
export const FEEDBACK_DIMS = (wDims) => {
  const noteDims = NOTE_DIMS(wDims);

  return {
    w: 2 * noteDims.w,
    h: 1.23 * noteDims.h,
  };
};

// Metrics
export const METRICS_DIMS = (wDims) => {
  const noteDims = NOTE_DIMS(wDims);
  const safeW = (wDims.w - RECEPTOR_DIMS(wDims).w / 2) / 2;

  return {
    w: safeW,
    h: noteDims.h,
    b: noteDims.h / 8,
    m: Math.ceil(Math.min(safeW, noteDims.h) / 11),
  };
};

// Exit
export const EXIT_DIMS = (wDims) => {
  const noteDims = NOTE_DIMS(wDims);

  return {
    w: noteDims.w,
    h: noteDims.w,
    b: noteDims.w / 1.5,
    m: noteDims.w / 4,
  };
};
