import React, { ReactElement, PropsWithChildren, CSSProperties } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import urls from '~/urls';
import Link from '~/components/flow/Link';

import whiteClouds from './assets/white-clouds.svg';
import solitudeClouds from './assets/solitude-clouds.svg';
import stars from './assets/stars.svg';

interface NightSkyProps {
  style?: CSSProperties;
}

function NightSky({
  style,
  children,
}: PropsWithChildren<NightSkyProps>): ReactElement {
  const nightSkyStyle = {
    display: 'table',
    width: '100%',
    height: '100vh',
    backgroundImage: `linear-gradient(${COLORS.luckyPoint} 0%, ${COLORS.bayOfMany} 40%, ${COLORS.danube} 70%, ${COLORS.tropicalBlue} 85%)`,
    ...style,
  };
  const containerStyle = {
    display: 'table-row',
    height: 1,
    verticalAlign: 'bottom',
    fontSize: FONT_SIZES.subtext,
    textAlign: 'right' as const,
  };
  const starsStyle = {
    height: 150,
    backgroundImage: `url(${stars})`,
    backgroundRepeat: 'repeat-x',
    backgroundPosition: '0 13px',
    marginBottom: -150,
  };
  const creditStyle = {
    color: COLORS.cornflower,
    marginBottom: '1.5em',
    marginRight: '1.5em',
  };
  const whiteCloudsStyle = {
    height: 126,
    marginBottom: '-3.6em',
    backgroundImage: `url(${whiteClouds})`,
    backgroundRepeat: 'repeat-x',
  };
  const cloudCoverStyle = {
    width: '100%',
    height: 61,
    marginBottom: -126,
    backgroundColor: COLORS.white,
  };
  const solitudeCloudsStyle = {
    ...whiteCloudsStyle,
    height: 183,
    marginBottom: -61,
    backgroundImage: `url(${solitudeClouds})`,
    backgroundPosition: '1.5em',
  };

  return (
    <div style={nightSkyStyle} id="night-sky">
      <div style={containerStyle}>
        <div style={starsStyle} />
      </div>
      {children}
      <div style={containerStyle}>
        <div style={solitudeCloudsStyle} />
        <div style={cloudCoverStyle} />
        <div style={whiteCloudsStyle} />
        <p style={creditStyle}>
          Designed by <Link href={urls.richardLinkedIn}>Richard Hsu</Link>
        </p>
      </div>
    </div>
  );
}

export default NightSky;
