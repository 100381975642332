import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import FramedView from '~/components/FramedView';
import Title from '~/components/titles/Title';
import Divider from '~/components/flow/Divider';
import urls from '~/urls';
import Link from '~/components/flow/Link';

import TeamMember from '../components/TeamMember';
import kindred from '../assets/kindred.jpg';
import kalapuikko from '../assets/kalapuikko.png';
import dismay from '../assets/dismay.png';

function About(): ReactElement {
  // Styling
  const aboutStyle = {
    width: 'min(45em, 82.5vw)',
  };
  const frameColors: [string, string] = [
    COLORS.waikawaGray,
    COLORS.waikawaGray,
  ];
  const teamMemberWidth = 130;
  const teamMemberStyle = {
    width: '33%',
    minWidth: `calc(${teamMemberWidth}px + 5%)`,
    marginTop: '1em',
  };

  return (
    <FramedView accent colors={frameColors} style={aboutStyle}>
      <Title>About</Title>
      <Divider />
      Beatboxing is awesome but learning it is difficult. There&apos;s too many
      techniques, nothing is named properly, and who knows when a random noise
      can become something legit. Beatbox Database aims to provide a community
      driven source of truth for all the discovered sounds and combos while
      letting beatboxers keep track of everything they&apos;re learning. Whether
      you practice singing, whistling, ventriloquism, konnakol, or any other
      type of vocal system performance, if you have a list of sounds to share or
      want to contribute something new, I hope you&apos;ll{' '}
      <Link href={urls.discordInvite}>join us</Link> in chasing after a complete
      knowledge of beatboxing.
      <br />
      <TeamMember
        src={kindred}
        name="Kindred"
        link={urls.discordInvite}
        width={teamMemberWidth}
        style={teamMemberStyle}
      />
      <TeamMember
        src={kalapuikko}
        name="Kalapuikko"
        link={urls.discordInvite}
        width={teamMemberWidth}
        style={teamMemberStyle}
      />
      <TeamMember
        src={dismay}
        name="DISMAY"
        link={urls.discordInvite}
        width={teamMemberWidth}
        style={teamMemberStyle}
      />
      <br />
    </FramedView>
  );
}

export default About;
