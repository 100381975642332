import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';

const instructionsStyle = {
  marginTop: 'max(1%, 0.7em)',
  marginBottom: '0.9em',
  color: COLORS.white,
  fontSize: FONT_SIZES.action,
};

function Instructions(): ReactElement {
  return <div style={instructionsStyle}>Tap or use arrow keys to play</div>;
}

export default Instructions;
