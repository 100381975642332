import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  CSSProperties,
  ReactElement,
  useState,
  useEffect,
  forwardRef,
  ForwardedRef,
  FocusEvent,
  MouseEvent,
} from 'react';

import { stopEventEffects } from '~/utils';
import COLORS from '~/constants/colors';
import VARIANTS from '~/constants/variants';
import { FONT_SIZES } from '~/constants/typography';
import { Theme } from '~/models';

const THEME = {
  neutral: {
    default: {
      color: COLORS.black,
      backgroundColor: COLORS.white,
      borderColor: COLORS.alto,
    },
    hover: {
      borderColor: COLORS.chetwodeBlue,
    },
    active: {
      borderColor: COLORS.dodgerBlue,
    },
  },
  disabled: {
    default: {
      color: COLORS.silverChalice,
      backgroundColor: COLORS.alabaster,
      borderColor: COLORS.alto,
    },
  },
} as Theme;

interface TextInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  variant?: VARIANTS;
  onMouseOver?: (event: MouseEvent) => void;
  onFocus?: (event: FocusEvent) => void;
  onBlur?: (event: FocusEvent) => void;
  disabled?: boolean;
  spacer?: ReactElement;
  style?: CSSProperties;
}

function TextInput({
  variant = VARIANTS.neutral,
  onMouseOver,
  onFocus,
  onBlur,
  disabled = false,
  spacer,
  style,
  ...props
}: TextInputProps): ReactElement {
  const states = disabled ? THEME.disabled : THEME[variant];
  const { color, ...defaultRest } = states.default;

  // Styling
  const disabledProps = disabled && {
    disabled,
    placeholder: props.placeholder ?? 'Input disabled',
  };
  const outerStyle = {
    display: spacer ? 'inline-flex' : 'inline-block',
    padding: '0.4em 0.4em',
    borderRadius: '0.3em',
    borderStyle: 'solid',
    borderWidth: 'thin',
    backgroundClip: 'padding-box',
    ...defaultRest,
  };
  const outerPropsStyle = Object.keys(style ?? {}).reduce(
    (acc, k) => (outerStyle[k] ? { ...acc, [k]: style?.[k] } : acc),
    {}
  );
  const innerPropsStyle = Object.keys(style ?? {}).reduce(
    (acc, k) => (!outerStyle[k] ? { ...acc, [k]: style?.[k] } : acc),
    {}
  );
  const innerStyle = {
    fontSize: FONT_SIZES.text,
    borderRadius: style?.borderRadius ?? outerStyle.borderRadius,
    borderStyle: 'none',
    outline: 'none',
    backgroundColor: COLORS.transparent,
    color: style?.color ?? color,
    ...(spacer && { flexGrow: 2, ...innerPropsStyle }),
  };
  const getTIStyle = (state) => ({
    ...(!spacer && innerStyle),
    ...outerStyle,
    ...(state.borderColor && { borderColor: state.borderColor }),
    ...(spacer ? { flexDirection: 'row' as const, ...outerPropsStyle } : style),
  });
  const [tIStyle, setTIStyle] = useState(getTIStyle(states.default));

  // Event handlers
  const [focused, setFocused] = useState(false);
  const handleMouseOver = (event) => {
    stopEventEffects(event);
    if (!disabled) {
      onMouseOver?.(event);
      if (!focused && states.hover) setTIStyle(getTIStyle(states.hover));
    }
  };
  const handleMouseLeave = (event) => {
    stopEventEffects(event);
    if (!disabled && !focused) setTIStyle(getTIStyle(states.default));
  };
  const handleFocus = (event) => {
    stopEventEffects(event);
    if (!disabled) {
      setFocused(true);
      onFocus?.(event);
      if (states.active) setTIStyle(getTIStyle(states.active));
    }
  };
  const handleBlur = (event) => {
    stopEventEffects(event);
    if (!disabled) {
      setFocused(false);
      onBlur?.(event);
      setTIStyle(getTIStyle(states.default));
    }
  };

  useEffect(() => setTIStyle(getTIStyle(states.default)), [variant, disabled]);

  return spacer ? (
    <div
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={tIStyle}
    >
      <input style={innerStyle} {...disabledProps} {...props} />
      {spacer}
    </div>
  ) : (
    <input
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={tIStyle}
      {...disabledProps}
      {...props}
    />
  );
}

export default forwardRef(
  (props: TextInputProps, ref: ForwardedRef<HTMLInputElement>) =>
    TextInput({ ...props, ref })
);
