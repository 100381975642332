import React, { ReactElement } from 'react';

import { Chart } from '../../../models';
import { State } from '../models';
import { MARKS } from '../../../constants/language';
import { getPx } from '../../../utils';
// TODO: make sure styles of components below match entity renderers in `Read`
import Bar from './Bar';
import Block from './Block';
import Carets from './Carets';
import Empty from './Empty';

interface MonitorProps {
  chart?: Chart;
  mpb: number;
  maxTiming: number;
  count: number;
  state?: State;
  progress?: number;
  group?: number;
}

function Monitor({
  chart = [],
  mpb,
  maxTiming,
  count,
  state,
  progress = 0,
  group,
}: MonitorProps): ReactElement {
  let safeChart = chart.filter((n) => n.node.notation !== MARKS.caret);
  const getBarKey = (idx) => `BeatbloxVisualizeMonitorBar${idx}`;
  const getBlockKey = (idx) => `BeatbloxVisualizeMonitorBlock${idx}`;

  // Calculate notes
  safeChart = safeChart.filter((n) => n.node.notation !== MARKS.rest);

  const shift = mpb / 2;
  const maxTrack = Math.max(...safeChart.map((n) => n.track)) + 2;
  const progressShifted = progress + shift;

  safeChart = safeChart.map((n) => {
    const notation =
      n.node.notation.length > 5
        ? `${n.node.notation.slice(0, 5)}...`
        : n.node.notation;

    return {
      ...n,
      track: n.track + 1,
      start: n.start + shift,
      ...(n.end && { end: n.end + shift }),
      node: { ...n.node, notation },
    };
  });

  // Calculate bars
  const bars = [...Array(count).keys()].map((k) => ({
    timing: Number(k) * mpb + shift,
    fuzzy: Number(k) !== 0,
  }));

  // Calculate carets
  const carets = chart
    .filter((n) => n.node.notation === MARKS.caret)
    .map((n) => ({ ...n, start: n.start + shift }));

  // Styling
  const unitWidth = 108;
  const unitHeight = 30;
  const noteWidth = 25;
  const noteHeight = 25;
  const base = 6;
  const ratio = 0.8;
  const width = count * unitWidth;
  const height = Math.max(maxTrack, 2) * unitHeight;
  const maxPaddedTiming = maxTiming + mpb;
  const monitorStyle = {
    maxWidth: 'max(100vw, 100%)',
    height: getPx(height + 2 * base * ratio),
    overflowX: 'scroll' as const,
  };
  const containerStyle = {
    display: 'inline-block',
    position: 'relative' as const,
    width,
    height,
    marginTop: getPx(base * ratio),
  };

  return (
    <div style={monitorStyle}>
      <div style={containerStyle}>
        {safeChart.length === 0 && count < 2 && (
          <Empty height={noteHeight} state={state} />
        )}
        {bars.map((b, idx) => (
          <Bar
            key={getBarKey(idx)}
            height={height}
            timing={b.timing}
            maxTiming={maxPaddedTiming}
            fuzzy={b.fuzzy}
            progress={progressShifted}
            group={group}
          />
        ))}
        {safeChart.map((n, idx) => (
          <Block
            key={getBlockKey(idx)}
            width={noteWidth}
            height={noteHeight}
            track={n.track}
            maxTrack={maxTrack}
            start={n.start}
            end={n.end}
            maxTiming={maxPaddedTiming}
            node={n.node}
            progress={progressShifted}
            group={group}
          />
        ))}
        <Carets
          height={height}
          base={base}
          ratio={ratio}
          start={carets[0]?.start}
          end={carets[1]?.start}
          maxTiming={maxPaddedTiming}
        />
      </div>
    </div>
  );
}

export default Monitor;
