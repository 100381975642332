import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  ReactElement,
  useState,
  useEffect,
  CSSProperties,
} from 'react';

import COLORS from '~/constants/colors';
import VARIANTS from '~/constants/variants';
import { Theme } from '~/models';
import { FONT_SIZES } from '~/constants/typography';
import { stopEventEffects } from '~/utils';

const THEME = {
  neutral: {
    default: {
      color: COLORS.black,
      backgroundColor: COLORS.white,
      borderColor: COLORS.alto,
    },
    hover: {
      color: COLORS.chetwodeBlue,
      borderColor: COLORS.chetwodeBlue,
    },
    active: {
      color: COLORS.indigo,
      borderColor: COLORS.dodgerBlue,
    },
  },
  primary: {
    default: {
      color: COLORS.white,
      backgroundColor: COLORS.dodgerBlue,
      borderColor: COLORS.dodgerBlue,
    },
    active: {
      color: COLORS.alabaster,
      backgroundColor: COLORS.waikawaGray,
      borderColor: COLORS.waikawaGray,
    },
    loading: {
      color: COLORS.gallery,
      backgroundColor: COLORS.waikawaGray,
      borderColor: COLORS.waikawaGray,
    },
  },
  secondary: {
    default: {
      color: COLORS.cornflower,
      backgroundColor: COLORS.white25,
      borderColor: COLORS.cornflower,
    },
  },
  warning: {
    default: {
      color: COLORS.black,
      backgroundColor: COLORS.kournikova,
      borderColor: COLORS.kournikova,
    },
  },
  fail: {
    default: {
      color: COLORS.white,
      backgroundColor: COLORS.mandy,
      borderColor: COLORS.mandy,
    },
  },
  success: {
    default: {
      color: COLORS.white,
      backgroundColor: COLORS.wildWillow,
      borderColor: COLORS.wildWillow,
    },
  },
  disabled: {
    default: {
      color: COLORS.silverChalice,
      backgroundColor: COLORS.alabaster,
      borderColor: COLORS.alabaster,
    },
  },
} as Theme;

interface ButtonProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label: string;
  loadingLabel?: string;
  variant?: VARIANTS;
  width?: string;
  callback?: (event: Event) => void | Promise<void>;
  loading?: boolean;
  disabled?: boolean;
  ignoreDisabledStyle?: boolean;
  style?: CSSProperties;
  disabledStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  disabledLabelStyle?: CSSProperties;
}

function Button({
  label,
  loadingLabel = '...',
  variant = VARIANTS.primary,
  width,
  callback,
  loading = false,
  disabled = false,
  ignoreDisabledStyle = false,
  style,
  disabledStyle,
  labelStyle, // Set label `color` in `style` not here
  disabledLabelStyle,
  ...props
}: ButtonProps): ReactElement {
  const useDisabledStyle = disabled && !ignoreDisabledStyle;
  const states = THEME[variant];

  // Styling
  const getBStyle = (state) => ({
    display: 'inline-block',
    width,
    padding: '0.6em',
    fontSize: FONT_SIZES.action,
    borderRadius: '0.5em',
    borderStyle: 'solid',
    borderWidth: 'thin',
    cursor: loading || disabled ? 'default' : 'pointer',
    boxShadow: `-0.1em 0.1em ${COLORS.black25}`,
    ...THEME.neutral.default,
    ...state,
    ...style,
    ...(useDisabledStyle && {
      ...THEME.disabled.default,
      ...disabledStyle,
    }),
  });
  const lStyle = {
    ...labelStyle,
    ...(useDisabledStyle && disabledLabelStyle),
  };
  const [bStyle, setBStyle] = useState(getBStyle(states.default));

  // Event handlers
  const handleMouseEnter = async (event) => {
    stopEventEffects(event);
    if (!loading && !disabled && states.hover)
      setBStyle(getBStyle(states.hover));
  };
  const handleMouseLeave = async (event) => {
    stopEventEffects(event);
    if (!loading && !disabled) setBStyle(getBStyle(states.default));
  };
  const handleMouseUp = async (event) => {
    stopEventEffects(event);
    if (!loading && !disabled) {
      await callback?.(event);
      setBStyle(getBStyle(states.hover ?? states.default));
    }
  };
  const handleTouchEnd = async (event) => {
    if (callback) stopEventEffects(event);
    if (!loading && !disabled) {
      await callback?.(event);
      setBStyle(getBStyle(states.default));
    }
  };
  const handleActive = async () => {
    if (!loading && !disabled && states.active)
      setBStyle(getBStyle(states.active));
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted)
      setBStyle(getBStyle((loading && states.loading) || states.default));

    return () => {
      isMounted = false;
    };
  }, [variant, loading, disabled, style]);

  return (
    <button
      type="button"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleActive}
      onMouseUp={handleMouseUp}
      onTouchStart={handleActive}
      onTouchEnd={handleTouchEnd}
      disabled={disabled}
      style={bStyle}
      {...props}
    >
      <b style={lStyle}>{(loading && loadingLabel) || label}</b>
    </button>
  );
}

export default Button;
