import React, { ReactElement, CSSProperties } from 'react';

import COLORS from '~/constants/colors';

interface PromoProps {
  label: string;
  callback?: (event: Event) => void;
  style?: CSSProperties;
}

function Promo({ label, callback, style }: PromoProps): ReactElement {
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    callback?.(event);
  };

  // Styling
  const promoStyle = {
    display: 'inline-block',
    width: '1.5em',
    margin: '0 0.25em 0 0',
    padding: '0.3em 0.1em',
    textAlign: 'center' as const,
    backgroundColor: COLORS.white,
    borderRadius: '0.3em',
    boxShadow: `-0.1em 0.1em ${COLORS.black25}`,
    fontSize: '1.25em',
    cursor: 'pointer',
    ...style,
  };

  return (
    <p role="none" style={promoStyle} onClick={handleClick}>
      {label}
    </p>
  );
}

export default Promo;
