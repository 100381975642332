import React, { useRef, useEffect, useState } from 'react';

import { stopEventEffects } from '~/utils';

import { MARKS } from '../../../constants/language';
import { useContext } from '../providers/Content';
import { cleanCarets } from '../../../utils';
import Line from './Line';
import Add from './Add';

const KEY_CODES = {
  enter: 'Enter',
  backspace: 'Backspace',
};

function Manager() {
  const { state, setPatterns, setGroups, setMuted } = useContext();
  const { patterns, groups, muted, disabled } = state;
  const [focusIdx, setFocusIdx] = useState(-1);
  const linesContainerRef = useRef<HTMLDivElement>(null);
  const insertCaret = (script, idx) =>
    `${script.slice(0, idx)}${MARKS.caret}${script.slice(idx)}`;
  const getLines = () => Array.from(linesContainerRef.current?.children ?? []);
  const getInput = (e?) => e?.getElementsByTagName('input')[0];
  const getLineKey = (idx) => `BeatbloxScriptManagerLine${idx}`;

  // Handlers
  const addPattern = (event) => {
    stopEventEffects(event);
    setPatterns([...patterns, '']);
    setGroups([...groups, 0]);
    setMuted([...muted, false]);
    setFocusIdx(patterns.length);
  };
  const getRemovePattern = (idx) => (event) => {
    const nextIdx = Math.max(idx - 1, 0);
    const newPatterns = patterns.filter((p, pIdx) => pIdx !== idx);

    if (!newPatterns[nextIdx]) newPatterns[nextIdx] = MARKS.caret;
    stopEventEffects(event);
    setPatterns(newPatterns);
    setGroups(groups.filter((g, gIdx) => gIdx !== idx));
    setMuted(muted.filter((m, mIdx) => mIdx !== idx));
    setFocusIdx(nextIdx);
  };
  const getHandleKeyDown = (idx, length) => (event) => {
    if (!disabled && !event.repeat) {
      if (event.code === KEY_CODES.enter) addPattern(event);
      else if (
        event.code === KEY_CODES.backspace &&
        !event.target.value &&
        length > 1
      )
        getRemovePattern(idx)(event);
    }
  };
  const handleChange = (event) => {
    const inputs = getLines().map(getInput);
    const newPatterns = inputs
      .map((i) => i.value)
      .map((v, idx) => {
        let pattern = v;

        if (document.activeElement === inputs[idx]) {
          const { selectionStart, selectionEnd } = inputs[idx];

          pattern = insertCaret(cleanCarets(v), selectionStart);
          if (selectionEnd && selectionStart !== selectionEnd)
            pattern = insertCaret(pattern, selectionEnd + 1);
        }

        return pattern;
      });

    stopEventEffects(event);
    setPatterns(newPatterns);
  };

  useEffect(() => {
    (async () => {
      document.addEventListener('selectionchange', handleChange); // TODO: check this approach works with mobile

      return () =>
        document.removeEventListener('selectionchange', handleChange);
    })();
  }, []);
  useEffect(() => {
    if (focusIdx >= 0) {
      getInput(getLines()[focusIdx])?.focus();
      setFocusIdx(-1);
    }
  }, [focusIdx]);

  return (
    <div>
      <div ref={linesContainerRef}>
        {patterns.map((p, idx, { length }) => (
          <Line
            key={getLineKey(idx)}
            index={idx}
            value={p}
            onChange={handleChange}
            onKeyDown={getHandleKeyDown(idx, length)}
            onDelete={getRemovePattern(idx)}
          />
        ))}
      </div>
      <Add onCreate={addPattern} />
    </div>
  );
}

export default Manager;
