import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import { LocationState } from '~/models';
import PATHS from '~/constants/paths';
import Link from '~/components/flow/Link';

function Caption(): ReactElement {
  const { state } = useLocation() as { state: LocationState };

  // Styling
  const captionStyle = {
    textAlign: 'center' as const,
  };

  return (
    <div style={captionStyle}>
      Already have an account?{' '}
      <Link to={PATHS.login} state={state}>
        Log in
      </Link>
      !
    </div>
  );
}

export default Caption;
