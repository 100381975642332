import React, { Dispatch, SetStateAction, ReactElement } from 'react';

import Option from './Option';

interface SettingsProps {
  selections: boolean[];
  setSelections: Dispatch<SetStateAction<boolean>>[];
}

const SettingsStyle = {
  marginBottom: 'max(1.5%, 1.5em)',
};

function Settings({ selections, setSelections }: SettingsProps): ReactElement {
  const indices = [0, 1, 2, 3];
  const optionLabels = ['ひ', 'カ', '10', 'yō'];
  const getKey = (index) => `KanaMatchSettingsOption${index}`;

  return (
    <div style={SettingsStyle}>
      {indices.map((index) => (
        <Option
          key={getKey(index)}
          label={optionLabels[index]}
          index={index}
          selections={selections}
          setSelection={setSelections[index]}
        />
      ))}
    </div>
  );
}

export default Settings;
