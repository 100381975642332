import axios from 'axios';

import { BadRequest } from '~/models';
import { STATUS_CODES } from '~/constants/api';

export const getErrorHandler =
  (task: string) =>
  (error: Error): Promise<never> => {
    const hasMessage = Object.prototype.hasOwnProperty.call(error, 'message');
    let errorMessage = hasMessage ? error.message : error;

    if (axios.isAxiosError(error)) {
      if (error.response) errorMessage = error.response.data;
      else if (error.request) errorMessage = error.request;
    }
    console.log(`Failed ${task}:`);
    console.log(errorMessage);

    return Promise.reject(error);
  };

export const isBadRequest = (error: unknown): error is BadRequest =>
  axios.isAxiosError(error) &&
  error.response?.status === STATUS_CODES.badRequest;
