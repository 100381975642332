import React from 'react';

import { stopEventEffects } from '~/utils';
import { FONT_SIZES } from '~/constants/typography';

import { useContext } from '../providers/Content';

interface MuteProps {
  index: number;
}

function Mute({ index }: MuteProps) {
  const { state, setMuted } = useContext();
  const { muted } = state;

  // Handlers
  const toggleMute = (event) => {
    const newMuted = [...muted];

    newMuted[index] = !muted[index];
    stopEventEffects(event);
    setMuted(newMuted);
  };

  // Styling
  const muteStyle = {
    display: 'inline-block',
    margin: '0 0.3em 0 0.2em',
    fontSize: FONT_SIZES.header,
    transform: 'translate(0, 9.5%)',
    cursor: 'pointer',
  };

  return (
    <div
      role="button"
      tabIndex={-1}
      onMouseUp={toggleMute}
      onTouchEnd={toggleMute}
      style={muteStyle}
    >
      {muted[index] ? '🔇' : '🔈'}
    </div>
  );
}

export default Mute;
