import { getArrayBuffer, getAudioBufferFromArrayBuffer } from '~/helpers/media';

// Convert PCM data to WAV
const writeString = (view, offset, string) => {
  for (let i = 0; i < string.length; i += 1)
    view.setUint8(offset + i, string.charCodeAt(i));
};

const floatTo16BitPCM = (output, offset, input) => {
  // eslint-disable-next-line no-param-reassign
  for (let i = 0; i < input.length; i += 1, offset += 2) {
    const s = Math.max(-1, Math.min(1, input[i]));
    output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
};

const writeFloat32 = (output, offset, input) => {
  // eslint-disable-next-line no-param-reassign
  for (let i = 0; i < input.length; i += 1, offset += 4)
    output.setFloat32(offset, input[i], true);
};

const encodeWav = (samples, format, sampleRate, numberOfChannels, bitDepth) => {
  const bytesPerSample = bitDepth / 8;
  const blockAlign = numberOfChannels * bytesPerSample;
  const buffer = new ArrayBuffer(44 + samples.length * bytesPerSample);
  const view = new DataView(buffer);

  writeString(view, 0, 'RIFF');
  view.setUint32(4, 36 + samples.length * bytesPerSample, true);
  writeString(view, 8, 'WAVE');
  writeString(view, 12, 'fmt ');
  view.setUint32(16, 16, true);
  view.setUint16(20, format, true);
  view.setUint16(22, numberOfChannels, true);
  view.setUint32(24, sampleRate, true);
  view.setUint32(28, sampleRate * blockAlign, true);
  view.setUint16(32, blockAlign, true);
  view.setUint16(34, bitDepth, true);
  writeString(view, 36, 'data');
  view.setUint32(40, samples.length * bytesPerSample, true);
  if (format === 1) floatTo16BitPCM(view, 44, samples);
  else writeFloat32(view, 44, samples);

  return buffer;
};

const interleave = (leftInput, rightInput) => {
  const length = leftInput.length + rightInput.length;
  const result = new Float32Array(length);
  let index = 0;
  let inputIndex = 0;

  while (index < length) {
    result[(index += 1)] = leftInput[inputIndex];
    result[(index += 1)] = rightInput[inputIndex];
    inputIndex += 1;
  }

  return result;
};

export const getWavFromPcm = (
  data:
    | Float32Array
    | Int16Array
    | [Float32Array, Float32Array]
    | [Int16Array, Int16Array],
  sampleRate: number,
  options = { numberOfChannels: 1, float32: true }
): ArrayBuffer => {
  const { numberOfChannels } = options;
  const format = options.float32 ? 3 : 1;
  const bitDepth = format === 3 ? 32 : 16;
  const samples = numberOfChannels === 2 ? interleave(data[0], data[1]) : data;

  return encodeWav(samples, format, sampleRate, numberOfChannels, bitDepth);
};

// Convert Blob to PCM data
export const getPcmFromBlob = async (blob: Blob): Promise<Float32Array> => {
  const arrayBuffer = await getArrayBuffer(blob);
  const audioBuffer = await getAudioBufferFromArrayBuffer(arrayBuffer);

  return audioBuffer.getChannelData(0);
};
