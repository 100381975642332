import React, { ReactElement, PropsWithChildren, CSSProperties } from 'react';

import { FONT_SIZES } from '~/constants/typography';

interface SubheaderProps {
  style?: CSSProperties;
}

function Subheader({
  style,
  children,
}: PropsWithChildren<SubheaderProps>): ReactElement {
  const subheaderStyle = {
    display: 'block',
    margin: '0 0 0.25em',
    fontSize: FONT_SIZES.action,
    textAlign: 'left' as const,
    ...style,
  };

  return <span style={subheaderStyle}>{children}</span>;
}

export default Subheader;
