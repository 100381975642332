import Matter from 'matter-js';

import { System } from '../models';
import Bar, { BAR_MODES } from '../entities/Bar';
import { getNewX } from '../utils';
import { GARBAGE_THRESHOLD, TEMPO_NUMERATOR } from '../constants';

const tempo: System = (entities, { time }) => {
  const newEntities = entities;
  const { receptor, bars } = entities;
  const { gameDims, engine, settings, state } = entities.game;
  const { velocity, bpm, loops, chart } = settings;
  const { startTime, renderedBarIdx, renderedNoteIdx } = state;

  // Store start time
  if (startTime < 0) newEntities.game.state.startTime = time.current;

  // Delete old or move active bars
  const endX = receptor.getPos().x;
  const barKeys = Object.keys(bars);

  barKeys.forEach((key) => {
    const bar = bars[key];
    const oldX = bar.getPos().x;
    const newX = getNewX(endX, bar.timing, time.current, velocity);
    const translation = { x: newX - oldX, y: 0 };
    const isGarbage =
      bar.garbageTime > 0 && time.current - bar.garbageTime > GARBAGE_THRESHOLD;

    if (newX < endX) {
      if (isGarbage) {
        Matter.Composite.remove(engine.world, bar.body);
        delete newEntities.bars[key];
      } else {
        newEntities.bars[key].garbageTime = time.current;
        bar.mode = BAR_MODES.fadeOut;
      }
    } else Matter.Body.translate(bar.body, translation);
  });

  // Add new bars if needed
  const timeBetweenBars = TEMPO_NUMERATOR / bpm;
  const startX = gameDims.w;
  const courtTime = (startX - endX) / velocity;
  const nextBarIdx = renderedBarIdx + 1;
  const nextBarTiming =
    nextBarIdx * timeBetweenBars +
    (startTime < 0 ? time.current : startTime) +
    courtTime;
  const newBarX = Math.max(
    getNewX(endX, nextBarTiming, time.current, velocity),
    endX
  );

  if (newBarX <= startX) {
    const fuzzy =
      (renderedNoteIdx + 1) % chart.length !== 0 ||
      renderedNoteIdx >= chart.length * loops - 1;
    const newBar = Bar(gameDims, newBarX, nextBarTiming, fuzzy);

    newEntities.bars[nextBarIdx] = newBar;
    Matter.Composite.add(engine.world, [newBar.body]);
    newEntities.game.state.renderedBarIdx +=
      renderedBarIdx < 0 ? nextBarIdx + 1 : 1;
  }

  return newEntities;
};

export default tempo;
