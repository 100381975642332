import React, { ReactElement, CSSProperties, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import COLORS from '~/constants/colors';
import TextInput from '~/components/inputs/TextInput';
import MagnifyingGlass from '~/components/shapes/MagnifyingGlass';

import { QueryInfo } from './models';
import { DEFAULT_QUERY_INFO } from './constants';

interface SearchBarProps {
  callback: (queryInfo: QueryInfo) => void | Promise<void>;
  placeholder?: string;
  disabledPlaceholder?: string;
  buttonWidth?: string;
  buttonFill?: string;
  disabledButtonFill?: string;
  disabled?: boolean;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
}

function SearchBar({
  callback,
  placeholder = 'Search',
  disabledPlaceholder = 'Log in to search',
  buttonWidth = '1.25em',
  buttonFill = COLORS.malibu,
  disabledButtonFill = COLORS.black,
  disabled = false,
  style,
  inputStyle,
  buttonStyle,
}: SearchBarProps): ReactElement {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query') || '';

  // Form
  const defaultValues = { ...DEFAULT_QUERY_INFO, query };
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({ defaultValues });
  const queryOpts = {
    required: true,
    validate: (value) => /\S/.test(value),
  };

  // Handlers
  const onSubmit = async (queryInfo) => {
    if (!isSubmitting && !disabled) await callback(queryInfo);
  };

  // Styling
  const searchBarStyle = {
    ...style,
  };
  const magnifyingGlassStyle = {
    paddingLeft: '0.25em',
    verticalAlign: 'top',
    cursor: disabled ? 'default' : 'pointer',
    ...buttonStyle,
  };

  // Reset form when no query
  useEffect(() => {
    if (!query) reset(defaultValues);
  }, [query]);

  return (
    <form style={searchBarStyle} onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        {...register('query', queryOpts)}
        placeholder={disabled ? disabledPlaceholder : placeholder}
        disabled={disabled}
        spacer={
          <MagnifyingGlass
            width={buttonWidth}
            fill={disabled ? disabledButtonFill : buttonFill}
            onMouseUp={handleSubmit(onSubmit)}
            onTouchEnd={handleSubmit(onSubmit)}
            style={magnifyingGlassStyle}
          />
        }
        style={inputStyle}
      />
    </form>
  );
}

export default SearchBar;
