import Matter from 'matter-js';
import YouTube from 'react-youtube';

import { RecordSystem } from './models';
import { TYPES } from '../constants/events';
import noteEntity from '../entities/noteEntity';
import { RECEPTOR_VARIANTS } from '../constants/layout';

const recordInputSystem: RecordSystem = (entities, { events }) => {
  const newEntities = entities;
  const { engine, windowDims } = entities.physics;
  const { receptor } = entities;
  const { renderedNoteIdx, startTime, youTubePlayerState, videoTime } =
    entities.state;

  // Check game started and song still playing
  if (startTime < 0 || youTubePlayerState !== YouTube.PlayerState.PLAYING)
    return newEntities;

  // Detect input and add note with timing
  const attemptedRecord = events.find(
    (event) => event.type === TYPES.onRecordPadSend
  );

  if (attemptedRecord) {
    const startY = receptor.getPos().y;
    const nextNoteIdx = renderedNoteIdx + 1;
    const newNote = noteEntity(windowDims, 0, startY, '', '', videoTime);

    newEntities.notes[nextNoteIdx] = newNote;
    newEntities.state.results.timings.push(videoTime);
    Matter.Composite.add(engine.world, [newNote.body]);
    newEntities.state.renderedNoteIdx = nextNoteIdx;
    receptor.animates[1].start(RECEPTOR_VARIANTS.combo);
  }

  return newEntities;
};

export default recordInputSystem;
