/* eslint-disable import/prefer-default-export */
export const getDistortionCurve = (amount: number): Float32Array => {
  const distortionSamples = 512;
  const curve = new Float32Array(distortionSamples);

  for (let i = 0; i < distortionSamples; i += 1) {
    const x = (i * 2) / distortionSamples - 1;

    curve[i] = ((Math.PI + amount) * x) / (Math.PI + amount * Math.abs(x));
  }

  return curve;
};
