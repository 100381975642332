import HIRAGANA from './hiragana';
import KATAKANA from './katakana';

const maxLives = 3;

const kanaSets = [
  HIRAGANA.monographs,
  KATAKANA.monographs,
  { ...HIRAGANA.diacritics, ...KATAKANA.diacritics },
  { ...HIRAGANA.digraphs, ...KATAKANA.digraphs },
];

export { maxLives, kanaSets };
