import { chainMiddlewares } from '~/helpers/state';
import { Reducer } from '~/models';

import { State } from './models';
import { ACTIONS } from './actions';
import { FAKE_STATE } from './constants';

const startAuthed = false;
const INITIAL_STATE: State =
  startAuthed && process.env.NODE_ENV === 'development'
    ? FAKE_STATE
    : { isAuthed: false };

const reducer: Reducer<State> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.login:
      return { ...state, isAuthed: true, user: action.payload };
    case ACTIONS.logout:
      return { isAuthed: false };
    case ACTIONS.updateAccount:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default chainMiddlewares(reducer);
export { INITIAL_STATE };
