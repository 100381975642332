import { Dispatch, SetStateAction, RefObject } from 'react';
import { AnimationControls } from 'framer-motion';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import ARROWS from './constants/arrows';
import { maxLives, kanaSets } from './constants/game';
import { State, SetState, StopwatchRef, KanaSet, Problems } from './models';

// Problems
const shuffle = (arr: Array<any>): any[] => arr.sort(() => 0.5 - Math.random());

const getChoices = (
  index: number,
  isFlipped: boolean,
  kanas: string[],
  romajis: string[]
): string[] => {
  const values = isFlipped ? kanas : romajis;
  const targetValue = values[index];
  const targetRomaji = romajis[index];
  const filtered = values.filter((value, pos) => romajis[pos] !== targetRomaji);
  const uniques = [...new Set(filtered)];

  return shuffle(shuffle(uniques).slice(0, 3).concat([targetValue]));
};

const getProblems = (selections: boolean[], flip: number): Problems => {
  const mergeSets = (acc, curr, index) =>
    curr ? { ...acc, ...kanaSets[index] } : acc;
  const set: KanaSet = selections.reduce(mergeSets, {});
  const kanas = Object.keys(set);
  const romajis = Object.values(set);
  const order = shuffle([...Array(kanas.length).keys()]);
  const flips = order.map(() => Math.random() < flip);

  return {
    maxPoints: order.length,
    targets: order.map((i) => [
      flips[i] ? romajis[i] : kanas[i],
      flips[i] ? kanas[i] : romajis[i],
    ]),
    choicesList: order.map((i) => getChoices(i, flips[i], kanas, romajis)),
  };
};

// Stopwatch
const formatTime = (accumulator: number): string => {
  const inSeconds = Math.floor(accumulator / 100);
  const centiseconds = `0${accumulator % 100}`.slice(-2);
  const seconds = `0${inSeconds % 60}`.slice(-2);
  const minutes = `0${Math.floor(inSeconds / 60)}`.slice(-2);

  return `${minutes}:${seconds}:${centiseconds}`;
};

// Handlers
const limitCondition = (state: State): boolean =>
  state.lives > 0 &&
  state.score[0] < state.maxPoints &&
  state.targets.length > 0;

const resetReport = (setState: SetState): void => {
  setState.setScore([0, true]);
  setState.setMistakes([]);
};

const resetUnderneath = (
  newProblems: Problems,
  setState: SetState,
  choicesControls: AnimationControls[],
  stopwatchRef: RefObject<StopwatchRef>
): void => {
  if (stopwatchRef.current) stopwatchRef.current.reset();
  setState.setLives(maxLives);
  setState.setTargets(newProblems.targets);
  setState.setChoicesList(newProblems.choicesList);
  choicesControls.map((controls) => controls.start('reset'));
};

const resetGame = (
  newProblems: Problems,
  setState: SetState,
  choicesControls: AnimationControls[],
  stopwatchRef: RefObject<StopwatchRef>
): void => {
  resetReport(setState);
  resetUnderneath(newProblems, setState, choicesControls, stopwatchRef);
};

const closeEnding = (
  setState: SetState,
  setShowEnding: Dispatch<SetStateAction<boolean>>
): void => {
  resetReport(setState);
  setShowEnding(false);
  enableBodyScroll(document);
};

const getKeyDownHandler =
  (
    state: State,
    setState: SetState,
    targetControls: AnimationControls,
    choicesControls: AnimationControls[],
    stopwatchRef: RefObject<StopwatchRef>,
    showEnding: boolean,
    setShowEnding: Dispatch<SetStateAction<boolean>>
  ) =>
  (event: KeyboardEvent): void => {
    if (showEnding && (event.code === 'Escape' || event.code === 'Enter')) {
      closeEnding(setState, setShowEnding);
    }
    if (Object.keys(ARROWS).includes(event.code)) {
      event.preventDefault();

      if (limitCondition(state) && !showEnding) {
        const answerIndex = ARROWS[event.code];
        const answer = state.choicesList[0][answerIndex];

        // Submit answer
        targetControls.start(Object.keys(ARROWS)[answerIndex]);
        if (state.targets[0][1] === answer) {
          const newTargets = state.targets.slice(1);
          const newChoicesList = state.choicesList.slice(1);
          const point = state.score[1] ? 1 : 0;

          setState.setScore([state.score[0] + point, true]);
          choicesControls.map((controls, index) =>
            index === answerIndex
              ? controls.start('right')
              : controls.start('reset')
          );
          setState.setTargets(newTargets);
          setState.setChoicesList(newChoicesList);
        } else {
          setState.setLives(state.lives - 1);
          setState.setScore([state.score[0], false]);
          setState.setMistakes([
            ...new Set([...state.mistakes, state.targets[0][0]]),
          ]);
          choicesControls[answerIndex].start('wrong');
        }
        if (state.score[0] === 0 && state.lives === maxLives)
          if (stopwatchRef.current) stopwatchRef.current.start();
      }
    }
  };

const getStateHandler =
  (
    selections: boolean[],
    flip: number,
    state: State,
    setState: SetState,
    choicesControls: AnimationControls[],
    stopwatchRef: RefObject<StopwatchRef>,
    showEnding: boolean,
    setShowEnding: Dispatch<SetStateAction<boolean>>
  ) =>
  (): void => {
    if (!limitCondition(state)) {
      const newProblems = getProblems(selections, flip);

      // Notify ending
      if (stopwatchRef.current) stopwatchRef.current.stop();
      setShowEnding(true);
      disableBodyScroll(document);

      // Reset underneath
      resetUnderneath(newProblems, setState, choicesControls, stopwatchRef);
    }
  };

const mouseDownHandler = (event: MouseEvent): void => {
  if (event.detail > 1) event.preventDefault();
};

export {
  getProblems,
  formatTime,
  getKeyDownHandler,
  resetGame,
  closeEnding,
  getStateHandler,
  mouseDownHandler,
};
