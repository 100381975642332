import Matter from 'matter-js';
import { useAnimation } from 'framer-motion';

import { Dim, Entity } from '../../models';
import Receptor, { ReceptorProps } from './Receptor';
import { posOf } from '../../utils';
import { RECEPTOR_MODES } from './constants';
import { getNoteDims } from '../Note';

export default (gameDims: Dim, x: number): Entity<ReceptorProps> => {
  const dims = { w: getNoteDims(gameDims).w / 2, h: gameDims.h };
  const body = Matter.Bodies.rectangle(
    gameDims.w / 4,
    gameDims.h / 2,
    dims.w,
    dims.h,
    { type: 'receptor' }
  );
  const animate = useAnimation();

  Matter.Body.setStatic(body, true);

  return {
    dims,
    getPos: () => posOf(body),
    render: Receptor,
    body,
    x,
    animate,
  };
};
export { RECEPTOR_MODES };
