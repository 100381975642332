import React, { ReactElement, useState, useLayoutEffect } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import Subtext from '~/components/titles/Subtext';
import Gap from '~/components/flow/Gap';
import Header from '~/components/titles/Header';
import Subheader from '~/components/titles/Subheader';
import Divider from '~/components/flow/Divider';
import Link from '~/components/flow/Link';
import { useAuth } from '~/providers/Auth';

import {
  useBeatboxContext,
  Technique,
} from '../../../providers/BeatboxProvider';
import { useRecordContext } from '../../../providers/RecordProvider';
import uris from '../../../uris';
import InlineList from './InlineList';
import VerticalList from './VerticalList';
import YouTubePlayer from './YouTubePlayer';
import InstagramEmbed from './InstagramEmbed';
import EmptyFace from './EmptyFace';
import CodeList from './CodeList';
import CONTENT, { KINDS } from '../../../constants/content';
import EditLink from './EditLink';
import Unauthed from '../../../pages/Unauthed';
import { DIFFICULTY_COLORS } from '../constants';
import { getShortNumber } from '../../../utils';
import LearnButton from '../../LearnButton';

interface TechniqueFaceProps {
  techniqueId?: number;
}

function TechniqueFace({ techniqueId }: TechniqueFaceProps): ReactElement {
  const { state: authState } = useAuth();
  const { getTechnique } = useBeatboxContext();
  const { state: recordState } = useRecordContext();
  const [technique, setTechnique] = useState<Technique>();
  const techniqueUri = uris.exploreTechnique(technique?.id);
  const techniqueUrl = `${window.location.origin}${techniqueUri}`;

  // Rendering
  const noDescriptionText = 'Description not provided';
  const linkNotFoundText = 'Link not found';

  // Styling
  const headerStyle = {
    marginTop: '0.25em',
  };
  const difficultyStyle = technique && {
    color: DIFFICULTY_COLORS[technique.difficulty],
  };
  const descriptionStyle = {
    fontSize: FONT_SIZES.text,
  };
  const statisticsStyle = {
    display: 'inline-block',
    margin: '0 0 0.5em',
  };
  const countStyle = {
    marginLeft: '0.6em',
  };

  useLayoutEffect(() => {
    (async () => {
      setTechnique(techniqueId ? await getTechnique(techniqueId) : undefined);
    })();
  }, [techniqueId, recordState.records]);

  // Reduce repetition
  const GraySubtext = (props) => Subtext({ color: COLORS.doveGray, ...props });

  // TODO: add colored borders or color technique name based on some idea of difficulty
  return (
    <>
      {technique && (
        <>
          <Header style={headerStyle}>{technique.name}</Header>
          <LearnButton techniqueId={techniqueId} />
          <GraySubtext style={statisticsStyle}>
            <b style={countStyle}>
              {getShortNumber(technique.counts?.learning)}
            </b>{' '}
            learning
            <b style={countStyle}>
              {getShortNumber(technique.counts?.learned)}
            </b>{' '}
            have learned
          </GraySubtext>
          <br />
          <GraySubtext>
            Share&nbsp;
            <b style={difficultyStyle}>{technique.kind || KINDS.sound}</b>
            :&nbsp;
            <Link href={techniqueUri}>{techniqueUrl}</Link>
          </GraySubtext>
          <EditLink
            content={CONTENT.techniques}
            uri={uris.manageTechnique(technique.id)}
          />
          <Divider />
          {authState.isAuthed ? (
            <>
              <CodeList
                labels={technique.categories?.map((category) => category.label)}
              />
              <GraySubtext style={descriptionStyle}>
                {technique.description || noDescriptionText}
              </GraySubtext>
              {!!technique.credited_up_users?.length && (
                <>
                  <Gap />
                  <Gap />
                  <Subheader>Pioneers and Influential Users</Subheader>
                  <InlineList
                    labels={technique.credited_up_users?.map(
                      (user) => user.handle
                    )}
                    links={technique.credited_up_users?.map((user) =>
                      uris.profile(user.handle)
                    )}
                  />
                </>
              )}
              {!!technique.parent_techniques?.length && (
                <>
                  <Gap />
                  <Gap />
                  <Subheader>Required Techniques</Subheader>
                  <InlineList
                    labels={technique.parent_techniques?.map((t) => t.name)}
                    links={technique.parent_techniques?.map((t) =>
                      uris.exploreTechnique(t.id)
                    )}
                  />
                </>
              )}
              {!!technique.child_techniques?.length && (
                <>
                  <Gap />
                  <Gap />
                  <Subheader>Derived Techniques</Subheader>
                  <InlineList
                    labels={technique.child_techniques?.map((t) => t.name)}
                    links={technique.child_techniques?.map((t) =>
                      uris.exploreTechnique(t.id)
                    )}
                  />
                </>
              )}
              {!!technique.demos?.length && (
                <>
                  <Gap />
                  <Gap />
                  <Subheader>Demos</Subheader>
                  {technique.demos?.[0].youtube && (
                    <YouTubePlayer link={technique.demos[0].youtube} />
                  )}
                  {technique.demos?.[0].instagram && (
                    <InstagramEmbed url={technique.demos[0].instagram} />
                  )}
                  <VerticalList
                    labels={technique.demos
                      ?.slice(1)
                      .map((d) => d.youtube || d.instagram || linkNotFoundText)}
                    links={technique.demos
                      ?.slice(1)
                      .map((d) => d.youtube || d.instagram || uris.notFound)}
                  />
                </>
              )}
              {!!technique.tutorials?.length && (
                <>
                  <Gap />
                  <Gap />
                  <Subheader>Tutorials</Subheader>
                  {technique.tutorials?.[0].youtube && (
                    <YouTubePlayer link={technique.tutorials[0].youtube} />
                  )}
                  {technique.tutorials?.[0].instagram && (
                    <InstagramEmbed url={technique.tutorials[0].instagram} />
                  )}
                  <VerticalList
                    labels={technique.tutorials
                      ?.slice(1)
                      .map((t) => t.youtube || t.instagram || linkNotFoundText)}
                    links={technique.tutorials
                      ?.slice(1)
                      .map((t) => t.youtube || t.instagram || uris.notFound)}
                  />
                </>
              )}
            </>
          ) : (
            <Unauthed nested />
          )}
        </>
      )}
      {!technique && <EmptyFace />}
    </>
  );
}

export default TechniqueFace;
