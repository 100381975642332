export const FONTS = {
  header: 'Oleo Script Swash Caps Regular',
  typewriter: 'Lucida Console, Courier, monospace',
};

export const FONT_SIZES = {
  appName: '50px',
  single: '35px',
  title: '30px',
  header: '23px',
  action: '17px',
  text: '15px',
  subtext: '13px',
};

export const FONT_WEIGHTS = {
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
  heavy: 900,
};
