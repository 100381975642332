import { Song, Homework } from '../models';

export const HOMEWORKS: Homework[] = [
  // How to play
  {
    title: 'Meet the Japanese Flick Keyboard',
    problems: [
      { prompt: 'あ', answers: ['あ'] },
      { prompt: 'い', answers: ['い'] },
      { prompt: 'う', answers: ['う'] },
      { prompt: 'え', answers: ['え'] },
      { prompt: 'お', answers: ['お'] },
    ],
  },
  {
    title: 'Learn the Flick Layout',
    problems: [
      { prompt: 'あ', answers: ['あ'] },
      { prompt: 'な', answers: ['な'] },
      { prompt: 'ら', answers: ['ら'] },
      { prompt: 'ⓜ', answers: ['ⓜ'] },
      { prompt: 'か', answers: ['か'] },
      { prompt: 'は', answers: ['は'] },
      { prompt: 'ま', answers: ['ま'] },
      { prompt: 'わ', answers: ['わ'] },
      { prompt: 'さ', answers: ['さ'] },
      { prompt: 'た', answers: ['た'] },
      { prompt: 'や', answers: ['や'] },
      { prompt: '、', answers: ['、'] },
    ],
  },
  {
    title: 'Practice Flick Typing',
    problems: [
      { prompt: 'め', answers: ['め'] },
      { prompt: 'ち', answers: ['ち'] },
      { prompt: 'ひ', answers: ['ひ'] },
      { prompt: 'か', answers: ['か'] },
      { prompt: 'も', answers: ['も'] },
      { prompt: 'せ', answers: ['せ'] },
      { prompt: 'の', answers: ['の'] },
      { prompt: '？', answers: ['？'] },
      { prompt: '…', answers: ['…'] },
      { prompt: 'ん', answers: ['ん'] },
      { prompt: 'み', answers: ['み'] },
      { prompt: 'し', answers: ['し'] },
      { prompt: 'ね', answers: ['ね'] },
      { prompt: 'そ', answers: ['そ'] },
      { prompt: 'す', answers: ['す'] },
      { prompt: 'に', answers: ['に'] },
      { prompt: 'る', answers: ['る'] },
      { prompt: 'よ', answers: ['よ'] },
      { prompt: 'あ', answers: ['あ'] },
      { prompt: '～', answers: ['～'] },
      { prompt: 'ろ', answers: ['ろ'] },
      { prompt: 'ぬ', answers: ['ぬ'] },
      { prompt: 'つ', answers: ['つ'] },
      { prompt: 'わ', answers: ['わ'] },
      { prompt: 'ー', answers: ['ー'] },
      { prompt: 'ま', answers: ['ま'] },
      { prompt: 'て', answers: ['て'] },
      { prompt: 'き', answers: ['き'] },
      { prompt: 'れ', answers: ['れ'] },
      { prompt: 'ⓜ', answers: ['ⓜ'] },
      { prompt: 'な', answers: ['な'] },
      { prompt: 'や', answers: ['や'] },
      { prompt: 'り', answers: ['り'] },
      { prompt: '、', answers: ['、'] },
      { prompt: 'さ', answers: ['さ'] },
      { prompt: 'く', answers: ['く'] },
      { prompt: '。', answers: ['。'] },
      { prompt: 'ら', answers: ['ら'] },
      { prompt: 'は', answers: ['は'] },
      { prompt: 'む', answers: ['む'] },
      { prompt: 'と', answers: ['と'] },
      { prompt: 'え', answers: ['え'] },
      { prompt: 'お', answers: ['お'] },
      { prompt: 'を', answers: ['を'] },
      { prompt: 'い', answers: ['い'] },
      { prompt: 'ゆ', answers: ['ゆ'] },
      { prompt: 'ふ', answers: ['ふ'] },
      { prompt: 'へ', answers: ['へ'] },
      { prompt: 'ほ', answers: ['ほ'] },
      { prompt: '！', answers: ['！'] },
      { prompt: 'た', answers: ['た'] },
      { prompt: 'う', answers: ['う'] },
      { prompt: 'け', answers: ['け'] },
      { prompt: 'こ', answers: ['こ'] },
    ],
  },
  {
    title: 'Just Numbers',
    problems: [
      { prompt: '一', answers: ['い', 'ち'] },
      { prompt: '二', answers: ['に'] },
      { prompt: '三', answers: ['さ', 'ん'] },
      { prompt: '四', answers: ['し'] },
      { prompt: '五', answers: ['こ', 'ⓜ'] },
      { prompt: '六', answers: ['ろ', 'く'] },
      { prompt: '七', answers: ['し', 'ち'] },
      { prompt: '八', answers: ['は', 'ち'] },
      { prompt: '九', answers: ['き', 'ゆ', 'ⓜ', 'う'] },
      { prompt: '十', answers: ['し', 'ⓜ', 'ゆ', 'ⓜ', 'う'] },
      { prompt: '百', answers: ['ひ', 'や', 'ⓜ', 'く'] },
      { prompt: '千', answers: ['せ', 'ん'] },
      { prompt: '万', answers: ['ま', 'ん'] },
    ],
  },

  // N5
  {
    title: 'N5 First Batch',
    problems: [
      { prompt: '日', answers: ['に', 'ち'] },
      { prompt: '一', answers: ['い', 'ち'] },
      { prompt: '国', answers: ['く', 'に'] },
      { prompt: '人', answers: ['ひ', 'と'] },
      { prompt: '年', answers: ['ね', 'ん'] },
      { prompt: '大', answers: ['お', 'お', 'き', 'い'] },
      { prompt: '十', answers: ['し', 'ⓜ', 'ゆ', 'ⓜ', 'う'] },
      { prompt: '二', answers: ['に'] },
      { prompt: '本', answers: ['ほ', 'ん'] },
      { prompt: '中', answers: ['ち', 'ゆ', 'ⓜ', 'う'] },
      { prompt: '長', answers: ['な', 'か', 'ⓜ', 'い'] },
      { prompt: '出', answers: ['し', 'ゆ', 'ⓜ', 'つ'] },
      { prompt: '三', answers: ['さ', 'ん'] },
      { prompt: '時', answers: ['し', 'ⓜ'] },
      { prompt: '行', answers: ['い', 'く'] },
      { prompt: '見', answers: ['み', 'る'] },
      { prompt: '月', answers: ['つ', 'き'] },
      { prompt: '分', answers: ['ふ', 'ⓜ', 'ん'] },
      { prompt: '後', answers: ['あ', 'と'] },
      { prompt: '前', answers: ['ま', 'え'] },
      { prompt: '生', answers: ['せ', 'い'] },
      { prompt: '五', answers: ['こ', 'ⓜ'] },
      { prompt: '間', answers: ['け', 'ん'] },
      { prompt: '上', answers: ['う', 'え'] },
      { prompt: '東', answers: ['ひ', 'か', 'ⓜ', 'し'] },
      { prompt: '四', answers: ['し'] },
      { prompt: '今', answers: ['い', 'ま'] },
      { prompt: '金', answers: ['き', 'ん'] },
      { prompt: '九', answers: ['き', 'ゆ', 'ⓜ', 'う'] },
    ],
  },
  {
    title: 'N5 Second Batch',
    problems: [
      { prompt: '入', answers: ['い', 'る'] },
      { prompt: '学', answers: ['か', 'ⓜ', 'く'] },
      { prompt: '高', answers: ['た', 'か', 'い'] },
      { prompt: '円', answers: ['ま', 'る'] },
      { prompt: '子', answers: ['こ'] },
      { prompt: '外', answers: ['そ', 'と'] },
      { prompt: '八', answers: ['は', 'ち'] },
      { prompt: '六', answers: ['ろ', 'く'] },
      { prompt: '下', answers: ['し', 'た'] },
      { prompt: '来', answers: ['き'] },
      { prompt: '気', answers: ['き'] },
      { prompt: '小', answers: ['ち', 'い', 'さ', 'い'] },
      { prompt: '七', answers: ['し', 'ち'] },
      { prompt: '山', answers: ['や', 'ま'] },
      { prompt: '話', answers: ['は', 'な', 'す'] },
      { prompt: '女', answers: ['お', 'ん', 'な'] },
      { prompt: '北', answers: ['き', 'た'] },
      { prompt: '午', answers: ['う', 'ま'] },
      { prompt: '百', answers: ['ひ', 'や', 'ⓜ', 'く'] },
      { prompt: '書', answers: ['し', 'よ', 'ⓜ'] },
      { prompt: '先', answers: ['さ', 'き'] },
      { prompt: '名', answers: ['な'] },
      { prompt: '川', answers: ['か', 'わ'] },
      { prompt: '千', answers: ['せ', 'ん'] },
      { prompt: '水', answers: ['み', 'す', 'ⓜ'] },
      { prompt: '半', answers: ['は', 'ん'] },
      { prompt: '男', answers: ['お', 'と', 'こ'] },
      { prompt: '西', answers: ['に', 'し'] },
      { prompt: '電', answers: ['て', 'ⓜ', 'ん'] },
      { prompt: '校', answers: ['こ', 'う'] },
    ],
  },
  {
    title: 'N5 Third Batch',
    problems: [
      { prompt: '語', answers: ['こ', 'ⓜ'] },
      { prompt: '土', answers: ['つ', 'ち'] },
      { prompt: '木', answers: ['も', 'く'] },
      { prompt: '聞', answers: ['き', 'く'] },
      { prompt: '食', answers: ['た', 'へ', 'ⓜ', 'る'] },
      { prompt: '車', answers: ['く', 'る', 'ま'] },
      { prompt: '何', answers: ['な', 'に'] },
      { prompt: '南', answers: ['み', 'な', 'み'] },
      { prompt: '万', answers: ['ま', 'ん'] },
      { prompt: '毎', answers: ['ま', 'い'] },
      { prompt: '白', answers: ['し', 'ろ'] },
      { prompt: '天', answers: ['て', 'ん'] },
      { prompt: '母', answers: ['か', 'あ'] },
      { prompt: '火', answers: ['ひ'] },
      { prompt: '右', answers: ['み', 'き', 'ⓜ'] },
      { prompt: '読', answers: ['よ', 'む'] },
      { prompt: '友', answers: ['と', 'も'] },
      { prompt: '左', answers: ['ひ', 'た', 'ⓜ', 'り'] },
      { prompt: '休', answers: ['き', 'ゆ', 'ⓜ', 'う'] },
      { prompt: '父', answers: ['と', 'う'] },
      { prompt: '雨', answers: ['あ', 'め'] },
    ],
  },

  // Memes
  {
    title: 'Semantic Memes and Dreams',
    problems: [
      { prompt: '🎉', answers: ['お', 'め', 'て', 'ⓜ', 'と', 'う'] },
      { prompt: '🇯🇵', answers: ['に', 'ほ', 'ん'] },
      { prompt: '☂️', answers: ['か', 'さ'] },
      { prompt: '🍉', answers: ['す', 'い', 'か'] },
      { prompt: '⛩', answers: ['と', 'り', 'い'] },
      { prompt: '🌸', answers: ['さ', 'く', 'ら'] },
      { prompt: '🚗', answers: ['く', 'る', 'ま'] },
      { prompt: '🥢', answers: ['は', 'し'] },
      { prompt: '🍱', answers: ['へ', 'ⓜ', 'ん', 'と'] },
      { prompt: '🐕', answers: ['い', 'ぬ'] },
      { prompt: '🚃', answers: ['て', 'ⓜ', 'ん', 'し', 'や', 'ⓜ'] },
      { prompt: '🌙', answers: ['つ', 'き'] },
      { prompt: '🥟', answers: ['き', 'ⓜ', 'よ', 'ⓜ', 'ー', 'さ', 'ⓜ'] },
      { prompt: '🍜', answers: ['ら', 'ー', 'め', 'ん'] },
      { prompt: '👎', answers: ['い', 'い', 'え'] },
      { prompt: '🍙', answers: ['お', 'に', 'き', 'ⓜ', 'り'] },
      { prompt: '🐲', answers: ['り', 'ゆ', 'ⓜ', 'う'] },
      { prompt: '👍', answers: ['は', 'い'] },
      { prompt: '🦋', answers: ['ち', 'よ', 'ⓜ', 'う'] },
      { prompt: '🍡', answers: ['た', 'ⓜ', 'ん', 'こ', 'ⓜ'] },
      { prompt: '🐭', answers: ['ね', 'す', 'ⓜ', 'み'] },
      { prompt: '🍎', answers: ['り', 'ん', 'こ', 'ⓜ'] },
      { prompt: '🌨', answers: ['ゆ', 'き'] },
      { prompt: '🦈', answers: ['さ', 'め'] },
      { prompt: '🍣', answers: ['す', 'し'] },
      { prompt: '☀️', answers: ['た', 'い', 'よ', 'う'] },
      { prompt: '🥋', answers: ['か', 'ら', 'て'] },
      { prompt: '🤓', answers: ['お', 'た', 'く'] },
      { prompt: '🤦‍♀️', answers: ['は', 'ⓜ', 'か'] },
      { prompt: '🍚', answers: ['こ', 'ⓜ', 'は', 'ん'] },
      { prompt: '🔥', answers: ['ひ'] },
      { prompt: '🐍', answers: ['へ', 'ひ', 'ⓜ'] },
      { prompt: '💴', answers: ['お', 'か', 'ね'] },
      { prompt: '🏯', answers: ['し', 'ろ'] },
      { prompt: '🚅', answers: ['し', 'ん', 'か', 'ん', 'せ', 'ん'] },
      { prompt: '❤️', answers: ['あ', 'い'] },
      { prompt: '🌻', answers: ['ひ', 'ま', 'わ', 'り'] },
      { prompt: '🙏', answers: ['あ', 'り', 'か', 'ⓜ', 'と', 'う'] },
      { prompt: '💯', answers: ['ひ', 'や', 'ⓜ', 'く'] },
      { prompt: '🦜', answers: ['と', 'り'] },
      { prompt: '🐵', answers: ['さ', 'る'] },
      { prompt: '🚲', answers: ['し', 'ⓜ', 'て', 'ん', 'し', 'や', 'ⓜ'] },
      { prompt: '🤷‍♂️', answers: ['わ', 'か', 'り', 'ま', 'せ', 'ん'] },
      { prompt: '🐈', answers: ['ね', 'こ'] },
      { prompt: '🦊', answers: ['き', 'つ', 'ね'] },
    ],
  },
];

export const SONGS: Song[] = [
  // Platinum
  {
    title: 'English Version - Card Captor Sakura- 3rd Opening Platinum',
    artist: 'ccs',
    source: 'https://youtu.be/8yHFJfcpD0g',
    bpm: 82,
    rhythms: [
      {
        name: "Beginner's Introduction",
        timings: [
          2073.574720759527, 4872.3254949911025, 7461.273397028263,
          9902.30511105068, 13537.30112097907, 14875.629461309147,
          16270.000014112298, 19303.251724006936, 20592.276177589614,
          22009.646202246175, 25090.567866047557, 25789.374957190546,
          27907.2100684341, 28607.324938918184, 31535.54173703976,
          33534.39828960799, 35066.87942604008, 36384.20382582673,
          37952.63832141779, 39233.84953194174, 40815.41871828513,
          42132.20161735035, 44332.19762448198, 45082.65462414244,
          47915.55114882603, 50736.368285186014, 52102.398754851936,
          53702.26776278082, 54902.20291341674, 56552.905290720366,
          59397.50104806488, 61636.53312022092, 63337.54788916601,
          64803.03082189507, 66503.0951825855, 67337.16004327465,
          70103.41556840556, 72203.10183824292, 73617.64698645151,
          76705.28423445992, 77838.5741090954,
        ],
      },
      {
        name: 'Wind, Rain, Shadow, Wood',
        timings: [
          2024.8608405539965, 4990.440764966438, 7432.05165257287,
          7832.6481646095945, 8759.587026406525, 9075.272136814096,
          9925.891935478097, 10360.502118137563, 10692.943447653373,
          11640.388664943197, 12007.078560333346, 13902.439024390245,
          14634.146341463416, 15365.853658536585, 16097.560975609756,
          16829.26829268293, 17637.18675817967, 18304.006856555792,
          18953.469493981098, 19269.10113486938, 19919.774657817474,
          20687.190500613255, 21035.88396787219, 21769.155883318042,
          22136.235820708356, 23780.48780487805, 24512.19512195122,
          25774.342748830433, 26462.897734002134, 27073.17073170732,
          28536.58536585366, 29268.292682926833, 30000, 31463.414634146342,
          32195.121951219513, 32926.829268292684, 34262.58978977833,
          34624.54594566888, 34961.29936699836, 37126.961704222194,
          37511.49164183212, 37876.96735969908, 40025.18727874784,
          40759.731955652824, 41791.61080131907, 42125.155442087416,
          43258.090841485966, 43541.46558318632, 44405.941353791844,
          44755.09345590437, 45731.707317073175, 46463.41463414634,
          47195.12195121952, 47926.829268292684, 48658.53658536586,
          49390.243902439026, 50912.21873456462, 51544.90073446207,
          52280.723005555046, 52963.50904111195, 53645.726795971925,
          54430.13452549936, 55113.48077038264, 55846.186019830406,
          56533.18834354458, 57250.214965648105, 57982.40769266013,
          58733.97149485394, 59481.3001303503, 60282.920104408135,
          61575.46607545702, 62044.539854440816, 63312.483283244175,
          63694.575730681376, 64777.335759767906, 65212.70060763429,
          66194.62963822247, 66527.58516557877, 67416.14692668518,
          68162.26759366147, 68895.68534244788, 69595.72950327501,
          70243.90243902439, 70975.60975609756, 71707.31707317074,
          73536.58536585367, 74634.14634146342, 76625.86801067225,
          77760.0716050942,
        ],
        promoText: 'Check out ccs on YouTube!',
        promoLink: 'https://www.youtube.com/channel/UCBegsbXrQZ4XOMlZedTz_tQ',
      },
    ],
  },

  // ESCAPE
  {
    source: 'https://youtu.be/duIjsK9fSVs',
    bpm: 150,
    title: 'DEVILOOF Kanta 「ESCAPE」',
    artist: 'Akaito Shizune',
    rhythms: [
      {
        name: 'Metronome Lost',
        timings: [
          3873.4258093499916, 4723.33592337555, 5556.722231876371,
          6206.148293755263, 7123.6807671332235, 7957.735534021986,
          8706.696021161113, 9340.552822998721, 10906.465779167102,
          11656.288653895173, 12672.011175801175, 13538.77839294061,
          14321.8993745325, 15138.803775611475, 15889.45669260823,
          16705.39053144737, 17488.873136235063, 18288.168712962015,
          19089.67509217127, 19889.526450633864, 20787.0327066478,
          21537.692706645357, 22353.642388539316, 23120.931587962208,
          23903.84439203577, 24687.848361832228, 25570.937789316275,
          26353.406647166423, 27170.581006570832, 27970.60822798384,
          28837.285715559203, 29570.744099405394, 30730.356338331912,
          31562.775647554212, 32730.674451847088, 33614.25356876296,
          34414.48136245812, 35230.091075966346, 35947.616709260576,
          36697.19340606815, 37514.97244433065, 38297.05424713037,
          39080.63952106008, 39915.03480751633, 40297.29415166706,
          40580.25813384918, 41565.11326709017, 42331.08821757805,
          43080.97947781901, 43880.28869942644, 44679.509296371114,
          45447.48767103757, 46247.882070829684, 47605.72955253746,
          48689.48969303214, 49540.25885685061, 50373.14782216078,
          51139.64348031453, 51940.03792185366, 52739.74528828235,
          53556.09746065588, 54006.19137215114, 54422.80572085752,
          55136.340560457465, 55947.01165809531, 56689.73327200504,
          57422.43130153725, 57905.16212306734, 58789.53938363167,
          59789.52010146199, 60706.17417745753, 61473.42533436075,
          62239.35069098411, 63538.381206424674, 64008.49844797919,
          64842.561453956805, 65541.892126702, 66357.57307084049,
          67158.98127209379, 67857.64391235492, 68807.41582451476,
          69493.0356422978, 70425.10046957502, 71158.59413529705,
          71942.97570966918, 72692.35495053513, 73492.74572572813,
          74342.54394123457, 75126.14851700088, 75909.02876924322,
          76655.24980989077, 77458.44963661248, 78289.69176831843,
          79254.3271272949, 80706.39430875899, 81788.77807565147,
          82989.58783339642, 84005.59786320846, 85423.32763503511,
          86538.92379073019, 87872.38668188389, 88754.93590960983,
          89204.69336344146, 89623.15913761094, 90373.0912640012,
          91072.37634289278, 91922.21293342568, 92739.98851201782,
          93540.10994229227, 95145.67139857943, 95979.50171623981,
          96780.81908461044, 97529.59202123932, 98297.17377284911,
          99131.21507149385, 99862.91312118442, 100713.25790101023,
          101513.73111288616, 102314.15934668307, 103113.86579870629,
          103596.78882857242, 104014.23752827627, 104713.53706550294,
          105529.75188357555, 106312.87796845692, 107549.6442332594,
          108217.28195657063, 108782.54944135364, 109416.38388951987,
          110231.8303099616, 111083.14418411259, 111731.86084011076,
          112616.89261105127, 113283.34449103901, 114316.13492955857,
          115181.89917851263, 115932.48902309571, 116681.9033045769,
          117183.08706476336, 118050.05429314841, 118816.7099288068,
          119665.92928198015, 120383.24605867224, 121215.53255546004,
          122017.27468495956, 122766.38721683339, 123582.88189939212,
          124299.67102155808, 125232.30137864698, 125913.62228040765,
          126794.81507242816, 127649.889110795, 128431.7891035399,
          129248.45667127315, 130017.00739241192, 130816.02719835275,
          131632.04851682452, 132365.5825988733, 133166.56807994342,
          133949.93089479193, 134716.31680294801, 135496.32312896274,
          136366.46809655905, 137133.0586597349, 137932.6176964396,
          138733.0702629094,
        ],
        promoText: 'Check out Akaito Shizune on YouTube!',
        promoLink: 'https://www.youtube.com/channel/UCWXCuYlMqiUJtqi6Kc4EBEQ',
      },
    ],
  },

  // Nyan Cat
  {
    source: 'https://youtu.be/PgkGXo7dw5U',
    bpm: 142,
    title: '[Arcaea Fanmade / April Fools] Nyan Cat',
    artist: 'なーど',
    rhythms: [
      {
        name: 'Endurance Test',
        timings: [
          1219.7895552568932, 2522.0741627181624, 3387.482297922418,
          4254.267484980944, 5070.422535211268, 5915.492957746479,
          6760.56338028169, 7605.633802816901, 8450.704225352112,
          9295.774647887323, 10140.845070422536, 10985.915492957747,
          11830.985915492958, 12676.05633802817, 13521.12676056338,
          14366.197183098591, 15211.267605633802, 16056.338028169013,
          16901.408450704224, 17746.478873239437, 18591.549295774646,
          19436.61971830986, 20281.690140845072, 21126.76056338028,
          21971.830985915494, 22816.901408450703, 23661.971830985916,
          24507.042253521126, 25352.11267605634, 26197.183098591548,
          27042.25352112676, 27887.323943661973, 28732.394366197183,
          29577.464788732395, 30422.535211267605, 31267.605633802817,
          32112.676056338027, 32957.74647887324, 33802.81690140845,
          34647.887323943665, 35492.957746478874, 36338.028169014084,
          37183.09859154929, 38028.16901408451, 38873.23943661972,
          39718.30985915493, 40563.380281690144, 41408.45070422535,
          42253.52112676056, 43098.59154929577, 43943.66197183099,
          44788.7323943662, 45633.80281690141, 46478.873239436616,
          47323.94366197183, 48169.01408450704, 49014.08450704225,
          49859.15492957747, 50704.22535211268, 51549.295774647886,
          52394.366197183095, 53239.43661971831, 54084.50704225352,
          54929.57746478873, 55774.64788732395, 56619.718309859156,
          57464.788732394365, 58309.859154929574, 59154.92957746479, 60000,
          60845.07042253521, 61690.140845070426, 62535.211267605635,
          63380.281690140844, 64225.35211267605, 65070.42253521127,
          65915.49295774648, 66760.56338028169, 67605.6338028169,
          68450.7042253521, 69295.77464788733, 70140.84507042254,
          70985.91549295775, 71830.98591549296, 72676.05633802817,
          73521.12676056338, 74366.19718309859, 75211.26760563381,
          76056.33802816902, 76901.40845070423, 77746.47887323944,
          78591.54929577465, 79436.61971830986, 80281.69014084506,
          81126.76056338029, 81971.8309859155, 82816.9014084507,
          83661.97183098592, 84507.04225352113, 85352.11267605633,
          86197.18309859154, 87042.25352112675, 87887.32394366198,
          88732.39436619719, 89577.4647887324, 90422.5352112676,
          91267.60563380281, 92112.67605633802, 92957.74647887323,
          93802.81690140846, 94647.88732394367, 95492.95774647887,
          96338.02816901408, 97183.0985915493, 98028.1690140845,
          98873.23943661971, 99718.30985915494, 100563.38028169014,
          101408.45070422535, 102253.52112676056, 103098.59154929577,
          103943.66197183098, 104788.73239436619, 105633.80281690141,
          106478.87323943662, 107323.94366197183, 108169.01408450704,
          109014.08450704225, 109859.15492957746, 110704.22535211267,
          111549.2957746479, 112394.3661971831, 113239.43661971831,
          114084.50704225352, 114929.57746478873, 115352.11267605633,
          115774.64788732394, 116408.45070422535, 116619.71830985915,
          117464.78873239437, 118309.85915492958, 119154.92957746479,
        ],
        promoText: 'Check out なーど on YouTube!',
        promoLink: 'https://www.youtube.com/channel/UC4d4zYVV4pkJWI-4Vb0Li0A',
      },
    ],
  },

  // On the Sunny Side of the Street
  {
    source: 'https://youtu.be/-hy8Z6AeGW4',
    bpm: 63,
    title: 'On the Sunny Side of the Street',
    artist: 'Yuki Takeda',
    rhythms: [
      {
        name: 'Jazz Unlocked',
        timings: [
          1477.1942325743082, 2061.7633964466568, 2294.2694263149374,
          3129.712090835628, 3363.0582978876782, 3779.190092739509,
          4230.846005703293, 5346.253326535004, 5945.0041664580285,
          6163.004101608175, 6663.152382757933, 7213.192377462988,
          7613.5942651643945, 8113.599263162715, 8596.122292756902,
          8842.885135917297, 10298.228438077786, 10797.347155966885,
          11046.562229444044, 11479.471775906244, 12980.679151575792,
          13696.601136827889, 14664.000403370788, 15313.13932227706,
          15613.52874417341, 16963.645740851687, 17597.19255524025,
          18527.667309689423, 19079.67414679554, 19481.27630385048,
          20764.224285366818, 21659.68112454279, 22508.124434631816,
          23423.780915800515, 24357.824462866694, 25392.348080908738,
          25924.857029726438, 26825.4275725684, 27791.754017557174,
          28575.1147077761, 29091.872676590367, 30734.326608167175,
          31018.81690372014, 31918.90782262818, 33065.30590551679,
          34133.550869272665, 35017.87861046712, 35950.91433344786,
          37002.35714818401, 37917.67665309712, 38767.57477491025,
          39719.34711353192, 40267.785858302, 40817.96492470407,
          41590.563070258926, 42122.364302834925, 44404.699918913335,
          45916.65045383836, 46483.853355578394, 47766.019241612674,
          48450.38546653732, 49384.64608489576, 49849.78276795822,
          50284.05036622048, 51550.43348069825, 52216.14862012981,
          53382.212539984466, 53783.61485587283, 54250.43818598078,
          56415.29562094459, 57030.92439139487, 58066.35200801262,
          59283.30801737144, 59782.56832846587, 60898.86983533319,
          61450.1548713111, 61700.21112197886, 62799.6090151522,
          63800.232379965644, 64284.35457510244, 64767.1550854636,
          66061.59061938885, 66595.31315309457, 67579.33701662473,
          68545.24182588967, 69062.85110725799, 69562.19669204295,
          70512.45581220744, 71578.94692973411, 72378.68775777894,
          73377.98374698289, 74311.71450211578, 75345.15319263212,
          76262.10255831614, 77246.18868276315, 78300.26039670702,
          79148.25983186647, 80181.63202277826, 81148.6788112187,
          81580.52105444, 82014.5115485186, 83014.07915482204,
          83930.53252108803, 84948.68773209685, 85965.57420081588,
          86897.36444546332, 87831.09119987159, 88781.24594469996,
          89702.40598012957, 90303.35846707475, 90786.67342413272,
          91603.23318968077, 92552.19343663375, 93585.78511629303,
          94520.12491045966, 95469.9984578747, 96452.18767450452,
          97403.08568051721, 98335.69904913161, 98835.147592187,
          99303.63397309351, 100371.47486079267, 101571.43982621092,
          102153.82205103987, 102693.83241870452, 103171.63146932212,
          103686.70253255554, 104120.2739119615, 104604.4045878683,
          105054.76781179814, 105537.55292432729, 106037.94434066235,
          106971.75022112875, 107920.48879022572, 108887.9062453331,
          109855.31580485562, 110821.28574944135, 112736.26139855922,
          113222.71534475718, 114155.86534922256, 115221.90457477747,
          116122.44108581079, 117872.15064849606, 118437.7755506318,
          118988.33275261553, 120671.1264450358, 121205.63706067974,
          122344.0392698858, 122794.03836069356, 123544.14071298577,
          123959.52583058535, 124577.26577816362,
        ],
        promoText: 'Check out Yuki Takeda on YouTube!',
        promoLink: 'https://www.youtube.com/c/YukiTakeda',
      },
    ],
  },

  // Rick Roll
  {
    source: 'https://youtu.be/BBJa32lCaaY',
    bpm: 130,
    title: 'Sorry, Sorry / 정국 Jungkook Focus',
    artist: 'BTS',
    preview: 'https://youtu.be/WdMJ1MM2J14',
    rhythms: [
      {
        name: 'Perfect Cadence',
        timings: [
          279.8046854477434, 1377.5443067950669, 2495.326598788378,
          3596.5955881532905, 4496.862181594183, 5230.441378850699,
          5679.670040349159, 6563.9504383504955, 7346.887294874119,
          7827.3294277590585, 8547.181009261107, 9147.172443370513,
          9929.648903904746,
        ],
        promoText: 'Check out BTS on YouTube!',
        promoLink: 'https://youtu.be/dQw4w9WgXcQ',
      },
    ],
  },

  // Folk Music
  {
    source: 'https://youtu.be/WjOwwTVgDE4',
    bpm: 105,
    title: 'あんたがたどこさ',
    artist: 'Miki Saito',
    rhythms: [
      {
        name: 'California',
        timings: [
          1898.9150365569433, 2494.8521475009748, 3010.4815857427893,
          3548.4336719662924, 4195.104026514543, 4745.3933131587855,
          5359.479019241301, 5898.764324650697, 6364.847279759522,
          7032.296503738432, 7614.643728569533, 8163.901461033801,
          8748.066167241741, 9281.694866947351, 9798.104483036319,
          10290.334791370697, 10956.858777420433, 11506.680022467188,
          12621.768479149601, 13207.265349298255, 13756.70691608866,
          14357.36081014346, 14923.321836280033, 15423.244035088323,
          15939.280430327633, 16973.592507425525, 17555.617680404794,
          18122.872467998517, 18719.00503794643, 19254.67059468975,
          19805.889514504383, 20290.220468890766, 20891.530611683924,
          21423.01913525824, 22007.1148722825, 22507.402621614114,
          23124.83118427699, 23640.44696742999, 24724.369940532153,
          25242.01406748027, 25774.302144579695, 26307.224750756955,
          26874.884317772445, 27440.87066699697, 28090.651650006086,
        ],
        promoText: 'Check out Miki Saito on YouTube!',
        promoLink: 'https://www.youtube.com/channel/UCU7qYVphynbcKlBFxp2l4BA',
      },
    ],
  },

  // Blue Bird
  {
    source: 'https://youtu.be/VpOjcKmWI5Y',
    bpm: 76,
    title: 'Blue Bird',
    artist: 'Jenny',
    rhythms: [
      {
        name: 'Nostalgic Hello',
        timings: [
          969.097575073743, 1633.5371514503456, 2320.6341145788956,
          4119.40803505119, 4868.962711298318, 5485.792094440334,
          7270.153337814804, 8020.761630211442, 8602.279364609496,
          9815.652821143525, 10215.181750637195, 10981.864293255918,
          11698.86039411542, 13228.607874276124, 14182.757954166236,
          14949.113028257238, 15632.877544248877, 16448.80869297934,
          17199.47560931681, 18083.171965320795, 18799.276617911062,
          19591.625815779462, 20291.31031067433, 21208.02422032723,
          21824.410366056487, 22756.995777007323, 23491.32893057753,
          24290.128444518974, 25140.788891144974, 26157.52509096573,
          26807.11041337118, 27524.87346334096, 28291.070329427243,
          29274.059358622704, 29973.567698436444, 30807.35262101197,
          31344.69917961623, 32499.622305801404, 33066.82780172019,
          33882.33907919705, 34615.78616123896, 35650.089766366684,
          36066.308457321655, 36448.1185816141, 37098.572218979265,
          38832.15929130365, 39952.83897964534, 40369.579661355674,
          41002.31561892006, 41836.92739695343, 43103.52041923585,
          43468.00611540977, 44103.684668949034, 45052.78836159167,
          45635.62362814086, 46169.522215371464, 46553.91069532251,
          47453.24367493266, 47886.276649571126, 48235.58387568801,
          50872.08498629774, 51455.52496212554, 52238.97416322249,
          52855.65146303822, 54137.95130941021, 54588.076149811364,
          55403.7077480409, 56055.098228927265, 56438.62075025859,
          57738.05358720515, 58520.968653400385, 59296.946219183155,
          60329.60203047451, 60728.87791818048, 61463.06295855903,
          62277.6656530974, 62812.12718574401, 64078.62366530911,
          64778.28346473021, 65428.3385181501, 66745.95677226107,
          67179.22707006834, 67945.98837237856, 68512.44814850738,
          68996.57893824561, 69710.04982490692, 70226.36819033249,
          71010.96437213158, 71694.62141852938, 72927.72444774407,
          73361.05474413853, 74094.39187983755, 74893.20675792449,
          75344.24419185346, 76176.8866873175, 76544.7713622576,
          77243.08833706834, 77960.5196681781, 78444.59012423393,
          79361.61522227545, 79711.5472407197, 80392.81551611926,
          81125.96229637328, 81726.45889947758, 82959.16463634677,
          83675.02196651003, 84469.62236533404, 85072.00982248799,
          85926.11519421643, 86626.59512775685, 87442.31589364429,
          88208.98698909121, 89025.72908698047, 89809.83574674468,
          90862.00333862867, 91478.56418111396, 92195.08442754473,
          92978.6441285876, 93828.74157661141, 94612.15606734123,
          95427.92907136795, 96844.37199642921,
        ],
        promoText: 'Check out Jenny 「xUnreachablee」 on YouTube!',
        promoLink: 'https://www.youtube.com/user/xUnreachablee',
      },
    ],
  },
];
