import { ROLES } from '~/providers/Auth';
import { isObject } from '~/utils';
import { prepCurrentPath } from '~/helpers/routing';

import { RelationOption } from './models';
import PATHS, { BEATBOXDB_PATH } from './constants/paths';
import BEATBOXDB_ROLES from './constants/roles';
import PERMISSIONS from './constants/permissions';
import CONTENT from './constants/content';

export const capitalizeFirstLetter = ([firstChar, ...rest]: string): string =>
  `${firstChar?.toUpperCase() ?? ''}${rest.join('')}`;

export const getSingular = (plural: string): string =>
  plural.replace('ies', 'ys').slice(0, -1);

export const getCurrentPath: () => string = prepCurrentPath(
  PATHS,
  BEATBOXDB_PATH,
  PATHS.explore
);

export const getShortNumber = (n?: number): string => {
  // TODO: look into `trailingZeroDisplay` option once supported by modern browsers to remove `return` logic
  const shortN = Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumSignificantDigits: 3,
    minimumSignificantDigits: 2,
  }).format(n || 0);

  return shortN.indexOf('.0') > shortN.length - 4
    ? shortN.split('.0').join('')
    : shortN;
};

// Access
export const hasRoleAccess = (
  state: any // TODO: fix `state` type to not depend on `State` from `Auth`
): boolean =>
  state.isAuthed &&
  Object.values(BEATBOXDB_ROLES).includes(
    state.user?.role.type || ROLES.public
  );

export const getPermitted = (
  state: any // TODO: fix `state` type to not depend on `State` from `Auth`
): string[] => {
  return PERMISSIONS[state.user?.role.type || ROLES.public].filter(
    (c) =>
      c !== CONTENT.subjects && c !== CONTENT.profiles && c !== CONTENT.records
  );
};

export const hasPermission = (
  state: any, // TODO: fix `state` type to not depend on `State` from `Auth`
  content: string
): boolean => state.isAuthed && getPermitted(state).includes(content);

// Content
export const checkIdFormat = (id: unknown): boolean => {
  const castId = Number(id);

  return Number.isInteger(castId) && castId > 0;
};

export const getRelationOptions = (
  contents?: Record<string, any>[],
  labelKey = 'label'
): RelationOption[] =>
  contents?.map((c) => ({ value: c.id, label: c[labelKey] })) || [];

export const infoFromContent = (
  content?: Record<string, any>,
  defaultInfo?: Record<string, any>
): Record<string, any> | undefined => {
  const transform = (v) => {
    if (isObject(v) && v.id) return v.id;
    else if (Array.isArray(v)) return v.map((e) => transform(e));

    return v;
  };
  const defaultInfoKeys = defaultInfo && Object.keys(defaultInfo);

  return (
    content &&
    Object.keys(content).reduce(
      (acc, k) =>
        !defaultInfoKeys || defaultInfoKeys.includes(k)
          ? { ...acc, [k]: transform(content[k]) }
          : acc,
      {} as Record<string, any>
    )
  );
};

export const isYouTubeLink = (value: string): boolean => {
  const linkRegex =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/i;

  return !!value.match(linkRegex);
};

export const isInstagramLink = (value: string): boolean => {
  const linkRegex =
    /^(((?:https?:)?\/\/)?(?:www\.)?(?:instagram.com|instagr.am|instagr.com)\/(p|tv|reel)\/([^/?#&]+)).*$/i;

  return !!value.match(linkRegex);
};
