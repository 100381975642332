import React, { ReactElement, Dispatch, SetStateAction } from 'react';
import { isFirefox } from 'react-device-detect';

import COLORS from '~/constants/colors';

import { Word } from '../../../models';

interface ChunkProps {
  word: Word;
  setWrongWord: Dispatch<SetStateAction<Word | undefined>>;
  color: string;
  highlight?: boolean;
}

function Chunk({
  word,
  setWrongWord,
  color,
  highlight,
}: ChunkProps): ReactElement {
  let top = '0';
  let height = '100%';

  if (isFirefox) {
    top = '1px';
    height = 'calc(100% - 1.75px)';
  }

  // Handlers
  const handleWrongWord = () => {
    // Do not use `stopEventEffects` here, breaks highlighting errors for some reason
    if (word.error) setWrongWord(word);
    else setWrongWord(undefined);
  };

  // Styling
  const chunkStyle = {
    display: 'inline-block',
    position: 'relative' as const,
    whiteSpace: 'break-spaces' as const,
  };
  const charStyle = {
    color: word.error ? COLORS.white : color,
  };
  const backgroundStyle = {
    position: 'absolute' as const,
    top,
    left: 0,
    width: '1ch',
    height,
    backgroundColor: word.error ? COLORS.mandy : COLORS.transparent,
    zIndex: -2,
  };
  const highlightStyle = {
    ...backgroundStyle,
    backgroundColor: COLORS.cornflower,
    opacity: 0.5,
    zIndex: -1,
  };

  return (
    <span
      role="none"
      onMouseUp={handleWrongWord}
      onTouchEnd={handleWrongWord}
      style={chunkStyle}
    >
      <span style={charStyle}>{word.chunk}</span>
      {highlight && <span style={highlightStyle} />}
      <span style={backgroundStyle} />
    </span>
  );
}

export default Chunk;
