import { MutableRefObject } from 'react';
import GameEngine from 'react-game-engine';

import { InputPadEntity } from './models';
import { Dim } from '../predicates/models';
import InputPad from '../renderers/InputPad';
import inputPadPredicate from '../predicates/inputPadPredicate';
import { PlaySettings } from '../models';

const inputPadEntity = (
  windowDims: Dim,
  settings: PlaySettings,
  gameEngineRef: MutableRefObject<GameEngine>,
  phase: string
): InputPadEntity => ({
  ...inputPadPredicate(
    windowDims,
    settings.inputMode,
    settings.whichPadLabels,
    settings.homework?.problems[0]?.answers[0],
    settings.guidance,
    gameEngineRef,
    phase
  ),
  renderer: InputPad,
});

export default inputPadEntity;
