import React, {
  ReactElement,
  ForwardedRef,
  forwardRef,
  useState,
  useImperativeHandle,
} from 'react';

import Menu from '../pages/Menu';
import NoLoad from '../pages/NoLoad';
import Ending from '../pages/Ending';
import Studio from '../pages/Studio';
import Production from '../pages/Production';
import Classroom from '../pages/Classroom';
import { Baton } from '../pages/Game';
import {
  DEFAULT_PLAY_SETTINGS,
  DEFAULT_PLAY_RESULTS,
  DEFAULT_RHYTHM,
  DEFAULT_SONG,
} from '../constants/defaults';
import PHASES from '../constants/phases';
import {
  PlaySettings,
  PlayResults,
  AnySettings,
  AnyResults,
  StudioSettings,
  RecordResults,
  Song,
} from '../models';
import NavSwitchBoard from './NavSwitchBoard';
import Tutorial from '../pages/Tutorial';

interface CloakProps {
  ref: ForwardedRef<Baton>;
}

function Cloak({ ref }: CloakProps): ReactElement {
  const [phase, setPhase] = useState(PHASES.menu);
  const [prevPhase, setPrevPhase] = useState(PHASES.menu);
  const [settings, setSettings] = useState<AnySettings>(DEFAULT_PLAY_SETTINGS);
  const [song, setSong] = useState<Song>(DEFAULT_SONG);
  const [videoPlayer, setVideoPlayer] = useState();
  const [playerState, setPlayerState] = useState(NaN);
  const [newRhythm, setNewRhythm] = useState(DEFAULT_RHYTHM);
  const [results, setResults] = useState<AnyResults>(DEFAULT_PLAY_RESULTS());

  useImperativeHandle(
    ref,
    () => ({
      phase,
      setPhase,
      prevPhase,
      setPrevPhase,
      settings,
      song,
      videoPlayer,
      setVideoPlayer,
      setPlayerState,
      setResults,
    }),
    [phase, prevPhase, settings, song.source, videoPlayer]
  );

  return (
    <>
      <NavSwitchBoard phase={phase} setPhase={setPhase} />
      <Menu
        phase={phase}
        setPhase={setPhase}
        setPrevPhase={setPrevPhase}
        setSettings={setSettings}
        setSong={setSong}
        videoPlayer={videoPlayer}
      />
      <Studio
        phase={phase}
        setPhase={setPhase}
        setPrevPhase={setPrevPhase}
        setSettings={setSettings}
        setSong={setSong}
        videoPlayer={videoPlayer}
        playerState={playerState}
        setPlayerState={setPlayerState}
        newRhythm={newRhythm}
        setNewRhythm={setNewRhythm}
      />
      <Classroom phase={phase} />
      {phase === PHASES.tutorial && <Tutorial />}
      {phase === PHASES.noLoad && (
        <NoLoad setPhase={setPhase} prevPhase={prevPhase} />
      )}
      {phase.slice(0, 6) === PHASES.ending && (
        <Ending
          phase={phase}
          setPhase={setPhase}
          results={results as PlayResults}
          settings={settings as PlaySettings}
        />
      )}
      {phase === PHASES.production && (
        <Production
          setPhase={setPhase}
          newRhythm={newRhythm}
          setNewRhythm={setNewRhythm}
          settings={settings as StudioSettings}
          results={results as RecordResults}
        />
      )}
    </>
  );
}

export default forwardRef((props, ref: ForwardedRef<Baton>) => Cloak({ ref }));
