import React, {
  useReducer,
  useMemo,
  PropsWithChildren,
  ReactElement,
  useLayoutEffect,
} from 'react';

import { createSafeContext } from '~/helpers/state';
import { useAuth } from '~/providers/Auth';

import { RecordContext, RecordState } from './models';
import {
  addRecordThunk,
  listRecordsThunk,
  editRecordThunk,
  deleteRecordThunk,
  clearRecordsThunk,
} from './actions';
import reducer, { INITIAL_STATE } from './reducer';

const [useRecordContext, RecordContextProvider] =
  createSafeContext<RecordContext>();

type RecordProviderProps = Record<never, never>;

function RecordProvider({
  children,
}: PropsWithChildren<RecordProviderProps>): ReactElement {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { state: authState } = useAuth();

  // Connect thunks
  const addRecord = addRecordThunk(dispatch);
  const listRecords = listRecordsThunk(dispatch);
  const editRecord = editRecordThunk(dispatch);
  const deleteRecord = deleteRecordThunk(dispatch);
  const clearRecords = clearRecordsThunk(dispatch);

  // Set up value
  const value = useMemo(() => {
    return {
      state: state as RecordState,
      addRecord,
      editRecord,
      deleteRecord,
    };
  }, [state]);

  // Handle logout
  useLayoutEffect(() => {
    if (!authState.isAuthed && state.records) clearRecords();
  }, [authState.isAuthed]);

  // List records
  useLayoutEffect(() => {
    (async () => {
      if (authState.isAuthed && !state.records) await listRecords();
    })();
  }, [authState.isAuthed]);

  return (
    <RecordContextProvider value={value}>{children}</RecordContextProvider>
  );
}

export default RecordProvider;
export { useRecordContext };
