import React, { useState, Dispatch, SetStateAction } from 'react';
import axios from 'axios';

import { mod, stopEventEffects } from '~/utils';
import { getArrayBuffer } from '~/helpers/media';
import COLORS from '~/constants/colors';
import Button from '~/components/buttons/Button';
import { FONT_SIZES } from '~/constants/typography';

import { Beat, Chart } from '../models';
import { getMaxTiming, extractNodes } from '../utils';
import { TEMPO_NUMERATOR } from '../constants/defaults';

const AudioContext = window.AudioContext || (window as any).webkitAudioContext;

interface ListenProps {
  beat: Beat;
  chart: Chart;
  label: string;
  setProgress?: Dispatch<SetStateAction<number>>;
}

function Listen({ beat, chart, label, setProgress }: ListenProps) {
  const [playing, setPlaying] = useState(false);
  const safeChart = chart
    .filter((n) => n.node.sample.recording.url)
    .sort((a, b) => a.start - b.start);
  const disabled = safeChart.length === 0 || playing;
  const maxTiming = getMaxTiming(chart);
  const mpb = TEMPO_NUMERATOR / beat.bpm;
  const maxPaddedTiming = maxTiming + mpb - mod(maxTiming, mpb);

  // Handlers
  const playChart = async (event) => {
    const context = new AudioContext();

    stopEventEffects(event);
    setPlaying(true);

    // Preload samples
    const nodes = extractNodes(chart);
    const audioBuffers = await Object.values(nodes).reduce(async (accP, n) => {
      const { url } = n.sample.recording;
      const acc = await accP;
      const response =
        url && (await axios.get(url, { responseType: 'blob' }).catch(() => {}));

      if (response) {
        const blob = response.data;
        const arrayBuffer = await getArrayBuffer(blob);

        return { ...acc, [n.id]: await context.decodeAudioData(arrayBuffer) };
      }

      return acc;
    }, Promise.resolve({}));
    const samples = [...Array(Number(beat.loops))].map(() =>
      safeChart.map((n) => {
        const bufferSourceNode = new AudioBufferSourceNode(context, {
          ...(audioBuffers[n.node.id] && { buffer: audioBuffers[n.node.id] }),
          ...(n.end && { loop: true }),
        });

        bufferSourceNode.connect(context.destination);

        return bufferSourceNode;
      })
    );

    // Play sounds with timer
    let currentLoop = 0;
    const startTimer = () => {
      let noteIdx = 0; // Represents first note not yet played
      let prevIdxs = [] as number[]; // For stopping samples that are too long
      let sustainIdxs = [] as number[];
      const startTime = Date.now();
      const timer = setInterval(() => {
        const currentTime = Date.now() - startTime;

        setProgress?.(currentTime);

        // Stop previous samples
        if (currentTime >= safeChart[noteIdx]?.start) {
          prevIdxs.forEach((i) => samples[currentLoop][i].stop());
          prevIdxs = [];
        }

        // Manage sustains
        sustainIdxs.forEach((i) => {
          if ((safeChart[i].end ?? 0) <= currentTime)
            samples[currentLoop][i].stop();
        });
        sustainIdxs = sustainIdxs.filter(
          (i) => (safeChart[i].end ?? 0) > currentTime
        );

        // Play next samples
        while (noteIdx < safeChart.length) {
          const note = safeChart[noteIdx];

          if (currentTime >= note.start) {
            samples[currentLoop][noteIdx].start();
            if (note.end) sustainIdxs.push(noteIdx);
            else prevIdxs.push(noteIdx);
            noteIdx += 1;
          } else break;
        }

        // End playing
        if (currentTime > maxPaddedTiming) {
          prevIdxs.forEach((i) => samples[currentLoop][i].stop());
          sustainIdxs.forEach((i) => samples[currentLoop][i].stop());
          samples[currentLoop].forEach((b) => b.disconnect());
          setProgress?.(0);
          clearInterval(timer);
          currentLoop += 1;
          if (currentLoop < beat.loops) startTimer();
          else {
            if (context.state !== 'closed') context.close();
            setPlaying(false);
          }
        }
      }, 1);
    };
    startTimer();
  };

  // Styling
  const listenStyle = {
    backgroundColor: COLORS.doveGray,
    borderColor: COLORS.doveGray,
    padding: '0.3em',
    borderRadius: '0.3em',
    fontSize: FONT_SIZES.text,
    boxShadow: `-0.07em 0.07em ${COLORS.black25}`,
  };
  const disabledStyle = {
    backgroundColor: COLORS.gallery,
    borderColor: COLORS.gallery,
  };
  const disabledLabelStyle = {
    color: COLORS.gray,
  };

  return (
    <Button
      label={label}
      disabled={disabled}
      callback={playChart}
      style={listenStyle}
      disabledStyle={disabledStyle}
      disabledLabelStyle={disabledLabelStyle}
    />
  );
}

export default Listen;
