import { Dim, Pos, StopwatchPredicate } from './models';
import { RECEPTOR_DIMS } from '../constants/layout';

const stopwatchPredicate = (
  windowDims: Dim,
  pos: Pos,
  time: number
): StopwatchPredicate => {
  const receptorDims = RECEPTOR_DIMS(windowDims);

  return {
    dims: { w: (receptorDims.w * 2) / 3, h: (receptorDims.h * 3) / 7 },
    getPos: () => pos,
    time,
    style: {
      zIndex: 3,
    },
  };
};

export default stopwatchPredicate;
