import React, { ReactElement } from 'react';
import { motion } from 'framer-motion';

import COLORS from '~/constants/colors';

import { RenderProps } from '../../models';
import {
  getWidth,
  getHeight,
  getLeft,
  getTop,
  getBorderWidth,
} from '../../align';
import { BAR_MODES } from './constants';

interface BarProps {
  timing: number;
  garbageTime: number;
  fuzzy: boolean;
  mode?: string;
}

function Bar({ entity, gameDims }: RenderProps<BarProps>): ReactElement {
  const pos = entity.getPos();

  // Styling
  const width = getWidth(gameDims, entity.dims.w, entity.dims.h);
  const height = getHeight(gameDims, entity.dims.h, entity.dims.w);
  const barStyle = {
    position: 'absolute' as const,
    width,
    height,
    left: getLeft(gameDims, pos.x - entity.dims.w / 2, pos.y, width),
    top: getTop(gameDims, pos.y - entity.dims.h / 2, pos.x, height),
    opacity: entity.mode === BAR_MODES.fadeIn ? 0 : 1,
    borderColor: entity.fuzzy ? COLORS.black25 : COLORS.black,
    borderStyle: 'dashed',
    borderWidth: getBorderWidth('0 0 0 1px'),
    zIndex: 1,
  };

  // Animation
  const variants = {
    reset: {
      opacity: 1,
    },
    fadeIn: {
      opacity: [0, 1],
      transition: { duration: 0.25 },
    },
    fadeOut: {
      opacity: [1, 0],
      transition: { duration: 0.25 },
    },
  };
  const animate = entity.mode ?? BAR_MODES.reset;

  return <motion.div style={barStyle} animate={animate} variants={variants} />;
}

export default Bar;
export type { BarProps };
