import Matter from 'matter-js';

import { Dim, Entity } from '../../models';
import Note, { NoteProps } from './Note';
import { posOf } from '../../utils';
import { NOTE_MODES } from './constants';
import { getNoteDims, getThresholds, getYFromTrack } from './utils';

export default (
  gameDims: Dim,
  x: number,
  track: number,
  key: string,
  timing: number,
  label: string,
  mode?: string
): Entity<NoteProps> => {
  const dims = getNoteDims(gameDims);
  const body = Matter.Bodies.rectangle(
    x,
    getYFromTrack(gameDims, track),
    dims.w,
    dims.h,
    { type: 'note' }
  );

  return {
    dims,
    getPos: () => posOf(body),
    render: Note,
    body,
    key,
    timing,
    attemptable: true,
    garbageTime: -1,
    label,
    mode,
  };
};
export { NOTE_MODES, getNoteDims, getThresholds, getYFromTrack };
