// TODO: currently `lamejs` dependency in `package.json` is from GitHub due to needing bug fix, switch to npm if version there ever gets updated
import { Mp3Encoder } from 'lamejs';

import {
  CHANNEL_COUNT,
  SAMPLE_RATE,
  BIT_RATE,
  MAX_SAMPLES,
} from '../constants/media';

// Helpers
const floatTo16BitPcm = (input) => {
  const output = new Int16Array(input.length);

  for (let i = 0; i < input.length; i += 1) {
    const s = Math.max(-1, Math.min(1, input[i]));

    output[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
  }

  return output;
};
const convertBuffer = (buffer) => {
  const data = new Float32Array(buffer);

  return floatTo16BitPcm(data);
};

class Encoder {
  config: Record<string, any> = {
    sampleRate: SAMPLE_RATE,
    bitRate: BIT_RATE,
  };

  mp3Encoder: Mp3Encoder;

  buffer: Int8Array[] = [];

  constructor(config?) {
    this.config = { ...this.config, ...config };
    this.mp3Encoder = new Mp3Encoder(
      CHANNEL_COUNT,
      this.config.sampleRate,
      this.config.bitRate
    );
    this.clearBuffer();
  }

  clearBuffer() {
    this.buffer = [];
  }

  appendToBuffer(buffer) {
    this.buffer.push(new Int8Array(buffer));
  }

  encode(buffer) {
    const monoSamples = convertBuffer(buffer);
    let remaining = monoSamples.length;

    for (let i = 0; remaining >= 0; i += MAX_SAMPLES) {
      const left = monoSamples.subarray(i, i + MAX_SAMPLES);
      const mp3Buffer = this.mp3Encoder.encodeBuffer(left);

      this.appendToBuffer(mp3Buffer);
      remaining -= MAX_SAMPLES;
    }
  }

  finish() {
    this.appendToBuffer(this.mp3Encoder.flush());

    return this.buffer;
  }
}

export default Encoder;
