import YouTube from 'react-youtube';

import {
  Rhythm,
  Meta,
  Song,
  Homework,
  PlaySettings,
  StudioSettings,
  ReviewState,
  PlayState,
  RecordState,
  PlayResults,
  RecordResults,
} from '../models';
import { FALL_SPEED, INITIAL_HEALTH } from './mechanics';

// Content defaults
export const DEFAULT_META: Meta = {
  source: '',
  bpm: 60,
};

export const DEFAULT_SONG: Song = {
  ...DEFAULT_META,
  title: '',
  artist: '',
  rhythms: [],
};

export const DEFAULT_RHYTHM: Rhythm = {
  name: '',
  timings: [],
};

export const DEFAULT_HOMEWORK: Homework = {
  title: '',
  problems: [],
};

// Default settings for game phases
const DEFAULT_SETTINGS = {
  song: DEFAULT_SONG,
  velocity: FALL_SPEED.default,
};

export const DEFAULT_PLAY_SETTINGS: PlaySettings = {
  ...DEFAULT_SETTINGS,
  rhythm: DEFAULT_RHYTHM,
  homework: DEFAULT_HOMEWORK,
  inputMode: '',
  whichPadLabels: '',
  guidance: false,
};

export const DEFAULT_STUDIO_SETTINGS: StudioSettings = {
  ...DEFAULT_SETTINGS,
  videoStartTime: 0,
  videoEndTime: 0,
};

// Default results for game phases
export const DEFAULT_PLAY_RESULTS = (): PlayResults => ({
  attempts: [],
  points: 0,
  health: INITIAL_HEALTH,
  damage: 0,
  perfect: 0,
  good: 0,
  okay: 0,
  bad: 0,
  awful: 0,
  pre: 0,
  miss: 0,
  wrong: 0,
  delta: 0,
  deltaSquared: 0,
  hits: 0,
  gotSpecial: false,
});

export const DEFAULT_RECORD_RESULTS = (): RecordResults => ({
  timings: [],
});

// Default states for game phases
const DEFAULT_STATE = {
  youTubePlayerState: YouTube.PlayerState.UNSTARTED,
  startTime: -1,
  renderedBarIdx: -1,
  videoTime: -1,
  videoDelta: -1,
};

export const DEFAULT_REVIEW_STATE = (): ReviewState => ({
  ...DEFAULT_STATE,
  renderedNoteIdx: -1,
});

export const DEFAULT_PLAY_STATE = (): PlayState => ({
  ...DEFAULT_REVIEW_STATE(),
  problemIdxs: [0, -1],
  attemptableIdxs: [0, 0],
  cumCorrect: true,
  cumSpecial: false,
  results: DEFAULT_PLAY_RESULTS(),
});

export const DEFAULT_RECORD_STATE = (): RecordState => ({
  ...DEFAULT_REVIEW_STATE(),
  results: DEFAULT_RECORD_RESULTS(),
});
