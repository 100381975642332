import { State } from './models';

export const STORAGE_KEYS = {
  refreshKey: 'AuthRefreshKey',
  logoutTime: 'AuthLogoutTime',
};

export const ROLES = {
  public: 'public',
  authed: 'authenticated',
};

// Fake state for development only
export const FAKE_STATE: State = {
  isAuthed: true,
  user: {
    id: 1,
    email: 'test@test.com',
    handle: 'tester',
    role: {
      name: 'Authenticated',
      description: 'Default role given to authenticated user.',
      type: ROLES.authed,
    },
  },
};
