import Matter from 'matter-js';
import YouTube from 'react-youtube';

import { PlaySystem } from './models';
import barEntity from '../entities/barEntity';
import PHASES from '../constants/phases';
import {
  NO_LOAD_THRESHOLD,
  TEMPO_NUMERATOR,
  GARBAGE_THRESHOLD,
} from '../constants/mechanics';
import { BAR_VARIANTS } from '../constants/layout';
import { getNewY } from '../utils';

const playTempoSystem: PlaySystem = (entities, { time }) => {
  const newEntities = entities;
  const { setPhase, receptor, videoPlayer, bars } = entities;
  const { engine, windowDims } = entities.physics;
  const { song, velocity } = entities.settings;
  const { startTime, renderedBarIdx, youTubePlayerState } = entities.state;

  // Transition to no load if video doesn't start for some seconds
  if (startTime < -1 && time.current + startTime > NO_LOAD_THRESHOLD) {
    setPhase(PHASES.noLoad);

    return newEntities;
  }

  // Handle timing synchronization
  const videoTime = videoPlayer?.getCurrentTime() * 1000 ?? -1;
  const prevVideoTime = entities.state.videoTime;
  const videoDelta = videoTime - prevVideoTime;

  newEntities.state.videoDelta = videoDelta;
  if (videoDelta <= 0) return newEntities; // Order needed for other systems
  newEntities.state.videoTime = videoTime;

  // Make sure start time set and YouTube background is playing
  if (startTime < 0 || youTubePlayerState !== YouTube.PlayerState.PLAYING) {
    if (youTubePlayerState === YouTube.PlayerState.PLAYING)
      newEntities.state.startTime = videoTime;
    else if (startTime === -1) newEntities.state.startTime = -time.current;

    return newEntities;
  }

  // Delete old or move active bars
  const endY = receptor.getPos().y;
  const barKeys = Object.keys(bars);

  barKeys.forEach((key) => {
    const bar = bars[key];
    const oldY = bar.getPos().y;
    const newY = getNewY(endY, bar.timing, videoTime, velocity);
    const translation = { x: 0, y: newY - oldY };
    const isGarbage =
      bar.garbageTime > 0 && videoTime - bar.garbageTime > GARBAGE_THRESHOLD;

    if (newY > endY) {
      if (isGarbage) {
        Matter.Composite.remove(engine.world, bar.body);
        delete newEntities.bars[key];
      } else {
        newEntities.bars[key].garbageTime = videoTime;
        bar.state = BAR_VARIANTS.fadeOut;
      }
    } else Matter.Body.translate(bar.body, translation);
  });

  // Add new bars if needed
  const timeBetweenBars = TEMPO_NUMERATOR / song.bpm;
  const distBetweenBars = timeBetweenBars * velocity;
  const courtDist = Math.ceil(endY / distBetweenBars) * distBetweenBars;
  const courtTime = courtDist / velocity;
  const startY = 0;
  const nextBarIdx = Math.floor((videoTime + courtTime) / timeBetweenBars);
  const nextBarStartTime = nextBarIdx * timeBetweenBars;
  const newBarY = Math.min(
    getNewY(endY, nextBarStartTime, videoTime, velocity),
    endY
  );

  if (newBarY >= startY && renderedBarIdx < nextBarIdx) {
    const newBar = barEntity(windowDims, newBarY, nextBarStartTime);

    newEntities.bars[nextBarIdx] = newBar;
    Matter.Composite.add(engine.world, [newBar.body]);
    newEntities.state.renderedBarIdx += renderedBarIdx < 0 ? nextBarIdx + 1 : 1;
  }

  return newEntities;
};

export default playTempoSystem;
