const ponderDomain = 'ponder.ac';

export const HOST =
  process.env.NODE_ENV === 'production' ? window.location.host : ponderDomain;

export default {
  ponder: ponderDomain,
  beatboxShare: 'beatbox.sh',
  beatblox: 'beatblox.tech',
  beatboxDb: 'beatboxdb.com',
};
