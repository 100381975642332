export default {
  menu: 'menu',
  game: 'game',
  play: 'gamePlay',
  record: 'gameRecord',
  review: 'gameReview',
  noLoad: 'noLoad',
  ending: 'ending',
  endingExit: 'endingExit',
  studio: 'studio',
  production: 'production',
  classroom: 'classroom',
  tutorial: 'tutorial',
};
