import { useLayoutEffect, SyntheticEvent } from 'react';

export const stopEventEffects = (event: Event | SyntheticEvent): void => {
  event.preventDefault();
  event.stopPropagation();
};

export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const mod = (a: number, q: number): number => ((a % q) + q) % q;

export const getModDistance = (a: number, b: number, q: number): number => {
  const average = (a + b) / 2;
  const standard = Math.abs(a - b);
  const reverse = Math.abs(mod(a - average, q) - mod(b - average, q));

  return Math.min(standard, reverse);
};

export const shuffleArray = (array: any[]): any[] =>
  array
    .map((value) => ({ value, compare: Math.random() }))
    .sort((a, b) => a.compare - b.compare)
    .map(({ value }) => value);

export const isObject = (value: any): boolean =>
  typeof value === 'object' && !Array.isArray(value) && value !== null;

export const isEmptyObject = (value: any): boolean => {
  if (isObject(value)) {
    for (const k in value) // eslint-disable-line no-restricted-syntax
      if (Object.prototype.hasOwnProperty.call(value, k)) return false;

    return true;
  }

  return false;
};

export const useTitle = (title: string): void => {
  useLayoutEffect(() => {
    const prevTitle = document.title;

    document.title = title;

    return () => {
      document.title = prevTitle;
    };
  });
};
