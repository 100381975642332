import Matter from 'matter-js';
import YouTube from 'react-youtube';

import { ReviewSystem } from './models';
import noteEntity from '../entities/noteEntity';
import { NOTE_DIMS, NOTE_KEYS } from '../constants/layout';
import { DEFAULT_RHYTHM } from '../constants/defaults';
import { GARBAGE_THRESHOLD } from '../constants/mechanics';
import { getNewY } from '../utils';

const reviewHighwaySystem: ReviewSystem = (entities) => {
  const newEntities = entities;
  const { receptor, notes } = entities;
  const { engine, windowDims } = entities.physics;
  const { song, velocity, videoStartTime, videoEndTime } = entities.settings;
  const { renderedNoteIdx, youTubePlayerState } = entities.state;
  const { startTime, videoTime, videoDelta } = entities.state;
  const rhythm = song.rhythms[0] ?? DEFAULT_RHYTHM;

  // Handle timing synchronization
  if (videoDelta <= 0) return newEntities;

  // Make sure start time set and YouTube background is playing
  if (startTime < 0 || youTubePlayerState !== YouTube.PlayerState.PLAYING)
    return newEntities;

  // Delete old or move active notes
  const endY = windowDims.h + NOTE_DIMS(windowDims).h;
  const receptorY = receptor.getPos().y;
  const noteKeys = Object.keys(notes);

  noteKeys.forEach((key) => {
    const note = notes[key];
    const oldY = note.getPos().y;
    const newY = getNewY(receptorY, note.timing, videoTime, velocity);
    const translation = { x: 0, y: newY - oldY };
    const isGarbage =
      videoTime - note.garbageTime > GARBAGE_THRESHOLD &&
      note.state !== NOTE_KEYS.miss &&
      !note.attemptable;

    if (newY > endY) {
      Matter.Composite.remove(engine.world, note.body);
      delete newEntities.notes[key];
    } else if (!note.state || note.state === NOTE_KEYS.miss || isGarbage)
      Matter.Body.translate(note.body, translation);
  });

  // Add notes based on timing and review start time
  const startY = 0;
  const nextNoteIdx = renderedNoteIdx + 1;

  if (nextNoteIdx < rhythm.timings.length) {
    const newNoteTiming = rhythm.timings[nextNoteIdx];
    const newNoteY = getNewY(receptorY, newNoteTiming, videoTime, velocity);

    if (newNoteTiming < videoStartTime || newNoteTiming >= videoEndTime)
      newEntities.state.renderedNoteIdx = nextNoteIdx;
    else if (newNoteY > startY) {
      const newNote = noteEntity(
        windowDims,
        0,
        newNoteY,
        '',
        '',
        newNoteTiming
      );

      newEntities.notes[nextNoteIdx] = newNote;
      Matter.Composite.add(engine.world, [newNote.body]);
      newEntities.state.renderedNoteIdx = nextNoteIdx;
    }
  }

  return newEntities;
};

export default reviewHighwaySystem;
