import React, {
  Dispatch,
  SetStateAction,
  ReactElement,
  useState,
  useRef,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { GameEngine } from 'react-game-engine';
import type YouTubePlayer from 'youtube-player';

import COLORS from '~/constants/colors';

import { AnySettings, AnyResults, Song } from '../models';
import { isGamePhase } from '../utils';
import { DEFAULT_PLAY_SETTINGS, DEFAULT_SONG } from '../constants/defaults';
import GetReady from '../components/GetReady';
import GameForeground from '../components/GameForeground';
import VideoBackground from '../components/VideoBackground';

interface Baton {
  phase: string;
  setPhase: Dispatch<SetStateAction<string>>;
  prevPhase: string;
  setPrevPhase: Dispatch<SetStateAction<string>>;
  settings: AnySettings;
  song: Song;
  videoPlayer: YouTubePlayer;
  setVideoPlayer: Dispatch<SetStateAction<YouTubePlayer>>;
  setPlayerState: Dispatch<SetStateAction<number>>;
  setResults: Dispatch<SetStateAction<AnyResults>>;
}

const DEFAULT_BATON: Baton = {
  phase: '',
  setPhase: () => {},
  prevPhase: '',
  setPrevPhase: () => {},
  settings: DEFAULT_PLAY_SETTINGS,
  song: DEFAULT_SONG,
  videoPlayer: null,
  setVideoPlayer: () => {},
  setPlayerState: () => {},
  setResults: () => {},
};

interface GameRef {
  setBaton: Dispatch<SetStateAction<Baton>>;
}

interface GameProps {
  ref: ForwardedRef<GameRef>;
}

function Game({ ref }: GameProps): ReactElement {
  const [baton, setBaton] = useState(DEFAULT_BATON);
  const {
    phase,
    setPhase,
    prevPhase,
    setPrevPhase,
    settings,
    song,
    videoPlayer,
    setVideoPlayer,
    setPlayerState,
    setResults,
  } = baton;

  useImperativeHandle(ref, () => ({ setBaton }));

  // Actual game stuff
  const [opacity, setOpacity] = useState(1);
  const gameEngineRef = useRef<GameEngine>(null);

  // Styling
  const gameStyle = {
    display: isGamePhase(phase) ? 'block' : 'none',
    // Needed to hide game excess when zoomed as transition fades
    position: 'fixed' as const, // Prevents input pad touches from dragging game
    top: 0,
    left: 0,
    width: '100%',
    height: 'calc(100vh - calc(100vh - 100%))',
    backgroundColor: COLORS.black,
    // Prevent highlighting issues with pad modes on mobile
    WebkitTouchCallout: 'none' as const,
    WebkitUserSelect: 'none' as const,
    KhtmlUserSelect: 'none' as const,
    MozUserSelect: 'none' as const,
    MsUserSelect: 'none',
    userSelect: 'none' as const,
  };

  return (
    <div style={gameStyle}>
      <GetReady opacity={opacity} song={song} />
      <GameForeground
        phase={phase}
        setPhase={setPhase}
        setPrevPhase={setPrevPhase}
        settings={settings}
        videoPlayer={videoPlayer}
        setResults={setResults}
        gameEngineRef={gameEngineRef}
      />
      <VideoBackground
        phase={phase}
        setPhase={setPhase}
        prevPhase={prevPhase}
        setPrevPhase={setPrevPhase}
        setOpacity={setOpacity}
        setVideoPlayer={setVideoPlayer}
        setPlayerState={setPlayerState}
        gameEngineRef={gameEngineRef}
      />
    </div>
  );
}

export default forwardRef((props, ref: ForwardedRef<GameRef>) => Game({ ref }));
export { DEFAULT_BATON };
export type { Baton };
