export const LABELS = {
  hiragana: [
    ['あ', 'い', 'う', 'え', 'お'],
    ['か', 'き', 'く', 'け', 'こ'],
    ['さ', 'し', 'す', 'せ', 'そ'],
    ['た', 'ち', 'つ', 'て', 'と'],
    ['な', 'に', 'ぬ', 'ね', 'の'],
    ['は', 'ひ', 'ふ', 'へ', 'ほ'],
    ['ま', 'み', 'む', 'め', 'も'],
    ['や', '🌸', 'ゆ', '🌻', 'よ'],
    ['ら', 'り', 'る', 'れ', 'ろ'],
    ['ⓜ'],
    ['わ', 'を', 'ん', 'ー', '～'],
    ['、', '。', '？', '！', '…'],
  ],
  katakana: [
    ['ア', 'イ', 'ウ', 'エ', 'オ'],
    ['カ', 'キ', 'ク', 'ケ', 'コ'],
    ['サ', 'シ', 'ス', 'セ', 'ソ'],
    ['タ', 'チ', 'ツ', 'テ', 'ト'],
    ['ナ', 'ニ', 'ヌ', 'ネ', 'ノ'],
    ['ハ', 'ヒ', 'フ', 'ヘ', 'ホ'],
    ['マ', 'ミ', 'ム', 'メ', 'モ'],
    ['ヤ', '🌸', 'ユ', '🌻', 'ヨ'],
    ['ラ', 'リ', 'ル', 'レ', 'ロ'],
    ['ⓜ'],
    ['ワ', 'ヲ', 'ン', 'ー', '～'],
    ['、', '。', '？', '！', '…'],
  ],
  romaji: {
    あ: 'a',
    い: 'i',
    う: 'u',
    え: 'e',
    お: 'o',

    // K
    か: 'ka',
    き: 'ki',
    く: 'ku',
    け: 'ke',
    こ: 'ko',

    // S
    さ: 'sa',
    し: 'shi',
    す: 'su',
    せ: 'se',
    そ: 'so',

    // T
    た: 'ta',
    ち: 'chi',
    つ: 'tsu',
    て: 'te',
    と: 'to',

    // N
    な: 'na',
    に: 'ni',
    ぬ: 'nu',
    ね: 'ne',
    の: 'no',

    // H
    は: 'ha',
    ひ: 'hi',
    ふ: 'fu',
    へ: 'he',
    ほ: 'ho',

    // M
    ま: 'ma',
    み: 'mi',
    む: 'mu',
    め: 'me',
    も: 'mo',

    // Y
    や: 'ya',
    ゆ: 'yu',
    よ: 'yo',

    // R
    ら: 'ra',
    り: 'ri',
    る: 'ru',
    れ: 're',
    ろ: 'ro',

    // W
    わ: 'wa',
    を: 'wo',

    // Nasal
    ん: 'n',
  },
};

export const KEYS = [
  'Digit1',
  'Digit2',
  'Digit3',
  'KeyQ',
  'KeyW',
  'KeyE',
  'KeyA',
  'KeyS',
  'KeyD',
  'KeyZ',
  'KeyX',
  'KeyC',
];

export const INDEX_MAP = {
  あ: [0, 0],
  い: [0, 1],
  う: [0, 2],
  え: [0, 3],
  お: [0, 4],
  か: [1, 0],
  き: [1, 1],
  く: [1, 2],
  け: [1, 3],
  こ: [1, 4],
  さ: [2, 0],
  し: [2, 1],
  す: [2, 2],
  せ: [2, 3],
  そ: [2, 4],
  た: [3, 0],
  ち: [3, 1],
  つ: [3, 2],
  て: [3, 3],
  と: [3, 4],
  な: [4, 0],
  に: [4, 1],
  ぬ: [4, 2],
  ね: [4, 3],
  の: [4, 4],
  は: [5, 0],
  ひ: [5, 1],
  ふ: [5, 2],
  へ: [5, 3],
  ほ: [5, 4],
  ま: [6, 0],
  み: [6, 1],
  む: [6, 2],
  め: [6, 3],
  も: [6, 4],
  や: [7, 0],
  '🌸': [7, 1],
  ゆ: [7, 2],
  '🌻': [7, 3],
  よ: [7, 4],
  ら: [8, 0],
  り: [8, 1],
  る: [8, 2],
  れ: [8, 3],
  ろ: [8, 4],
  'ⓜ': [9, 0],
  わ: [10, 0],
  を: [10, 1],
  ん: [10, 2],
  ー: [10, 3],
  '～': [10, 4],
  '、': [11, 0],
  '。': [11, 1],
  '？': [11, 2],
  '！': [11, 3],
  '…': [11, 4],
  ア: [0, 0],
  イ: [0, 1],
  ウ: [0, 2],
  エ: [0, 3],
  オ: [0, 4],
  カ: [1, 0],
  キ: [1, 1],
  ク: [1, 2],
  ケ: [1, 3],
  コ: [1, 4],
  サ: [2, 0],
  シ: [2, 1],
  ス: [2, 2],
  セ: [2, 3],
  ソ: [2, 4],
  タ: [3, 0],
  チ: [3, 1],
  ツ: [3, 2],
  テ: [3, 3],
  ト: [3, 4],
  ナ: [4, 0],
  ニ: [4, 1],
  ヌ: [4, 2],
  ネ: [4, 3],
  ノ: [4, 4],
  ハ: [5, 0],
  ヒ: [5, 1],
  フ: [5, 2],
  ヘ: [5, 3],
  ホ: [5, 4],
  マ: [6, 0],
  ミ: [6, 1],
  ム: [6, 2],
  メ: [6, 3],
  モ: [6, 4],
  ヤ: [7, 0],
  ユ: [7, 2],
  ヨ: [7, 4],
  ラ: [8, 0],
  リ: [8, 1],
  ル: [8, 2],
  レ: [8, 3],
  ロ: [8, 4],
  ワ: [10, 0],
  ヲ: [10, 1],
  ン: [10, 2],
};
