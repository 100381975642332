/* eslint-disable import/prefer-default-export */
export const getCaretPositions = (
  element: HTMLElement | null
): { start: number; end: number; direction?: 'forward' | 'backward' } => {
  const selection = window.getSelection();

  if (!selection || !element) return { start: -1, end: -1 };

  const offsets = [selection.anchorOffset, selection.focusOffset];
  const targets = [selection.anchorNode, selection.focusNode];
  const lastIdx = element?.textContent ? element.textContent.length : 0;

  if (!element.contains(targets[0]) || !element.contains(targets[1]))
    return { start: -1, end: -1 };
  else if (targets[0] === element) return { start: lastIdx, end: lastIdx };
  else if (targets[1] === element) return { start: 0, end: lastIdx };

  let hits = [false, false];
  let ps = [0, 0];
  const walk = (e) => {
    let found = false;

    hits = targets.map((t, idx) => hits[idx] || e === t);
    if (hits[0] && hits[1]) found = true;
    else if (e.textContent && !e.firstChild)
      ps = ps.map((p, idx) => (!hits[idx] ? p + e.textContent.length : p));
    for (let c = e.firstChild; c && !found; c = c.nextSibling) found = walk(c);

    return found;
  };

  walk(element);
  ps = ps.map((p, idx) => p + offsets[idx]);

  const start = Math.min(...ps);
  const end = Math.max(...ps);
  const direction = ps[0] < ps[1] ? 'forward' : 'backward';

  return { start, end, ...(ps[0] !== ps[1] && { direction }) };
};
