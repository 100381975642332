import React, { ReactElement, useRef, useEffect } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';

import Map from '../../components/Map';
import ContentCard, { ContentCardRef } from '../../components/ContentCard';
import ModeFlipper from './components/ModeFlipper';
import FieldList from './components/FieldList';
import SurpriseMe from '../../components/SurpriseMe';
import { useBeatboxContext } from '../../providers/BeatboxProvider';
import NotFound from '../NotFound';
import { EXPLORE_PATHS } from '../../constants/paths';
import { MODES } from './constants';

function Explore(): ReactElement {
  const { '*': path } = useParams();
  const {
    state: beatboxState,
    listCategories,
    listTechniques,
  } = useBeatboxContext();
  const contentCardRef = useRef<ContentCardRef>(null);

  // Styling
  const exploreStyle = {
    width: '100%',
  };
  const cornerStyle = {
    position: 'absolute' as const,
    left: '0.6em',
    bottom: '0.6em',
    zIndex: 2,
  };

  // List categories and techniques
  useEffect(() => {
    (async () => {
      await listCategories();
      await listTechniques();
    })();
  }, [beatboxState.subject]);

  // Reduce repetition
  const MapWithRef = () => <Map contentCardRef={contentCardRef} />;
  const FieldListWithRef = ({ mode }) => (
    <FieldList mode={mode} contentCardRef={contentCardRef} />
  );

  return (
    <div style={exploreStyle}>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route index element={<MapWithRef />} />

        {/* Map */}
        <Route path={EXPLORE_PATHS.map} element={<MapWithRef />} />

        {/* List */}
        <Route path={EXPLORE_PATHS.list}>
          <Route index element={<FieldListWithRef mode={MODES.list} />} />
          <Route
            path={EXPLORE_PATHS.listCategory}
            element={<FieldListWithRef mode={MODES.list} />}
          />
        </Route>

        {/* Study */}
        <Route path={EXPLORE_PATHS.study}>
          <Route index element={<FieldListWithRef mode={MODES.study} />} />
          <Route
            path={EXPLORE_PATHS.studyDifficulty}
            element={<FieldListWithRef mode={MODES.study} />}
          />
        </Route>

        {/* Content cards */}
        <Route path={EXPLORE_PATHS.technique} element={<MapWithRef />} />
        <Route path={EXPLORE_PATHS.demo} element={<MapWithRef />} />
        <Route path={EXPLORE_PATHS.tutorial} element={<MapWithRef />} />
      </Routes>
      <ContentCard ref={contentCardRef} />
      <div style={cornerStyle}>
        <ModeFlipper path={path} />
        <SurpriseMe contentCardRef={contentCardRef} />
      </div>
    </div>
  );
}

export default Explore;
