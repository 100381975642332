import { MetricsEntity } from './models';
import { Dim, Pos } from '../predicates/models';
import metricsPredicate from '../predicates/metricsPredicate';
import Metrics from '../renderers/Metrics';

const metricsEntity = (
  windowDims: Dim,
  pos: Pos,
  total: number
): MetricsEntity => ({
  ...metricsPredicate(windowDims, pos, total),
  renderer: Metrics,
});

export default metricsEntity;
