import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { useNavigate, useLocation, To } from 'react-router-dom';

import { LocationState } from '~/models';
import VARIANTS from '~/constants/variants';
import PATHS from '~/constants/paths';
import COLORS from '~/constants/colors';
import { useAuth } from '~/providers/Auth';
import TextInput from '~/components/inputs/TextInput';
import Subtext from '~/components/titles/Subtext';
import Button from '~/components/buttons/Button';
import Announcement from '~/components/Announcement';
import Gap from '~/components/flow/Gap';

import { DEFAULT_LOGIN } from '../constants';

function Form(): ReactElement {
  const [fail, setFail] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation() as { state: LocationState };
  const { login } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: DEFAULT_LOGIN });
  const emailOpts = {
    required: true,
    validate: (email) => validator.isEmail(email),
  };
  const passwordOpts = { required: true };
  const loginButtonText = 'Log In';
  const loginButtonLoadingText = 'Logging in...';
  const cancelButtonText = 'Cancel';

  // Handlers
  const onLogin = (loginInfo) => {
    return new Promise<void>((resolve) => {
      setFail(false);
      login(loginInfo)
        .then(() => navigate(state?.from || PATHS.root))
        .catch(() => {
          setFail(true);
          resolve();
        });
    });
  };
  const handleCancel = () => navigate((state?.from || -1) as To);

  // Styling
  const textInputStyle = {
    width: 'min(320px, 75vw)',
  };
  const buttonHolderStyle = {
    textAlign: 'center' as const,
  };
  const loginButtonStyle = {
    width: '63%',
    marginRight: '3%',
  };
  const cancelButtonStyle = {
    width: '33%',
  };
  const announcementStyle = {
    width: 'min(calc(320px - 1.5em), calc(75vw - 1.5em))',
  };

  return (
    <>
      {fail && (
        <>
          <Announcement style={announcementStyle}>
            Login attempt failed
          </Announcement>
          <Gap />
        </>
      )}
      <form>
        <div>
          <TextInput
            {...register('email', emailOpts)}
            type="email"
            placeholder="Email"
            style={textInputStyle}
          />
          {errors.email && (
            <Subtext color={COLORS.mandy}>Email required</Subtext>
          )}
        </div>
        <Gap />
        <div>
          <TextInput
            {...register('password', passwordOpts)}
            type="password"
            placeholder="Password"
            style={textInputStyle}
          />
          {errors.password && (
            <Subtext color={COLORS.mandy}>Password required</Subtext>
          )}
        </div>
        <Gap />
        <div style={buttonHolderStyle}>
          <Button
            label={loginButtonText}
            loadingLabel={loginButtonLoadingText}
            loading={isSubmitting}
            onClick={handleSubmit(onLogin)}
            onTouchEnd={handleSubmit(onLogin)}
            style={loginButtonStyle}
          />
          <Button
            label={cancelButtonText}
            variant={VARIANTS.secondary}
            onClick={handleCancel}
            onTouchEnd={handleCancel}
            style={cancelButtonStyle}
          />
        </div>
      </form>
    </>
  );
}

export default Form;
