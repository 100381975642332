import { SyntheticEvent } from 'react';

import { stopEventEffects } from '~/utils';

import { Node, Note } from './models';
import { MARKS } from './constants/language';

export const getPx = (v?: number): string => `${v ?? 0}px`;

export const getMaxTiming = (chart: Note[]): number => {
  const lastNote = chart.reduce(
    (acc, n) => ((acc.end ?? acc.start) > (n.end ?? n.start) ? acc : n),
    { end: 0 } as Note
  );

  return lastNote.end ?? lastNote.start;
};

export const extractNodes = (chart: Note[]): Record<number, Node> =>
  chart.map((n) => n.node).reduce((acc, n) => ({ ...acc, [n.id]: n }), {});

export const cleanCarets = (s: string): string => {
  const re = new RegExp(`\\${MARKS.caret}`, 'g');

  return s.replace(re, '');
};

export const getPlaySound =
  (url: string) =>
  (event: SyntheticEvent): void => {
    const audio = new Audio(url);

    stopEventEffects(event);
    audio.ontimeupdate = () => {
      if (audio.currentTime > 0.6) audio.pause();
    };
    audio.play();
  };
