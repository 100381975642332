import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  MutableRefObject,
} from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5Hierarchy from '@amcharts/amcharts5/hierarchy';
import am5ThemesAnimated from '@amcharts/amcharts5/themes/Animated';
import { useParams } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';

import COLORS from '~/constants/colors';
import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';
import { stopEventEffects, sleep } from '~/utils';
import Code from '~/components/flow/Code';

import { Vertex, VertexDicts } from '../models';
import { DEFAULT_CATEGORY_VERTEX } from '../constants/defaults';
import {
  useBeatboxContext,
  Category,
  Technique,
} from '../providers/BeatboxProvider';
import { ContentCardRef } from './ContentCard';

interface MapProps {
  contentCardRef: MutableRefObject<ContentCardRef | null>;
}

// TODO: add breadcrumb navigation via https://www.amcharts.com/docs/v5/charts/hierarchy/breadcrumbs/
function Map({ contentCardRef }: MapProps): ReactElement {
  const { techniqueId, demoId, tutorialId } = useParams();
  const { state: beatboxState } = useBeatboxContext();
  const [opacity, setOpacity] = useState(1);
  const exploreTrigger = isBrowser ? 'Left-click' : 'Tap';
  const inspectTrigger = isBrowser ? 'right-click' : 'double-tap';

  // Settings
  const seriesRef = useRef<am5Hierarchy.ForceDirected | null>(null);
  const chartDiv = 'beatboxdb-map-chart-div';
  const seriesOpts = {
    downDepth: 1,
    initialDepth: 0,
    topDepth: 1,
    valueField: 'value',
    categoryField: 'name',
    idField: 'id',
    childDataField: 'children',
    linkWithField: 'link',
    minRadius: 7.5,
    maxRadius: am5.percent(3.5),
    nodePadding: 10,
    centerStrength: 0.4,
    manyBodyStrength: -4,
    velocityDecay: 0.9,
  };

  // Data
  const [vertices, setVertices] = useState<Vertex[]>([]);
  const rootVertex: Vertex = {
    id: 'r0',
    name: '',
    value: 0,
    children: vertices,
  };
  const data = [rootVertex];

  // Processing
  const getVertexId = (l: string, id: number): string => `${l}${id}`;
  const categoryToVertex = (c: Category): Vertex => ({
    id: getVertexId('c', c.id),
    name: c.label,
    children: [],
  });
  const techniqueToVertex = (t: Technique): Vertex => ({
    id: getVertexId('t', t.id),
    name: t.name,
    children: [],
    link: t.parent_techniques?.map((pt) => getVertexId('t', pt.id)) || [],
    value: 1,
  });
  const getTechniqueDict = (ts: Technique[]): Record<number, Technique> =>
    ts.reduce((acc, t) => ({ ...acc, [t.id]: t }), {});
  const getInitVertexDicts = (
    cs: Category[],
    ts: Technique[]
  ): VertexDicts => ({
    categories: cs.reduce(
      (acc, c) => ({ ...acc, [getVertexId('c', c.id)]: categoryToVertex(c) }),
      { [DEFAULT_CATEGORY_VERTEX.id]: DEFAULT_CATEGORY_VERTEX }
    ),
    techniques: ts.reduce(
      (acc, t) => ({ ...acc, [getVertexId('t', t.id)]: techniqueToVertex(t) }),
      {}
    ),
  });
  const getParentVertexIdPair = (
    t: Technique,
    tDict: Record<number, Technique>
  ): [string, number] => {
    const getUATime = (c) => new Date(c.updatedAt).getTime();
    const c0 = t.categories?.sort((c1, c2) => getUATime(c2) - getUATime(c1))[0];
    const pt0 = c0
      ? t.parent_techniques?.find((pt) =>
          tDict[pt.id].categories?.some((ptc) => ptc.id === c0.id)
        )
      : t.parent_techniques?.[0];

    return pt0
      ? ['t', pt0.id]
      : ['c', c0?.id || Number(DEFAULT_CATEGORY_VERTEX.id.slice(1))];
  };
  const dictReducer =
    (tDict: Record<number, Technique>) =>
    (vDicts: VertexDicts, t: Technique): VertexDicts => {
      const pvIdPair = getParentVertexIdPair(t, tDict);
      const pvId = getVertexId(pvIdPair[0], pvIdPair[1]);
      const vId = getVertexId('t', t.id);
      const keys =
        pvIdPair[0] === 'c'
          ? ['categories', 'techniques']
          : ['techniques', 'categories'];

      return {
        [keys[0]]: {
          ...vDicts[keys[0]],
          [pvId]: {
            ...vDicts[keys[0]][pvId],
            children: vDicts[keys[0]][pvId].children.concat(
              vDicts.techniques[vId]
            ),
          },
        },
        [keys[1]]: vDicts[keys[1]],
      } as VertexDicts;
    };
  const treeRecursor =
    (vDicts: VertexDicts) =>
    (v: Vertex): Vertex => {
      if (v.children.length === 0) return v;

      return {
        ...v,
        children: v.children.map((cv) =>
          treeRecursor(vDicts)(vDicts.techniques[cv.id])
        ),
      };
    };
  const calcValue = (v: Vertex): Vertex => {
    const children = v.children.map(calcValue);
    const value = children.reduce((acc, cv) => acc + (cv.value || 0), 0) + 1;

    return { ...v, children, value };
  };
  const notEmptyDefaultCategoryVertex = (v: Vertex): boolean =>
    v.name !== DEFAULT_CATEGORY_VERTEX.name || v.children.length > 0;

  // Styling
  const chartTime = '2s';
  const loadingTime = '1s';
  const containerStyle = {
    position: 'absolute' as const,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    marginBottom: '-0.5em',
    zIndex: -1,
  };
  const disclaimerStyle = {
    marginTop: '-0.5em',
    textAlign: 'center' as const,
    color: COLORS.white,
  };
  const chartDivStyle = {
    width: '100%',
    height: '110%',
    opacity: 1 - opacity,
    transition: `opacity ${chartTime}`,
  };
  const loadingStyle = {
    display: 'table',
    position: 'absolute' as const,
    width: '100%',
    height: '100%',
    textAlign: 'center' as const,
    opacity,
    zIndex: 2 * opacity,
    transition: `opacity ${loadingTime}, z-index 0s ${loadingTime}`,
  };
  const loadingCellStyle = {
    display: 'table-cell',
    verticalAlign: 'middle',
  };
  const loadingCodeStyle = {
    color: COLORS.white,
    fontSize: FONT_SIZES.title,
    fontWeight: FONT_WEIGHTS.bold,
    boxShadow: `-0.08em 0.08em ${COLORS.black25}`,
  };

  // Set up graph
  useLayoutEffect(() => {
    const root = am5.Root.new(chartDiv);
    const container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );
    const series = container.children.push(
      am5Hierarchy.ForceDirected.new(root, seriesOpts)
    );

    // Handle showing card
    if (isBrowser)
      series.nodes.template.events.on('rightclick', (e) => {
        const nodeId = (e.target.dataItem?.dataContext as any).id;

        if (nodeId?.[0] === 't')
          contentCardRef.current?.showTechnique(Number(nodeId.slice(1)));
      });
    else {
      const callbacks = {};
      const clicks = {};
      const doubleClickTime = 200;

      series.nodes.template.events.on('pointerdown', (e) => {
        const key = '_listeners';
        const { uid } = e.target;

        if (!callbacks[uid])
          callbacks[uid] = e.target.events[key]
            .filter((l) => l.type === 'click')
            .map((l) => l.callback);
        callbacks[uid].slice(1).forEach((c) => e.target.events.off('click', c));
      });
      series.nodes.template.events.on('click', async (e) => {
        const { uid } = e.target;

        if (!clicks[uid]) {
          clicks[uid] = true;
          await sleep(doubleClickTime);
          if (clicks[uid] && callbacks[uid][3]) {
            callbacks[uid][1]();
            callbacks[uid][3]();
          }
        } else {
          const nodeId = (e.target.dataItem?.dataContext as any).id;

          if (nodeId?.[0] === 't')
            contentCardRef.current?.showTechnique(Number(nodeId.slice(1)));
        }
        callbacks[uid].slice(1).forEach((c) => e.target.events.on('click', c));
        if (clicks[uid]) delete clicks[uid];
      });
    }

    // Match label color to tooltip text
    series.labels.template.adapters.add('fill', (fill, target) => {
      const hex = target.parent?.children.values[1].get(
        'fill' as keyof am5.ISpriteSettings
      );
      const sum = [hex?.r, hex?.g, hex?.b].reduce((acc, v) => acc + v, 0);

      return am5.color(sum < 448 ? COLORS.white : COLORS.black);
    });

    // Complete graph setup
    root.setThemes([am5ThemesAnimated.new(root)]);
    root.addDisposer(
      am5.utils.addEventListener(root.dom, 'contextmenu', stopEventEffects)
    );
    seriesRef.current = series;

    return () => root.dispose();
  }, []);

  // Process data
  useEffect(() => {
    if (beatboxState.categories && beatboxState.techniques) {
      const tDict = getTechniqueDict(beatboxState.techniques);
      const initVDicts = getInitVertexDicts(
        beatboxState.categories,
        beatboxState.techniques
      );
      const vDicts = beatboxState.techniques.reduce(
        dictReducer(tDict),
        initVDicts
      );
      const newVertices = Object.values(vDicts.categories)
        .map(treeRecursor(vDicts))
        .map(calcValue)
        .map((v) => ({ ...v, value: (v.value || 0) - 1 }))
        .filter(notEmptyDefaultCategoryVertex);

      setVertices(newVertices);
      setOpacity(0);
    }
  }, [beatboxState.categories, beatboxState.techniques]);

  // Set data in graph
  useEffect(() => {
    seriesRef.current?.data.setAll(data);
    // seriesRef.current?.set('selectedDataItem', seriesRef.current?.dataItems[0]);
  }, [data]);

  // Show card from routing
  useEffect(() => {
    if (techniqueId) contentCardRef.current?.showTechnique(techniqueId);
    else if (demoId) contentCardRef.current?.showDemo(demoId);
    else if (tutorialId) contentCardRef.current?.showTutorial(tutorialId);
  }, [techniqueId, demoId, tutorialId]);

  return (
    <>
      <div style={disclaimerStyle}>
        {exploreTrigger} to explore, {inspectTrigger} to inspect
      </div>
      <div style={containerStyle}>
        <div style={loadingStyle}>
          <div style={loadingCellStyle}>
            <Code style={loadingCodeStyle}>Loading</Code>
          </div>
        </div>
        <div id={chartDiv} style={chartDivStyle} />
      </div>
    </>
  );
}

export default Map;
