import React, { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';

import COLORS from '~/constants/colors';
import FramedView from '~/components/FramedView';
import Title from '~/components/titles/Title';
import Divider from '~/components/flow/Divider';
import { useAuth } from '~/providers/Auth';

import PhaseSwitchBoard from '../components/PhaseSwitchBoard';
import NotFound from './NotFound';
import Unauthed from './Unauthed';
import WantedList from '../components/WantedList';
import { hasRoleAccess, hasPermission } from '../utils';
import CONTENT from '../constants/content';
import PATHS, { BOUNTIES_PATHS } from '../constants/paths';

// TODO: consider eventually adding way to exchange techniques
function Bounties(): ReactElement {
  const { state: authState } = useAuth();
  const phasePathsDict = Object.keys(BOUNTIES_PATHS)
    .filter((k) => CONTENT[k])
    .reduce((acc, k) => ({ ...acc, [k]: BOUNTIES_PATHS[k] }), {});

  // Styling
  const bountiesStyle = {
    width: 'min(45em, 82.5vw)',
  };
  const frameColors: [string, string] = [
    COLORS.waikawaGray,
    COLORS.waikawaGray,
  ];

  // Reduce repetition
  const RoleUnauthed = () => <Unauthed nested roleRequired />;
  const indexKey = hasRoleAccess(authState)
    ? CONTENT.techniques
    : PATHS.unauthed;
  const indexElements = {
    techniques: <WantedList plural={CONTENT.techniques} />,
    unauthed: <RoleUnauthed />,
  };

  return (
    <FramedView accent colors={frameColors} style={bountiesStyle}>
      <Title>Bounties</Title>
      <br />
      <PhaseSwitchBoard
        authState={authState}
        pagePath={PATHS.bounties}
        phasePathsDict={phasePathsDict}
      />
      <Divider />
      <Routes>
        <Route path="*" element={<NotFound nested />} />
        <Route index element={indexElements[indexKey]} />

        {/* Techniques */}
        {hasRoleAccess(authState) ? (
          <Route
            path={BOUNTIES_PATHS.techniques}
            element={<WantedList plural={CONTENT.techniques} />}
          />
        ) : (
          <Route path={BOUNTIES_PATHS.techniques} element={<RoleUnauthed />} />
        )}

        {/* Demos */}
        {hasPermission(authState, CONTENT.demos) ? (
          <Route
            path={BOUNTIES_PATHS.demos}
            element={<WantedList plural={CONTENT.demos} />}
          />
        ) : (
          <Route path={BOUNTIES_PATHS.demos} element={<RoleUnauthed />} />
        )}

        {/* Tutorials */}
        {hasPermission(authState, CONTENT.tutorials) ? (
          <Route
            path={BOUNTIES_PATHS.tutorials}
            element={<WantedList plural={CONTENT.tutorials} />}
          />
        ) : (
          <Route path={BOUNTIES_PATHS.tutorials} element={<RoleUnauthed />} />
        )}
      </Routes>
    </FramedView>
  );
}

export default Bounties;
