import React, { ReactElement } from 'react';

import { InputPadPredicate } from '../predicates/models';
import { RendererProps } from './models';
import FullPad from '../components/FullPad';

function InputPad({
  predicate,
}: RendererProps<InputPadPredicate>): ReactElement {
  const panelB = predicate.panelDims.b ?? 0;
  const panelM = predicate.panelDims.m ?? 0;
  const panelMeas = {
    width: `${predicate.panelDims.w}px`,
    height: `${predicate.panelDims.h}px`,
    border: `${panelB}px`,
    margin: `${panelM}px`,
    space: `${2 * panelB + 2 * panelM}px`,
  };

  return (
    <FullPad
      callback={predicate.callback}
      panelMeas={panelMeas}
      inputMode={predicate.inputMode}
      whichPadLabels={predicate.whichPadLabels}
      targetLabel={predicate.targetLabel}
      guidance={predicate.guidance}
      phase={predicate.phase}
      style={predicate.style}
    />
  );
}

export default InputPad;
