import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';

import { stopEventEffects } from '~/utils';
import COLORS from '~/constants/colors';
import Link from '~/components/flow/Link';
import urls from '~/urls';
import Code from '~/components/flow/Code';
import FramedView from '~/components/FramedView';
import Divider from '~/components/flow/Divider';
import Checkbox from '~/components/inputs/Checkbox';
import Title from '~/components/titles/Title';
import Header from '~/components/titles/Header';

import FullPad from '../components/FullPad';
import { getWindowDims } from '../utils';
import {
  NOTE_DIMS,
  NOTE_KEYS,
  INPUT_MODES,
  RECEPTOR_DIMS,
} from '../constants/layout';
import notePredicate from '../predicates/notePredicate';
import Note from '../renderers/Note';
import bridgePredicate from '../predicates/bridgePredicate';
import Bridge from '../renderers/Bridge';
import receptorPredicate from '../predicates/receptorPredicate';
import Receptor from '../renderers/Receptor';
import { DEFAULT_INPUT_PANEL_MEAS } from '../components/InputPanel';

function Tutorial(): ReactElement {
  const windowDims = getWindowDims();
  const noteDims = NOTE_DIMS(windowDims);
  const receptorDims = RECEPTOR_DIMS(windowDims);
  const receptorB = receptorDims.b ?? 0;
  const getPx = (v) => `${v}px`;

  // Demo notes
  const demoNotesContainer = {
    width: `min(${getPx(2.5 * noteDims.w + 2 * receptorB)}, 90%)`,
    height: getPx(3 * noteDims.h + 2 * receptorB),
    display: 'inline-flex',
    position: 'relative' as const,
    margin: `calc(1em - ${getPx(receptorB)}) calc(1em - ${getPx(
      receptorB
    )}) 0.25em`,
    overflow: 'hidden',
  };
  const note1 = notePredicate(
    windowDims,
    -windowDims.w / 2 + noteDims.w / 2 + receptorB,
    noteDims.h / 2 + receptorB,
    '本',
    '',
    0,
    false
  );
  const note2 = notePredicate(
    windowDims,
    -windowDims.w / 2 + 2 * noteDims.w + receptorB,
    noteDims.h / 2 + 2 * noteDims.h + receptorB,
    '本',
    '',
    0,
    false,
    NOTE_KEYS.medial
  );
  const bridge1 = bridgePredicate(
    windowDims,
    2 * noteDims.h,
    0,
    -windowDims.w / 2 + 2 * noteDims.w + receptorB,
    2.5 * noteDims.h + receptorB,
    0
  );

  // Demo receptor
  const demoReceptorContainer = {
    width: `min(${getPx(
      receptorDims.w + noteDims.w / 2 + 4 * receptorB
    )}, 90%)`,
    height: getPx(1.75 * noteDims.h + 2 * receptorB),
    display: 'inline-flex',
    position: 'relative' as const,
    margin: `calc(1em - ${getPx(receptorB)}) calc(1em - ${getPx(
      receptorB
    )}) 0.25em`,
    overflow: 'hidden',
  };
  const note3 = notePredicate(
    windowDims,
    -windowDims.w / 2 + noteDims.w / 2 + 2 * receptorB,
    noteDims.h / 2 + receptorB,
    '日',
    '',
    0,
    false,
    NOTE_KEYS.lateral
  );
  const receptor1 = receptorPredicate(
    windowDims,
    1.25 * noteDims.h,
    {},
    INPUT_MODES.gym,
    -windowDims.w / 2 + receptorDims.w / 2 + 4 * receptorB
  );

  // Input pad demo
  const [isSwipe, setIsSwipe] = useState(false);
  const [inputMode, setInputMode] = useState(INPUT_MODES.key);
  const panelMeas = {
    ...DEFAULT_INPUT_PANEL_MEAS,
    ...(inputMode === INPUT_MODES.gym && {
      width: isMobile ? 'min(16.7vmin, 105px)' : 'min(9.66vmin, 105px)',
      height: isMobile ? 'min(11.9vmin, 75px)' : 'min(6.9vmin, 75px)',
      border: isMobile ? 'min(0.48vmin, 3px)' : 'min(0.28vmin, 3px)',
      margin: isMobile ? 'min(0.48vmin, 3px)' : 'min(0.28vmin, 3px)',
      space: isMobile ? 'min(1.92vmin, 12px)' : 'min(1.12vmin, 12px)', // From 2 margins plus 2 borders
    }),
  };
  const fullPadRef = useRef<HTMLDivElement>(null);
  const fullPadStyle = {
    position: 'fixed' as const,
  };
  const handleChecked = (mode) => (event) => {
    if (event.target.checked) setInputMode(mode);
    else setInputMode(INPUT_MODES.key);
  };
  const handleTouchMove = () => {
    setIsSwipe(true);
  };
  const handleHideFullPad = (event) => {
    const clickOutside = !fullPadRef.current?.contains(event.target);
    const notCheckbox = event.target.type !== 'checkbox';
    const isDisplayed = fullPadRef.current?.style.display !== 'none';
    const notSwipe = !(isMobile && isSwipe);

    if (clickOutside && isDisplayed && notCheckbox && notSwipe) {
      stopEventEffects(event);
      setInputMode(INPUT_MODES.key);
    }
    setIsSwipe(false);
  };

  // Styling
  const tutorialStyle = {
    width: 'min(45em, 82.5vw)',
    paddingBottom: '1.08em',
    ...(inputMode !== INPUT_MODES.key && {
      // Prevent highlighting issues with pad on mobile
      WebkitTouchCallout: 'none' as const,
      WebkitUserSelect: 'none' as const,
      KhtmlUserSelect: 'none' as const,
      MozUserSelect: 'none' as const,
      MsUserSelect: 'none',
      userSelect: 'none' as const,
    }),
  };
  const frameColors: [string, string] = [
    COLORS.waikawaGray,
    COLORS.waikawaGray,
  ];
  const ulStyle = {
    paddingLeft: '1.5em',
  };
  const checkboxLevel = {
    margin: '0.5em 0',
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleHideFullPad);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleHideFullPad);

    return () => {
      document.removeEventListener('mousedown', handleHideFullPad);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleHideFullPad);
    };
  });

  return (
    <FramedView accent colors={frameColors} style={tutorialStyle}>
      <Title>Tutorial</Title>
      <br />
      This app is a rhythm game that helps you study kanji and practice typing
      in Japanese. The timing combos reinforce memory chunking, while the song
      tries to make the learning process less mundane. Basically, imagine your
      flashcards became DDR.
      <Divider />
      <Header>Colloquial Concepts</Header>
      Here&apos;s some lingo you&apos;ll encounter.
      <ul style={ulStyle}>
        <li>
          <b>Songs</b> provide music for the game
        </li>
        <li>
          <b>Rhythms</b> are timing patterns for songs
          <ul style={ulStyle}>
            <li>Each rhythm is associated to one song</li>
            <li>A song can have multiple rhythms of varying difficulty</li>
          </ul>
        </li>
        <li>
          <b>Homeworks</b> are the problems you want to practice during gameplay
        </li>
        <ul style={ulStyle}>
          <li>Each problem consists of a prompt and answer</li>
        </ul>
      </ul>
      To exemplify, suppose you play a song with a rhythm consisting of two
      timings. You&apos;ve picked a homework with a problem that has prompt{' '}
      <Code>本</Code> and answer <Code>ほん</Code>. You&apos;d see something
      like this.
      <br />
      <div style={demoNotesContainer}>
        <Note predicate={note1} />
        <Note predicate={note2} />
        <Bridge predicate={bridge1} />
      </div>
      <br />
      The correct input for the leading blue note would be <Code>ほ</Code>. The
      correct input for the trailing green note would be <Code>ん</Code>. The
      tail extending out of the leading note indicates the two notes are part of
      the same problem.
      <Divider />
      <Header>Gameplay Guide</Header>
      As with many rhythm games, the goal here is to hit a note on the note
      highway when it lines up with the receptor by creating some input. Stay
      calm! This note hasn&apos;t arrived yet.
      <br />
      <div style={demoReceptorContainer}>
        <Note predicate={note3} />
        <Receptor predicate={receptor1} />
      </div>
      <br />
      Unlike many rhythm games, the input pad is based on a Japanese flick
      keyboard. You want to <i>type</i> in time with the arriving notes. There
      are three modes that can be chosen.
      <ul style={ulStyle}>
        <li>
          <b>🏃‍♀️ mode</b> is a single flick panel that changes depending on the
          upcoming note
          <ul style={ulStyle}>
            <li>
              You can click and swipe or hold <Code>s</Code> and use the arrow
              keys
            </li>
            <div style={checkboxLevel}>
              <Checkbox
                checked={inputMode === INPUT_MODES.jog}
                onChange={handleChecked(INPUT_MODES.jog)}
              />{' '}
              Try out jog mode
            </div>
          </ul>
        </li>
        <li>
          <b>🏋️‍♂️ mode</b> is the full Japanese flick keyboard
          <ul style={ulStyle}>
            <li>
              You can click and swipe or hold a key between <Code>1</Code> and{' '}
              <Code>c</Code> and use the arrow keys
            </li>
            <li>
              Even if the flick ring doesn&apos;t appear, you can still swipe
            </li>
            <li>This mode is not recommend for beginners</li>{' '}
            <div style={checkboxLevel}>
              <Checkbox
                checked={inputMode === INPUT_MODES.gym}
                onChange={handleChecked(INPUT_MODES.gym)}
              />{' '}
              Try out gym mode
            </div>
          </ul>
        </li>
        <li>
          <b>⌨️ mode</b> is input by keyboard
          <ul style={ulStyle}>
            <li>
              This mode won&apos;t work on mobile unless you have an external
              keyboard
            </li>
            <li>
              Some homeworks require keyboard entry depending on the answers
            </li>
          </ul>
        </li>
      </ul>{' '}
      <FullPad
        panelMeas={panelMeas}
        inputMode={inputMode}
        targetLabel="あ"
        callback={() => ({})}
        style={fullPadStyle}
        ref={fullPadRef}
      />
      With that, you should be able to piece together how to play. If you have
      further questions or find any bugs, feel free to join my{' '}
      <Link href={urls.discordInvite}>Discord</Link> and post in{' '}
      <Code>#japanese</Code>. Lets ace N5 by end of this year!
    </FramedView>
  );
}

export default Tutorial;
