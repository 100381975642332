import React, { Dispatch, SetStateAction, useState } from 'react';

import { stopEventEffects } from '~/utils';

import { Settings } from '../../models';
import { RATES } from '../../constants';
import { ALPHA_0, ALPHA_1, ALPHA_2, ALPHA_3, ALPHA_4 } from '../../beats';
import { adjustChartRate, processBeat } from '../../utils';
import { BEAT } from '../../../../constants/defaults';
import Visualize from '../../../../components/Visualize';
import { Beat } from '../../../../models';

interface MenuProps {
  settings: Settings;
  setSettings: Dispatch<SetStateAction<Settings>>;
  setBeat: Dispatch<SetStateAction<Beat>>;
  progress: number;
}

function Menu({ settings, setSettings, setBeat, progress }: MenuProps) {
  const [rate, setRate] = useState(RATES.default);
  const options = {
    [ALPHA_0.title]: ALPHA_0,
    [ALPHA_1.title]: ALPHA_1,
    [ALPHA_2.title]: ALPHA_2,
    [ALPHA_3.title]: ALPHA_3,
    [ALPHA_4.title]: ALPHA_4,
  };
  const getBeatKey = (idx) => `BeatbloxReadMenuBeatOptions${idx}`;
  const getRateKey = (idx) => `BeatbloxReadMenuRateOptions${idx}`;

  // Handlers
  const switchBeat = (event) => {
    const beat = options[event.target.value];
    const processed = processBeat(beat, rate);

    stopEventEffects(event);
    setSettings({
      ...settings,
      title: beat.title,
      bpm: beat.bpm * rate,
      chart: processed.chart,
      charts: processed.charts,
    });
    setBeat(beat);
  };
  const selectRate = (event) => {
    stopEventEffects(event);
    setRate(event.target.value);
    setSettings({
      ...settings,
      bpm: settings.bpm * event.target.value,
      chart: adjustChartRate(settings.chart, event.target.value),
      charts: Object.keys(settings.charts).reduce(
        (acc, g) => ({
          ...acc,
          [g]: adjustChartRate(settings.charts[g], event.target.value),
        }),
        {}
      ),
    });
  };

  return (
    <>
      <select value={settings.title} onChange={switchBeat}>
        <option value="" disabled hidden>
          Select beat
        </option>
        {Object.keys(options).map((k, idx) => (
          <option key={getBeatKey(idx)} value={k}>
            {k}
          </option>
        ))}
      </select>
      <select value={rate} onChange={selectRate}>
        {Object.values(RATES).map((r, idx) => (
          <option key={getRateKey(idx)} value={r}>
            {r}
          </option>
        ))}
      </select>
      <Visualize
        beat={options[settings.title] ?? BEAT}
        charts={settings.charts}
        progress={progress}
      />
    </>
  );
}

export default Menu;
