import React, { ReactElement, CSSProperties } from 'react';

import COLORS from '~/constants/colors';

interface DividerProps {
  style?: CSSProperties;
}

function Divider({ style }: DividerProps): ReactElement {
  const dividerStyle = {
    backgroundColor: COLORS.alto,
    border: 0,
    height: '1px',
    margin: '1em 0',
    ...style,
  };

  return <hr style={dividerStyle} />;
}

export default Divider;
