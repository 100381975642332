import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import { FONT_WEIGHTS } from '~/constants/typography';

import { Node } from '../../../models';
import { MISSING, GROUPS } from '../../../constants/defaults';
import { getPx, getPlaySound } from '../../../utils';

interface BlockProps {
  width: number;
  height: number;
  track: number;
  maxTrack: number;
  start: number;
  end?: number;
  maxTiming: number;
  node: Node;
  progress: number;
  group?: number;
}

function Block({
  width,
  height,
  track,
  maxTrack,
  start,
  end,
  maxTiming,
  node,
  progress = 0,
  group,
}: BlockProps): ReactElement {
  const borderWidth = 2;
  const blockWidth = getPx(width);
  const blockHeight = getPx(height);
  const bridgeColor = COLORS.gray33;
  let blockColor = COLORS.gray;

  if (progress > start) blockColor = GROUPS[group ?? 0];
  else if (node.id === MISSING.id) blockColor = COLORS.mandy;

  // Handlers
  const playSound = getPlaySound(node.sample.recording.url);

  // Styling
  const blockStyle = {
    position: 'absolute' as const,
    minWidth: blockWidth,
    height: blockHeight,
    left: `${(start / maxTiming) * 100}%`,
    top: `${(track / maxTrack) * 100}%`,
    padding: `0 ${getPx(5)}`,
    transform: `translate(-${getPx(width / 2)}, -50%)`,
    textAlign: 'center' as const,
    lineHeight: getPx(height - 2 * borderWidth),
    color: node.id === MISSING.id ? COLORS.white : COLORS.tundora,
    fontWeight: FONT_WEIGHTS.bold,
    backgroundColor: node.id === MISSING.id ? blockColor : COLORS.white,
    borderRadius: `calc(${blockHeight} * 0.3)`,
    border: `${getPx(borderWidth)} solid ${blockColor}`,
    boxSizing: 'border-box' as const,
    overflow: 'hidden',
    cursor: 'pointer',
    zIndex: 4,
  };
  const bridgeStyle = {
    position: 'absolute' as const,
    ...(end && { width: `${((end - start) / maxTiming) * 100}%` }),
    height: `calc(${blockHeight} / 2)`,
    left: `${(start / maxTiming) * 100}%`,
    top: `${(track / maxTrack) * 100}%`,
    transform: 'translate(0, -50%)',
    backgroundColor: bridgeColor,
    zIndex: 2,
  };
  const progressStyle = {
    ...bridgeStyle,
    ...(end && {
      width: `${((Math.min(progress, end) - start) / maxTiming) * 100}%`,
    }),
    backgroundColor: blockColor,
    zIndex: 3,
  };

  return (
    <>
      <div
        role="button"
        tabIndex={-1}
        onMouseUp={playSound}
        onTouchEnd={playSound}
        style={blockStyle}
      >
        {node.notation}
      </div>
      {!Number.isNaN(end) && <div style={bridgeStyle} />}
      {!Number.isNaN(end) && progress > start && <div style={progressStyle} />}
    </>
  );
}

export default Block;
