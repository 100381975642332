export const getArrayBuffer = async (
  file: File | Blob
): Promise<ArrayBuffer> => {
  let arrayBuffer;

  if (file.arrayBuffer) arrayBuffer = await file.arrayBuffer();
  else {
    const fileReader = new FileReader();
    const bufferPromise = new Promise((resolve) => {
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.readAsArrayBuffer(file);
    });

    arrayBuffer = await bufferPromise;
  }

  return arrayBuffer;
};

export const getAudioBufferFromArrayBuffer = async (
  arrayBuffer: ArrayBuffer
): Promise<AudioBuffer> => {
  const context = new AudioContext();
  const audioBuffer = await context.decodeAudioData(arrayBuffer);

  if (context.state !== 'closed') await context.close();

  return audioBuffer;
};
