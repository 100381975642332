import React, { ReactElement, useLayoutEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import COLORS from '~/constants/colors';
import FramedView from '~/components/FramedView';
import Title from '~/components/titles/Title';
import Header from '~/components/titles/Header';
import Divider from '~/components/flow/Divider';
import { useAuth } from '~/providers/Auth';
import Gap from '~/components/flow/Gap';
import Paginator from '~/components/Paginator';

import CONTENT from '../constants/content';
import { useBeatboxContext } from '../providers/BeatboxProvider';
import ContentItem from '../components/ContentItem';
import NoContentItems from '../components/NoContentItems';
import uris from '../uris';
import ContentCard, { ContentCardRef } from '../components/ContentCard';
import LearnButton from '../components/LearnButton';

function Search(): ReactElement {
  const { state: authState } = useAuth();
  const { state: beatboxState, listMainSearchTechniques } = useBeatboxContext();
  const contentCardRef = useRef<ContentCardRef>(null);
  const getCallback = (techniqueId) => () =>
    contentCardRef.current?.showTechnique(techniqueId);
  const getKey = (idx) => `BeatboxDbSearchContentItem${idx}`;

  // Processing
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const page = Number(searchParams.get('page')) || 1;
  const data = beatboxState.mainSearchTechniques?.data;
  const itemTotal =
    beatboxState.mainSearchTechniques?.meta.pagination.total || 0;

  // Styling
  const searchStyle = {
    width: 'min(45em, 82.5vw)',
  };
  const itemTotalStyle = {
    color: COLORS.dodgerBlue,
  };
  const learnButtonStyle = {
    float: 'right' as const,
    marginRight: '0.15em',
  };
  const frameColors: [string, string] = [
    COLORS.waikawaGray,
    COLORS.waikawaGray,
  ];

  // List found techniques
  useLayoutEffect(() => {
    (async () => {
      if (authState.isAuthed && query)
        await listMainSearchTechniques(query, page);
    })();
  }, [beatboxState.subject, query, page]);

  return (
    <>
      <FramedView accent colors={frameColors} style={searchStyle}>
        <Title>Search</Title>
        <Divider />
        <Header>Results Found</Header>A total of{' '}
        <b style={itemTotalStyle}>{itemTotal}</b>{' '}
        {itemTotal === 1 ? 'entry' : 'entries'} matched your query
        <Gap />
        {(data?.length || 0) > 0 ? (
          <>
            {data?.map((t, idx) => (
              <ContentItem
                key={getKey(idx)}
                id={t.id}
                label={t.name}
                gutterWidth="5.5em"
                callback={getCallback(t.id)}
              >
                <LearnButton
                  techniqueId={t.id}
                  orientation="left"
                  style={learnButtonStyle}
                />
              </ContentItem>
            ))}
            <Paginator link={uris.search(query)} itemTotal={itemTotal} />
          </>
        ) : (
          <NoContentItems plural={CONTENT.techniques} />
        )}
      </FramedView>
      <ContentCard ref={contentCardRef} />
    </>
  );
}

export default Search;
