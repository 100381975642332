import React, {
  ReactElement,
  Dispatch,
  SetStateAction,
  CSSProperties,
} from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import Slider from '~/components/inputs/Slider';

interface SongIntervalSliderProps {
  duration: number;
  playerStartTime: number;
  playerEndTime: number;
  setPlayerStartTime: Dispatch<SetStateAction<number>>;
  setPlayerEndTime: Dispatch<SetStateAction<number>>;
  style?: CSSProperties;
}

function SongIntervalSlider({
  duration,
  playerStartTime,
  playerEndTime,
  setPlayerStartTime,
  setPlayerEndTime,
  style,
}: SongIntervalSliderProps): ReactElement {
  const handleChange = (values) => {
    if (values[0] !== playerStartTime) setPlayerStartTime(values[0]);
    if (values[1] !== playerEndTime) setPlayerEndTime(values[1]);
  };
  const getMark = (label) => ({
    style: {
      maxWidth: 'min(3em, 15vw)',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: COLORS.tundora,
      fontSize: FONT_SIZES.text,
      whiteSpace: 'nowrap' as const,
    },
    label,
  });
  const getActiveMark = (label) => ({
    style: {
      ...getMark(label).style,
      color: COLORS.dodgerBlue,
    },
    label,
  });
  const marks = {
    0: getMark(0),
    [duration]: getMark(duration),
    [playerStartTime]: getActiveMark(playerStartTime),
    [playerEndTime]: getActiveMark(playerEndTime),
  };

  // Styling
  const songIntervalSliderStyle = {
    width: 'min(20em, 60vw)',
    height: '2em',
    ...style,
  };
  const handleStyle = {
    width: '1.25em',
    height: '1.25em',
    marginTop: '-0.5em',
    backgroundColor: COLORS.dodgerBlue,
    borderColor: COLORS.linkWater,
    opacity: 1,
    boxShadow: 'none',
  };

  return (
    <Slider
      range
      pushable
      min={0}
      max={duration}
      step={1}
      marks={marks}
      value={[playerStartTime, playerEndTime]}
      onChange={handleChange}
      handleStyle={handleStyle}
      style={songIntervalSliderStyle}
    />
  );
}

export default SongIntervalSlider;
