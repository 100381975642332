import DOMAINS, { HOST } from '~/constants/domains';

const getHostPath = (path, domain) => (HOST === domain ? '' : path);

export default {
  root: '/',
  login: '/login',
  register: '/register',
  settings: '/settings',

  // Japanese
  kanaMatch: '/japanese/1',
  flowGo: '/japanese/2',

  // Beatbox
  beatboxShare: getHostPath('/beatbox/1', DOMAINS.beatboxShare),
  beatblox: getHostPath('/beatbox/2', DOMAINS.beatblox),
  beatboxDb: getHostPath('/beatbox/3', DOMAINS.beatboxDb),
};
