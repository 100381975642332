import React, { ReactElement } from 'react';

import ProgressBar from '~/components/ProgressBar';

interface ScoreProps {
  points: number;
  maxPoints: number;
}

function Score({ points, maxPoints }: ScoreProps): ReactElement {
  return <ProgressBar points={points} total={maxPoints} />;
}

export default Score;
