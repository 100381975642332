import { chainMiddlewares } from '~/helpers/state';
import { Reducer } from '~/models';

import { State } from './models';
import { ACTIONS } from './actions';

const INITIAL_STATE: State = {
  patterns: [''],
  groups: [0],
  sentences: [],
  muted: [false],
  disabled: true,
};

const reducer: Reducer<State> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.setNodes:
      return { ...state, nodes: action.payload };
    case ACTIONS.setPatterns:
      return { ...state, patterns: action.payload };
    case ACTIONS.setGroups:
      return { ...state, groups: action.payload };
    case ACTIONS.setSentences:
      return { ...state, sentences: action.payload };
    case ACTIONS.setMuted:
      return { ...state, muted: action.payload };
    case ACTIONS.setDisabled:
      return { ...state, disabled: action.payload };
    default:
      return state;
  }
};

export default chainMiddlewares(reducer);
export { INITIAL_STATE };
