import { Settings } from './models';
import { BEAT } from '../../constants/defaults';

export const RATES = {
  default: 1,
  75: 0.75,
  50: 0.5,
};

export const SETTINGS: Settings = {
  velocity: 0.26,
  title: BEAT.title,
  bpm: BEAT.bpm,
  loops: BEAT.loops,
  chart: [],
  charts: {}, // TODO: fix this from type jank
};
