import Matter from 'matter-js';

import { KEYS } from '../../../../../constants/defaults';
import { System } from '../models';
import Note, { getNoteDims, NOTE_MODES } from '../entities/Note';
import { GARBAGE_THRESHOLD, TEMPO_NUMERATOR } from '../constants';
import { getNewX } from '../utils';

const score: System = (entities, { time }) => {
  const newEntities = entities;
  const { receptor, notes } = entities;
  const { gameDims, setRunning, engine, settings, setInputs, state } =
    entities.game;
  const { velocity, bpm, loops, chart } = settings;
  const { startTime, renderedNoteIdx, inputs } = state;

  // Delete old or move active notes
  const endX = -getNoteDims(gameDims).w;
  const receptorX = receptor.getPos().x;
  const noteKeys = Object.keys(notes);

  noteKeys.forEach((key) => {
    const note = notes[key];
    const oldX = note.getPos().x;
    const newX = getNewX(receptorX, note.timing, time.current, velocity);
    const translation = { x: newX - oldX, y: 0 };
    const isGarbage =
      time.current - note.garbageTime > GARBAGE_THRESHOLD &&
      note.mode !== NOTE_MODES.miss &&
      !note.attemptable;

    if (newX < endX) {
      Matter.Composite.remove(engine.world, note.body);
      delete newEntities.notes[key];
    } else if (
      !note.mode ||
      note.mode === NOTE_MODES.miss ||
      note.mode === NOTE_MODES.wrong ||
      isGarbage
    )
      Matter.Body.translate(note.body, translation);

    // Change note mode on miss
    if (
      note.attemptable &&
      newX + note.dims.w / 2 < receptorX - receptor.dims.w / 2
    )
      note.mode = NOTE_MODES.miss;
  });

  // Add notes based on chart
  const startX = gameDims.w;
  const nextNoteIdx = renderedNoteIdx + 1;
  const maxNoteIdx = chart.length * loops - 1;

  if (nextNoteIdx <= maxNoteIdx) {
    const newNoteIdx = nextNoteIdx % chart.length;
    const timeBetweenBars = TEMPO_NUMERATOR / bpm;
    const lastSoundStart = chart[chart.length - 1].start;
    const loopTime =
      Math.floor(nextNoteIdx / chart.length) *
      Math.floor(lastSoundStart / timeBetweenBars + 1) *
      timeBetweenBars;
    const courtTime = (startX - receptorX) / velocity;
    const newNoteStart =
      chart[newNoteIdx].start + startTime + courtTime + loopTime;
    const newNoteX = getNewX(receptorX, newNoteStart, time.current, velocity);

    if (newNoteX <= startX) {
      const newNote = Note(
        gameDims,
        newNoteX,
        chart[newNoteIdx].track,
        chart[newNoteIdx].key ?? KEYS[0], // TODO: can we guarantee `key` instead of using default?
        newNoteStart,
        chart[newNoteIdx].node.notation
      );

      newEntities.notes[nextNoteIdx] = newNote;
      Matter.Composite.add(engine.world, [newNote.body]);
      newEntities.game.state.renderedNoteIdx = nextNoteIdx;
    }
  } else if (nextNoteIdx > maxNoteIdx && Object.keys(notes).length === 0) {
    setInputs(inputs);
    setRunning(false);
  }

  return newEntities;
};

export default score;
