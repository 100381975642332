import { Dim, Pos, MetricsPredicate } from './models';
import { METRICS_DIMS } from '../constants/layout';
import { INITIAL_HEALTH } from '../constants/mechanics';

const metricsPredicate = (
  windowDims: Dim,
  pos: Pos,
  total: number
): MetricsPredicate => {
  return {
    dims: METRICS_DIMS(windowDims),
    getPos: () => pos,
    hearts: INITIAL_HEALTH,
    special: false,
    points: 0,
    total,
    style: {
      zIndex: 3,
    },
  };
};

export default metricsPredicate;
