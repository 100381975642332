// TODO: delete this file and make sure tracks are handled correctly
import { Beat } from '../../models';
// import { readNodes } from '../Write/services';

const nodes = []; // await readNodes();
const B = nodes[0];
const t = nodes[1];
const k = nodes[2];
const Pf = nodes[3];
const Rec = nodes[4];
const ib = nodes[20];

// Beats
export const ALPHA_0: Beat = {
  id: 0,
  title: 'Easy Peezy',
  loops: 1,
  bpm: 60,
  layers: [{ pattern: 'B k B k t' }],
  nodes: [B, k, t],
};

export const ALPHA_1: Beat = {
  id: 1,
  title: 'Kinda Mid',
  loops: 2,
  bpm: 60,
  layers: [{ pattern: 'B tk- B B tk- B B k' }],
  nodes: [B, t, k],
};

export const ALPHA_2: Beat = {
  id: 2,
  title: 'Oofy Doofy',
  loops: 3,
  bpm: 160,
  layers: [{ pattern: 'Btk-- - Btk-- - tk--- - k' }],
  nodes: [B, t, k],
};

export const ALPHA_3: Beat = {
  id: 3,
  title: 'Weirdo Whistle',
  loops: 2,
  bpm: 60,
  layers: [
    { pattern: 'B t k t Bt t k t', group: 0 },
    { pattern: 'ttt t ttt B ttt B Bt k t', group: 1 },
    { pattern: 'Rec > > > - Rec>', group: 2 },
    { pattern: '- ib > > B t', group: 3 },
    { pattern: '- - - ib > >', group: 4 },
  ],
  nodes: [Rec, B, t, k, ib],
};

export const ALPHA_4: Beat = {
  id: 4,
  title: 'Pfizer Boots',
  loops: 4,
  bpm: 60,
  layers: [{ pattern: 'B t Pf t Bt t Pf t' }],
  nodes: [B, t, Pf],
};
