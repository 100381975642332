import React, {
  Dispatch,
  SetStateAction,
  ForwardedRef,
  ReactElement,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';

import COLORS from '~/constants/colors';

import { formatTime } from '../helpers';
import { StopwatchRef } from '../models';

interface StopwatchProps {
  setPlaytime: Dispatch<SetStateAction<string>>;
}

const stopwatchStyle = {
  display: 'flex',
  justifyContent: 'center',
};
const caseStyle = {
  width: '85%',
  height: '1.5em',
  backgroundColor: COLORS.black25,
  borderRadius: '0.3em',
  overflow: 'hidden',
};
const dialStyle = {
  margin: '0.25em',
  color: COLORS.cornflower,
  fontFamily: 'Roboto Mono, monospace',
};

function Stopwatch(
  { setPlaytime }: StopwatchProps,
  ref: ForwardedRef<StopwatchRef>
): ReactElement {
  const [isRunning, setIsRunning] = useState(false);
  const [accumulator, setAccumulator] = useState(0);
  let startTime;
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const stopRunning = () => {
    clearInterval(intervalRef.current as NodeJS.Timeout);
    setIsRunning(false);
  };

  useImperativeHandle(ref, () => ({
    start: () => {
      if (!isRunning) {
        setIsRunning(true);
        startTime = Date.now();
        intervalRef.current = setInterval(() => {
          setAccumulator(() => Math.round((Date.now() - startTime) / 10));
        }, 10);
      }
    },
    stop: () => {
      stopRunning();
      if (accumulator > 0) setPlaytime(formatTime(accumulator));
    },
    reset: () => {
      stopRunning();
      setAccumulator(0);
    },
  }));

  return (
    <div style={stopwatchStyle}>
      <div style={caseStyle}>
        <p style={dialStyle}>{formatTime(accumulator)}</p>
      </div>
    </div>
  );
}

export default forwardRef(Stopwatch);
