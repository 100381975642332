import {
  Preset,
  RAW,
  NATURAL,
  BALANCED,
  BASSY,
  HEAVY,
} from '../devices/Transformer';

export const PRESETS: Record<string, string> = {
  raw: 'raw',
  natural: 'natural',
  balanced: 'balanced',
  bassy: 'bassy',
  heavy: 'heavy',
};

export const PRESET_CONFIGS: Record<string, Preset> = {
  raw: RAW,
  natural: NATURAL,
  balanced: BALANCED,
  bassy: BASSY,
  heavy: HEAVY,
};

export const PRESET_LABELS: Record<string, string> = {
  raw: 'Raw',
  natural: 'Natural',
  balanced: 'Balanced',
  bassy: 'Bassy',
  heavy: 'Heavy',
};
