import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import { FONT_WEIGHTS } from '~/constants/typography';

import { getPx } from '../../../utils';
import { State } from '../models';

interface EmptyProps {
  height: number;
  state?: State;
}

function Empty({ height, state }: EmptyProps): ReactElement {
  const blockHeight = getPx(height);
  const borderWidth = 2;
  const blockColor = COLORS.gray;
  const messages = {
    loading: 'Loading',
    ready: 'Ready for Pattern',
  };

  // Styling
  const emptyStyle = {
    position: 'absolute' as const,
    height: blockHeight,
    left: '50%',
    top: '50%',
    padding: `0 ${getPx(5)}`,
    transform: `translate(-50%, -50%)`,
    textAlign: 'center' as const,
    lineHeight: getPx(height - 2 * borderWidth),
    color: state === 'loading' ? COLORS.weatheredStone : COLORS.tundora,
    fontWeight: FONT_WEIGHTS.bold,
    backgroundColor: COLORS.white,
    borderRadius: `calc(${blockHeight} * 0.3)`,
    border: `${getPx(borderWidth)} solid ${blockColor}`,
    boxSizing: 'border-box' as const,
    whiteSpace: 'nowrap' as const,
    zIndex: 4,
  };

  return state ? <div style={emptyStyle}>{messages[state]}</div> : <></>;
}

export default Empty;
