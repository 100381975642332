import React, { ReactElement } from 'react';

import Code from '~/components/flow/Code';

interface CodeListProps {
  labels?: string[];
}

function CodeList({ labels }: CodeListProps): ReactElement {
  const getKey = (idx) => `BeatboxDbContentCardCodeListCode${idx}`;

  // Styling
  const codeStyle = {
    display: 'inline-block',
    margin: '0 0.15em 0.3em',
  };

  return (
    <>
      {labels?.map((label, idx) => (
        <Code key={getKey(idx)} style={codeStyle}>
          {label}
        </Code>
      ))}
    </>
  );
}

export default CodeList;
