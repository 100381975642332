import React, { ReactElement, useEffect, useCallback } from 'react';

import { FONT_SIZES } from '~/constants/typography';
import DawnHorizon from '~/components/backgrounds/DawnHorizon';
import Brand from '~/components/titles/Brand';

import Game, { DEFAULT_BATON } from './pages/Game';
import Cloak from './components/Cloak';
import { isGamePhase } from './utils';

function FlowGo(): ReactElement {
  let baton = DEFAULT_BATON;
  let setBaton;
  const gameRef = useCallback((node) => {
    if (!setBaton) setBaton = node.setBaton;
  }, []);
  const cloakRef = useCallback((node) => {
    const background = document.getElementById('dawn-horizon');

    baton = { ...baton, ...node };
    setBaton?.(baton);
    if (background)
      background.style.display = isGamePhase(node?.phase) ? 'none' : 'table';
  }, []);

  // Styling
  const flowGoStyle = {
    fontSize: FONT_SIZES.text,
  };
  const containerStyle = {
    width: '100%',
    textAlign: 'center' as const,
  };
  const cloakStyle = {
    display: 'flex',
    minWidth: 350,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  };

  useEffect(() => setBaton?.(baton), [setBaton]);

  return (
    <div style={flowGoStyle}>
      <div style={containerStyle}>
        <DawnHorizon>
          <div style={cloakStyle}>
            <Brand topic="Kanji" />
            <Cloak ref={cloakRef} />
          </div>
        </DawnHorizon>
        <Game ref={gameRef} />
      </div>
    </div>
  );
}

export default FlowGo;
