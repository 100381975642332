import React, {
  ReactElement,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  Dispatch,
  SetStateAction,
} from 'react';

import { getArrayBuffer, getAudioBufferFromArrayBuffer } from '~/helpers/media';
import COLORS from '~/constants/colors';
import Announcement from '~/components/Announcement';
import Header from '~/components/titles/Header';
import Subheader from '~/components/titles/Subheader';
import Divider from '~/components/flow/Divider';
import Gap from '~/components/flow/Gap';
import { STATUS_CODES } from '~/constants/api';
import Break from '~/components/flow/Break';

import Container from '../components/Container';
import AudioPlayer from '../components/AudioPlayer';
import VideoPlayer from '../components/VideoPlayer';
import ActionPanel from '../components/ActionPanel';
import FileControls from '../components/FileControls';
import { PRESETS, PRESET_CONFIGS } from '../constants/mastering';
import { DeviceState, ListenControls } from '../models';
import { AUDIO_TIME_LIMIT_SECONDS, VIDEO_SIZE_LIMIT } from '../constants/media';

interface UploadProps {
  setListenControls: Dispatch<SetStateAction<ListenControls>>;
  automaticStart: boolean;
  setAutomaticStart: Dispatch<SetStateAction<boolean>>;
}

function Upload({
  setListenControls,
  automaticStart,
  setAutomaticStart,
}: UploadProps): ReactElement {
  const [pickerState, setPickerState] = useState<DeviceState>('closed');
  const [errorText, setErrorText] = useState('');
  const [cookedUrl, setCookedUrl] = useState('');
  const [cookedMedia, setCookedMedia] = useState<Blob>();
  const [rawAudioBuffer, setRawAudioBuffer] = useState<AudioBuffer>();
  const [mimeType, setMimeType] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const config = {
    mastering: PRESETS.raw,
    preset: PRESET_CONFIGS.raw,
  };

  // Controls
  const startPicking = () => {
    if (pickerState !== 'suspended' && pickerState !== 'running')
      fileInputRef.current?.click();
  };
  const cookFile = async (event) => {
    const inputFile = event.target.files[0];

    setPickerState('running');

    // Begin processing media
    setErrorText('');
    try {
      // Decode media file and check if audio or video
      const inputBlob = new Blob([inputFile], { type: inputFile.type });
      const inputUrl = URL.createObjectURL(inputBlob);
      const inputType = inputBlob.type;
      const arrayBuffer = await getArrayBuffer(inputFile);
      const audioBuffer = await getAudioBufferFromArrayBuffer(arrayBuffer);

      if (!inputType.startsWith('audio') && !inputType.startsWith('video'))
        throw new Error('File not decodable');

      // Check duration and size
      if (audioBuffer.duration > AUDIO_TIME_LIMIT_SECONDS)
        throw new Error('Please choose a shorter media file');
      if (inputType.startsWith('video') && inputBlob.size > VIDEO_SIZE_LIMIT)
        throw new Error('Please choose a smaller video');

      // Update state
      setCookedUrl(inputUrl);
      setCookedMedia(inputBlob);
      setRawAudioBuffer(audioBuffer);
      setMimeType(inputType);
      setPickerState('done');
    } catch (error: any) {
      setPickerState(cookedUrl ? 'done' : 'closed');
      if (error.message.toLowerCase().includes('decod'))
        setErrorText('Please choose a file with a decodable format');
      else if (error.response?.status === STATUS_CODES.tooManyRequests)
        setErrorText('Server is tired, please try again in 30 seconds');
      else setErrorText(error.message);
    }
  };

  // Styling
  const headerStyle = {
    margin: 0,
  };
  const timeLimitStyle = {
    color: COLORS.yellowGreen,
  };
  const doNotStyle = {
    color: COLORS.mandy,
  };

  useEffect(() => {
    if (cookedMedia) setCookedUrl(URL.createObjectURL(cookedMedia));
  }, [cookedMedia]);
  useLayoutEffect(() => {
    (async () => {
      if (automaticStart) {
        setAutomaticStart(false);
        await startPicking();
      }
    })();
  }, [automaticStart]);

  return (
    <>
      <Container>
        {errorText && (
          <>
            <Announcement>{errorText}</Announcement>
            <Break />
          </>
        )}
        <Header style={headerStyle}>
          Upload media up to <b style={timeLimitStyle}>3 minutes</b> long!
        </Header>
        <Divider />
        <Subheader>
          Please <b style={doNotStyle}>do not</b> publish copyrighted content
        </Subheader>
      </Container>
      <Break />
      <Break />
      {cookedUrl && cookedMedia && rawAudioBuffer && (
        <div>
          {mimeType.startsWith('audio') && <AudioPlayer url={cookedUrl} />}
          {mimeType.startsWith('video') && <VideoPlayer url={cookedUrl} />}
          <Gap />
          <ActionPanel
            cookedUrl={cookedUrl}
            cookedMedia={cookedMedia}
            setCookedMedia={setCookedMedia}
            setErrorText={setErrorText}
            config={config}
            rawAudioBuffer={rawAudioBuffer}
            show="file"
            setListenControls={setListenControls}
          />
          <Break />
        </div>
      )}
      <FileControls deviceState={pickerState} onStart={startPicking} />
      <input
        id="beatboxshare-upload-file-input"
        type="file"
        hidden
        ref={fileInputRef}
        onChange={cookFile}
      />
    </>
  );
}

export default Upload;
