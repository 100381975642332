import { System } from '../models';
import { RECEPTOR_MODES } from '../entities/Receptor';
import { NOTE_MODES, getThresholds } from '../entities/Note';
import { EVENT_TYPES } from '../constants';

const input: System = (entities, { time, events }) => {
  const newEntities = entities;
  const { notes, receptor } = entities;
  const { gameDims, settings, state } = entities.game;
  const { chart } = settings;
  const { renderedNoteIdx } = state;
  const inputEvent = events.find((e) => e.type === EVENT_TYPES.onKeyDown);
  const inputKey = (inputEvent as CustomEvent)?.detail.key;

  // Loop through attemptable notes
  const noteKeys = Object.keys(notes);

  noteKeys.every((key) => {
    const note = notes[key];

    if (!note.attemptable) return true;

    // Calculate if player missed note completely
    const endX = receptor.getPos().x;
    const xDiff = note.getPos().x - endX;
    const thresholds = getThresholds(gameDims);
    const missedTargetNote = xDiff < -thresholds.miss;
    let rating = NOTE_MODES.miss;

    // Handle input for timing and correctness
    const xDist = Math.abs(xDiff);
    const madeProperAttempt = inputEvent && xDist <= thresholds.miss;
    const madePreAttempt = xDist > thresholds.miss && xDiff > 0 && inputEvent;

    // Check correctness
    if (madeProperAttempt || madePreAttempt) {
      if (inputKey !== note.key) rating = NOTE_MODES.wrong;
      else if (xDist <= thresholds.perfect) rating = NOTE_MODES.perfect;
      else if (xDist <= thresholds.right) rating = NOTE_MODES.right;
      else if (madePreAttempt) rating = NOTE_MODES.miss; // TODO: may want to distinguish misses that are too early from too late
    }

    // Update returned entities
    if (missedTargetNote || madeProperAttempt || madePreAttempt) {
      newEntities.notes[key].attemptable = false;
      newEntities.notes[key].garbageTime = time.current;
      newEntities.notes[key].mode = rating;

      // Animate receptor
      if (rating === NOTE_MODES.wrong)
        receptor.animate.start(RECEPTOR_MODES.wrong);
      else if (rating === NOTE_MODES.right)
        receptor.animate.start(RECEPTOR_MODES.right);
      else if (rating === NOTE_MODES.perfect)
        receptor.animate.start(RECEPTOR_MODES.perfect);
    }

    return false;
  });

  // Keep track of inputs
  if (inputKey) {
    const note = chart.find((n) => n.key === inputKey);

    newEntities.game.state.inputs.push({
      prompt: chart[renderedNoteIdx % chart.length],
      attempt: {
        track: note?.track,
        key: inputKey,
        start: time.current,
        node: note?.node,
      },
    });
  }

  return newEntities;
};

export default input;
