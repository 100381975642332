import Matter from 'matter-js';
import YouTube from 'react-youtube';

import { RecordSystem } from './models';
import barEntity from '../entities/barEntity';
import PHASES from '../constants/phases';
import { NO_LOAD_THRESHOLD, TEMPO_NUMERATOR } from '../constants/mechanics';
import { BAR_VARIANTS } from '../constants/layout';
import { getNewY } from '../utils';

const recordTempoSystem: RecordSystem = (entities, { time }) => {
  const newEntities = entities;
  const { setPhase, receptor, videoPlayer, bars } = entities;
  const { engine, windowDims } = entities.physics;
  const { song, velocity, videoEndTime } = entities.settings;
  const { startTime, renderedBarIdx, youTubePlayerState } = entities.state;

  // Transition to no load if video doesn't start for some seconds
  if (startTime < -1 && time.current + startTime > NO_LOAD_THRESHOLD) {
    setPhase(PHASES.noLoad);

    return newEntities;
  }

  // Handle timing synchronization
  const videoTime = videoPlayer?.getCurrentTime() * 1000 ?? -1;
  const prevVideoTime = entities.state.videoTime;
  const videoDelta = videoTime - prevVideoTime;

  newEntities.state.videoDelta = videoDelta;
  if (videoDelta <= 0) return newEntities; // Order needed for other systems
  newEntities.state.videoTime = videoTime;

  // Make sure start time set and YouTube background is playing
  if (startTime < 0 || youTubePlayerState !== YouTube.PlayerState.PLAYING) {
    if (youTubePlayerState === YouTube.PlayerState.PLAYING)
      newEntities.state.startTime = videoTime;
    else if (startTime === -1) newEntities.state.startTime = -time.current;

    return newEntities;
  }

  // Finish game if past recording end time
  if (startTime > 0 && videoTime > videoEndTime) {
    setPhase(PHASES.production);

    return newEntities;
  }

  // Delete old or move active bars
  const startY = receptor.getPos().y;
  const endY = 0;
  const barKeys = Object.keys(bars);

  barKeys.forEach((key) => {
    const bar = bars[key];
    const oldY = bar.getPos().y;
    const newY = getNewY(startY, bar.timing, videoTime, velocity, 1);
    const translation = { x: 0, y: newY - oldY };

    if (newY < endY) {
      Matter.Composite.remove(engine.world, bar.body);
      delete newEntities.bars[key];
    } else Matter.Body.translate(bar.body, translation);
  });

  // Add new bars if needed
  const timeBetweenBars = TEMPO_NUMERATOR / song.bpm;
  const nextBarIdx = Math.floor(videoTime / timeBetweenBars);
  const nextBarStartTime = nextBarIdx * timeBetweenBars;
  const newBarY = getNewY(startY, nextBarStartTime, videoTime, velocity, 1);

  if (newBarY <= startY && renderedBarIdx < nextBarIdx) {
    const newBar = barEntity(
      windowDims,
      newBarY,
      nextBarStartTime,
      false,
      BAR_VARIANTS.fadeIn
    );

    newEntities.bars[nextBarIdx] = newBar;
    Matter.Composite.add(engine.world, [newBar.body]);
    newEntities.state.renderedBarIdx += renderedBarIdx < 0 ? nextBarIdx + 1 : 1;
  }

  // Update stopwatch
  newEntities.stopwatch.time = videoTime;

  return newEntities;
};

export default recordTempoSystem;
