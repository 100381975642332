import { isMobile } from 'react-device-detect';

import { TEMPO_NUMERATOR } from '../../../../constants/defaults';

export const EVENT_TYPES = {
  onKeyDown: 'onKeyDown',
  onKeyUp: 'onKeyUp',
};

// Device alignment
export const IS_MOBILE = isMobile; // Centralize for easier testing
export const ALIGN_THRESHOLD = 100;

// Mechanics
export const GARBAGE_THRESHOLD = 500;

export { TEMPO_NUMERATOR };
