import { createContext, useContext } from 'react';

import { SafeContext, Reducer } from '~/models';

export const createSafeContext = <T>(): SafeContext<T> => {
  const context = createContext<T | undefined>(undefined);
  const useSafeContext = () => {
    const value = useContext(context);

    if (!value)
      throw new Error('useContext must be inside a Provider with a value!');

    return value;
  };

  return [useSafeContext, context.Provider] as const;
};

export const chainMiddlewares = <S>(reducer: Reducer<S>): Reducer<S> => {
  // Middlewares
  const logAction = (state, action) => {
    console.log(`Dispatching:`);
    console.log(action);

    return state;
  };
  const logPreviousState = (state) => {
    console.log(`Previous state:`);
    console.log(state);

    return state;
  };
  const logNextState = (state) => {
    console.log(`Next state:`);
    console.log(state);

    return state;
  };

  // Coordinate chaining
  const chain = [logAction, logPreviousState, reducer, logNextState];
  const newReducer = (state, action) =>
    chain.reduce((acc, middleware) => middleware(acc, action), state);

  return newReducer;
};
