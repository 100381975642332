import React, { ReactElement } from 'react';
import { motion } from 'framer-motion';
import { isFirefox } from 'react-device-detect';

interface CaretProps {
  shift: boolean;
  color: string;
  highlight?: boolean;
}

function Caret({ shift, color, highlight }: CaretProps): ReactElement {
  let height = 'calc(1em + 2px)';
  let translateX = shift ? '-50%' : '0';
  let translateY = 'calc(-75% - 0.25px)';

  if (isFirefox) {
    height = 'calc(1em + 0.5px)';
    translateX = '0';
    translateY = '-75%';
  }

  // Styling
  const caretStyle = {
    display: 'inline-block',
    position: 'relative' as const,
    width: 0,
  };
  const blinkerStyle = {
    position: 'absolute' as const,
    width: '1px',
    height,
    backgroundColor: color,
    transform: `translate(${translateX}, ${translateY})`,
  };

  // Animation
  const blinking = {
    opacity: [1, 1, 0, 0],
    transition: {
      duration: 1,
      repeat: Infinity,
      repeatDelay: 0,
      times: [0, 0.5, 0.5, 1],
    },
  };

  return highlight ? (
    <></>
  ) : (
    <span style={caretStyle}>
      <motion.span animate={blinking} style={blinkerStyle} />
    </span>
  );
}

export default Caret;
