import React, { ReactElement } from 'react';

import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';
import COLORS from '~/constants/colors';

interface FeatureProps {
  src: string;
  name: string;
  caption: string;
  text: string;
}

function Feature({ src, name, caption, text }: FeatureProps): ReactElement {
  const imgDiameter = 90;
  const imgMargin = '1.3em';

  // Styling
  const featureStyle = {
    display: 'flex',
  };
  const frameStyle = {
    width: `${imgDiameter}px`,
    height: `${imgDiameter}px`,
    marginRight: imgMargin,
    borderRadius: '50%',
    boxShadow: `-0.13em 0.13em ${COLORS.black25}`,
    overflow: 'hidden',
  };
  const imgStyle = {
    width: '100%',
    height: '100%',
  };
  const blurbStyle = {
    width: `calc(100% - ${imgDiameter}px - ${imgMargin})`,
    alignSelf: 'center',
  };
  const nameStyle = {
    fontSize: FONT_SIZES.action,
    fontWeight: FONT_WEIGHTS.bold,
  };
  const captionStyle = {
    fontSize: FONT_SIZES.action,
  };
  const textStyle = {
    fontSize: FONT_SIZES.subtext,
  };

  return (
    <div style={featureStyle}>
      <div style={frameStyle}>
        <img src={src} alt={name} style={imgStyle} />
      </div>
      <div style={blurbStyle}>
        <span style={nameStyle}>{name}</span>{' '}
        <span style={captionStyle}>{caption}</span>
        <br />
        <span style={textStyle}>{text}</span>
      </div>
    </div>
  );
}

export default Feature;
