import { useReducer, useEffect, CSSProperties, RefObject } from 'react';
import { SwipeableHandlers, useSwipeable } from 'react-swipeable';
import { isBrowser, isMobile } from 'react-device-detect';

import { FONT_SIZES } from '~/constants/typography';

import {
  setLenThunk,
  dragThunk,
  swipeThunk,
  nextThunk,
  doneThunk,
  jumpThunk,
} from './actions';
import reducer, { INITIAL_STATE } from './reducer';
import { SlideSize } from './models';

const DEFAULT_SLIDE_SIZE: SlideSize = {
  width: '8em',
  height: '8em',
  margin: '0.5em',
  border: '0.2em',
  total: 'calc(8em + 1em + 0.4em)',
  totalPx: 1.5 * 9.4 * parseInt(FONT_SIZES.text, 10),
};

const getExtendCount = (len: number): number => Math.ceil(len / 2 + 1);

const useCarousel = (
  len: number,
  slideSize = DEFAULT_SLIDE_SIZE,
  transitionTime = 300, // In milliseconds
  interval = -1,
  ref?: RefObject<HTMLInputElement>
): [number, (desiredIdx: number) => void, SwipeableHandlers, CSSProperties] => {
  const [state, dispatch] = useReducer(reducer, { ...INITIAL_STATE, len });
  const extendCount = getExtendCount(len);
  const handlers = useSwipeable({
    onSwiping: dragThunk(dispatch)(slideSize.totalPx),
    onSwiped: swipeThunk(dispatch, state)(slideSize.totalPx, ref),
    preventDefaultTouchmoveEvent: true,
    trackMouse: isBrowser,
    trackTouch: isMobile,
  });

  // Styling
  const { activeIdx, desiredIdx, offset } = state;
  const smoothTransition = `transform ${transitionTime}ms ease`;
  const elasticTransition = `transform ${transitionTime}ms cubic-bezier(0.68, -0.55, 0.265, 1.55)`;
  const totalLen = len + 2 * extendCount;
  const marginOffsetCount = -activeIdx - 2 - ((len % 2) - 1) / 2;
  const containerStyle: CSSProperties = {
    transform: 'translateX(0)',
    width: `calc(${totalLen} * ${slideSize.total})`,
    marginLeft: `calc(${marginOffsetCount} * ${slideSize.total})`,
  };

  if (state.desiredIdx !== state.activeIdx) {
    const idxDiff = desiredIdx - activeIdx;
    const idxDist = Math.abs(idxDiff);
    const distOverHalf = idxDist > len / 2;
    const idxDir = (distOverHalf ? 1 : -1) * Math.sign(idxDiff);
    const dragDir = Math.sign(state.offset || 0);
    const shiftCount = distOverHalf ? len - idxDist : idxDist;
    const shiftPercent = (((dragDir || idxDir) * shiftCount) / totalLen) * 100;

    containerStyle.transition = smoothTransition;
    containerStyle.transform = `translateX(${shiftPercent}%)`;
  } else if (!Number.isNaN(state.offset)) {
    if (state.offset !== 0)
      containerStyle.transform = `translateX(${offset}px)`;
    else containerStyle.transition = elasticTransition;
  }

  useEffect(() => setLenThunk(dispatch)(len), [len]);

  useEffect(() => {
    const doneTimer = setTimeout(doneThunk(dispatch), transitionTime);

    return () => clearTimeout(doneTimer);
  }, [state.desiredIdx]);

  if (interval >= 0)
    useEffect(() => {
      const nextTimer = setTimeout(nextThunk(dispatch), interval);

      return () => clearTimeout(nextTimer);
    }, [state.offset, state.activeIdx]);

  return [
    state.activeIdx,
    jumpThunk(dispatch, state)(0, ref),
    handlers,
    containerStyle,
  ];
};

export default useCarousel;
export { DEFAULT_SLIDE_SIZE, getExtendCount };
