import React, { ReactElement, MutableRefObject } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import Code from '~/components/flow/Code';
import { stopEventEffects } from '~/utils';

import { useBeatboxContext } from '../providers/BeatboxProvider';
import { ContentCardRef } from './ContentCard';

interface SurpriseMeProps {
  contentCardRef: MutableRefObject<ContentCardRef | null>;
}

function SurpriseMe({ contentCardRef }: SurpriseMeProps): ReactElement {
  const { state: beatboxState } = useBeatboxContext();

  // Handlers
  const handleSurprise = (event) => {
    stopEventEffects(event);
    if (beatboxState.techniques) {
      const idx = Math.floor(Math.random() * beatboxState.techniques.length);
      const technique = beatboxState.techniques[idx];

      contentCardRef.current?.showTechnique(technique?.id);
    }
  };

  // Styling
  const surpriseMeStyle = {
    display: 'inline-block',
    backgroundImage: `linear-gradient(135deg, ${COLORS.mandy} 18%, ${COLORS.jaffa} 18%, ${COLORS.jaffa} 34%, ${COLORS.kournikova} 34%, ${COLORS.kournikova} 50%, ${COLORS.wildWillow} 50%, ${COLORS.wildWillow} 66%, ${COLORS.malibu} 66%, ${COLORS.malibu} 82%, ${COLORS.lilacBush} 82%)`,
    fontSize: FONT_SIZES.action,
    cursor: 'pointer',
    verticalAlign: 'bottom',
    boxShadow: `-0.1em 0.1em ${COLORS.black25}`,
    textShadow: `-1px -1px 0 ${COLORS.black}, -1px 1px 0 ${COLORS.black}, 0 -1px 0 ${COLORS.black}, 0 1px 0 ${COLORS.black}, 1px -1px 0 ${COLORS.black}, 1px 1px 0 ${COLORS.black}`,
  };

  return (
    <Code
      onMouseUp={handleSurprise}
      onTouchEnd={handleSurprise}
      style={surpriseMeStyle}
    >
      🎲
    </Code>
  );
}

export default SurpriseMe;
