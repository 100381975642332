import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useRef,
} from 'react';

import { stopEventEffects } from '~/utils';
import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';
import COLORS from '~/constants/colors';

import { Beat, Charts } from '../../../models';
import Listen from '../../../components/Listen';

interface ControlsProps {
  beat: Beat;
  setBeat: Dispatch<SetStateAction<Beat>>;
  charts: Charts;
  setProgress: Dispatch<SetStateAction<number>>;
  example?: Beat; // TODO: remove this in future versions
}

function Controls({
  beat,
  setBeat,
  charts,
  setProgress,
  example, // TODO: remove this in future versions
}: ControlsProps) {
  const [bpmColor, setBpmColor] = useState(COLORS.platinum);
  const [loopsColor, setLoopsColor] = useState(COLORS.platinum);
  const chart = Object.values(charts).reduce((acc, c) => [...acc, ...c], []);

  // Handlers
  const getFocusNumber = (setState) => (event) => {
    stopEventEffects(event);
    setState(COLORS.doveGray);
  };
  const getBlurNumber = (key, setState, max?) => (event) => {
    let value = Math.max(event.target.value, 1);

    stopEventEffects(event);
    if (max && value > max) value = max;
    setBeat({ ...beat, [key]: value });
    setState(COLORS.platinum);
    event.target.value = value; // eslint-disable-line no-param-reassign
  };

  // Styling
  const controlsStyle = {
    display: 'inline-block',
  };
  const columnStyle = {
    width: '6em',
  };
  const labelStyle = {
    color: COLORS.tundora,
    fontWeight: FONT_WEIGHTS.bold,
  };
  const getNumberStyle = (borderColor) => ({
    display: 'inline-block',
    width: '3.5em',
    fontSize: FONT_SIZES.text,
    color: COLORS.tundora,
    borderColor,
    borderRadius: '0.3em',
    borderStyle: 'solid',
    borderWidth: 'thin',
    padding: '0.3em 0.6em',
    outline: 'none',
  });

  // Example
  // TODO: remove this and relevant rendered stuff below like props passed in future versions
  const loopsRef = useRef<HTMLInputElement>(null);
  const bpmRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (example && loopsRef.current && bpmRef.current) {
      bpmRef.current.value = String(example.bpm);
      loopsRef.current.value = String(example.loops);
      setBeat({ ...beat, bpm: example.bpm, loops: example.loops });
    }
  }, [example]);

  return (
    <table style={controlsStyle}>
      <tbody>
        <tr>
          <td style={labelStyle}>Loops</td>
          <td />
          <td style={labelStyle}>BPM</td>
        </tr>
        <tr>
          <td style={columnStyle}>
            <input
              type="number"
              inputMode="numeric"
              defaultValue={beat.loops}
              min={1}
              step={1}
              onFocus={getFocusNumber(setLoopsColor)}
              onBlur={getBlurNumber('loops', setLoopsColor)}
              style={getNumberStyle(loopsColor)}
              ref={loopsRef}
            />
          </td>
          <td>
            <Listen
              beat={beat}
              chart={chart}
              label="Listen"
              setProgress={setProgress}
            />
          </td>
          <td style={columnStyle}>
            <input
              type="number"
              inputMode="numeric"
              defaultValue={beat.bpm}
              min={1}
              max={300}
              step={1}
              onFocus={getFocusNumber(setBpmColor)}
              onBlur={getBlurNumber('bpm', setBpmColor, 300)}
              style={getNumberStyle(bpmColor)}
              ref={bpmRef}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Controls;
