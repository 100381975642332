import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  CSSProperties,
  ReactElement,
  ForwardedRef,
  forwardRef,
} from 'react';

interface CheckboxProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  style?: CSSProperties;
  ref: ForwardedRef<HTMLInputElement>;
}

function Checkbox({ style, ref, ...props }: CheckboxProps): ReactElement {
  const checkboxStyle = {
    display: 'inline-block',
    width: '1.3em',
    height: '1.3em',
    ...style,
  };

  return <input type="checkbox" style={checkboxStyle} ref={ref} {...props} />;
}

export default forwardRef(
  (props: CheckboxProps, ref: ForwardedRef<HTMLInputElement>) =>
    Checkbox({ ...props, ref })
);
