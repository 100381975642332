import Matter from 'matter-js';

import { Dim, BridgePredicate } from './models';
import { posOf, dimsOf } from '../utils';
import { BRIDGE_WIDTH } from '../constants/layout';

const bridgePredicate = (
  windowDims: Dim,
  h: number,
  h0: number,
  xOff: number,
  y: number,
  timing: number
): BridgePredicate => {
  const bridgeWidth = BRIDGE_WIDTH(windowDims);
  const body = Matter.Bodies.rectangle(
    windowDims.w / 2 + xOff,
    y - h / 2,
    bridgeWidth,
    h,
    { type: 'bridge' }
  );
  const bridgeStyle = {
    zIndex: 2,
  };

  Matter.Body.setCentre(body, { x: 0, y: h / 2 }, true);

  return {
    dims: dimsOf(body),
    getDims: () => dimsOf(body),
    getPos: () => posOf(body),
    body,
    h0,
    xOff,
    timing,
    active: false,
    style: bridgeStyle,
  };
};

export default bridgePredicate;
