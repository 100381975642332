import React, { ReactElement, PropsWithChildren } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import { useAuth, ROLES } from '~/providers/Auth';
import PATHS from '~/constants/paths';

interface RestrictAccessProps {
  role?: string;
  redirect?: string | -1;
}

function RestrictAccess({
  role = ROLES.authed,
  redirect = PATHS.login,
  children,
}: PropsWithChildren<RestrictAccessProps>): ReactElement {
  const authState = useAuth().state;
  let allowed;

  switch (role) {
    case ROLES.public:
      allowed = !authState.isAuthed;
      break;
    case ROLES.authed:
      allowed = authState.isAuthed;
      break;
    default:
      allowed = false;
  }

  // Coordinate redirect
  const location = useLocation();
  const navState = { from: location.pathname };
  const safeRedirect =
    redirect === -1
      ? (location.state as Record<string, string>)?.from || PATHS.root
      : redirect;

  return allowed ? (
    <>{children}</>
  ) : (
    <Navigate to={safeRedirect} state={navState} replace />
  );
}

export default RestrictAccess;
