import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import Subtext from '~/components/titles/Subtext';
import Gap from '~/components/flow/Gap';
import Header from '~/components/titles/Header';
import Subheader from '~/components/titles/Subheader';
import Divider from '~/components/flow/Divider';
import Link from '~/components/flow/Link';
import Code from '~/components/flow/Code';
import { useAuth } from '~/providers/Auth';

import { useBeatboxContext } from '../../../providers/BeatboxProvider';
import uris from '../../../uris';
import InlineList from './InlineList';
import YouTubePlayer from './YouTubePlayer';
import InstagramEmbed from './InstagramEmbed';
import EmptyFace from './EmptyFace';
import CONTENT from '../../../constants/content';
import EditLink from './EditLink';
import Unauthed from '../../../pages/Unauthed';

function DemoFace(): ReactElement {
  const { state: authState } = useAuth();
  const { demo } = useBeatboxContext().state;
  const demoUri = uris.exploreDemo(demo?.id);
  const demoUrl = `${window.location.origin}${demoUri}`;

  // Styling
  const headerStyle = {
    marginTop: '0.25em',
  };

  return (
    <>
      {demo && (
        <>
          <Header style={headerStyle}>
            Demo <Code>{demo.id}</Code>
          </Header>
          <Subtext color={COLORS.doveGray}>
            Share:&nbsp;<Link href={demoUri}>{demoUrl}</Link>
          </Subtext>
          <EditLink content={CONTENT.demos} uri={uris.manageDemo(demo.id)} />
          <Divider />
          {authState.isAuthed ? (
            <>
              {demo.youtube && <YouTubePlayer link={demo.youtube} />}
              {demo.instagram && <InstagramEmbed url={demo.instagram} />}
              {!!demo.techniques?.length && (
                <>
                  <Gap />
                  <Gap />
                  <Subheader>Demonstrated Techniques</Subheader>
                  <InlineList
                    labels={demo.techniques?.map((t) => t.name)}
                    links={demo.techniques?.map((t) =>
                      uris.exploreTechnique(t.id)
                    )}
                  />
                </>
              )}
            </>
          ) : (
            <Unauthed nested />
          )}
        </>
      )}
      {!demo && <EmptyFace />}
    </>
  );
}

export default DemoFace;
