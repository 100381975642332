import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import COLORS from '~/constants/colors';
import FramedView from '~/components/FramedView';
import Title from '~/components/titles/Title';
import Divider from '~/components/flow/Divider';
import Button from '~/components/buttons/Button';
import { FONT_SIZES } from '~/constants/typography';
import Gap from '~/components/flow/Gap';
import { stopEventEffects } from '~/utils';

interface NotFoundProps {
  withCode?: boolean;
}

function NotFound({ withCode = false }: NotFoundProps): ReactElement {
  const navigate = useNavigate();
  const handleGoBack = (event) => {
    stopEventEffects(event);
    navigate(-1);
  };

  // Styling
  const notFoundStyle = {
    display: 'inline-block',
    width: 'min(30em, 82.5vw)',
    backgroundColor: COLORS.mandy,
    color: COLORS.white,
  };
  const titleStyle = {
    color: COLORS.white,
  };
  const dividerStyle = {
    backgroundColor: COLORS.white,
  };
  const buttonStyle = {
    width: '9em',
    margin: '0 0.25em 0.38em',
    fontSize: FONT_SIZES.text,
    color: COLORS.black,
    backgroundColor: COLORS.white,
    borderColor: COLORS.white,
  };

  return (
    <FramedView style={notFoundStyle}>
      <Title style={titleStyle}>Not Found</Title>
      <Divider style={dividerStyle} />
      You&apos;ve discovered the hidden treasure of nothing. But forget not the
      wisdoms of old.
      {withCode
        ? ' The requested clip may have expired or been deleted. '
        : " Code requires time and effort, a kindred to history's greatest towers and stone scultures. Do not take that for granite and know there may be something here in the future. "}
      For now, please check your spelling in the address bar.
      <Gap />
      <Gap />
      <Button label="Go Back" callback={handleGoBack} style={buttonStyle} />
    </FramedView>
  );
}

export default NotFound;
