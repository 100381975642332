import Matter from 'matter-js';

import { Dim, Entity } from '../../models';
import Bar, { BarProps } from './Bar';
import { posOf } from '../../utils';
import { BAR_MODES } from './constants';

export default (
  gameDims: Dim,
  x: number,
  timing: number,
  fuzzy = true,
  mode?: string
): Entity<BarProps> => {
  const dims = { w: 0, h: gameDims.h };
  const body = Matter.Bodies.rectangle(x, gameDims.h / 2, dims.w, dims.h, {
    type: 'bar',
  });

  return {
    dims,
    getPos: () => posOf(body),
    render: Bar,
    body,
    timing,
    garbageTime: -1,
    fuzzy,
    mode,
  };
};
export { BAR_MODES };
