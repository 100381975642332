import React, { ReactElement, PropsWithChildren, CSSProperties } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';

interface PromptProps {
  color?: string;
  style?: CSSProperties;
}

function Prompt({
  color = COLORS.black,
  style,
  children,
}: PropsWithChildren<PromptProps>): ReactElement {
  const promptStyle = {
    display: 'block',
    color,
    fontWeight: FONT_WEIGHTS.normal,
    fontSize: FONT_SIZES.action,
    textAlign: 'left' as const,
    ...style,
  };

  return <span style={promptStyle}>{children}</span>;
}

export default Prompt;
