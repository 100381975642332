export const MODES = {
  map: 'map',
  list: 'list',
  study: 'study',
};

export const FIELD_NAMES = {
  category: 'category',
  difficulty: 'difficulty',
};
