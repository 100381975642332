import React, { ReactElement } from 'react';
import { motion } from 'framer-motion';

import COLORS from '~/constants/colors';

import { BarPredicate } from '../predicates/models';
import { RendererProps } from './models';
import { BAR_VARIANTS } from '../constants/layout';

function Bar({ predicate }: RendererProps<BarPredicate>): ReactElement {
  const pos = predicate.getPos();
  const needsFadeIn = predicate.state === BAR_VARIANTS.fadeIn;

  // Styling
  const getPx = (v) => `${v}px`;
  const barStyle = {
    position: 'absolute' as const,
    width: getPx(predicate.dims.w),
    height: getPx(predicate.dims.h),
    left: getPx(pos.x - predicate.dims.w / 2),
    top: getPx(pos.y - predicate.dims.h / 2),
    opacity: needsFadeIn ? 0 : 1,
    borderColor: predicate.fuzzy ? COLORS.white25 : COLORS.white75,
    borderStyle: 'dashed',
    borderWidth: `${getPx(1)} 0 0`,
    ...predicate.style,
  };

  // Animation
  const variants = {
    reset: {
      opacity: 1,
    },
    fadeIn: {
      opacity: [0, 1],
      transition: { duration: 0.25 },
    },
    fadeOut: {
      opacity: [1, 0],
      transition: { duration: 0.25 },
    },
  };
  const animate = predicate.state ?? 'reset';

  return (
    <motion.div
      {...(!needsFadeIn && { initial: false })}
      style={barStyle}
      animate={animate}
      variants={variants}
    />
  );
}

export default Bar;
