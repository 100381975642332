import { ActionDispatch, ActionCreator } from '~/models';

import {
  RecordInfo,
  Record,
  AddRecord,
  ListRecords,
  EditRecord,
  DeleteRecord,
  ClearRecords,
} from './models';
import {
  createRecord,
  readRecords,
  updateRecord,
  deleteRecord,
} from './services';

export const ACTIONS = {
  addRecord: 'BeatboxDb/record/addRecord',
  listRecords: 'BeatboxDb/record/listRecords',
  editRecord: 'BeatboxDb/record/editRecord',
  deleteRecord: 'BeatboxDb/record/deleteRecord',
  clearRecords: 'BeatboxDb/record/clearRecords',
};

// Creators
const getActionCreator =
  <T>(type): ActionCreator<T> =>
  (payload) => ({ type, ...(payload && { payload }) });

// Thunks
const fixCategoriesOrder = (record) => ({
  ...record,
  technique: {
    ...record.technique,
    categories: record.technique.ordered_categories?.map((cId) =>
      record.technique.categories?.find((c) => c.id === cId)
    ),
  },
});

export const addRecordThunk =
  (dispatch: ActionDispatch): AddRecord =>
  async (recordInfo: RecordInfo) => {
    let content = await createRecord(recordInfo);

    content = fixCategoriesOrder(content);
    dispatch(getActionCreator(ACTIONS.addRecord)(content));

    return (content as Record).id;
  };

export const listRecordsThunk =
  (dispatch: ActionDispatch): ListRecords =>
  async () => {
    let contents = await readRecords();

    contents = contents.map(fixCategoriesOrder);
    dispatch(getActionCreator(ACTIONS.listRecords)(contents));
  };

export const editRecordThunk =
  (dispatch: ActionDispatch): EditRecord =>
  async (id: number, recordInfo: RecordInfo) => {
    let content = await updateRecord(id, recordInfo);

    content = fixCategoriesOrder(content);
    dispatch(getActionCreator(ACTIONS.editRecord)(content));
  };

export const deleteRecordThunk =
  (dispatch: ActionDispatch): DeleteRecord =>
  async (id: number) => {
    const content = await deleteRecord(id);

    dispatch(getActionCreator(ACTIONS.deleteRecord)(content));
  };

export const clearRecordsThunk =
  (dispatch: ActionDispatch): ClearRecords =>
  () => {
    dispatch(getActionCreator(ACTIONS.clearRecords)());
  };
