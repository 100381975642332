export const MARKS = {
  space: ' ',
  rest: '-',
  sustain: '>',
  hold: '.',
  wildcard: '*',
  missing: '?',
  open: '[',
  close: ']',
  caret: '|',
};

export const ERRORS = {
  space: 'Redundant space',
  sustain: "Previous notation can't be sustained",
  hold: "Previous notation can't be held",
  open: 'Opened notation groups must be closed',
  close: 'Closed notation groups must be opened',
  notation: 'Notation not found',
};

export const DESCRIPTIONS = {
  space: 'Separate notation between beats',
  rest: 'Indicate a pause',
  sustain: 'Sustain previous notation',
  hold: 'Sustain but without affecting timing',
  wildcard: 'Indicate an interchangeable sound',
  open: 'Start multi-letter notation',
  close: 'End multi-letter notation',
};
