import React, {
  DetailedHTMLProps,
  TextareaHTMLAttributes,
  CSSProperties,
  ReactElement,
  useState,
  useEffect,
  forwardRef,
  ForwardedRef,
  FocusEvent,
  MouseEvent,
} from 'react';

import { FONT_SIZES } from '~/constants/typography';
import VARIANTS from '~/constants/variants';
import { stopEventEffects } from '~/utils';
import COLORS from '~/constants/colors';
import { Theme } from '~/models';

const THEME = {
  neutral: {
    default: {
      color: COLORS.black,
      backgroundColor: COLORS.white,
      borderColor: COLORS.alto,
    },
    hover: {
      borderColor: COLORS.chetwodeBlue,
    },
    active: {
      borderColor: COLORS.dodgerBlue,
    },
  },
  disabled: {
    default: {
      color: COLORS.silverChalice,
      backgroundColor: COLORS.alabaster,
      borderColor: COLORS.alto,
    },
  },
} as Theme;

interface TextAreaProps
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  variant?: VARIANTS;
  onMouseOver?: (event: MouseEvent) => void;
  onFocus?: (event: FocusEvent) => void;
  onBlur?: (event: FocusEvent) => void;
  disabled?: boolean;
  style?: CSSProperties;
}

function TextArea({
  variant = VARIANTS.neutral,
  onMouseOver,
  onFocus,
  onBlur,
  disabled = false,
  style,
  ...props
}: TextAreaProps): ReactElement {
  const states = disabled ? THEME.disabled : THEME[variant] ?? THEME.neutral;

  // Styling
  const disabledProps = disabled && {
    disabled,
    placeholder: props.placeholder ?? 'Input disabled',
  };
  const getTAStyle = (state) => ({
    display: 'inline-block',
    fontSize: FONT_SIZES.text,
    borderRadius: '0.3em',
    borderStyle: 'solid',
    borderWidth: 'thin',
    padding: '0.4em 0.4em',
    outline: 'none',
    backgroundClip: 'padding-box',
    ...states.default,
    ...(state.borderColor && { borderColor: state.borderColor }),
    ...style,
  });
  const [tAStyle, setTAStyle] = useState(getTAStyle(states.default));

  // Event handlers
  const [focused, setFocused] = useState(false);
  const handleMouseOver = (event) => {
    stopEventEffects(event);
    if (!disabled) {
      onMouseOver?.(event);
      if (!focused && states.hover) setTAStyle(getTAStyle(states.hover));
    }
  };
  const handleMouseLeave = (event) => {
    stopEventEffects(event);
    if (!disabled && !focused) setTAStyle(getTAStyle(states.default));
  };
  const handleFocus = (event) => {
    stopEventEffects(event);
    if (!disabled) {
      setFocused(true);
      onFocus?.(event);
      if (states.active) setTAStyle(getTAStyle(states.active));
    }
  };
  const handleBlur = (event) => {
    stopEventEffects(event);
    if (!disabled) {
      setFocused(false);
      onBlur?.(event);
      setTAStyle(getTAStyle(states.default));
    }
  };

  useEffect(() => setTAStyle(getTAStyle(states.default)), [variant, disabled]);

  return (
    <textarea
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={tAStyle}
      {...disabledProps}
      {...props}
    />
  );
}

export default forwardRef(
  (props: TextAreaProps, ref: ForwardedRef<HTMLTextAreaElement>) =>
    TextArea({ ...props, ref })
);
