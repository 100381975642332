import React, { ReactElement } from 'react';

import { FONT_SIZES } from '~/constants/typography';
import COLORS from '~/constants/colors';

import { SwitchProps } from '../models';

function CircleSwitch({
  label,
  active = false,
  callback,
  style,
  activeStyle,
  ...props
}: SwitchProps): ReactElement {
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    callback?.(event);
  };

  // Styling
  const diameter = 1.5;
  const circleSwitchStyle = {
    display: 'inline-block',
    width: `${diameter}em`,
    height: `${diameter}em`,
    margin: '0 0.2em',
    lineHeight: '1.45em',
    fontSize: FONT_SIZES.single,
    color: COLORS.white,
    backgroundColor: COLORS.dodgerBlue,
    borderRadius: `${diameter / 2}em`,
    verticalAlign: 'top',
    outline: 'none',
    overflow: 'hidden',
    cursor: 'pointer',
    ...style,
  };
  const circleSwitchActiveStyle = {
    ...circleSwitchStyle,
    color: COLORS.alabaster,
    backgroundColor: COLORS.waikawaGray,
    ...activeStyle,
  };
  const circleSwitchLabelStyle = {
    marginLeft: '0.05em',
    pointerEvents: 'none' as const,
  };

  return (
    <div
      role="none"
      onMouseDown={handleClick}
      onTouchStart={handleClick}
      style={active ? circleSwitchActiveStyle : circleSwitchStyle}
      {...props}
    >
      <b style={circleSwitchLabelStyle}>{label}</b>
    </div>
  );
}

export default CircleSwitch;
