import { IS_MOBILE, ALIGN_THRESHOLD } from './constants';
import { Dim } from './models';
import { getPx } from './utils';

const getNum = (v) => Number(String(v).replace('px', ''));

export const rotatePos = (
  coordinate: number | string,
  dimOld: number,
  dimNew: number
): string => getPx((getNum(coordinate) / dimOld) * dimNew);

export const getWidth = (g: Dim, w: number, h: number): string => {
  const safeH = h < ALIGN_THRESHOLD ? h : (h / g.h) * g.w;

  return getPx(!IS_MOBILE ? w : safeH);
};

export const getHeight = (g: Dim, h: number, w: number): string => {
  const safeW = w < ALIGN_THRESHOLD ? w : (w / g.w) * g.h;

  return getPx(!IS_MOBILE ? h : safeW);
};

export const getLeft = (
  g: Dim,
  l: number,
  y: number,
  w: number | string
): string => getPx(!IS_MOBILE ? l : (y / g.h) * g.w - getNum(w) / 2);

export const getTop = (
  g: Dim,
  t: number,
  x: number,
  h: number | string
): string => getPx(!IS_MOBILE ? t : ((g.w - x) / g.w) * g.h - getNum(h) / 2);

export const getBorderWidth = (bw: string): string => {
  if (!IS_MOBILE) return bw;

  const ws = bw.split(' ');

  return ws
    .map((w, idx) => (idx % 2 === 0 ? ws[idx + 1] : ws[idx - 1]))
    .join(' ');
};
