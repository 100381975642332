import React, { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';

import HollowStudy from '~/components/backgrounds/HollowStudy';
import Brand from '~/components/titles/Brand';
import { FONT_SIZES } from '~/constants/typography';
import { useAuth } from '~/providers/Auth';
import { useTitle } from '~/utils';
import { addStar } from '~/helpers/routing';

import urls from './urls';
import PATHS, { BEATBOXDB_PATH } from './constants/paths';
import NavSwitchBoard from './components/NavSwitchBoard';
import MainSearchBar from './components/MainSearchBar';
import BeatboxProvider, {
  useBeatboxContext,
} from './providers/BeatboxProvider';
import RecordProvider from './providers/RecordProvider';
import Welcome from './pages/Welcome';
import Unauthed from './pages/Unauthed';
import NotFound from './pages/NotFound';
import Explore from './pages/Explore';
import Manage from './pages/Manage';
import Bounties from './pages/Bounties';
import Records from './pages/Records';
import Profile from './pages/Profile';
import Search from './pages/Search';
import About from './pages/About';

function BeatboxDb(): ReactElement {
  const { state: authState } = useAuth();
  const { state: beatboxState } = useBeatboxContext();
  const credits = [{ name: 'amCharts', link: urls.amCharts }];

  useTitle('Beatbox Database');

  // Styling
  const beatboxDbStyle = {
    width: '100%',
    fontSize: FONT_SIZES.text,
    textAlign: 'center' as const,
  };
  const backgroundStyle = {
    position: 'absolute' as const,
    minHeight: '45em',
    zIndex: -2,
  };
  const containerStyle = {
    display: 'flex',
    minWidth: 350,
    paddingBottom: '1em',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  };

  // Both `index` and `path` needed in index route to avoid runtime warning since `Explore` also has routes
  return (
    <div style={beatboxDbStyle}>
      <HollowStudy credits={credits} style={backgroundStyle}>
        <div style={containerStyle}>
          <Brand
            name={beatboxState.subject?.name || 'beatbox'}
            topic="Database"
            link={BEATBOXDB_PATH || PATHS.home}
          />
          {authState.isAuthed /* && !authState.user?.onboarding */ ? (
            <Welcome />
          ) : (
            <>
              <NavSwitchBoard />
              <MainSearchBar />
              <Routes>
                <Route path="*" element={<NotFound />} />
                <Route index path={addStar(PATHS.home)} element={<Explore />} />

                {/* Explore */}
                <Route path={addStar(PATHS.explore)} element={<Explore />} />

                {/* Manage */}
                {authState.isAuthed ? (
                  <Route path={addStar(PATHS.manage)} element={<Manage />} />
                ) : (
                  <Route
                    path={PATHS.manage}
                    element={<Unauthed roleRequired />}
                  />
                )}

                {/* Bounties */}
                {authState.isAuthed ? (
                  <Route
                    path={addStar(PATHS.bounties)}
                    element={<Bounties />}
                  />
                ) : (
                  <Route
                    path={PATHS.bounties}
                    element={<Unauthed roleRequired />}
                  />
                )}

                {/* Records */}
                {authState.isAuthed ? (
                  <Route path={addStar(PATHS.records)} element={<Records />} />
                ) : (
                  <Route path={PATHS.records} element={<Unauthed />} />
                )}

                {/* Profile */}
                <Route path={PATHS.profile} element={<Profile />} />

                {/* Search */}
                {authState.isAuthed ? (
                  <Route path={PATHS.search} element={<Search />} />
                ) : (
                  <Route
                    path={PATHS.search}
                    element={<Unauthed roleRequired />}
                  />
                )}

                {/* About */}
                <Route path={PATHS.about} element={<About />} />
              </Routes>
            </>
          )}
        </div>
      </HollowStudy>
    </div>
  );
}

export default () => (
  <BeatboxProvider>
    <RecordProvider>
      <BeatboxDb />
    </RecordProvider>
  </BeatboxProvider>
);
