import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import { LocationState } from '~/models';
import Link from '~/components/flow/Link';
import PATHS from '~/constants/paths';

function Caption(): ReactElement {
  const { state } = useLocation() as { state: LocationState };

  // Styling
  const captionStyle = {
    textAlign: 'center' as const,
  };

  return (
    <div style={captionStyle}>
      Don&apos;t have an account?{' '}
      <Link to={PATHS.register} state={state}>
        Sign up
      </Link>
      !
    </div>
  );
}

export default Caption;
