import React, { ReactElement, CSSProperties } from 'react';
import RCSlider, { SliderProps as RCSliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';

interface SliderProps extends RCSliderProps {
  style?: CSSProperties;
}

function Slider({ style, ...props }: SliderProps): ReactElement {
  const sliderStyle = {
    display: 'inline-block',
    minWidth: '10em',
    ...style,
  };

  return (
    <div style={sliderStyle}>
      <RCSlider {...props} />
    </div>
  );
}

export default Slider;
