import React, { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';

import { FONT_SIZES } from '~/constants/typography';
import { useTitle } from '~/utils';
import COLORS from '~/constants/colors';
import HollowStudy from '~/components/backgrounds/HollowStudy';

import urls from './urls';
import PATHS from './constants/paths';
import BrandNav from './components/BrandNav';
import NotFound from './pages/NotFound';
import Write from './pages/Write';
import Read from './pages/Read';
import About from './pages/About';

function Beatblox(): ReactElement {
  const credits = [{ name: 'BBXINT', link: urls.bbxInt }];

  useTitle('Beatblox');

  // Styling
  const beatbloxStyle = {
    width: '100%',
    fontSize: FONT_SIZES.text,
    textAlign: 'center' as const,
  };
  const backgroundStyle = {
    position: 'absolute' as const,
    backgroundColor: COLORS.white,
    overflow: 'hidden',
    zIndex: -2,
  };
  const containerStyle = {
    display: 'flex',
    minWidth: 350,
    paddingBottom: '1em',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  };

  return (
    <div style={beatbloxStyle}>
      <HollowStudy
        action="Created by"
        credits={credits}
        style={backgroundStyle}
      >
        <div style={containerStyle}>
          <BrandNav />
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route index element={<Write />} />
            <Route path={PATHS.write} element={<Write />} />
            <Route path={PATHS.read} element={<Read />} />
            <Route path={PATHS.about} element={<About />} />
          </Routes>
        </div>
      </HollowStudy>
    </div>
  );
}

export default Beatblox;
