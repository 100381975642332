import { ActionDispatch, ActionCreator } from '~/models';
import { setStorageItem } from '~/helpers/storage';

import { User, LoginInfo, SignupInfo, AccountInfo } from './models';
import { setAxiosAuthHeader } from './utils';
import { STORAGE_KEYS } from './constants';
import {
  revokeRefresh,
  refreshAuth,
  login,
  createUser,
  updateMe,
  deleteMe,
} from './services';

export const ACTIONS = {
  login: 'Auth/login',
  logout: 'Auth/logout',
  updateAccount: 'Auth/updateAccount',
};

// Creators
const loginCreator: ActionCreator<User> = (user) => ({
  type: ACTIONS.login,
  payload: user,
});
const logoutCreator: ActionCreator<string> = () => ({
  type: ACTIONS.logout,
});
const updateAccountCreator: ActionCreator<User> = (user) => ({
  type: ACTIONS.updateAccount,
  payload: user,
});

// Thunks
export const logoutThunk =
  (dispatch: ActionDispatch) => async (): Promise<void> => {
    setAxiosAuthHeader();
    setStorageItem(STORAGE_KEYS.logoutTime, Date.now()); // For synchronizing logouts
    await revokeRefresh();
    dispatch(logoutCreator());
  };

export const loadAuthThunk =
  (dispatch: ActionDispatch) => async (): Promise<void> => {
    const { accessToken, user } = (await refreshAuth()) || {};

    if (accessToken && user) {
      setAxiosAuthHeader(accessToken);
      dispatch(loginCreator(user));
    } else await logoutThunk(dispatch)();
  };

export const loginThunk =
  (dispatch: ActionDispatch) =>
  async (loginInfo: LoginInfo): Promise<void> => {
    const { accessToken, user } = await login(loginInfo);

    setAxiosAuthHeader(accessToken);
    dispatch(loginCreator(user));
  };

export const signupThunk =
  (dispatch: ActionDispatch) =>
  async (signupInfo: SignupInfo): Promise<void> => {
    const loginInfo = {
      email: signupInfo.email,
      password: signupInfo.password,
    };

    await createUser(signupInfo);
    await loginThunk(dispatch)(loginInfo);
  };

export const updateAccountThunk =
  (dispatch: ActionDispatch) =>
  async (accountInfo: AccountInfo): Promise<void> => {
    const user = await updateMe(accountInfo);

    dispatch(updateAccountCreator(user));
  };

export const deleteAccountThunk =
  (dispatch: ActionDispatch) => async (): Promise<void> => {
    await deleteMe();
    await logoutThunk(dispatch)();
  };
