import { Beat, Chart, Charts } from '../../models';
import getParser from '../../parsers/groups';
import { RATES } from './constants';

export const adjustChartRate = (chart: Chart, rate: number): Chart =>
  chart.map((n) => ({
    ...n,
    start: n.start / rate,
    ...(n.end && { end: n.end / rate }),
  }));

export const processBeat = (
  beat: Beat,
  rate: number = RATES.default
): { chart: Chart; charts: Charts } => {
  const parser = getParser(beat.nodes, beat.bpm);
  const parsed = parser(beat.layers);
  const chart = adjustChartRate(parsed.chart, rate);
  const charts = Object.keys(parsed.charts).reduce(
    (acc, g) => ({ ...acc, [g]: adjustChartRate(parsed.charts[g], rate) }),
    {}
  );

  // TODO: may also want to return `sentence` for highlighting outdated `notation`
  return { chart, charts }; // TODO: fix this jank from temporary fix
};
