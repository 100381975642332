import { Preset } from './models';
import { getDistortionCurve } from './utils';

export const ACTIVE_COMP_ATTACK = 0.1;
export const ACTIVE_COMP_RELEASE = 0.2;

export const RAW: Preset = {
  lowFreq: 0,
  lowGain: 0,
  highFreq: 20000,
  highGain: 0,
  highPassF: 0,
  highPassQ: 0,
  gain: 1,
  distortionAmount: 0,
  distortionCurve: null,
  compThresh: 0,
  compKnee: 0,
  compRatio: 1,
  compAttack: 0,
  compRelease: 0,
};

export const NATURAL: Preset = {
  lowFreq: 150,
  lowGain: 8,
  highFreq: 8000,
  highGain: 12,
  highPassF: 50,
  highPassQ: 2,
  gain: 1.5,
  distortionCurve: null,
  compThresh: -15,
  compKnee: 30,
  compRatio: 2,
  compAttack: ACTIVE_COMP_ATTACK,
  compRelease: ACTIVE_COMP_RELEASE,
};

export const BALANCED: Preset = {
  lowFreq: 150,
  lowGain: 10,
  highFreq: 8000,
  highGain: 10,
  highPassF: 50,
  highPassQ: 2,
  gain: 1.5,
  distortionCurve: null,
  compThresh: -30,
  compKnee: 30,
  compRatio: 7,
  compAttack: ACTIVE_COMP_ATTACK,
  compRelease: ACTIVE_COMP_RELEASE,
};

export const BASSY: Preset = {
  lowFreq: 150,
  lowGain: 16,
  highFreq: 8000,
  highGain: 10,
  highPassF: 50,
  highPassQ: 2,
  gain: 1.5,
  distortionCurve: getDistortionCurve(2),
  compThresh: -35,
  compKnee: 30,
  compRatio: 8,
  compAttack: ACTIVE_COMP_ATTACK,
  compRelease: ACTIVE_COMP_RELEASE,
};

export const HEAVY: Preset = {
  lowFreq: 150,
  lowGain: 8,
  highFreq: 8000,
  highGain: 20,
  highPassF: 50,
  highPassQ: 2,
  gain: 1.5,
  distortionCurve: getDistortionCurve(3),
  compThresh: -40,
  compKnee: 30,
  compRatio: 15,
  compAttack: ACTIVE_COMP_ATTACK,
  compRelease: ACTIVE_COMP_RELEASE,
};
