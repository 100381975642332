import { NOTE_DIMS } from './layout';

export const NO_LOAD_THRESHOLD = 6969;
export const GARBAGE_THRESHOLD = 500;
export const TEMPO_NUMERATOR = 60000;
export const INITIAL_HEALTH = 10;
export const RECORD_KEY_CODE = 'Enter';
export const SPECIAL_CHANCE = 1 / 1000;
export const SPECIAL_SCORE_THRESHOLD = 0.9;
export const MIN_SPECIAL_POSSIBLE_POINTS = 1024;

const getThresholdUnit = (wDims) => NOTE_DIMS(wDims).h / 5;
export const THRESHOLDS = (wDims) => {
  const thresholdUnit = getThresholdUnit(wDims);

  return {
    perfect: thresholdUnit,
    good: thresholdUnit * 2,
    okay: thresholdUnit * 3,
    bad: thresholdUnit * 4,
    awful: thresholdUnit * 5,
    miss: thresholdUnit * 5,
  };
};

export const POINTS = {
  perfect: 32,
  good: 16,
  okay: 8,
  bad: 4,
  awful: 2,
  pre: 1,
  miss: 0,
  wrong: 0,
};

export const DAMAGE = {
  perfect: -1,
  good: 0,
  okay: 0,
  bad: 0,
  awful: 0,
  pre: 0,
  miss: 1,
  wrong: 2,
};

export const FALL_SPEED = {
  default: 0.26,
  min: 0.1,
  max: 0.42,
  step: 0.08,
};
