import React from 'react';

import NightSky from '~/components/backgrounds/NightSky';
import Brand from '~/components/titles/Brand';
import { FONT_SIZES } from '~/constants/typography';
import FramedView from '~/components/FramedView';
import Gap from '~/components/flow/Gap';

import Form from './components/Form';
import Caption from './components/Caption';

function Register() {
  const registerStyle = {
    width: '100%',
    fontSize: FONT_SIZES.text,
    textAlign: 'center' as const,
  };
  const containerStyle = {
    display: 'flex',
    minWidth: 350,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  };

  return (
    <div style={registerStyle}>
      <NightSky>
        <div style={containerStyle}>
          <Brand topic="Register" />
          <FramedView accent>
            <Gap />
            <Caption />
            <br />
            <Form />
          </FramedView>
        </div>
      </NightSky>
    </div>
  );
}

export default Register;
