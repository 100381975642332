import React, { ReactElement } from 'react';

import Header from '~/components/titles/Header';
import Divider from '~/components/flow/Divider';

function EmptyFace(): ReactElement {
  // Styling
  const headerStyle = {
    marginTop: '0.25em',
  };

  return (
    <>
      <Header style={headerStyle}>404</Header>
      <Divider />
      This is not the card you are looking for.
    </>
  );
}

export default EmptyFace;
