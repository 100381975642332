import React, { ReactElement, PropsWithChildren, CSSProperties } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import urls from '~/urls';
import Link from '~/components/flow/Link';

import selagoClouds from './assets/selago-clouds.svg';
import linkWaterClouds from './assets/link-water-clouds.svg';

interface DayGroundProps {
  style?: CSSProperties;
}

function DayGround({
  style,
  children,
}: PropsWithChildren<DayGroundProps>): ReactElement {
  const dayGroundStyle = {
    display: 'table',
    width: '100%',
    height: '100vh',
    backgroundColor: COLORS.tropicalBlue,
    ...style,
  };
  const containerStyle = {
    display: 'table-row',
    height: 1,
    fontSize: FONT_SIZES.subtext,
    textAlign: 'right' as const,
  };
  const terrainStyle = {
    marginTop: -468,
  };
  const creditStyle = {
    color: COLORS.mineShaft,
    backgroundColor: COLORS.capePalliser,
    padding: '1em 1.5em 1.5em 0',
  };
  const creditLinkStyle = {
    color: COLORS.dreamlessSleep,
  };
  const whiteCloudsStyle = {
    height: 126,
    marginBottom: -1,
    backgroundImage: `url(${selagoClouds})`,
    backgroundRepeat: 'repeat-x',
  };
  const cloudCoverStyle = {
    width: '100%',
    height: 150,
    marginBottom: -1,
    backgroundColor: COLORS.selago,
  };
  const solitudeCloudsStyle = {
    ...whiteCloudsStyle,
    height: 183,
    marginBottom: -112,
    backgroundImage: `url(${linkWaterClouds})`,
    backgroundPosition: '1.5em',
  };
  const grassStyle = {
    ...cloudCoverStyle,
    height: 42,
    backgroundColor: COLORS.deco,
  };
  const tanSoilStyle = {
    ...cloudCoverStyle,
    height: 42,
    backgroundColor: COLORS.tan,
  };
  const capePalliserSoilStyle = {
    ...cloudCoverStyle,
    height: 42,
    backgroundColor: COLORS.capePalliser,
  };

  return (
    <div style={dayGroundStyle} id="day-ground">
      <div style={containerStyle} />
      {children}
      <div style={containerStyle}>
        <div style={terrainStyle}>
          <div style={solitudeCloudsStyle} />
          <div style={whiteCloudsStyle} />
          <div style={cloudCoverStyle} />
          <div style={grassStyle} />
          <div style={tanSoilStyle} />
          <div style={capePalliserSoilStyle} />
          <div style={creditStyle}>
            Designed by{' '}
            <Link href={urls.richardLinkedIn} style={creditLinkStyle}>
              Richard Hsu
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DayGround;
