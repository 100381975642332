import { RefObject } from 'react';
import { SwipeEventData } from 'react-swipeable';

import { ActionDispatch, ActionCreator } from '~/models';
import { mod } from '~/utils';

import { CarouselState } from './models';

export const ACTIONS = {
  setLen: 'carousel/setLen',
  next: 'carousel/next',
  prev: 'carousel/prev',
  jump: 'carousel/jump',
  drag: 'carousel/drag',
  done: 'carousel/done',
};

// Creators
const setLenCreator: ActionCreator<number> = (len) => ({
  type: ACTIONS.setLen,
  payload: len,
});
const nextCreator: ActionCreator = () => ({ type: ACTIONS.next });
const prevCreator: ActionCreator = () => ({ type: ACTIONS.prev });
const jumpCreator: ActionCreator<number> = (desiredIdx) => ({
  type: ACTIONS.jump,
  payload: desiredIdx,
});
const dragCreator: ActionCreator<number> = (offset) => ({
  type: ACTIONS.drag,
  payload: offset,
});
const doneCreator: ActionCreator = () => ({ type: ACTIONS.done });

// Thunks
export const setLenThunk =
  (dispatch: ActionDispatch) =>
  (len: number): void =>
    dispatch(setLenCreator(len));
export const dragThunk =
  (dispatch: ActionDispatch) =>
  (maxOffset = Infinity) =>
  (eventData: SwipeEventData): void => {
    const offsetDist = Math.min(Math.abs(eventData.deltaX), maxOffset);
    const offsetSign = Math.sign(eventData.deltaX);

    if (!eventData.first) dispatch(dragCreator(offsetSign * offsetDist));
  };
export const swipeThunk =
  (dispatch: ActionDispatch, state: CarouselState) =>
  (maxOffset: number, ref?: RefObject<HTMLInputElement>) =>
  (eventData: SwipeEventData): void => {
    if (Math.abs(eventData.deltaX) > maxOffset / 3.5) {
      dispatch(eventData.deltaX < 0 ? nextCreator() : prevCreator());

      // Handle updating ref
      const current = ref?.current;
      const desiredIdx = state.activeIdx + (eventData.deltaX < 0 ? 1 : -1);

      if (current) current.value = String(mod(desiredIdx, state.len));
    } else dispatch(dragCreator(0));
  };
export const nextThunk = (dispatch: ActionDispatch) => (): void =>
  dispatch(nextCreator());
export const doneThunk = (dispatch: ActionDispatch) => (): void =>
  dispatch(doneCreator());
export const jumpThunk =
  (dispatch: ActionDispatch, state: CarouselState) =>
  (maxOffset = Infinity, ref?: RefObject<HTMLInputElement>) =>
  (desiredIdx: number): void => {
    if (Number.isNaN(state.offset) || Math.abs(state.offset) <= maxOffset) {
      dispatch(jumpCreator(desiredIdx));

      // Handle updating ref
      const current = ref?.current;

      if (current) current.value = String(mod(desiredIdx, state.len));
    }
  };
