import { Kind, Difficulty, State } from '../models';

export default {
  profiles: 'profiles',
  beatboxers: 'beatboxers',
  subjects: 'subjects',
  categories: 'categories',
  techniques: 'techniques',
  wantedTechniques: 'wantedTechniques',
  mainSearchTechniques: 'mainSearchTechniques',
  demos: 'demos',
  wantedDemos: 'wantedDemos',
  tutorials: 'tutorials',
  wantedTutorials: 'wantedTutorials',
  records: 'records',
};

// Techniques
export const KINDS: Record<string, Kind> = {
  sound: 'sound',
  combo: 'combo',
};

export const DIFFICULTIES: Record<string, Difficulty> = {
  fundamentals: 'fundamentals',
  beginner: 'beginner',
  intermediate: 'intermediate',
  advanced: 'advanced',
  expert: 'expert',
  unique: 'unique',
};

// Records
export const STATES: Record<string, State> = {
  learning: 'learning',
  grinding: 'grinding',
  satisfied: 'satisfied',
  archived: 'archived',
};
