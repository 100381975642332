import { BarEntity } from './models';
import { Dim } from '../predicates/models';
import Bar from '../renderers/Bar';
import barPredicate from '../predicates/barPredicate';

const barEntity = (
  windowDims: Dim,
  y: number,
  timing: number,
  fuzzy = true,
  state?: string
): BarEntity => ({
  ...barPredicate(windowDims, y, timing, fuzzy, state),
  renderer: Bar,
});

export default barEntity;
