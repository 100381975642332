import React, {
  ReactElement,
  Dispatch,
  SetStateAction,
  CSSProperties,
} from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import Slider from '~/components/inputs/Slider';

import { FALL_SPEED } from '../constants/mechanics';

interface NoteVelocitySliderProps {
  velocity: number;
  setVelocity: Dispatch<SetStateAction<number>>;
  markLabel?: string;
  markColor?: string;
  style?: CSSProperties;
}

function NoteVelocitySlider({
  velocity,
  setVelocity,
  markLabel = 'Note Velocity',
  markColor = COLORS.white,
  style,
}: NoteVelocitySliderProps): ReactElement {
  const handleChange = (value) => setVelocity(value);
  const getMark = (label) => ({
    style: {
      color: markColor,
      fontSize: FONT_SIZES.text,
      whiteSpace: 'nowrap' as const,
    },
    label,
  });
  const marks = {
    [FALL_SPEED.default]: getMark(markLabel),
    [FALL_SPEED.min]: getMark('Slow'),
    [FALL_SPEED.max]: getMark('Fast'),
  };

  // Styling
  const noteVelocitySliderStyle = {
    width: 'min(20em, 60vw)',
    height: '2em',
    ...style,
  };
  const handleStyle = {
    width: '1.25em',
    height: '1.25em',
    marginTop: '-0.5em',
    backgroundColor: COLORS.dodgerBlue,
    borderColor: COLORS.linkWater,
    opacity: 1,
    boxShadow: 'none',
  };

  return (
    <Slider
      min={FALL_SPEED.min}
      max={FALL_SPEED.max}
      step={FALL_SPEED.step}
      marks={marks}
      value={velocity}
      onChange={handleChange}
      included={false}
      handleStyle={handleStyle}
      style={noteVelocitySliderStyle}
    />
  );
}

export default NoteVelocitySlider;
