import { ActionDispatch, ActionCreator, JournalResponse } from '~/models';

import { capitalizeFirstLetter, getSingular } from '../../utils';
import CONTENT from '../../constants/content';
import {
  Content,
  Technique,
  BeatboxState,
  AddContent,
  GetId,
  SetId,
  SetBeatboxer,
  SetSubject,
  ListContent,
  ListWantedContent,
  ListMainSearchTechniques,
  EditId,
  DeleteId,
} from './models';
import {
  readProfiles,
  readBeatboxer,
  readSubject,
  getCreateContent,
  getReadContent,
  getReadContents,
  getUpdateContent,
  getDeleteContent,
} from './services';

export const ACTIONS = {
  add: 'BeatboxDb/beatbox/add',
  set: 'BeatboxDb/beatbox/set',
  list: 'BeatboxDb/beatbox/list',
  listWanted: 'BeatboxDb/beatbox/listWanted',
  listMainSearchTechniques: 'BeatboxDb/beatbox/listMainSearchTechniques',
  edit: 'BeatboxDb/beatbox/edit',
  delete: 'BeatboxDb/beatbox/delete',
};

// Creators
const getActionType = (prefix, content) =>
  `${ACTIONS[prefix]}${capitalizeFirstLetter(content)}`;
const getCrudCreator =
  <T>(prefix: string, content: string): ActionCreator<T> =>
  (payload) => ({
    type: getActionType(prefix, content),
    ...(payload && { payload }),
  });
const listMainSearchTechniquesCreator: ActionCreator<
  JournalResponse<Technique>
> = (payload) => ({ type: ACTIONS.listMainSearchTechniques, payload });

// Thunks
const fixCategoriesOrder = (technique) => ({
  ...technique,
  categories: technique.ordered_categories?.map((cId) => {
    const category = technique.categories?.find((c) => c.id === cId);

    // Need to expand manually here due to weird behavior with object references
    return {
      id: category?.id,
      label: category?.label,
      subject: category?.subject,
    };
  }),
});
const getPopulate = (plural) => ({
  populate: {
    categories: ['subject'],
    techniques: [
      'credited_up_users',
      'subject',
      'categories',
      'parent_techniques',
      'child_techniques',
      'demos',
      'tutorials',
    ],
    wantedTechniques: ['demos', 'tutorials'],
    demos: ['techniques'],
    tutorials: ['categories', 'techniques'],
  }[plural],
});

export const setBeatboxerThunk =
  (dispatch: ActionDispatch): SetBeatboxer =>
  async (handle) => {
    const beatboxer = handle ? await readBeatboxer(handle) : undefined;

    dispatch(getCrudCreator('set', getSingular(CONTENT.beatboxers))(beatboxer));
  };

export const listProfilesThunk =
  (dispatch: ActionDispatch): ListContent =>
  async () => {
    const profiles = await readProfiles();

    dispatch(getCrudCreator('list', CONTENT.profiles)(profiles));
  };

export const setSubjectThunk =
  (dispatch: ActionDispatch): SetSubject =>
  async () => {
    const subject = await readSubject();

    dispatch(getCrudCreator('set', getSingular(CONTENT.subjects))(subject));
  };

export const getAddThunk =
  <T>(pluralType: string) =>
  (dispatch: ActionDispatch, state: BeatboxState): AddContent<T> =>
  async (info: T) => {
    if (state.subject) {
      let content = await getCreateContent(pluralType, getPopulate(pluralType))(
        info,
        state.subject.id
      );

      if (pluralType === CONTENT.techniques)
        content = fixCategoriesOrder(content);
      dispatch(getCrudCreator('add', getSingular(pluralType))(content));

      return (content as Content).id;
    }

    return NaN;
  };

export const getGetThunk =
  <T>(pluralType: string): GetId<T> =>
  async (id: number) => {
    const singularType = getSingular(pluralType);
    let content = await getReadContent(
      singularType,
      getPopulate(pluralType)
    )(id);

    if (pluralType === CONTENT.techniques)
      content = fixCategoriesOrder(content);

    return content as T;
  };

export const getSetThunk =
  (pluralType: string) =>
  (dispatch: ActionDispatch): SetId =>
  async (id: number) => {
    const singularType = getSingular(pluralType);
    let content = await getReadContent(
      singularType,
      getPopulate(pluralType)
    )(id);

    if (pluralType === CONTENT.techniques)
      content = fixCategoriesOrder(content);
    dispatch(getCrudCreator('set', singularType)(content));
  };

export const getListThunk =
  (pluralType: string) =>
  (dispatch: ActionDispatch, state: BeatboxState): ListContent =>
  async (page?: number) => {
    if (state.subject) {
      let contents = await getReadContents(pluralType)(
        state.subject.id,
        page,
        getPopulate(pluralType)
      );

      if (pluralType === CONTENT.techniques && Array.isArray(contents))
        contents = contents.map(fixCategoriesOrder);
      dispatch(getCrudCreator('list', pluralType)(contents));
    }
  };

export const getListWantedThunk =
  (pluralType: string) =>
  (dispatch: ActionDispatch, state: BeatboxState): ListWantedContent =>
  async (page: number) => {
    if (state.subject) {
      const contents = await getReadContents(pluralType)(
        state.subject.id,
        page,
        pluralType === CONTENT.techniques
          ? {
              'filters[$or][0][description][$eq]': '',
              'filters[$or][1][demos][id][$null]': true,
              'filters[$or][2][tutorials][id][$null]': true,
              sort: 'updatedAt:asc',
              ...getPopulate(CONTENT.wantedTechniques),
            }
          : { 'filters[techniques][id][$null]': true, sort: 'updatedAt:asc' }
      );

      dispatch(getCrudCreator('listWanted', pluralType)(contents));
    }
  };

export const listMainSearchTechniquesThunk =
  (dispatch: ActionDispatch, state: BeatboxState): ListMainSearchTechniques =>
  async (query: string, page: number) => {
    if (state.subject) {
      const fields = ['name', 'description'];
      const uniqueQueryWords = [
        ...new Set(query.toLowerCase().split(' ')),
      ].filter((w) => w);
      const filters = fields.map((f, fIdx) =>
        uniqueQueryWords.map((w, wIdx, { length }) => [
          `filters[$or][${fIdx * length + wIdx}][${f}][$containsi]`,
          w,
        ])
      );
      const contents = (await getReadContents(CONTENT.techniques)(
        state.subject.id,
        page,
        { ...Object.fromEntries(filters.flat()), sort: 'relevance:desc' }
      )) as JournalResponse<Technique>;

      dispatch(listMainSearchTechniquesCreator(contents));
    }
  };

export const getEditThunk =
  <T>(pluralType: string) =>
  (dispatch: ActionDispatch): EditId<T> =>
  async (id: number, info: T) => {
    const singularType = getSingular(pluralType);
    let content = await getUpdateContent(singularType, getPopulate(pluralType))(
      id,
      info
    );

    if (pluralType === CONTENT.techniques)
      content = fixCategoriesOrder(content);
    dispatch(getCrudCreator('edit', singularType)(content));
  };

export const getDeleteThunk =
  (pluralType: string) =>
  (dispatch: ActionDispatch): DeleteId =>
  async (id: number) => {
    const singularType = getSingular(pluralType);

    await getDeleteContent(singularType)(id);
    dispatch(getCrudCreator('delete', singularType)());
  };
