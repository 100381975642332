import urls from '~/urls';

import { BSH_PATH } from './constants/paths';

export default {
  clips: `${urls.api}/beatbox-clips`,
  clip: (code) => `${urls.api}/beatbox-clips/${code}`,
  clipRecording: (url) =>
    url.startsWith('/') ? `${urls.base}/${url.slice(1)}` : url,
  mux: `${urls.api}/beatbox-clips/mux`,
  listen: (code) => `${window.location.origin}${BSH_PATH}/${code}`,

  // External
  kindredYouTube: 'https://www.youtube.com/@kindreon',
  kalapuikkoInstagram: 'https://www.instagram.com/nollabbx/',
};
