/* eslint-disable import/prefer-default-export */
import { Node, Nodes } from '../../models';
import { MIME_TYPES } from '../../constants/media';
import B from './assets/b-kick.flac';
import t from './assets/t-hi-hat.flac';
import k from './assets/k-snare.flac';
import Pf from './assets/pf-snare.flac';
import rw from './assets/recorder-whistle.flac';
import d from './assets/dry-kick.flac';
import OD from './assets/inward-od-bass.flac';
import Ts from './assets/open-hi-hat.flac';
import BMG from './assets/spit-snare.flac';
import G from './assets/throat-kick.flac';
import Bs from './assets/bs-kick.flac';
import f from './assets/f-hi-hat.flac';
import K1 from './assets/outward-k-snare.flac';
import Psh from './assets/psh-snare.flac';
import hut from './assets/sucker-punch.flac';
import c from './assets/tongue-click.flac';
import df from './assets/df-hi-hat.flac';
import K from './assets/inward-k-snare.flac';
import KIM from './assets/kim-squeak.flac';
import Peh from './assets/peh-snare.flac';
import ib from './assets/inward-bass.flac';
import d1 from './assets/d-kick.flac';
import hcp from './assets/hollow-clop.flac';
import hilr from './assets/hollow-inward-liproll.flac';
import hub from './assets/hut-percussion.flac';
import B1 from './assets/inward-kick.flac';
import k1 from './assets/k-percussion.flac';
import lrs from './assets/liproll-scratch.flac';
import mg from './assets/machine-gun-percussion.flac';
import B2 from './assets/outward-subbass-kick.flac';
import osb from './assets/outward-subbass.flac';
import dk from './assets/duck-sound.flac';
import icr from './assets/inward-click-roll.flac';
import ks from './assets/kiss.flac';
import lr from './assets/liproll.flac';
import stb from './assets/sharp-tongue-bass.flac';
import slr from './assets/subbass-liproll.flac';
import A from './assets/cough-snare.flac';
import nA from './assets/nose-cough-snare.flac';
import FBMG from './assets/footboxg-bmg.flac';
import zb from './assets/zombie-bass.flac';

const getNode = (
  id: number,
  name: string,
  notation: string,
  url: string
): Node => ({
  id,
  name,
  notation,
  sample: {
    id,
    recording: {
      mime: MIME_TYPES.flac,
      url,
    },
  },
});

// TODO: actually get data from backend
export const readNodes = async (): Promise<Nodes> => {
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  await delay(2000);

  return [
    getNode(0, 'B kick', 'B', B),
    getNode(1, 't hi-hat', 't', t),
    getNode(2, 'k rimshot', 'k', k),
    getNode(3, 'Pf snare', 'Pf', Pf),
    getNode(4, 'recorder whistle', 'rw', rw),
    getNode(5, 'dry kick', 'd', d),
    getNode(6, 'inward OD bass', 'OD', OD),
    getNode(7, 'open hi-hat', 'Ts', Ts),
    getNode(8, 'spit snare', 'BMG', BMG),
    getNode(9, 'throat kick', 'G', G),
    getNode(10, 'Bs kick', 'Bs', Bs),
    getNode(11, 'f hi-hat', 'f', f),
    getNode(12, 'outward K snare', 'K1', K1),
    getNode(13, 'Psh snare', 'Psh', Psh),
    getNode(14, 'sucker punch', 'hut', hut),
    getNode(15, 'tongue click', 'c', c),
    getNode(16, 'df hi-hat', 'df', df),
    getNode(17, 'inward K snare', 'K', K),
    getNode(18, 'KIM squeak', 'KIM', KIM),
    getNode(19, 'Peh snare', 'Peh', Peh),
    getNode(20, 'inward bass', 'ib', ib),
    getNode(21, 'd kick', 'd1', d1),
    getNode(22, 'hollow clop', 'hcp', hcp),
    getNode(23, 'hollow inward lip roll', 'hilr', hilr),
    getNode(24, 'lip sucker punch', 'hub', hub),
    getNode(25, 'inward kick', 'B1', B1),
    getNode(26, 'k percussion', 'k1', k1),
    getNode(27, 'lip roll scratch', 'lrs', lrs),
    getNode(28, 'machine gun', 'mg', mg),
    getNode(29, 'outward subbass kick', 'B2', B2),
    getNode(30, 'outward subbass', 'osb', osb),
    getNode(31, 'duck sound', 'dk', dk),
    getNode(32, 'inward click roll', 'icr', icr),
    getNode(33, 'kiss', 'ks', ks),
    getNode(34, 'lip roll', 'lr', lr),
    getNode(35, 'sharp tongue bass', 'stb', stb),
    getNode(36, 'subbass lip roll', 'slr', slr),
    getNode(37, 'cough snare', 'A', A),
    getNode(38, 'nasal cough snare', 'nA', nA),
    getNode(39, 'FootboxG spit snare', 'FBMG', FBMG),
    getNode(40, 'zombie bass', 'zb', zb),
  ];
};
