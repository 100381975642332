import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import COLORS from '~/constants/colors';
import FramedView from '~/components/FramedView';
import Title from '~/components/titles/Title';
import Divider from '~/components/flow/Divider';
import Button from '~/components/buttons/Button';
import { FONT_SIZES } from '~/constants/typography';
import Gap from '~/components/flow/Gap';
import { stopEventEffects } from '~/utils';

interface NotFoundProps {
  nested?: boolean;
}

function NotFound({ nested = false }: NotFoundProps): ReactElement {
  const navigate = useNavigate();
  const handleGoBack = (event) => {
    stopEventEffects(event);
    navigate(-1);
  };

  // Styling
  const notFoundStyle = {
    display: 'inline-block',
    width: 'min(45em, 82.5vw)',
  };
  const frameColors: [string, string] = [
    COLORS.waikawaGray,
    COLORS.waikawaGray,
  ];
  const buttonStyle = {
    width: '9em',
    margin: '0 0.25em 0.38em',
    fontSize: FONT_SIZES.text,
  };

  // Deal with nesting
  const ConditionalView = ({ children }) =>
    nested ? (
      <>{children}</>
    ) : (
      <FramedView accent colors={frameColors} style={notFoundStyle}>
        {children}
      </FramedView>
    );

  return (
    <ConditionalView>
      {!nested && (
        <>
          <Title>Not Found</Title>
          <Divider />
        </>
      )}
      You&apos;ve discovered the hidden treasure of nothing. But forget not the
      wisdoms of old. Code requires time and effort, a kindred to history&apos;s
      greatest towers and stone scultures. Do not take that for granite and know
      there may be something here in the future. For now, please check your
      spelling in the address bar.
      <Gap />
      <Button
        label="Go Back"
        onClick={handleGoBack}
        onTouchEnd={handleGoBack}
        style={buttonStyle}
      />
    </ConditionalView>
  );
}

export default NotFound;
