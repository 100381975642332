import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import COLORS from '~/constants/colors';
import { useAuth } from '~/providers/Auth';

import SearchBar from './SearchBar';
import uris from '../uris';

function MainSearchBar(): ReactElement {
  const { state: authState } = useAuth();
  const navigate = useNavigate();

  // Handlers
  const callback = async (queryInfo) => {
    if (authState.isAuthed) navigate(uris.search(queryInfo.query, 1));
  };

  // Styling
  const mainSearchBarStyle = {
    marginBottom: '2em',
  };
  const inputStyle = {
    width: '18em',
    borderRadius: '0.5em',
    borderColor: COLORS.black50,
    color: !authState.isAuthed ? COLORS.black : COLORS.white,
    backgroundColor: COLORS.black25,
  };

  return (
    <SearchBar
      callback={callback}
      placeholder="Search database"
      disabled={!authState.isAuthed}
      style={mainSearchBarStyle}
      inputStyle={inputStyle}
    />
  );
}

export default MainSearchBar;
