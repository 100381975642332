import { Node, Nodes, Tracks, Sentence, Chart } from '../models';
import {
  TEMPO_NUMERATOR,
  KEYS,
  REST,
  WILDCARD,
  MISSING,
  CARET,
} from '../constants/defaults';
import { MARKS, ERRORS } from '../constants/language';
import { cleanCarets } from '../utils';

type Parser = (
  pattern: string,
  tracks?: Tracks,
  wIdx?: number,
  nIdx?: number,
  prev?: string | Node
) => {
  wLen: number;
  tracks: Tracks;
  sentence: Sentence;
  chart: Chart;
  end?: number;
};

const getParser = (nodes: Nodes, bpm: number): Parser => {
  const parser: Parser = (pattern, tracks = {}, wIdx = 0, nIdx = 0, prev?) => {
    const getNode = (s) => {
      const cleaned = cleanCarets(s);

      return nodes.find((n) => n.notation === cleaned);
    };
    const getTrack = (n = MISSING) =>
      tracks[n.id] ?? Math.max(...Object.values(tracks), -1) + 1;
    const getTracks = (n = MISSING) => ({ ...tracks, [n.id]: getTrack(n) });
    const getSentence = (p, value, error?) => [{ value, error }, ...p.sentence];
    const getChart = (p, ts, node = MISSING, start, end?, closeIdx?) => {
      const caretIdx = pattern.indexOf(MARKS.caret);

      return [
        { track: ts[node.id], key: KEYS[ts[node.id]], start, end, node },
        ...(closeIdx && caretIdx > -1 && caretIdx < closeIdx
          ? [{ track: ts[CARET.id], start, end, node: CARET }]
          : []),
        ...p.chart,
      ];
    };

    // Base case
    if (!pattern) return { wLen: nIdx, tracks, sentence: [], chart: [] };

    // Recursion
    let pNext = pattern.slice(1);
    const wDur = TEMPO_NUMERATOR / Math.max(bpm, 1);

    if (pattern[0] === MARKS.space) {
      const parsed = parser(pNext, tracks, wIdx + 1, 0, prev);
      const accept = nIdx > 0 && pattern.length > 1;

      return {
        wLen: 0,
        tracks: parsed.tracks,
        sentence: getSentence(parsed, MARKS.space, !accept && ERRORS.space),
        chart: parsed.chart,
        end: parsed.end,
      };
    } else if (pattern[0] === MARKS.rest) {
      const parsed = parser(pNext, tracks, wIdx, nIdx + 1, MARKS.rest);
      const wLen = Math.max(nIdx + 1, parsed.wLen);
      const start = wIdx * wDur + nIdx * (wDur / wLen);

      return {
        wLen,
        tracks: parsed.tracks, // TODO: may want to add rest to `tracks`
        sentence: getSentence(parsed, MARKS.rest),
        chart: getChart(parsed, tracks, REST, start), // TODO: may want to calculate `tracks` with rest
      };
    } else if (pattern[0] === MARKS.sustain) {
      const parsed = parser(pNext, tracks, wIdx, nIdx + 1, MARKS.sustain);
      const accept = prev && prev !== MARKS.rest && prev !== MARKS.hold;
      const wLen = Math.max(nIdx + 1, parsed.wLen);
      const end = wIdx * wDur + (nIdx + 1) * (wDur / wLen);

      return {
        wLen,
        tracks: parsed.tracks,
        sentence: getSentence(parsed, MARKS.sustain, !accept && ERRORS.sustain),
        chart: parsed.chart,
        end: Math.max(end, parsed.end ?? -1),
      };
    } else if (pattern[0] === MARKS.hold) {
      const parsed = parser(pNext, tracks, wIdx, nIdx, MARKS.hold);
      const accept =
        nIdx > 0 &&
        prev !== MARKS.rest &&
        prev !== MARKS.sustain &&
        prev !== MARKS.hold;
      const wLen = Math.max(nIdx, parsed.wLen);

      return {
        wLen,
        tracks: parsed.tracks,
        sentence: getSentence(parsed, MARKS.hold, !accept && ERRORS.hold),
        chart: parsed.chart,
        end: wIdx * wDur + nIdx * (wDur / wLen),
      };
    } else if (pattern[0] === MARKS.wildcard) {
      const ts = getTracks(WILDCARD);
      const parsed = parser(pNext, ts, wIdx, nIdx + 1, MARKS.wildcard);
      const wLen = Math.max(nIdx + 1, parsed.wLen);
      const start = wIdx * wDur + nIdx * (wDur / wLen);

      return {
        wLen,
        tracks: parsed.tracks,
        sentence: getSentence(parsed, MARKS.wildcard),
        chart: getChart(parsed, ts, WILDCARD, start, parsed.end),
      };
    } else if (pattern[0] === MARKS.open) {
      const closeIdx = pattern.slice(1).indexOf(MARKS.close) + 1;
      const sliceIdx = closeIdx < 1 ? pattern.length : closeIdx + 1;
      const value = pattern.slice(0, sliceIdx);

      if (closeIdx < 1)
        return {
          wLen: nIdx + 1,
          tracks: getTracks(),
          sentence: getSentence({ sentence: [] }, value, ERRORS.open),
          chart: [],
        };
      pNext = pattern.slice(closeIdx + 1);

      const node = getNode(pattern.slice(1, closeIdx));
      const ts = getTracks(node);
      const parsed = parser(pNext, ts, wIdx, nIdx + 1, node);
      const wLen = Math.max(nIdx + 1, parsed.wLen);
      const start = wIdx * wDur + nIdx * (wDur / wLen);

      return {
        wLen,
        tracks: parsed.tracks,
        sentence: getSentence(parsed, value, !node && ERRORS.notation),
        chart: getChart(parsed, ts, node, start, parsed.end, closeIdx),
      };
    } else if (pattern[0] === MARKS.close) {
      const parsed = parser(pNext, tracks, wIdx, nIdx + 1, MARKS.close);

      return {
        wLen: nIdx + 1,
        tracks: parsed.tracks,
        sentence: getSentence(parsed, MARKS.close, ERRORS.close),
        chart: parsed.chart,
      };
    } else if (pattern[0] === MARKS.caret) {
      const parsed = parser(pNext, tracks, wIdx, nIdx, prev);
      let start = wIdx * wDur;

      if (nIdx > 0 && (pattern[1] === MARKS.space || pattern.length === 1))
        start += (nIdx - 1) * (wDur / nIdx);
      else if (pattern.length > 1)
        start += nIdx * (wDur / Math.max(nIdx + 1, parsed.wLen));

      return {
        wLen: parsed.wLen,
        tracks: parsed.tracks,
        sentence: getSentence(parsed, MARKS.caret),
        chart: getChart(parsed, tracks, CARET, start),
        end: parsed.end,
      };
    }

    // Default
    const markIdxs = Object.values(MARKS)
      .filter((m) => m !== MARKS.caret)
      .map((m) => pattern.indexOf(m))
      .filter((i) => i >= 0);
    const minIdx = Math.min(...markIdxs, pattern.length);
    const pair = [...Array(minIdx).keys()]
      .map((i) => pattern.slice(0, i + 1))
      .map((v) => [v, getNode(v)] as [string, Node])
      .find((p) => p[1]);
    const notation = pair?.[0] ?? pattern[0];
    const node = pair?.[1];

    pNext = pattern.slice(notation.length);

    const ts = getTracks(node);
    const parsed = parser(pNext, ts, wIdx, nIdx + 1, node);
    const wLen = Math.max(nIdx + 1, parsed.wLen);
    const start = wIdx * wDur + nIdx * (wDur / wLen);

    return {
      wLen,
      tracks: parsed.tracks,
      sentence: getSentence(parsed, notation, !node && ERRORS.notation),
      chart: getChart(parsed, ts, node, start, parsed.end, notation.length),
    };
  };

  return parser;
};

export default getParser;
