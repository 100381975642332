import BeatboxProvider, { useBeatboxContext } from './BeatboxProvider';
import {
  CategoryInfo,
  TechniqueInfo,
  DemoInfo,
  TutorialInfo,
  Category,
  Technique,
} from './models';

export default BeatboxProvider;
export { useBeatboxContext };
export type {
  CategoryInfo,
  TechniqueInfo,
  DemoInfo,
  TutorialInfo,
  Category,
  Technique,
};
