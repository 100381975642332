import React, { ReactElement, PropsWithChildren } from 'react';

function Container({ children }: PropsWithChildren<unknown>): ReactElement {
  const containerStyle = {
    display: 'inline-block',
    width: 'min(30em, 82.5vw)',
  };

  return <div style={containerStyle}>{children}</div>;
}

export default Container;
