import { addParams } from '~/helpers/routing';

import { BEATBOXDB_PATH } from './constants/paths';

export default {
  explore: `${BEATBOXDB_PATH}/explore`,
  exploreMap: `${BEATBOXDB_PATH}/explore/map`,
  exploreList: `${BEATBOXDB_PATH}/explore/list`,
  exploreStudy: `${BEATBOXDB_PATH}/explore/study`,
  exploreModeField: (mode, field) =>
    `${BEATBOXDB_PATH}/explore/${mode}/${field}`,
  exploreTechnique: (techniqueId) =>
    `${BEATBOXDB_PATH}/explore/techniques/${techniqueId}`,
  exploreDemo: (demoId) => `${BEATBOXDB_PATH}/explore/demos/${demoId}`,
  exploreTutorial: (tutorialId) =>
    `${BEATBOXDB_PATH}/explore/tutorials/${tutorialId}`,
  manageCategories: `${BEATBOXDB_PATH}/manage/categories`,
  manageCategory: (categoryId) =>
    `${BEATBOXDB_PATH}/manage/categories/${categoryId}`,
  manageTechniques: `${BEATBOXDB_PATH}/manage/techniques`,
  manageTechnique: (techniqueId) =>
    `${BEATBOXDB_PATH}/manage/techniques/${techniqueId}`,
  manageDemos: `${BEATBOXDB_PATH}/manage/demos`,
  manageDemo: (demoId) => `${BEATBOXDB_PATH}/manage/demos/${demoId}`,
  manageTutorials: `${BEATBOXDB_PATH}/manage/tutorials`,
  manageTutorial: (tutorialId) =>
    `${BEATBOXDB_PATH}/manage/tutorials/${tutorialId}`,
  bountiesTechniques: `${BEATBOXDB_PATH}/bounties/techniques`,
  bountiesDemos: `${BEATBOXDB_PATH}/bounties/demos`,
  bountiesTutorials: `${BEATBOXDB_PATH}/bounties/tutorials`,
  records: `${BEATBOXDB_PATH}/records`,
  recordsState: (state) => `${BEATBOXDB_PATH}/records/${state}`,
  profile: (handle) => `${BEATBOXDB_PATH}/@${handle}`,
  search: (query, page?) =>
    addParams(`${BEATBOXDB_PATH}/search`, { query, page }),
  notFound: `${BEATBOXDB_PATH}/not-found`,
};
