import { NoteEntity } from './models';
import { Dim } from '../predicates/models';
import Note from '../renderers/Note';
import notePredicate from '../predicates/notePredicate';
import { NOTE_KEYS } from '../constants/layout';

const noteEntity = (
  windowDims: Dim,
  xOff: number,
  y: number,
  prompt: string,
  answer: string,
  timing: number,
  theme = NOTE_KEYS.center,
  state?: string
): NoteEntity => ({
  ...notePredicate(
    windowDims,
    xOff,
    y,
    prompt,
    answer,
    timing,
    true,
    theme,
    state
  ),
  renderer: Note,
});

export default noteEntity;
