import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import SwitchBoard from '~/components/SwitchBoard';
import COLORS from '~/constants/colors';

import PATHS from '../constants/paths';
import { getCurrentPath } from '../utils';

function NavSwitchBoard(): ReactElement {
  const navigate = useNavigate();
  const currentPath = getCurrentPath();
  const paths = [
    PATHS.explore,
    PATHS.manage,
    PATHS.bounties,
    PATHS.records,
    PATHS.about,
  ];
  const labels = ['E', 'M', 'B', 'R', '?'];
  const choices = paths.map((p) => currentPath.includes(p));
  const callbacks = paths.map((c) => () => navigate(c));

  // Styling
  const navSwitchBoardStyle = {
    marginBottom: '1em',
  };
  const switchStyle = {
    color: COLORS.alabaster,
    backgroundColor: COLORS.waikawaGray,
  };
  const switchActiveStyle = {
    color: COLORS.white,
    backgroundColor: COLORS.cornflower,
  };

  return (
    <SwitchBoard
      labels={labels}
      choices={choices}
      callbacks={callbacks}
      switchStyle={switchStyle}
      switchActiveStyle={switchActiveStyle}
      style={navSwitchBoardStyle}
    />
  );
}

export default NavSwitchBoard;
