import React, { ReactElement, useRef, useState } from 'react';
import { GameEngine } from 'react-game-engine';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';

import { SETTINGS } from './constants';
import { BEAT } from '../../constants/defaults';
import { Input } from './models';
import { Beat } from '../../models';
import Play from './components/Play';
import Menu from './components/Menu';
import Listen from '../../components/Listen';
import Game from './components/Game';

function Read(): ReactElement {
  const [running, setRunning] = useState(false);
  const [beat, setBeat] = useState<Beat>(BEAT);
  const [settings, setSettings] = useState(SETTINGS);
  const [inputs, setInputs] = useState<Input[]>([]);
  const [progress, setProgress] = useState(0);
  const gameEngineRef = useRef<GameEngine>();

  // Styling
  const readStyle = {
    fontSize: FONT_SIZES.text,
    backgroundColor: COLORS.white,
  };
  const containerStyle = {
    width: '100%',
    height: '100vh',
    textAlign: 'center' as const,
  };

  return (
    <div style={readStyle}>
      <div style={containerStyle}>
        {!running && (
          <>
            <Play chart={settings.chart} setRunning={setRunning} />
            <Menu
              settings={settings}
              setSettings={setSettings}
              setBeat={setBeat}
              progress={progress}
            />
            <Listen
              beat={beat}
              chart={settings.chart}
              label="Preview"
              setProgress={setProgress}
            />
            {inputs.length > 0 && (
              <Listen
                beat={beat}
                chart={inputs.map((i) => i.attempt)}
                label="Replay"
              />
            )}
          </>
        )}
        <Game
          running={running}
          setRunning={setRunning}
          settings={settings}
          setInputs={setInputs}
          gameEngineRef={gameEngineRef}
        />
      </div>
    </div>
  );
}

export default Read;
