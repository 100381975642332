import { AccountInfo, PasswordInfo } from '~/providers/Auth';

export const DEFAULT_ACCOUNT_INFO: AccountInfo = {
  email: '',
  handle: '',
};

export const DEFAULT_PASSWORD_INFO: PasswordInfo = {
  currentPassword: '',
  password: '',
};
