import axios from 'axios';

import { addParams } from '~/helpers/routing';
import { flattenData } from '~/helpers/journal';

import urls from '../../urls';
import { Record, RecordInfo } from './models';

const config = { withCredentials: true };
const pack = (data) => ({ data });
const populate = { populate: ['technique'] };

export const createRecord = async (recordInfo: RecordInfo): Promise<Record> => {
  const url = addParams(urls.records, populate);
  const response = await axios.post(url, pack(recordInfo), config);

  return flattenData(response.data.data);
};

export const readRecords = async (): Promise<Record[]> => {
  const url = addParams(urls.records, populate);
  const response = await axios.get(url, config);

  return flattenData(response.data.data);
};

export const updateRecord = async (
  id: number,
  recordInfo: RecordInfo
): Promise<Record> => {
  const url = addParams(urls.record(id), populate);
  const response = await axios.put(url, pack(recordInfo), config);

  return flattenData(response.data.data);
};

export const deleteRecord = async (id: number): Promise<Record> => {
  const response = await axios.delete(urls.record(id), config);

  return flattenData(response.data.data);
};
