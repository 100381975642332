import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import Subtext from '~/components/titles/Subtext';
import Gap from '~/components/flow/Gap';
import Header from '~/components/titles/Header';
import Subheader from '~/components/titles/Subheader';
import Divider from '~/components/flow/Divider';
import Link from '~/components/flow/Link';
import Code from '~/components/flow/Code';
import { useAuth } from '~/providers/Auth';

import { useBeatboxContext } from '../../../providers/BeatboxProvider';
import uris from '../../../uris';
import InlineList from './InlineList';
import YouTubePlayer from './YouTubePlayer';
import InstagramEmbed from './InstagramEmbed';
import EmptyFace from './EmptyFace';
import CodeList from './CodeList';
import CONTENT from '../../../constants/content';
import EditLink from './EditLink';
import Unauthed from '../../../pages/Unauthed';

function TutorialFace(): ReactElement {
  const { state: authState } = useAuth();
  const { tutorial } = useBeatboxContext().state;
  const tutorialUri = uris.exploreTutorial(tutorial?.id);
  const tutorialUrl = `${window.location.origin}${tutorialUri}`;

  // Styling
  const headerStyle = {
    marginTop: '0.25em',
  };

  return (
    <>
      {tutorial && (
        <>
          <Header style={headerStyle}>
            Tutorial <Code>{tutorial.id}</Code>
          </Header>
          <Subtext color={COLORS.doveGray}>
            Share:&nbsp;<Link href={tutorialUri}>{tutorialUrl}</Link>
          </Subtext>
          <EditLink
            content={CONTENT.tutorials}
            uri={uris.manageTutorial(tutorial.id)}
          />
          <Divider />
          {authState.isAuthed ? (
            <>
              <CodeList
                labels={tutorial.categories?.map((category) => category.label)}
              />
              {tutorial.youtube && <YouTubePlayer link={tutorial.youtube} />}
              {tutorial.instagram && (
                <InstagramEmbed url={tutorial.instagram} />
              )}
              {!!tutorial.techniques?.length && (
                <>
                  <Gap />
                  <Gap />
                  <Subheader>Explained Techniques</Subheader>
                  <InlineList
                    labels={tutorial.techniques?.map((t) => t.name)}
                    links={tutorial.techniques?.map((t) =>
                      uris.exploreTechnique(t.id)
                    )}
                  />
                </>
              )}
            </>
          ) : (
            <Unauthed nested />
          )}
        </>
      )}
      {!tutorial && <EmptyFace />}
    </>
  );
}

export default TutorialFace;
