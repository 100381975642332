export default {
  // Grayscale
  transparent: 'transparent',
  white25: '#ffffff40',
  white50: '#ffffff80',
  white75: '#ffffffbf',
  white: '#ffffff',
  alabaster: '#fbfbfb',
  gallery: '#eaeaea',
  alto: '#d8d8d8',
  silver: '#c8c8c8',
  weatheredStone: '#c4c4c4',
  platinum: '#b9b9b9',
  silverChalice: '#a9a9a9',
  gray: '#919191',
  gray33: '#9191915A',
  doveGray: '#737373',
  tundora: '#424242',
  mineShaft: '#333333',
  codGray: '#262626',
  dreamlessSleep: '#111111',
  black25: '#00000040',
  black50: '#00000080',
  black75: '#000000bf',
  black: '#000000',

  // Browns
  tan: '#caa27c',
  capePalliser: '#a76244',

  // Reds
  cinderella: '#facdc7',
  hotPink: '#fe6ca8',
  tonysPink: '#e48b7d',
  mandy: '#e24a4a',
  peanut: '#7b2317',

  // Oranges
  dairyCream: '#fadec8',
  roseBud: '#f9b08b',
  atomicTangerine: '#faa175',
  jaffa: '#f7834a',

  // Yellows
  eggWhite: '#ffecc1',
  kournikova: '#fed677',
  gold: '#ffd700',

  // Greens
  fallGreen: '#e3ebbc',
  deco: '#c1dda5',
  yellowGreen: '#a8df6c',
  wildWillow: '#bbc971',
  pastelGreen50: '#80e27e80',
  pastelGreen: '#80e27e',
  swampGreen: '#94b186',
  sushi: '#78c242',
  salem: '#0a992a',

  // Blues
  selago: '#f0f5fd',
  solitude: '#e8f4ff',
  tropicalBlue: '#d4e7f8',
  linkWater: '#d7dff2',
  ghost: '#c5cbd2',
  cornflower: '#89b5e8',
  chetwodeBlue: '#8ca5db',
  malibu: '#6ca8fe',
  malibu15: '#6ca8fe26',
  danube: '#73a0d4',
  fountainBlue: '#5e96bf',
  dodgerBlue: '#448ef7',
  horizon: '#5c889b',
  gothic: '#6c989b',
  waikawaGray: '#5c799b',
  indigo: '#3e6bcc',
  bayOfMany: '#26478e',
  pickledBluewood: '#314159',
  pickledBluewood15: '#31415926',
  luckyPoint: '#1b2371',

  // Purples
  coldPurple: '#9ca5db',
  lilacBush: '#936cd2',
};
