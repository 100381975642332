import { ExitEntity } from './models';
import { Dim, Pos } from '../predicates/models';
import exitPredicate from '../predicates/exitPredicate';
import Exit from '../renderers/Exit';

const exitEntity = (
  windowDims: Dim,
  pos: Pos,
  callback: () => void
): ExitEntity => ({
  ...exitPredicate(windowDims, pos, callback),
  renderer: Exit,
});

export default exitEntity;
