import React, { ReactElement } from 'react';
import { motion, AnimationControls } from 'framer-motion';

import COLORS from '~/constants/colors';

import { RenderProps } from '../../models';
import { getPx } from '../../utils';
import { getWidth, getHeight, getLeft, getTop } from '../../align';

interface ReceptorProps {
  x: number;
  animate: AnimationControls;
}

function Receptor({
  entity,
  gameDims,
}: RenderProps<ReceptorProps>): ReactElement {
  const pos = entity.getPos();
  const landingB = entity.dims.b ?? 0;

  // Frame
  const getRGBA = (c) => {
    const matches = c.match(/\w\w/g) ?? [];
    const [r, g, b, a] = matches.map((v) => parseInt(v, 16));

    return `rgba(${r}, ${g}, ${b}, ${a ? a / 255 : 1})`;
  };
  const getFrame = (c, w = landingB, s = entity.dims.w / 4) =>
    `0 0 0 ${getPx(w)} ${getRGBA(c)}, 0 0 ${getPx(s)} 0 ${getRGBA(
      COLORS.black50
    )}`;

  // Styling
  const width = getWidth(gameDims, entity.dims.w, entity.dims.h);
  const height = getHeight(gameDims, entity.dims.h, entity.dims.w);
  const receptorStyle = {
    position: 'absolute' as const,
    width,
    height,
    left: getLeft(gameDims, pos.x - entity.dims.w / 2, pos.y, width),
    top: getTop(gameDims, pos.y - entity.dims.h / 2, pos.x, height),
    zIndex: 3,
  };
  const landingStyle = {
    display: 'inline-block',
    width,
    height,
    backgroundColor: COLORS.black50,
    boxShadow: getFrame(COLORS.white),
  };

  // Animation
  const variants = {
    reset: {
      x: 0,
      backgroundColor: COLORS.black50,
    },
    wrong: {
      x: [0, -2.5, 2.5, 0],
      backgroundColor: [COLORS.mandy, COLORS.black50],
      transition: { duration: 0.3 },
    },
    right: {
      backgroundColor: [COLORS.malibu, COLORS.black50],
      transition: { duration: 0.3 },
    },
    perfect: {
      backgroundColor: [
        COLORS.pastelGreen,
        COLORS.malibu,
        COLORS.lilacBush,
        COLORS.mandy,
        COLORS.jaffa,
        COLORS.kournikova,
        COLORS.pastelGreen,
        COLORS.black50,
      ],
      transition: { duration: 0.6, ease: 'easeIn' },
    },
  };

  return (
    <div style={receptorStyle}>
      <motion.div
        initial={false}
        animate={entity.animate}
        variants={variants}
        style={landingStyle}
      />
    </div>
  );
}

export default Receptor;
export type { ReceptorProps };
