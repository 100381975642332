import React, { ReactElement, useState, useEffect } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';
import Code from '~/components/flow/Code';

import { Song } from '../models';
import { getVideoId } from '../utils';

interface GetReadyProps {
  opacity: number;
  song: Song;
}

function GetReady({ opacity, song }: GetReadyProps): ReactElement {
  const [thumbnailIdx, setThumbnailIdx] = useState(0);
  const [thumbnailUrl, setThumbnailUrl] = useState(''); // Prevents users from seeing background switch through sizes
  const videoId = getVideoId(song.preview ?? song.source);
  const sizes = ['maxresdefault', 'sddefault', 'hqdefault'];
  const widthThresholds = [640, 480];
  const metaSource = `https://img.youtube.com/vi/${videoId}/${sizes[thumbnailIdx]}.jpg`;
  const metaHolder = new Image();

  metaHolder.src = metaSource;
  metaHolder.onload = () => {
    if (metaHolder.width < widthThresholds[thumbnailIdx])
      setThumbnailIdx(thumbnailIdx + 1);
    else setThumbnailUrl(metaSource);
  };

  useEffect(() => {
    setThumbnailIdx(0);
    setThumbnailUrl('');
  }, [song.source]);

  // Styling
  const getReadyStyle = {
    opacity,
    display: 'table',
    position: 'absolute' as const,
    width: '100%',
    height: 'calc(100vh - calc(100vh - 100%))',
    zIndex: 20 * opacity,
    color: COLORS.white,
    backgroundColor: COLORS.black,
    transition: 'opacity 0.5s, z-index 0s 0.5s',
  };
  const thumbnailStyle = {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${thumbnailUrl})`,
    backgroundSize: 'contain',
    boxShadow: `0 0 1.5em 1.5em ${COLORS.black} inset`,
    opacity: 0.5,
    zIndex: -1,
  };
  const alertStyle = {
    display: 'table-cell',
    padding: '0 min(1em, 4vw)',
    verticalAlign: 'middle',
    textAlign: 'center' as const,
    fontSize: FONT_SIZES.appName,
  };
  const levelRowSpaceStyle = {
    height: '0.75em',
  };
  const songInfoStyle = {
    textShadow: `-0.06em 0.06em ${COLORS.black25}`,
  };
  const byStyle = {
    fontWeight: FONT_WEIGHTS.thin,
  };
  const bpmStyle = {
    color: COLORS.pastelGreen,
    backgroundColor: COLORS.black50,
    borderColor: COLORS.black50,
    borderStyle: 'solid',
    borderWidth: 'thin',
  };

  return (
    <div style={getReadyStyle}>
      <div style={thumbnailStyle} />
      <div style={alertStyle}>
        <div style={songInfoStyle}>
          <i>{song.title}</i>
          <br />
          <span style={byStyle}>by</span> <b>{song.artist}</b>
        </div>
        <div style={levelRowSpaceStyle} />
        <Code style={bpmStyle}>
          <b>{song.bpm} BPM</b>
        </Code>
      </div>
    </div>
  );
}

export default GetReady;
