import React, { ReactElement, useEffect, useState } from 'react';

import COLORS from '~/constants/colors';
import { stopEventEffects } from '~/utils';
import { FONT_WEIGHTS } from '~/constants/typography';

import { RecordPadPredicate } from '../predicates/models';
import { RendererProps } from './models';
import { EVENTS } from '../constants/events';
import { RECORD_KEY_CODE } from '../constants/mechanics';

function RecordPad({
  predicate,
}: RendererProps<RecordPadPredicate>): ReactElement {
  const { gameEngineRef } = predicate;
  const [isActive, setIsActive] = useState(false);
  const panelB = predicate.panelDims.b ?? 0;
  const panelM = predicate.panelDims.m ?? 0;
  const meas = {
    width: `${predicate.panelDims.w}px`,
    height: `${predicate.panelDims.h}px`,
    border: `${panelB}px`,
    margin: `${panelM}px`,
    space: `${2 * panelB + 2 * panelM}px`,
  };

  // Mouse interactions
  const handleCenterUp = (event) => {
    if (isActive) {
      stopEventEffects(event);
      gameEngineRef.current?.dispatch(EVENTS.onRecordPadSend());
      setIsActive(false);
    }
  };
  const handleCenterDown = (event) => {
    if (!event.repeat && !isActive) setIsActive(true);
  };
  const handleMouseLeave = (event) => {
    if (isActive) {
      stopEventEffects(event);
      setIsActive(false);
    }
  };

  // Touch interactions
  const handleTouchEnd = (event) => {
    const changedTouch = event.changedTouches[0];
    const x = changedTouch.pageX;
    const y = changedTouch.pageY;
    const endElement = document.elementFromPoint(x, y);

    if (changedTouch.target === endElement) handleCenterUp(event);
    else handleMouseLeave(event);
  };

  // Key interactions
  const handleKeyDown = (event) => {
    if (event.code === RECORD_KEY_CODE) handleCenterDown(event);
  };
  const handleKeyUp = (event) => {
    if (event.code === RECORD_KEY_CODE) handleCenterUp(event);
  };

  // Styling
  const calcLen = (dim) => `calc(${dim} + 2 * ${meas.border})`;
  const recordPadStyle = {
    position: 'absolute' as const,
    width: meas.width,
    height: meas.height,
    left: '50%',
    bottom: `calc(${meas.height} + ${meas.space})`,
    marginLeft: `calc(-1 * ${calcLen(meas.width)} / 2)`,
    lineHeight: meas.height,
    textAlign: 'center' as const,
    borderStyle: 'solid',
    borderColor: COLORS.white,
    borderWidth: meas.border,
    borderRadius: `calc(4 * ${meas.border})`,
    color: COLORS.white,
    backgroundColor: isActive ? COLORS.black75 : COLORS.black50,
    boxShadow: `0 0 0.35em 0 ${COLORS.black25}`,
    overflow: 'hidden',
    cursor: 'pointer',
    ...predicate.style,
  };
  const fontSize = `calc(${meas.height} / 2)`;
  const labelStyle = {
    display: 'inline-block',
    lineHeight: 'normal',
    paddingBottom: `calc(${fontSize} / 4)`,
    verticalAlign: 'middle',
    fontSize,
    fontWeight: FONT_WEIGHTS.medium,
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  });

  return (
    <div
      role="none"
      style={recordPadStyle}
      onMouseDown={handleCenterDown}
      onMouseUp={handleCenterUp}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleCenterDown}
      onTouchEnd={handleTouchEnd}
    >
      <div style={labelStyle}>+</div>
    </div>
  );
}

export default RecordPad;
