import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import { FONTS } from '~/constants/typography';

interface TimerBarProps {
  duration: number;
  maxDuration: number;
}

function TimerBar({ duration, maxDuration }: TimerBarProps): ReactElement {
  const width = '95%';
  const height = 'calc(1.5em - 1px)';
  const loadedWidth = `${Number((100 * duration) / maxDuration).toFixed(2)}%`;
  const borderRadius = '0.3em';
  const formattedDuration = new Date(duration).toISOString().slice(14, -3);

  // Styling
  const progressBarStyle = {
    display: 'flex',
    justifyContent: 'center',
  };
  const containerStyle = {
    width,
    height,
    lineHeight: height,
    backgroundColor: COLORS.black50,
    border: `1px solid ${COLORS.doveGray}`,
    borderRadius,
    overflow: 'hidden',
    textAlign: 'right' as const,
  };
  const loadedStyle = {
    ...containerStyle,
    width: loadedWidth,
    backgroundColor: COLORS.yellowGreen,
    border: 'unset',
    borderRadius: 0,
  };
  const durationStyle = {
    margin: `calc(-1 * ${height}) ${borderRadius} 0 0`,
    color: COLORS.white,
    fontFamily: FONTS.typewriter,
  };

  return (
    <div style={progressBarStyle}>
      <div style={containerStyle}>
        <div style={loadedStyle} />
        <p style={durationStyle}>
          <b>{formattedDuration}</b>
        </p>
      </div>
    </div>
  );
}

export default TimerBar;
