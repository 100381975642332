import React, { ReactElement, CSSProperties, useState, useEffect } from 'react';

import COLORS from '~/constants/colors';
import { stopEventEffects, mod } from '~/utils';

interface FlipperProps {
  labels: string[];
  idx?: number;
  auto?: boolean;
  callback?: (event: Event) => void;
  callbacks?: ((event: Event) => void)[];
  disabled?: boolean;
  shadow?: number;
  style?: CSSProperties;
  caseStyles?: CSSProperties[];
}

function Flipper({
  labels,
  idx,
  auto = false,
  callback,
  callbacks,
  disabled = false,
  shadow = 0.1,
  style,
  caseStyles,
}: FlipperProps): ReactElement {
  const safeIdx = idx ? mod(idx, labels.length) : 0;
  const [labelIdx, setLabelIdx] = useState(safeIdx);
  const label = labels[labelIdx];
  const caseStyle = caseStyles?.[labelIdx] ?? {};
  const preventEventDefault = (event) => event.preventDefault();
  const handleCallback = (event) => {
    stopEventEffects(event);
    if (!disabled) {
      if (callback) callback(event);
      else if (callbacks) callbacks[labelIdx](event);

      // Auto label flipping
      if (auto) setLabelIdx((labelIdx + 1) % labels.length);
    }
  };

  // Styling
  const borderRadius = 3 * shadow;
  const labelMargin = 1.5 * shadow;
  const disabledCaseStyle = {
    color: COLORS.silverChalice,
    backgroundColor: COLORS.alabaster,
    cursor: 'default',
  };
  const flipperStyle = {
    display: 'inline-block',
    justifyContent: 'center',
    marginBottom: `${shadow}em`,
    outline: 'none',
    cursor: 'pointer',
    // Case appearance
    borderRadius: `${borderRadius}em`,
    boxShadow: `-${shadow}em ${shadow}em ${COLORS.black25}`,
    overflow: 'hidden',
    ...style,
    ...caseStyle,
    ...(disabled && disabledCaseStyle),
  };
  const labelStyle = {
    display: 'inline-block',
    margin: `${labelMargin}em`,
    pointerEvents: 'none' as const,
  };

  useEffect(() => setLabelIdx(safeIdx), [idx]);

  return (
    <div
      role="button"
      tabIndex={0}
      onMouseDown={handleCallback}
      onMouseUp={preventEventDefault}
      onTouchStart={handleCallback}
      onTouchEnd={preventEventDefault}
      style={flipperStyle}
    >
      <b style={labelStyle}>{label}</b>
    </div>
  );
}

export default Flipper;
