import React, { ReactElement, useState, useRef, useEffect } from 'react';
import { useAnimation } from 'framer-motion';

import { FONT_SIZES } from '~/constants/typography';
import Brand from '~/components/titles/Brand';
import NightSky from '~/components/backgrounds/NightSky';

import Instructions from './components/Instructions';
import Tiles from './components/Tiles';
import { maxLives } from './constants/game';
import {
  getProblems,
  formatTime,
  getKeyDownHandler,
  resetGame,
  getStateHandler,
  mouseDownHandler,
} from './helpers';
import { Score } from './models';
import Settings from './components/Settings';
import Ending from './components/Ending';

const kanaMatchStyle = {
  fontSize: FONT_SIZES.text,
  minHeight: '85vh',
};
const containerStyle = {
  width: '100%',
  minWidth: 350,
  textAlign: 'center' as const,
};
const gameStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column' as const,
};

function KanaMatch(): ReactElement {
  // Selections
  const selectionStates = [useState(true)].concat(
    [...Array(3)].map(() => useState(false as boolean))
  );
  const selections = selectionStates.map((e) => e[0]);
  const setSelections = selectionStates.map((e) => e[1]);
  const [flip, setFlip] = useState(0.5);

  // Game state
  const startScore: Score = [0, true];
  const problems = getProblems(selections, flip);
  const { maxPoints } = problems;
  const [lives, setLives] = useState(maxLives);
  const [score, setScore] = useState(startScore);
  const [targets, setTargets] = useState(problems.targets);
  const [choicesList, setChoicesList] = useState(problems.choicesList);
  const [mistakes, setMistakes] = useState([] as string[]);
  const state = { lives, score, targets, choicesList, mistakes, maxPoints };
  const setState = {
    setLives,
    setScore,
    setTargets,
    setChoicesList,
    setMistakes,
  };
  const [playtime, setPlaytime] = useState(formatTime(0));
  const stopwatchRef = useRef(null);
  const [showEnding, setShowEnding] = useState(false);

  // Animation
  const targetControls = useAnimation();
  const choicesControls = [...Array(4)].map(() => useAnimation());

  // Handlers
  const keyDownHandler = getKeyDownHandler(
    state,
    setState,
    targetControls,
    choicesControls,
    stopwatchRef,
    showEnding,
    setShowEnding
  );
  const stateHandler = getStateHandler(
    selections,
    flip,
    state,
    setState,
    choicesControls,
    stopwatchRef,
    showEnding,
    setShowEnding
  );

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    window.addEventListener('keyup', stateHandler);
    window.addEventListener('mousedown', mouseDownHandler, false);
    window.addEventListener('mouseup', stateHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
      window.removeEventListener('keyup', stateHandler);
      window.removeEventListener('mousedown', mouseDownHandler);
      window.removeEventListener('mouseup', stateHandler);
    };
  });

  useEffect(() => {
    const newProblems = getProblems(selections, flip);

    resetGame(newProblems, setState, choicesControls, stopwatchRef);
  }, [...selections, flip]);

  return (
    <div style={kanaMatchStyle}>
      <div style={containerStyle}>
        <NightSky>
          <div style={gameStyle}>
            <Brand topic="Kana" />
            <Settings selections={selections} setSelections={setSelections} />
            <Tiles
              lives={lives}
              score={score}
              maxPoints={maxPoints}
              target={targets[0] ? targets[0][0] : ''}
              choices={choicesList[0] ?? []}
              targetControls={targetControls}
              choicesControls={choicesControls}
              setPlaytime={setPlaytime}
              stopwatchRef={stopwatchRef}
              flip={flip}
              setFlip={setFlip}
            />
          </div>
          <Instructions />
        </NightSky>
        <Ending
          setState={setState}
          showEnding={showEnding}
          setShowEnding={setShowEnding}
          points={score[0]}
          maxPoints={maxPoints}
          playtime={playtime}
          mistakes={mistakes}
        />
      </div>
    </div>
  );
}

export default KanaMatch;
