import React, { ReactElement, PropsWithChildren, CSSProperties } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';

interface HeaderProps {
  color?: string;
  style?: CSSProperties;
}

function Header({
  color = COLORS.black,
  style,
  children,
}: PropsWithChildren<HeaderProps>): ReactElement {
  // Styling
  const headerStyle = {
    margin: '0.5em 0',
    color,
    fontWeight: FONT_WEIGHTS.medium,
    fontSize: FONT_SIZES.header,
    textAlign: 'left' as const,
    ...style,
  };

  return <h2 style={headerStyle}>{children}</h2>;
}

export default Header;
