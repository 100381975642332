import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';

import { Predicate } from '../predicates/models';
import { RendererProps } from './models';

type BridgeProps<T extends Predicate> = RendererProps<T>;

function Bridge({ predicate }: BridgeProps<typeof predicate>): ReactElement {
  const dims = predicate.getDims();
  const pos = predicate.getPos();

  // Styling
  const getPx = (v) => `${v}px`;
  const bridgeStyle = {
    position: 'absolute' as const,
    width: getPx(dims.w),
    height: getPx(dims.h),
    left: getPx(pos.x - dims.w / 2),
    top: getPx(pos.y - dims.h),
    backgroundColor: predicate.active ? COLORS.black50 : COLORS.black25,
    boxShadow: `0 0 ${getPx(dims.w / 5)} 0 ${COLORS.white25}`,
    ...predicate.style,
  };
  const ballW = 1.5 * dims.w;
  const endBallStyle = {
    position: 'absolute' as const,
    width: getPx(ballW),
    height: getPx(ballW),
    top: getPx(-ballW / 2),
    left: getPx(-dims.w / 4),
    borderRadius: getPx(dims.w),
    backgroundColor: COLORS.alto,
    boxShadow: `0 0 ${getPx(dims.w / 5)} 0 ${COLORS.black25}`,
  };
  const startBallStyle = {
    ...endBallStyle,
    top: getPx(dims.h - ballW / 2),
  };

  return (
    <div style={bridgeStyle}>
      <div style={endBallStyle} />
      <div style={startBallStyle} />
    </div>
  );
}

export default Bridge;
