import { AnySystem } from './models';
import { TYPES } from '../constants/events';

const youTubeSystem: AnySystem = (entities, { events }) => {
  const newEntities = entities;
  const { youTubePlayerState } = entities.state;
  const matchEvent = (event) => event.type === TYPES.yTPSC;
  const newStateEvent = events.reverse().find(matchEvent);

  if (newStateEvent && youTubePlayerState !== newStateEvent.detail.playerState)
    newEntities.state.youTubePlayerState = newStateEvent.detail.playerState;

  return newEntities;
};

export default youTubeSystem;
