import YouTube from 'react-youtube';

import { ReviewSystem } from './models';
import { NOTE_KEYS, RECEPTOR_VARIANTS } from '../constants/layout';

const reviewInputSystem: ReviewSystem = (entities) => {
  const newEntities = entities;
  const { notes, receptor } = entities;
  const { startTime, videoTime, videoDelta, youTubePlayerState } =
    entities.state;
  const { velocity } = entities.settings;

  // Check game started and song still playing
  if (startTime < 0 || youTubePlayerState !== YouTube.PlayerState.PLAYING)
    return newEntities;

  // Loop through notes to check if hit
  const noteKeys = Object.keys(notes);

  noteKeys.forEach((key) => {
    const note = notes[key];

    if (!note.attemptable) return;

    // Check when note crosses receptor
    const endY = receptor.getPos().y;
    const yChange = (videoDelta * velocity) / 3;
    const yDiff = note.getPos().y + yChange - endY;
    const hitTargetNote = yDiff >= 0;

    if (hitTargetNote) {
      newEntities.notes[key].state = NOTE_KEYS.perfect;
      newEntities.notes[key].attemptable = false;
      newEntities.notes[key].garbageTime = videoTime;
      receptor.animates[1].start(RECEPTOR_VARIANTS.combo);
    }
  });

  return newEntities;
};

export default reviewInputSystem;
