import React, { ReactElement, CSSProperties } from 'react';

interface BreakProps {
  style?: CSSProperties;
}

function Break({ style }: BreakProps): ReactElement {
  const breakStyle = {
    height: '1em',
    ...style,
  };

  return <div style={breakStyle} />;
}

export default Break;
