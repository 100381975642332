import { Preset, Nodes } from './models';
import { RAW } from './constants';

class Transformer {
  config: Preset = RAW;

  context: AudioContext;

  nodes: Nodes;

  connect: typeof AudioNode.prototype.connect = (target) =>
    this.nodes.output.connect(target);

  disconnect: typeof AudioNode.prototype.disconnect = () =>
    Object.values(this.nodes).forEach((node) => node.disconnect());

  setPreset(preset: Preset): void {
    this.nodes.lowShelf.frequency.value = preset.lowFreq;
    this.nodes.lowShelf.gain.value = preset.lowGain;
    this.nodes.highShelf.frequency.value = preset.highFreq;
    this.nodes.highShelf.gain.value = preset.highGain;
    this.nodes.highPass.frequency.value = preset.highPassF;
    this.nodes.highPass.Q.value = preset.highPassQ;
    this.nodes.gain.gain.value = preset.gain;
    this.nodes.distortion.curve = preset.distortionCurve;
    this.nodes.compressor.threshold.value = preset.compThresh;
    this.nodes.compressor.knee.value = preset.compKnee;
    this.nodes.compressor.ratio.value = preset.compRatio;
    this.nodes.compressor.attack.value = preset.compAttack;
    this.nodes.compressor.release.value = preset.compRelease;
    this.config = { ...this.config, ...preset }; // A little jank but needed for external calls
  }

  constructor(context, config?) {
    this.config = { ...this.config, ...config };
    this.context = context;

    // Initialize effect nodes
    this.nodes = {
      input: this.context.createGain(),
      lowShelf: this.context.createBiquadFilter(),
      highShelf: this.context.createBiquadFilter(),
      gain: this.context.createGain(),
      distortion: this.context.createWaveShaper(),
      highPass: this.context.createBiquadFilter(),
      compressor: this.context.createDynamicsCompressor(),
      output: this.context.createGain(),
    };
    this.nodes.lowShelf.type = 'lowshelf';
    this.nodes.highShelf.type = 'highshelf';
    this.nodes.highPass.type = 'highpass';
    this.setPreset(this.config);

    // Node routing
    this.nodes.input.connect(this.nodes.lowShelf);
    this.nodes.lowShelf.connect(this.nodes.highShelf);
    this.nodes.highShelf.connect(this.nodes.gain);
    this.nodes.gain.connect(this.nodes.distortion);
    this.nodes.distortion.connect(this.nodes.compressor);
    this.nodes.compressor.connect(this.nodes.highPass);
    this.nodes.highPass.connect(this.nodes.output);
  }
}

export default Transformer;
