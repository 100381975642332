import { Reducer } from '~/models';
import { mod } from '~/utils';
import { chainMiddlewares } from '~/helpers/state';

import { CarouselState } from './models';
import { ACTIONS } from './actions';

const INITIAL_STATE: CarouselState = {
  len: 0,
  activeIdx: 0,
  desiredIdx: 0,
  offset: 0,
};

const reducer: Reducer<CarouselState> = (state = INITIAL_STATE, action) => {
  const goPrev = (idx) => mod(idx - 1, state.len);
  const goNext = (idx) => mod(idx + 1, state.len);

  switch (action.type) {
    case ACTIONS.setLen:
      return { ...state, len: action.payload };
    case ACTIONS.jump:
      return mod(action.payload, state.len) !== state.activeIdx
        ? { ...state, desiredIdx: mod(action.payload, state.len) }
        : state;
    case ACTIONS.next:
      return { ...state, desiredIdx: goNext(state.activeIdx) };
    case ACTIONS.prev:
      return { ...state, desiredIdx: goPrev(state.activeIdx) };
    case ACTIONS.done:
      return { ...state, offset: NaN, activeIdx: state.desiredIdx };
    case ACTIONS.drag:
      return { ...state, offset: action.payload };
    default:
      return state;
  }
};

export default chainMiddlewares(reducer);
export { INITIAL_STATE };
