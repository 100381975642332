import React, { ReactElement } from 'react';
import { motion, AnimationControls } from 'framer-motion';

import COLORS from '~/constants/colors';

import ARROWS from '../constants/arrows';
import { targetStyle, targetLabelStyle } from './Target';

interface ChoiceProps {
  label: string;
  index: number;
  choiceControls: AnimationControls;
}

const choiceStyle = {
  ...targetStyle,
  color: COLORS.white,
  backgroundColor: COLORS.black25,
  border: `0.15em solid ${COLORS.white}`,
  outline: 'none',
  boxShadow: 'none',
  cursor: 'pointer',
};

// Animation
const variants = {
  right: {
    color: [COLORS.pastelGreen, COLORS.white],
    borderColor: [COLORS.pastelGreen, COLORS.white],
    backgroundColor: [COLORS.pastelGreen50, COLORS.black25],
    transition: { duration: 0.3 },
  },
  wrong: {
    x: [0, -2.5, 2.5, 0],
    color: COLORS.mandy,
    borderColor: COLORS.mandy,
    transition: { duration: 0.1 },
  },
  reset: {
    color: COLORS.white,
    borderColor: COLORS.white,
  },
};

function Choice({ label, index, choiceControls }: ChoiceProps): ReactElement {
  const eventKey = { code: Object.keys(ARROWS)[index] };
  const mouseDownHandler = (): boolean =>
    window.dispatchEvent(new KeyboardEvent('keydown', eventKey));
  const mouseUpHandler = (): boolean =>
    window.dispatchEvent(new KeyboardEvent('keyup', eventKey));
  const touchStartHandler = (event): void => {
    event.preventDefault();
    window.dispatchEvent(new KeyboardEvent('keydown', eventKey));
  };
  const touchEndHandler = (event): void => {
    event.preventDefault();
    window.dispatchEvent(new KeyboardEvent('keyup', eventKey));
  };

  return (
    <motion.div
      role="button"
      tabIndex={index}
      onMouseDown={mouseDownHandler}
      onMouseUp={mouseUpHandler}
      onTouchStart={touchStartHandler}
      onTouchEnd={touchEndHandler}
      style={choiceStyle}
      animate={choiceControls}
      variants={variants}
    >
      <b style={targetLabelStyle}>{label}</b>
    </motion.div>
  );
}

export default Choice;
