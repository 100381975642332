// TODO: delete this file and make sure tracks are handled correctly
import { Beat } from '../../models';

export const LINKS = [
  undefined,
  'https://www.youtube.com/embed/TIBHnQavUG4?start=96',
  undefined,
  'https://www.youtube.com/embed/1tEcW-LgwH0?start=333',
  'https://www.youtube.com/embed/BuyEzVPXMLc?start=56',
  'https://www.youtube.com/embed/Smz6NxE2mcE?start=53',
  'https://www.youtube.com/embed/CyBlilUzSFk?start=234',
  'https://www.youtube.com/embed/TQsiXWltMKs?start=339',
  'https://www.youtube.com/embed/OY-Ec8zX2bw?start=185',
  'https://www.youtube.com/embed/NJ35cGuG9WM?start=51',
];

export const BEATS: Beat[] = [
  {
    id: -1,
    title: 'Kalapuikko Random Idea',
    loops: 2,
    bpm: 120,
    layers: [
      { pattern: '[hilr] K Bt-B K-tK [hilr] K BtBB t[k1]Kt' },
      { pattern: '[B1] - - - [B1]' },
    ],
    nodes: [],
  },
  {
    id: -1,
    title: 'Kindred ASMR Drop',
    loops: 1,
    bpm: 160,
    layers: [
      { pattern: '--t[K1] - - - t[K1]-- - --t[K1] - - --[Ts]-f' },
      {
        pattern:
          '- KA[k1] [k1][k1]K [k]A[k1] -KA [k1]Kk A[k1]- KA[k1] KB[Peh] K-- tBc K',
      },
    ],
    nodes: [],
  },
  {
    id: -1,
    title: 'Ramzo Sick Trio Beat',
    loops: 2,
    bpm: 120,
    layers: [
      {
        pattern: 'B tkK[Peh]KB [Psh] B[hut] [osb][hut] B [Psh] [rw][rw]',
        group: 0,
      },
      {
        pattern: '-[OD] -[OD] -[OD] -[OD] -[OD] -[OD] -[OD] [hcp][OD]',
        group: 1,
      },
      {
        pattern:
          '[hilr] [ib] [hilr][ib] [hilr] dd d[ib] [hilr] [mg][lrs][mg][lrs]',
        group: 2,
      },
    ],
    nodes: [],
  },
  {
    id: -1,
    title: 'Helium BEST DRUM AND BASS PATTERNS #27',
    loops: 3,
    bpm: 165,
    layers: [{ pattern: 'B[Ts] KB tkB- [Ts]K tK-B K-tK -BK- tK[Peh]K' }],
    nodes: [],
  },
  {
    id: -1,
    title: 'Helium GBB 2020 Wildcard',
    loops: 3,
    bpm: 186,
    layers: [
      { pattern: '[Bs] [Pf][df] [OD]B [Pf][OD] B-[k1]K [Pf]t KB [Pf]K' },
    ],
    nodes: [],
  },
  {
    id: -1,
    title: 'Zekka SBX Kickback 2021 Wildcard',
    loops: 2,
    bpm: 127,
    layers: [
      {
        pattern:
          'B tB[k1][k1] [K1][Peh]-K tB [B2][hub][mg][Peh] t[lrs][B2]- [K1][Peh]-K t-B[d1]B-B-',
      },
      { pattern: '[osb]>' },
    ],
    nodes: [],
  },
  {
    id: -1,
    title: 'D-Low SBX Kickback 2021 Quarterfinal',
    loops: 1,
    bpm: 125,
    layers: [
      {
        pattern:
          '-[hcp] -[hcp] -[hcp] -[hcp] [B1]-[hcp]B [B1]B[hcp]- K-[hcp]B [B1]-[hcp]B [B1]B[B1]- [hcp]B[B1]- K-[hcp]B [B1]B[B1]B [B1]-[hcp]B [B1]B[hcp]- K-[hcp]B [B1]-[hcp]B [B1]B[hcp]- [hcp]B[B1]- KB tB--',
      },
      {
        pattern:
          '- - - - [hilr]- -[hilr] [hilr] [hilr] -[hilr] -[hilr] [hilr] - [hilr]- -[hilr] [hilr] [hilr] -[hilr] -[hilr] -[osb] -[osb]>>',
      },
    ],
    nodes: [],
  },
  {
    id: -1,
    title: 'Zer0 Duck House',
    loops: 2,
    bpm: 130,
    layers: [
      {
        pattern:
          '-[dk]t[icr] k[dk]t[icr] -f[stb]> kf[stb]> -f[stb]> kf[stb]> -f[stb].k kf[stb]-', // TODO: last rest in pattern should be sustain but parser bug so using rest for demos
      },
      { pattern: 'G G G G G G G G' },
    ],
    nodes: [],
  },
  {
    id: -1,
    title: 'NaPoM This Is Just the Beginning',
    loops: 1,
    bpm: 125,
    layers: [
      {
        pattern:
          '[B1] [lr]-B k [lr]-B [B1] [lr]-B k [lr]-B [B1] [lr]-B k [lr]-B [B1] [lr]-B [B1] [lr]-B',
      },
      {
        pattern:
          '[slr] - [hilr] - [slr][slr][slr] - [hilr] - [hilr] - [hilr] - [slr][slr][slr] - [slr][slr][slr]',
      },
    ],
    nodes: [],
  },
  {
    id: -1,
    title: 'Colaps WBC 7ToSmoke 2018 Battle #9',
    loops: 2,
    bpm: 270,
    layers: [
      {
        pattern:
          '[mg][mg] [mg][mg] [Peh]K KK K [Peh]K - [Peh]K tf [k1]K [Peh]K KK K - BB BB BB BB B - K t[Peh] -K tB tB BB B - K t[k1]K [Peh] tB',
      },
      {
        pattern:
          '- - - - - - t[k1]k - - - - - - t[k1]k - - - - [osb] > - - - - - - [osb] >',
      },
    ],
    nodes: [],
  },
];
