import React, { Dispatch, SetStateAction } from 'react';

import { stopEventEffects } from '~/utils';

import { Chart } from '../../../models';

interface PlayProps {
  chart: Chart;
  setRunning: Dispatch<SetStateAction<boolean>>;
}

function Play({ chart, setRunning }: PlayProps) {
  const disabled = chart.length === 0;

  // Handlers
  const startGame = (event) => {
    stopEventEffects(event);
    setRunning(true);
  };

  return (
    <button type="button" disabled={disabled} onClick={startGame}>
      Play
    </button>
  );
}

export default Play;
