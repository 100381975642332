import { Option } from './models';

export const GAIN_OPTIONS: Option[] = [
  {
    title: 'Master',
    option: 'gain',
    min: 1,
    max: 10,
    step: 0.1,
    units: 'dB',
  },
  {
    title: 'Bass cutoff',
    option: 'lowFreq',
    min: 0,
    max: 400,
    step: 1,
    units: 'Hz',
  },
  {
    title: 'Bass boost',
    option: 'lowGain',
    min: 0,
    max: 25,
    step: 0.1,
    units: 'dB',
  },
  {
    title: 'Treble cutoff',
    option: 'highFreq',
    min: 3000,
    max: 20000,
    step: 1,
    units: 'Hz',
  },
  {
    title: 'Treble boost',
    option: 'highGain',
    min: 0,
    max: 25,
    step: 0.1,
    units: 'dB',
  },
];

export const ATTENUATION_OPTIONS: Option[] = [
  {
    title: 'High-pass filter',
    option: 'highPassF',
    min: 0,
    max: 400,
    step: 1,
    units: 'Hz',
  },
];

export const COMPRESSION_OPTIONS: Option[] = [
  {
    title: 'Threshold',
    option: 'compThresh',
    min: -60,
    max: 0,
    step: 0.1,
    units: 'dB',
  },
  {
    title: 'Knee',
    option: 'compKnee',
    min: 0,
    max: 40,
    step: 0.1,
    units: 'dB',
  },
  {
    title: 'Ratio',
    option: 'compRatio',
    min: 1,
    max: 20,
    step: 1,
  },
];

export const DISTORTION_OPTIONS: Option[] = [
  {
    title: 'Amount',
    option: 'distortionAmount',
    min: 0,
    max: 30,
    step: 1,
  },
];
