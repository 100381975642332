import React from 'react';

import NightSky from '~/components/backgrounds/NightSky';
import Brand from '~/components/titles/Brand';
import { FONT_SIZES } from '~/constants/typography';
import FramedView from '~/components/FramedView';
import Gap from '~/components/flow/Gap';

import Caption from './components/Caption';
import Form from './components/Form';

function Login() {
  // Styling
  const loginStyle = {
    width: '100%',
    fontSize: FONT_SIZES.text,
    textAlign: 'center' as const,
  };
  const containerStyle = {
    display: 'flex',
    minWidth: 350,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  };

  return (
    <div style={loginStyle}>
      <NightSky>
        <div style={containerStyle}>
          <Brand topic="Login" />
          <FramedView accent>
            <Gap />
            <Caption />
            <br />
            <Form />
          </FramedView>
        </div>
      </NightSky>
    </div>
  );
}

export default Login;
