import { getModDistance } from '~/utils';

const offsets = {
  device: Math.min(-1 * new Date().getTimezoneOffset(), 12 * 60),
  ohio: -5 * 60,
  frankfurt: 2 * 60,
}; // For some reason JavaScript uses minutes
const regions = {
  ohio: '',
  frankfurt: '-eu',
};
const zones = Object.keys(offsets).reduce(
  (acc, k) => ({ ...acc, [k]: offsets[k] + 12 * 60 }),
  {} as Record<string, number>
);
const getGap = (o) => getModDistance(o, zones.device, 25 * 60);
const distances = Object.keys(regions).reduce(
  (acc, k) => ({ ...acc, [k]: getGap(zones[k]) }),
  {} as Record<string, number>
);
const nearest = Object.keys(distances).reduce(
  (acc, k) => (distances[acc] < distances[k] ? acc : k),
  regions.ohio
);
const base =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_JOURNAL_URL
    : `${window.location.protocol}//${process.env.REACT_APP_JOURNAL_SUBDOMAIN}${regions[nearest]}.${window.location.host}`;

export default {
  base,
  api: `${base}/api`,
  auth: `${base}/api/auth`,

  // External
  discordInvite: 'https://discord.gg/AamYsuaJ3w',
  richardLinkedIn: 'https://www.linkedin.com/in/swrichard-hsu',
};
