export const CHANNEL_COUNT = 1;
export const SAMPLE_RATE = 44100;
export const BIT_RATE = 192;
export const BIT_RATE_THOUSANDS = 192000;
export const MAX_SAMPLES = 1152;

export const CONSTRAINTS = {
  autoGainControl: 'autoGainControl',
  noiseSuppression: 'noiseSuppression',
  echoCancellation: 'echoCancellation',
};

export const CONSTRAINT_DESCRIPTIONS = {
  autoGainControl: 'Volume correction',
  noiseSuppression: 'Noise suppression',
  echoCancellation: 'Echo cancellation',
};

export const MIME_TYPES = {
  mp3: 'audio/mp3',
  mp4: 'video/mp4',
  webm: 'video/webm',
  mkv: 'video/x-matroska',
};

export const AUDIO_TIME_LIMIT = 3 * 60 * 1000; // Three minutes in milliseconds
export const AUDIO_TIME_LIMIT_SECONDS = 3 * 60;
export const VIDEO_SIZE_LIMIT = 287.6 * 1024 * 1024; // 287.6 megabytes
