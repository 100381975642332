import React, { ReactElement, useState, useEffect, useRef } from 'react';

import Announcement from '~/components/Announcement';
import Gap from '~/components/flow/Gap';
import COLORS from '~/constants/colors';

interface VideoPlayerProps {
  url?: string;
  preview?: MediaStream;
}

function VideoPlayer({ url, preview }: VideoPlayerProps): ReactElement {
  const [errorText, setErrorText] = useState(
    !url && !preview ? 'No video source was provided' : ''
  );
  const controlsList = 'nodownload nofullscreen noremoteplayback';
  const videoRef = useRef<HTMLVideoElement>(null);

  // Prevent resize when switching presets
  const width = '21.5em';
  const height =
    videoRef.current?.videoWidth &&
    `calc(${width} * ${
      videoRef.current.videoHeight / videoRef.current.videoWidth
    })`;

  // Handlers
  const onError = () => {
    setErrorText('Could not load video, please try again later');
  };

  // Styling
  const videoStyle = {
    width,
    ...(height && { height }),
    backgroundColor: COLORS.black,
    borderRadius: '0.3em',
    boxShadow: `0 0 0.4em ${COLORS.black25}`,
  };

  useEffect(() => {
    if (videoRef.current && preview) videoRef.current.srcObject = preview;
  }, [preview]);

  return (
    <div>
      {errorText && (
        <>
          <Announcement>{errorText}</Announcement>
          <Gap />
        </>
      )}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        {...(url && { src: url, controls: true, controlsList })}
        {...(preview && { autoPlay: true })}
        onError={onError}
        style={videoStyle}
        ref={videoRef}
      />
    </div>
  );
}

export default VideoPlayer;
