import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';

import { SwitchProps } from '../models';

function BlockSwitch({
  label,
  active = false,
  callback,
  style,
  activeStyle,
  ...props
}: SwitchProps): ReactElement {
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    callback?.(event);
  };

  // Styling
  const sideLength = 1.5;
  const blockSwitchStyle = {
    display: 'inline-block',
    width: `${sideLength}em`,
    height: `${sideLength}em`,
    margin: '0 0.2em',
    lineHeight: '1.45em',
    fontSize: FONT_SIZES.header,
    color: COLORS.white,
    backgroundColor: COLORS.dodgerBlue,
    borderRadius: '0.3em',
    boxShadow: `-0.046em 0.046em ${COLORS.black25}`,
    verticalAlign: 'top',
    outline: 'none',
    overflow: 'hidden',
    cursor: 'pointer',
    ...style,
  };
  const blockSwitchActiveStyle = {
    ...blockSwitchStyle,
    color: COLORS.alabaster,
    backgroundColor: COLORS.waikawaGray,
    ...activeStyle,
  };
  const blockSwitchLabelStyle = {
    marginLeft: '0.05em',
    pointerEvents: 'none' as const,
  };

  return (
    <div
      role="none"
      onMouseDown={handleClick}
      onTouchStart={handleClick}
      style={active ? blockSwitchActiveStyle : blockSwitchStyle}
      {...props}
    >
      <b style={blockSwitchLabelStyle}>{label}</b>
    </div>
  );
}

export default BlockSwitch;
