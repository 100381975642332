import axios from 'axios';

import { getStorageItem } from '~/helpers/storage';

import { STORAGE_KEYS } from './constants';

// Axios
export const getAxiosAuthHeader = (): string | undefined =>
  axios.defaults.headers.common.Authorization;

export const setAxiosAuthHeader = (accessToken?: string): void => {
  if (accessToken)
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  else delete axios.defaults.headers.common.Authorization;
};

// Refresh
export const isPreviouslyAuthed = (): boolean =>
  Boolean(!getAxiosAuthHeader() && getStorageItem(STORAGE_KEYS.refreshKey));
