import React, { ReactElement, CSSProperties } from 'react';

import COLORS from '~/constants/colors';

type Direction = 'up' | 'right' | 'down' | 'left';

interface TriangleProps {
  base: string;
  ratio: number;
  direction: Direction;
  fill?: string;
  style?: CSSProperties;
}

function Triangle({
  base,
  ratio,
  direction,
  fill = COLORS.alabaster,
  style,
}: TriangleProps): ReactElement {
  const w = 20;
  const h = w;
  const hW = w / 2;
  const hH = h / 2;
  const d = `M ${hW} 0 L ${w} ${h} L 0 ${h} L ${hW} 0`;
  let containerWidth = base;
  let containerHeight = `calc(${base} * ${ratio})`;
  let viewBox = `0 0 ${w} ${h * ratio}`;
  let degrees = 0;
  let xScale = 1;
  let yScale = ratio;

  if (direction === 'left' || direction === 'right') {
    containerWidth = containerHeight;
    containerHeight = base;
    viewBox = `0 0 ${w * ratio} ${h}`;
    degrees = 270;
    xScale = ratio;
    yScale = 1;
    if (direction === 'right') degrees = 90;
  } else if (direction === 'down') degrees = 180;

  // Get transform functions
  const scale = `scale(${xScale}, ${yScale})`;
  const rotate = `rotate(${degrees}, ${hW}, ${hH})`;
  const transform = `${scale} ${rotate}`;

  // Styling
  const triangleStyle = {
    display: 'inline-block',
    width: containerWidth,
    height: containerHeight,
    overflow: 'hidden',
    ...style,
  };
  const svgStyle = {
    display: 'block',
    width: '100%',
    height: '100%',
  };

  return (
    <div style={triangleStyle}>
      <svg viewBox={viewBox} fill={fill} style={svgStyle}>
        <g transform={transform}>
          <path d={d} />
        </g>
      </svg>
    </div>
  );
}

export default Triangle;
export type { Direction };
