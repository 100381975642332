/* eslint-disable import/prefer-default-export */
import COLORS from '~/constants/colors';

export const DIFFICULTY_COLORS = {
  fundamentals: COLORS.mandy,
  beginner: COLORS.jaffa,
  intermediate: COLORS.kournikova,
  advanced: COLORS.wildWillow,
  expert: COLORS.malibu,
  unique: COLORS.lilacBush,
};
