import React, {
  ReactElement,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from 'react';

import Annotate from '~/components/Annotate';
import COLORS from '~/constants/colors';
import { FONT_WEIGHTS } from '~/constants/typography';

import { Nodes } from '../../../models';
import { MARKS, ERRORS } from '../../../constants/language';
import { useContext } from '../providers/Content';

interface AutocompleteProps {
  chunk?: string;
  setChunk: Dispatch<SetStateAction<string | undefined>>;
  width: string;
}

function Autocomplete({
  chunk,
  setChunk,
  width,
}: AutocompleteProps): ReactElement {
  const [matches, setMatches] = useState<Nodes>([]);
  const { state } = useContext();
  const getResultKey = (idx) => `BeatbloxWriteScriptAutocompleteResult${idx}`;

  // Search
  const { space, open, ...safeMarks } = MARKS;
  const safeChunk = Object.values(safeMarks).reduce(
    (acc, m) => acc?.replace(m, ''),
    chunk
  );

  useEffect(() => {
    let newMatches;

    if (safeChunk === MARKS.open) newMatches = state.nodes;
    else if (safeChunk) {
      const query = safeChunk.replace(open, '');

      newMatches = state.nodes
        ?.map((n) => {
          let score = 0;

          // TODO: improve match ranking method
          if (n.notation.includes(query))
            score += (4 * query.length) / n.notation.length;
          else if (n.notation.toLowerCase().includes(query.toLowerCase()))
            score += (3 * query.length) / n.notation.length;
          else if (n.name.includes(query))
            score += (2 * query.length) / n.name.length;
          else if (n.name.toLowerCase().includes(query.toLowerCase()))
            score += query.length / n.name.length;

          return [n, score];
        })
        .filter((p) => p[1])
        .sort((a, b) => Number(b[1]) - Number(a[1]))
        .map((p) => p[0]);
    }
    if (newMatches) setMatches(newMatches);
  }, [safeChunk]);

  // Calculate
  const maxCols = 3.5;
  const safeLength = Math.max(matches.length, 1);
  const height = Math.min(safeLength * 1.17, maxCols);
  const containerWidth = `calc(${width} - 2em)`;
  const containerHeight = `${height + 1.2}em`;
  const contentWidth = `calc(${width} - 3em)`;
  const contentHeight = `${height + 0.3}em`;

  // Handlers
  const callback = () => setChunk(undefined);

  // Styling
  const contentStyle = {
    width: contentWidth,
    height: contentHeight,
    padding: '0.5em',
  };
  const wrapperStyle = {
    width: contentWidth,
    height: contentHeight,
    lineHeight: '1.3em',
    color: COLORS.white,
    overflowY: 'scroll' as const,
    textAlign: 'left' as const,
    wordWrap: 'break-word' as const,
  };
  const notationStyle = {
    padding: '0 0.2em',
    color: COLORS.tundora,
    backgroundColor: COLORS.alto,
    borderRadius: '0.2em',
    fontWeight: FONT_WEIGHTS.bold,
  };

  return (
    <Annotate
      visible={safeChunk && safeChunk !== space}
      callback={callback}
      containerWidth={containerWidth}
      containerHeight={containerHeight}
      containerBackgroundColor={COLORS.tundora}
      contentStyle={contentStyle}
    >
      <div style={wrapperStyle}>
        {matches.length > 0 ? (
          matches.map((n, idx) => (
            <div key={getResultKey(idx)}>
              <span style={notationStyle}>{n.notation}</span> {n.name}
            </div>
          ))
        ) : (
          <>
            <span style={notationStyle}>{MARKS.missing}</span> {ERRORS.notation}
          </>
        )}
      </div>
    </Annotate>
  );
}

export default Autocomplete;
