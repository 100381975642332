import React, { ReactElement, ForwardedRef, forwardRef } from 'react';
import { isMobile } from 'react-device-detect';

import NumberInput from '~/components/inputs/NumberInput';
import COLORS from '~/constants/colors';

import { SONGS } from '../constants/alpha';

interface SongNumberInputProps {
  callback: (value: number) => void;
  ref: ForwardedRef<HTMLInputElement>;
}

function SongNumberInput({
  callback,
  ref,
}: SongNumberInputProps): ReactElement {
  // Styling
  const style = {
    margin: '0 1em',
    color: COLORS.white,
    backgroundColor: COLORS.black25,
    borderColor: COLORS.black50,
  };

  return (
    <NumberInput
      placeholder="Song Number"
      {...(isMobile && { width: '7.5em' })}
      callback={callback}
      listId="flowgo-menu-song-setter-datalist"
      options={SONGS.map((s, idx) => [idx, s.title])}
      optionAriaLabel="Song number option"
      style={style}
      ref={ref}
    />
  );
}

export default forwardRef(
  (props: SongNumberInputProps, ref: ForwardedRef<HTMLInputElement>) =>
    SongNumberInput({ ...props, ref })
);
