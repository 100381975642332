import { StopwatchEntity } from './models';
import { Dim, Pos } from '../predicates/models';
import stopwatchPredicate from '../predicates/stopwatchPredicate';
import Stopwatch from '../renderers/Stopwatch';

const stopwatchEntity = (
  windowDims: Dim,
  pos: Pos,
  time: number
): StopwatchEntity => ({
  ...stopwatchPredicate(windowDims, pos, time),
  renderer: Stopwatch,
});

export default stopwatchEntity;
