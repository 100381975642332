import React, { ReactElement, MutableRefObject } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from '~/providers/Auth';
import Gap from '~/components/flow/Gap';
import Header from '~/components/titles/Header';
import Link from '~/components/flow/Link';
import COLORS from '~/constants/colors';
import { FONT_WEIGHTS } from '~/constants/typography';
import Paginator, { getPageSlice } from '~/components/Paginator';
import Subtext from '~/components/titles/Subtext';
import Subheader from '~/components/titles/Subheader';

import CONTENT, { STATES } from '../constants/content';
import { useRecordContext } from '../providers/RecordProvider';
import uris from '../uris';
import ContentItem from './ContentItem';
import NoContentItems from './NoContentItems';
import { capitalizeFirstLetter } from '../utils';
import { State } from '../models';
import { DEFAULT_CATEGORY_LABEL } from '../constants/defaults';
import { ContentCardRef } from './ContentCard';
import RecordToggler from './RecordToggler';

interface SavedListProps {
  state: State;
  contentCardRef: MutableRefObject<ContentCardRef | null>;
}

function SavedList({ state, contentCardRef }: SavedListProps): ReactElement {
  const { state: authState } = useAuth();
  const { state: recordState } = useRecordContext();
  const cappedState = capitalizeFirstLetter(state);
  const headers = {
    learning: `🧠 ${cappedState} List`,
    grinding: `💪 ${cappedState} Group`,
    satisfied: `💯 ${cappedState} Set`,
    archived: `💤 ${cappedState} Array`,
  };
  const blurbs = {
    learning: "you're learning but haven't figured out",
    grinding: "you've figured out and are actively grinding",
    satisfied: "you've gotten to a level you're happy with",
    archived: "you couldn't figure out but want to revisit later",
  };
  const showTotalsPrefix =
    state === STATES.satisfied ? `${cappedState} with` : cappedState;
  const profileUri = uris.profile(authState.user?.handle);
  const profileUrl = `${window.location.origin}${profileUri}`;
  const getLabel = (r) =>
    r?.technique.categories?.[0].label || DEFAULT_CATEGORY_LABEL;
  const getCallback = (techniqueId) => () =>
    contentCardRef.current?.showTechnique(techniqueId);
  const getKey = (idx) => `BeatboxDbSavedListDiv${idx}`;

  // Processing
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || 1;
  const stateRecords = recordState.records?.filter((r) => r.state === state);
  const recordsDict = stateRecords?.reduce((acc, r) => {
    const label = getLabel(r);
    const list = acc[label] ? acc[label].concat(r) : [r];

    return { ...acc, [label]: list };
  }, {} as Record<string, any>);
  const source = Object.values(recordsDict || {}).flat();
  const data = getPageSlice(source, page);
  const recordsTotal = recordState.records?.length || 0;
  const itemTotal = source?.length || 0;

  // Styling
  const itemTotalStyle = {
    color: COLORS.dodgerBlue,
  };
  const shareProgressStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap' as const,
  };
  const categoryLabelStyle = {
    fontWeight: FONT_WEIGHTS.medium,
  };
  const recordTogglerStyle = {
    float: 'right' as const,
    marginRight: '0.15em',
  };

  return (
    <>
      <Header>{headers[state]}</Header>
      For saved techniques {blurbs[state]}
      <Gap />
      <Subtext color={COLORS.doveGray}>
        <li>
          {showTotalsPrefix} <b style={itemTotalStyle}>{itemTotal}</b> out of{' '}
          <b style={itemTotalStyle}>{recordsTotal}</b> saved techniques
        </li>
        {state !== STATES.archived && (
          <>
            <Gap />
            <li style={shareProgressStyle}>
              Share your progress: <Link href={profileUri}>{profileUrl}</Link>
            </li>
          </>
        )}
      </Subtext>
      <Gap />
      {itemTotal > 0 ? (
        <>
          {data?.map((r, idx) => (
            <div key={getKey(idx)}>
              {getLabel(r) !== getLabel(data[idx - 1]) && (
                <>
                  <Gap />
                  <Subheader style={categoryLabelStyle}>
                    {getLabel(r)}
                  </Subheader>
                </>
              )}
              <ContentItem
                id={r.technique.id}
                label={r.technique.name}
                gutterWidth="5.5em"
                callback={getCallback(r.technique.id)}
              >
                <RecordToggler
                  techniqueId={r.technique.id}
                  style={recordTogglerStyle}
                />
              </ContentItem>
            </div>
          ))}
          <Paginator link={uris.recordsState(state)} itemTotal={itemTotal} />
        </>
      ) : (
        <NoContentItems plural={CONTENT.records} />
      )}
    </>
  );
}

export default SavedList;
