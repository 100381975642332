import Matter from 'matter-js';
import YouTube from 'react-youtube';

import { PlaySystem } from './models';
import noteEntity from '../entities/noteEntity';
import bridgeEntity from '../entities/bridgeEntity';
import {
  NOTE_DIMS,
  NOTE_KEYS,
  LATERAL_OFFSET,
  RECEPTOR_DIMS,
  INPUT_MODES,
} from '../constants/layout';
import { LABELS, INDEX_MAP } from '../constants/kana';
import { GARBAGE_THRESHOLD, SPECIAL_CHANCE } from '../constants/mechanics';
import { getNewY } from '../utils';

const playHighwaySystem: PlaySystem = (entities) => {
  const newEntities = entities;
  const { receptor, notes, bridges } = entities;
  const { engine, windowDims } = entities.physics;
  const { rhythm, homework, velocity, inputMode } = entities.settings;
  const { renderedNoteIdx, problemIdxs, youTubePlayerState } = entities.state;
  const { startTime, videoTime, videoDelta } = entities.state;

  // Handle timing synchronization
  if (videoDelta <= 0) return newEntities;

  // Make sure start time set and YouTube background is playing
  if (startTime < 0 || youTubePlayerState !== YouTube.PlayerState.PLAYING)
    return newEntities;

  // Delete old or move active notes
  const endY = windowDims.h + NOTE_DIMS(windowDims).h;
  const receptorY = receptor.getPos().y;
  const noteKeys = Object.keys(notes);

  noteKeys.forEach((key) => {
    const note = notes[key];
    const oldY = note.getPos().y;
    const newY = getNewY(receptorY, note.timing, videoTime, velocity);
    const translation = { x: 0, y: newY - oldY };
    const isGarbage =
      videoTime - note.garbageTime > GARBAGE_THRESHOLD &&
      note.state !== NOTE_KEYS.miss &&
      !note.attemptable;

    if (newY > endY) {
      Matter.Composite.remove(engine.world, note.body);
      delete newEntities.notes[key];
    } else if (!note.state || note.state === NOTE_KEYS.miss || isGarbage)
      Matter.Body.translate(note.body, translation);
  });

  // Delete old or move active bridges
  const receptorDims = RECEPTOR_DIMS(windowDims);
  const receptorTopY = receptorY - (receptorDims.h - receptorDims.b) / 2;
  const bridgeKeys = Object.keys(bridges);

  bridgeKeys.forEach((key) => {
    const bridge = bridges[key];
    const bridgePos = bridge.getPos();
    const newY = getNewY(receptorY, bridge.timing, videoTime, velocity);
    const yChange = newY - bridgePos.y;
    const translation = { x: 0, y: yChange };

    if (newY > receptorTopY) {
      const oldH = bridge.getDims().h;
      const newH = bridge.h0 - (newY - receptorTopY);
      const hChange = Math.abs(newH - oldH);
      const receptorDist = Math.max(receptorTopY - bridgePos.y, 0);
      const lengthChange = hChange - receptorDist;
      const { vertices } = bridge.body;

      if (!bridge.active) newEntities.bridges[key].active = true;
      if (receptorDist > 0)
        Matter.Body.translate(bridge.body, { x: 0, y: receptorDist });
      vertices[0].y += lengthChange;
      vertices[1].y += lengthChange;
      if (vertices[1].y <= receptorTopY)
        Matter.Body.setVertices(bridge.body, vertices);
      else {
        Matter.Composite.remove(engine.world, bridge.body);
        delete newEntities.bridges[key];
      }
    } else Matter.Body.translate(bridge.body, translation);
  });

  // Add notes based on timing
  const startY = 0;
  const nextNoteIdx = renderedNoteIdx + 1;

  if (nextNoteIdx < rhythm.timings.length) {
    const newNoteTiming = rhythm.timings[nextNoteIdx];
    const newNoteY = getNewY(receptorY, newNoteTiming, videoTime, velocity);

    if (newNoteY > startY) {
      const nextProblemIdxs: [number, number] =
        problemIdxs[1] + 1 === homework.problems[problemIdxs[0]].answers.length
          ? [problemIdxs[0] + 1, 0]
          : [problemIdxs[0], problemIdxs[1] + 1];
      const nextProblem = homework.problems[nextProblemIdxs[0]];
      const newNoteAnswer = nextProblem.answers[nextProblemIdxs[1]];
      const newNotePrompt =
        newNoteAnswer === LABELS.hiragana[9][0]
          ? newNoteAnswer
          : nextProblem.prompt;
      const newNotePadIdxMod = (INDEX_MAP[newNoteAnswer]?.[0] ?? 1) % 3;
      const newNoteXOff =
        inputMode !== INPUT_MODES.jog
          ? (newNotePadIdxMod - 1) * LATERAL_OFFSET(windowDims)
          : 0;
      const newNoteTheme =
        Math.random() < SPECIAL_CHANCE
          ? NOTE_KEYS.special
          : [NOTE_KEYS.lateral, NOTE_KEYS.center, NOTE_KEYS.medial][
              newNotePadIdxMod
            ];
      const newNote = noteEntity(
        windowDims,
        newNoteXOff,
        newNoteY,
        newNotePrompt,
        newNoteAnswer,
        newNoteTiming,
        newNoteTheme
      );

      newEntities.notes[nextNoteIdx] = newNote;
      Matter.Composite.add(engine.world, [newNote.body]);
      newEntities.state.renderedNoteIdx = nextNoteIdx;
      newEntities.state.problemIdxs = nextProblemIdxs;

      // Add bridges to indicate combos
      const nextLength = homework.problems[nextProblemIdxs[0]].answers.length;
      const nextAnswerIdx = nextProblemIdxs[1] + 1;
      const lastTimingsIdx = rhythm.timings.length - 1;

      if (nextAnswerIdx < nextLength && nextNoteIdx < lastTimingsIdx) {
        const nextTimingGap = rhythm.timings[nextNoteIdx + 1] - newNoteTiming;
        const chip = Math.min(receptorTopY - newNoteY, 0);
        const newBridgeH0 = nextTimingGap * velocity;
        const newBridgeH = newBridgeH0 + chip;
        const prevBridgeXOff = bridges[renderedNoteIdx]?.xOff;
        const newBridgeXOff =
          prevBridgeXOff !== undefined && nextProblemIdxs[1] > 0
            ? prevBridgeXOff
            : newNoteXOff;
        const newBridgeY = Math.min(newNoteY, receptorTopY);
        const newBridge = bridgeEntity(
          windowDims,
          newBridgeH,
          newBridgeH0,
          newBridgeXOff,
          newBridgeY,
          newNoteTiming
        );

        newEntities.bridges[nextNoteIdx] = newBridge;
        Matter.Composite.add(engine.world, [newBridge.body]);
      }
    }
  }

  return newEntities;
};

export default playHighwaySystem;
