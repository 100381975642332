import { isEmptyObject } from '~/utils';
import { getArrayBuffer, getAudioBufferFromArrayBuffer } from '~/helpers/media';

import Encoder from '../Encoder';
import { Streams, Nodes, Recorders, Storage } from './models';
import { DeviceState } from '../../models';
import { getWavFromPcm, getPcmFromBlob } from './utils';
import { filterConstraints } from '../../utils';
import { CHANNEL_COUNT, SAMPLE_RATE, MIME_TYPES } from '../../constants/media';

interface MicrophoneConfig extends Record<string, any> {
  cookedCallback: (blob: Blob) => void;
  rawCallback: (buffer: AudioBuffer) => void;
  durationCallback: (duration: number) => void;
  maxDuration?: number;
  maxDurationCallback?: () => void | Promise<void>;
}

const AudioContext = window.AudioContext || (window as any).webkitAudioContext; // Needed for older browser support so type jank used to silence TypeScript

class Microphone {
  config: Record<string, any> = {
    startRecordingAt: 300, // For preventing weird loud pop at start of recording on some Macs
    timeslice: 100, // Impacts duration increment
  };

  state: DeviceState = 'closed';

  canProcessLive = 'ScriptProcessorNode' in window;

  encoder: Encoder;

  context?: AudioContext;

  streams: Streams = {};

  nodes: Nodes = {};

  recorders: Recorders = {};

  storage: Storage = { rawLiveBuffer: [] };

  timerToStart?: ReturnType<typeof setTimeout>;

  duration = 0;

  lastRunningTime?: number;

  constructor(config: MicrophoneConfig) {
    this.config = { ...this.config, ...config };
    this.encoder = new Encoder(this.config);
  }

  updateConfig(config) {
    this.config = { ...this.config, ...config };
  }

  addListener(stream) {
    this.timerToStart = setTimeout(() => {
      delete this.timerToStart;
    }, this.config.startRecordingAt); // Skip loud pop that happens once you start listening to microphone

    // Set things up to process data from microphone
    if (!this.context) throw new Error('Unable to find audio context');
    this.nodes.microphone = this.context.createMediaStreamSource(stream);
    this.nodes.rawSink = this.context.createMediaStreamDestination();
    this.streams.source = stream;
    this.streams.raw = this.nodes.rawSink.stream;

    // Retrieve data based on browser compatibility
    const handleLiveData = async (event) => {
      // Do nothing checks
      if (!this.lastRunningTime) throw new Error('Last running time not set');
      if (this.timerToStart) return; // Wait for loud pop
      if (this.state === 'suspended') return;

      // Stop recording if past max duration
      const currentTime = Date.now();
      const durationChange = currentTime - this.lastRunningTime;

      if (
        this.config.maxDuration &&
        this.duration + durationChange > this.config.maxDuration
      ) {
        if (this.config.maxDurationCallback)
          await this.config.maxDurationCallback();
        else await this.stop();

        return;
      }

      // Increment duration if checks pass
      this.duration += durationChange;
      this.lastRunningTime = currentTime;
      this.config.durationCallback(this.duration);

      // Process live if supported
      if (this.canProcessLive) {
        this.encoder.encode(event.inputBuffer.getChannelData(0));
        if (event.inputBuffer.length > 0) {
          const channelData: Float32Array = event.inputBuffer.getChannelData(0);

          this.storage.rawLiveBuffer.push(...Array.from(channelData));
        }
      }
    };
    const handleRawPostBlob = async (event) => {
      if (event.data.size > 0) {
        this.storage.rawPostBlob = event.data;

        // Encode data since not processed live
        const pcmData = await getPcmFromBlob(event.data);

        this.encoder.encode(pcmData);
        this.handleCookedAudio();
        await this.handleRawAudioBuffer();
      }
    };

    if (this.canProcessLive) {
      this.nodes.processor = this.context.createScriptProcessor(
        0, // Setting buffer size at 0 tells browser to choose best size
        CHANNEL_COUNT,
        CHANNEL_COUNT
      );
      this.nodes.processor.onaudioprocess = handleLiveData;

      // Begin retrieving microphone data
      this.nodes.microphone.connect(this.nodes.processor);
      this.nodes.processor.connect(this.nodes.rawSink);
    } else if ('MediaRecorder' in window) {
      this.nodes.splitter = this.context.createChannelSplitter(2);
      this.recorders.timer = new MediaRecorder(this.streams.raw);
      this.recorders.raw = new MediaRecorder(this.streams.raw);
      this.recorders.timer.ondataavailable = handleLiveData;
      this.recorders.raw.ondataavailable = handleRawPostBlob;

      // Begin retrieving microphone data
      this.nodes.microphone.connect(this.nodes.splitter);
      this.nodes.splitter.connect(this.nodes.rawSink, 0); // Forces recording in mono
      this.recorders.timer.start(this.config.timeslice);
      this.recorders.raw.start();
    } else throw new Error('Audio processing is not supported');
  }

  async start() {
    let audioConfig: boolean | Record<string, any> = filterConstraints({
      channelCount: CHANNEL_COUNT,
      ...this.config.audio,
    });

    if (isEmptyObject(audioConfig)) audioConfig = true;

    // Connect to microphone
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: this.config.deviceId
          ? { deviceId: { exact: this.config.deviceId } }
          : audioConfig,
        video: false,
      });

      // Fallback way to get sample rate on Firefox since `resistFingerprinting` hides it from stream
      if (!this.config.refContext) {
        this.config.refContext = new AudioContext();
        await this.config.refContext.close();
      }

      // Set up context with stream sample rate
      this.config.sampleRate =
        stream.getAudioTracks()[0].getSettings().sampleRate ??
        this.config.refContext.sampleRate ??
        SAMPLE_RATE;
      this.context = new AudioContext({ sampleRate: this.config.sampleRate });
      if (this.config.sampleRate !== this.encoder.config.sampleRate)
        this.encoder = new Encoder(this.config);

      // Add listener and set state
      this.addListener(stream);
      this.duration = 0;
      this.lastRunningTime = Date.now();
      this.state = 'running';
    } catch (error) {
      if ((error as Error).name === 'NotAllowedError')
        throw new Error('Please allow access to your microphone');

      throw new Error('Please refresh the page and try again');
    }
  }

  async pause() {
    if (!this.lastRunningTime) throw new Error('Last running time not set');
    await this.context?.suspend();
    Object.values(this.recorders).forEach((recorder) => recorder.pause());
    this.duration += Date.now() - this.lastRunningTime;
    this.config.durationCallback(this.duration);
    this.state = 'suspended';
  }

  async resume() {
    await this.context?.resume();
    Object.values(this.recorders).forEach((recorder) => recorder.resume());
    this.lastRunningTime = Date.now();
    this.state = 'running';
  }

  async clean() {
    // Disconnect nodes
    Object.values(this.nodes).forEach((node) => node.disconnect());
    if (this.nodes.processor) this.nodes.processor.onaudioprocess = null;

    // Clear context
    if (this.context?.state !== 'closed') await this.context?.close();
    this.state = 'done';

    // Stop recorders
    Object.values(this.recorders).forEach((recorder) => recorder.stop());

    // Stop tracks and remove recording icon from Chrome tab
    Object.values(this.streams).forEach((stream) =>
      stream.getAudioTracks().forEach((track) => track.stop())
    );
  }

  async stop() {
    await this.clean();

    // Need to manually call if processed live
    if (this.canProcessLive) {
      this.handleCookedAudio();
      await this.handleRawAudioBuffer();
    }
  }

  async reset() {
    await this.clean();
    this.encoder.clearBuffer();
    this.storage = { rawLiveBuffer: [] };
    this.duration = 0;
    this.lastRunningTime = undefined;
  }

  handleCookedAudio() {
    // Finish encoding to MP3
    const cookedBuffer = this.encoder.finish();

    if (!cookedBuffer || cookedBuffer.length === 0)
      throw new Error('No MP3 buffer to send');

    // Construct MP3 blob
    const cookedAudio = new Blob(cookedBuffer, { type: MIME_TYPES.mp3 });

    this.encoder.clearBuffer();
    this.config.cookedCallback(cookedAudio);
  }

  async handleRawAudioBuffer() {
    if (this.storage.rawLiveBuffer.length === 0 && !this.storage.rawPostBlob)
      throw new Error('No raw buffer to send');

    // Construct WAV array buffer
    let arrayBuffer;

    if (this.canProcessLive) {
      arrayBuffer = getWavFromPcm(
        Float32Array.from(this.storage.rawLiveBuffer),
        this.config.sampleRate
      );
    } else {
      if (!this.storage.rawPostBlob) throw new Error('No raw blob to extract');
      arrayBuffer = await getArrayBuffer(this.storage.rawPostBlob);
    }

    // Get audio buffer from array buffer
    const rawAudioBuffer = await getAudioBufferFromArrayBuffer(arrayBuffer);

    this.storage.rawLiveBuffer = [];
    delete this.storage.rawPostBlob;
    this.config.rawCallback(rawAudioBuffer);
  }
}

export default Microphone;
