import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';

import VARIANTS from '~/constants/variants';
import COLORS from '~/constants/colors';
import { useAuth } from '~/providers/Auth';
import TextInput from '~/components/inputs/TextInput';
import Subtext from '~/components/titles/Subtext';
import Button from '~/components/buttons/Button';
import Gap from '~/components/flow/Gap';
import { getFormErrorHandler } from '~/helpers/journal';
import Announcement from '~/components/Announcement';
import Prompt from '~/components/titles/Prompt';

import { DEFAULT_PASSWORD_INFO } from '../constants';

function Password(): ReactElement {
  const { changePassword } = useAuth();
  const [passwordFail, setPasswordFail] = useState<string | undefined>();
  const [showPasswordSuccess, setShowPasswordSuccess] = useState(false);
  const {
    register: passwordRegister,
    handleSubmit: handlePasswordSubmit,
    setError: setPasswordError,
    getValues: getPasswordValues,
    reset: passwordReset,
    formState: { errors: passwordErrors, isSubmitting: isSubmittingPassword },
  } = useForm({ defaultValues: DEFAULT_PASSWORD_INFO });
  const currentPasswordOpts = { required: true, minLength: 6 };
  const passwordOpts = {
    ...currentPasswordOpts,
    validate: (newPassword) =>
      newPassword !== getPasswordValues('currentPassword'),
  };
  const passwordButtonText = 'Confirm';
  const passwordButtonLoadingText = 'Revising...';

  // Handlers
  const onPasswordConfirm = (passwordInfo) => {
    return new Promise<void>((resolve) => {
      setPasswordFail(undefined);
      setShowPasswordSuccess(false);
      changePassword(passwordInfo)
        .then(() => {
          passwordReset(DEFAULT_PASSWORD_INFO);
          setShowPasswordSuccess(true);
        })
        .catch(
          getFormErrorHandler(
            DEFAULT_PASSWORD_INFO,
            setPasswordError,
            setPasswordFail
          )
        );
      resolve();
    });
  };

  // Styling
  const textInputStyle = {
    width: 'min(320px, 75vw)',
  };
  const primaryButtonStyle = {
    width: '63%',
    marginRight: '3%',
  };
  const announcementStyle = {
    width: 'min(calc(320px - 1.5em), calc(75vw - 1.5em))',
  };

  return (
    <>
      {showPasswordSuccess && (
        <>
          <Announcement variant={VARIANTS.success} style={announcementStyle}>
            Password successfully updated!
          </Announcement>
          <Gap />
        </>
      )}
      {passwordFail && (
        <>
          <Announcement style={announcementStyle}>{passwordFail}</Announcement>
          <Gap />
        </>
      )}
      <form>
        <Prompt>Change your password?</Prompt>
        <Gap />
        <div>
          <TextInput
            {...passwordRegister('currentPassword', currentPasswordOpts)}
            type="password"
            placeholder="Current password"
            style={textInputStyle}
          />
          {passwordErrors.currentPassword && (
            <Subtext color={COLORS.mandy}>
              {passwordErrors.currentPassword.message ||
                'Correct current password required'}
            </Subtext>
          )}
          <Gap />
          <TextInput
            {...passwordRegister('password', passwordOpts)}
            type="password"
            placeholder="New password"
            style={textInputStyle}
          />
          {passwordErrors.password && (
            <Subtext color={COLORS.mandy}>
              {passwordErrors.password.message ||
                'New password with at least 6 characters required'}
            </Subtext>
          )}
        </div>
        <Gap />
        <Button
          label={passwordButtonText}
          loadingLabel={passwordButtonLoadingText}
          loading={isSubmittingPassword}
          onClick={handlePasswordSubmit(onPasswordConfirm)}
          onTouchEnd={handlePasswordSubmit(onPasswordConfirm)}
          style={primaryButtonStyle}
        />
      </form>
    </>
  );
}

export default Password;
