import React, { Dispatch, SetStateAction, ReactElement } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';

interface OptionProps {
  label: string;
  index: number;
  selections: boolean[];
  setSelection: Dispatch<SetStateAction<boolean>>;
}

const diameter = 1.5;
const optionStyle = {
  display: 'inline-block',
  width: `${diameter}em`,
  height: `${diameter}em`,
  margin: '0 0.2em',
  lineHeight: '1.45em',
  fontSize: FONT_SIZES.single,
  color: COLORS.cornflower,
  backgroundColor: COLORS.indigo,
  borderRadius: `${diameter / 2}em`,
  outline: 'none',
  overflow: 'hidden',
  cursor: 'pointer',
};
const optionActiveStyle = {
  ...optionStyle,
  color: COLORS.dairyCream,
  backgroundColor: COLORS.jaffa,
};
const optionLabelStyle = {
  marginLeft: '0.05em',
  pointerEvents: 'none' as const,
};

function Option({
  label,
  index,
  selections,
  setSelection,
}: OptionProps): ReactElement {
  const mouseDownHandler = () => {
    const futureSelections = selections.concat(!selections[index]);

    futureSelections.splice(index, 1);
    if (futureSelections.reduce((acc, cur) => acc || cur))
      setSelection(!selections[index]);
  };

  return (
    <div
      role="button"
      tabIndex={index}
      onMouseDown={mouseDownHandler}
      style={selections[index] ? optionActiveStyle : optionStyle}
    >
      <b style={optionLabelStyle}>{label}</b>
    </div>
  );
}

export default Option;
