import React, { ReactElement, useState, CSSProperties, useEffect } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import Slider from '~/components/inputs/Slider';
import TextInput from '~/components/inputs/TextInput';
import Gap from '~/components/flow/Gap';

import { getCustomPreset, setCustomPresetOption } from '../../../services';
import { getDistortionCurve } from '../../../devices/Transformer';

interface OptionInputProps {
  title: string;
  option: string;
  reset: boolean;
  min: number;
  max: number;
  step: number;
  units?: string;
  style?: CSSProperties;
}

function OptionInput({
  title,
  option,
  reset,
  min,
  max,
  step,
  units,
  style,
}: OptionInputProps): ReactElement {
  const [optionValue, setOptionValue] = useState(getCustomPreset()[option]);

  // Handlers
  const handleSliderChange = (value) => {
    setCustomPresetOption(option, value);
    if (option === 'distortionAmount') {
      if (value > 0)
        setCustomPresetOption('distortionCurve', getDistortionCurve(value));
      else setCustomPresetOption('distortionCurve', null);
    }
    setOptionValue(value);
  };
  const handleTextInputChange = (event) =>
    handleSliderChange(Number(event.target.value));
  const handleTextInputBlur = (event) => {
    const value = Number(event.target.value);

    if (value < min) handleSliderChange(min);
    else if (value > max) handleSliderChange(max);
  };

  // Marks
  const getMark = (label) => ({
    style: {
      maxWidth: 'min(3.6em, 15vw)',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: COLORS.tundora,
      fontSize: FONT_SIZES.text,
      whiteSpace: 'nowrap' as const,
    },
    label: units ? `${label} ${units}` : label,
  });
  const marks = {
    [min]: getMark(min < 1000 ? min : '3k'),
    [max]: getMark(max < 1000 ? max : '20k'),
  };

  // Styling
  const textInputStyle = {
    width: 'min(4.5em, 20vw)',
    marginLeft: '0.5em',
  };
  const sliderStyle = {
    float: 'right' as const,
    width: 'min(16em, 38vw)',
    minWidth: 0,
    height: '2.2em',
    margin: '0 min(2.5em, 10vw)',
  };
  const handleStyle = {
    width: '1.25em',
    height: '1.25em',
    marginTop: '-0.5em',
    backgroundColor: COLORS.dodgerBlue,
    borderColor: COLORS.linkWater,
    opacity: 1,
    boxShadow: 'none',
  };

  useEffect(() => {
    setOptionValue(getCustomPreset()[option]);
  }, [reset]);

  return (
    <div style={style}>
      {title}
      <Gap />
      <TextInput
        type="number"
        min={min}
        max={max}
        step={step}
        value={optionValue}
        onChange={handleTextInputChange}
        onBlur={handleTextInputBlur}
        style={textInputStyle}
      />
      <Slider
        min={min}
        max={max}
        step={step}
        marks={marks}
        value={optionValue}
        onChange={handleSliderChange}
        handleStyle={handleStyle}
        style={sliderStyle}
      />
    </div>
  );
}

export default OptionInput;
