import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';

interface NotFoundProps {
  withCode?: boolean;
}

function NotFound({ withCode = false }: NotFoundProps): ReactElement {
  // Styling
  const notFoundStyle = {
    display: 'inline-block',
    width: 'min(30em, 82.5vw)',
    backgroundColor: COLORS.mandy,
    color: COLORS.white,
  };

  return (
    <div style={notFoundStyle}>
      You&apos;ve discovered the hidden treasure of nothing. But forget not the
      wisdoms of old.
      {withCode
        ? ' The requested beat may have expired or been deleted. '
        : " Code requires time and effort, a kindred to history's greatest towers and stone scultures. Do not take that for granite and know there may be something here in the future. "}
      For now, please check your spelling in the address bar.
    </div>
  );
}

export default NotFound;
