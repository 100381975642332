import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';

import { RendererProps } from './models';
import { StopwatchPredicate } from '../predicates/models';

function Stopwatch({
  predicate,
}: RendererProps<StopwatchPredicate>): ReactElement {
  const pos = predicate.getPos();
  const time = Math.min(Math.round(predicate.time), 99999999);

  // Styling
  const getPx = (v) => `${v}px`;
  const lateralPadding = predicate.dims.w / 22;
  const shiftSpace = 1 + lateralPadding;
  const stopwatchStyle = {
    position: 'absolute' as const,
    width: getPx(predicate.dims.w),
    height: getPx(predicate.dims.h),
    left: getPx(pos.x - predicate.dims.w / 2 - shiftSpace),
    top: getPx(pos.y - predicate.dims.h / 2),
    padding: `0 ${getPx(lateralPadding)}`,
    lineHeight: getPx(predicate.dims.h),
    textAlign: 'right' as const,
    backgroundColor: COLORS.black50,
    borderRadius: getPx(predicate.dims.h / 6),
    borderColor: COLORS.black25,
    borderStyle: 'solid',
    borderWidth: getPx(1),
    overflow: 'hidden',
    ...predicate.style,
  };
  const labelStyle = {
    display: 'inline-block',
    position: 'relative' as const,
    marginTop: getPx(-predicate.dims.h / 16),
    verticalAlign: 'middle',
    color: COLORS.alto,
    fontSize: getPx(predicate.dims.h / 1.6),
  };
  const unitStyle = {
    ...labelStyle,
    marginTop: getPx(-predicate.dims.h / 8),
    color: COLORS.cornflower,
  };

  return (
    <div style={stopwatchStyle}>
      <code style={labelStyle}>{time}</code> <span style={unitStyle}>ms</span>
    </div>
  );
}

export default Stopwatch;
