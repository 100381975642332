import { MutableRefObject } from 'react';
import Matter from 'matter-js';
import { GameEngine } from 'react-game-engine';

import { Dim, Entity } from '../../models';
import { Chart } from '../../../../../../models';
import { KEYS } from '../../../../../../constants/defaults';
import Keyboard, { KeyboardProps } from './Keyboard';
import { posOf } from '../../utils';
import { getNoteDims } from '../Note';

export default (
  gameDims: Dim,
  gameEngineRef: MutableRefObject<GameEngine>,
  chart: Chart
): Entity<KeyboardProps> => {
  const dims = { w: getNoteDims(gameDims).w * 2, h: gameDims.h };
  const body = Matter.Bodies.rectangle(
    gameDims.w / 5,
    gameDims.h / 2,
    dims.w,
    dims.h,
    { type: 'keyboard' }
  );
  const tracks = chart.reduce(
    (acc, n) => ({
      ...acc,
      [n.track]: {
        number: n.track,
        key: n.key ?? KEYS[0], // TODO: again see if can ensure this rather than using default
        node: n.node,
      },
    }),
    {}
  );

  Matter.Body.setStatic(body, true);

  return {
    dims,
    getPos: () => posOf(body),
    render: Keyboard,
    body,
    gameEngineRef,
    tracks,
  };
};
