/* eslint-disable */ // TODO: used to silence excessively deep type instantiation error, remove in future and handle properly
import React, { ReactElement, CSSProperties } from 'react';
import { GroupBase, Props } from 'react-select';
import { Controller, Control } from 'react-hook-form';

import COLORS from '~/constants/colors';
import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';
import Select from '~/components/inputs/Select';

import { capitalizeFirstLetter } from '../utils';

interface OptionSelectProps<
  Option = any,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends Props<Option, IsMulti, Group> {
  name: string;
  control: Control;
  defaultValue?: any;
  rules?: Record<string, any>;
  placeholder?: string;
  disabled?: boolean;
  style?: CSSProperties;
}

// TODO: add draggable sorting via https://react-select.com/advanced#sortable-multiselect
// TODO: ideally options are loaded via API call to Journal in case too many entries to load all directly. Needs some pagination-flavored strategy to deal with this.
function OptionSelect({
  name,
  control,
  defaultValue,
  rules,
  isMulti = true,
  placeholder,
  disabled,
  style,
  ...props
}: OptionSelectProps): ReactElement {
  const optionDict = props.options?.reduce(
    (acc, o) => ({
      ...acc,
      [o.value]: o.label,
    }),
    {} as Record<string, number>
  );
  const getSelectValue = (options) =>
    isMulti ? options.map((o) => o.value) : options.value;
  const getValue = (value) =>
    isMulti
      ? value?.map((id) => ({ value: id, label: optionDict[id] }))
      : value && { value, label: capitalizeFirstLetter(value) };

  // Styling
  const baseState = disabled
    ? {
        color: COLORS.silverChalice,
        backgroundColor: COLORS.alabaster,
        borderColor: COLORS.alto,
      }
    : {
        color: COLORS.black,
        backgroundColor: COLORS.white,
        borderColor: COLORS.alto,
      };
  const optionSelectStyles = {
    control: () => ({
      fontSize: FONT_SIZES.text,
      fontWeight: FONT_WEIGHTS.normal,
      backgroundColor: style?.backgroundColor ?? baseState.backgroundColor,
      borderColor: style?.borderColor ?? baseState.borderColor,
      boxShadow: `-0.13em 0.13em ${COLORS.black25}`,
      '&:hover': undefined,
      ...style,
    }),
    placeholder: () => ({
      whiteSpace: 'nowrap' as const,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }),
    dropdownIndicator: () => ({
      color: style?.color ?? baseState.color,
      ':hover': undefined,
    }),
  };

  return <></>;
  // (
  //   <Controller
  //     name={name}
  //     control={control}
  //     defaultValue={defaultValue}
  //     rules={rules}
  //     render={({ field: { onChange, value, ...rest } }) => (
  //       <Select
  //         {...rest}
  //         onChange={(os) => onChange(getSelectValue(os))}
  //         value={getValue(value)}
  //         isMulti={isMulti}
  //         placeholder={placeholder}
  //         disabled={disabled}
  //         styles={optionSelectStyles}
  //         {...props}
  //       />
  //     )}
  //   />
  // );
}

export default OptionSelect;
