import { useLocation, matchRoutes } from 'react-router-dom';

import DOMAINS, { HOST } from '~/constants/domains';

export const addStar = (path: string): string => `${path}/*`;

export const addParams = (
  url: string,
  params: Record<string, any> = {}
): string => {
  try {
    const newUrl = new URL(url);

    newUrl.searchParams.forEach((v, k) => {
      if (!v) newUrl.searchParams.delete(k);
    });
    Object.keys(params).forEach((k) => {
      if (params[k] !== undefined && params[k] !== null) {
        if (newUrl.searchParams.has(k)) newUrl.searchParams.set(k, params[k]);
        else newUrl.searchParams.append(k, params[k]);
      }
    });

    return newUrl.toString();
  } catch {
    const safeUrl = new URL(url, window.location.origin).toString();

    return addParams(safeUrl, params).slice(window.location.origin.length);
  }
};

export const getRelativeUrl = (url: string = window.location.href): string => {
  const splitUrl = url.split('//')[1] || '/';
  const startIdx = splitUrl.indexOf('/');

  return startIdx >= 0 ? splitUrl.substring(startIdx) : '/';
};

export const getHostUrl = (path: string, domain = DOMAINS.ponder) =>
  HOST === domain ? path : `${window.location.protocol}//${domain}${path}`;

export const prepCurrentPath =
  (paths: Record<string, string>, base: string, fallback: string) =>
  (): string => {
    const routes = Object.values(paths).map((p) => ({
      path: addStar(`${base}/${p}`),
    }));
    const location = useLocation();
    const matches = matchRoutes(routes, location);
    const match = matches?.[0].pathname.slice(base.length + 1);

    return match || fallback;
  };
