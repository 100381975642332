import { Dim, Pos, ExitPredicate } from './models';
import { EXIT_DIMS } from '../constants/layout';

const exitPredicate = (
  windowDims: Dim,
  pos: Pos,
  callback: () => void
): ExitPredicate => {
  return {
    dims: EXIT_DIMS(windowDims),
    getPos: () => pos,
    callback,
    style: {
      zIndex: 3,
    },
  };
};

export default exitPredicate;
