const getEvent = (type, key?, value?): CustomEvent =>
  key && value
    ? new CustomEvent(type, { detail: { [key]: value } })
    : new CustomEvent(type);

export const TYPES = {
  stopGame: 'stopGame',
  swapEntities: 'swapEntities',
  onKeyDown: 'onKeyDown',
  onInputPadSend: 'onInputPadSend',
  onRecordPadSend: 'onRecordPadSend',
  yTPSC: 'youTubePlayerStateChange',
};

export const EVENTS = {
  stopGame: () => getEvent(TYPES.stopGame),
  swapEntities: (fresh) => getEvent(TYPES.swapEntities, 'entities', fresh),
  onKeyDown: (label) => getEvent([TYPES.onKeyDown], 'character', label),
  onInputPadSend: (label) =>
    getEvent([TYPES.onInputPadSend], 'character', label),
  onRecordPadSend: () => getEvent(TYPES.onRecordPadSend),
  yTPSC: (data) => getEvent(TYPES.yTPSC, 'playerState', data),
};
