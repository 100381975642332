import React, {
  DetailedHTMLProps,
  AnchorHTMLAttributes,
  PropsWithChildren,
  ReactElement,
  CSSProperties,
} from 'react';
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom';

import COLORS from '~/constants/colors';

type AnchorProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;
type LinkProps = {
  style?: CSSProperties;
} & (ReactRouterLinkProps | AnchorProps);

function Link({
  style,
  children,
  ...props
}: PropsWithChildren<LinkProps>): ReactElement {
  const linkStyle = {
    textDecoration: 'none',
    color: COLORS.chetwodeBlue,
    ...style,
  };

  return (props as AnchorProps).href ? (
    <a style={linkStyle} {...props}>
      {children}
    </a>
  ) : (
    <ReactRouterLink style={linkStyle} {...(props as ReactRouterLinkProps)}>
      {children}
    </ReactRouterLink>
  );
}

export default Link;
