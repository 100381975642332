import React, { ReactElement, CSSProperties } from 'react';

import COLORS from '~/constants/colors';

interface HeartProps {
  width: string;
  fill?: string;
  style?: CSSProperties;
}

function Heart({
  width,
  fill = COLORS.mandy,
  style,
}: HeartProps): ReactElement {
  const heartStyle = {
    display: 'inline-block',
    width,
    height: width,
    overflow: 'hidden',
    ...style,
  };
  const svgStyle = {
    display: 'block',
    width: '100%',
    height: '100%',
  };

  return (
    <div style={heartStyle}>
      <svg viewBox="0 0 341 312" fill={fill} style={svgStyle}>
        <g transform="rotate(225, 150, 121)">
          <path d="M0 200 v-200 h200 a100,100 90 0,1 0,200 a100,100 90 0,1 -200,0 z" />
        </g>
      </svg>
    </div>
  );
}

export default Heart;
