import { chainMiddlewares } from '~/helpers/state';
import { Reducer } from '~/models';

import { BeatboxState } from './models';
import { ACTIONS } from './actions';

const INITIAL_STATE: BeatboxState = {};

const reducer: Reducer<BeatboxState> = (state = INITIAL_STATE, action) => {
  const getStateKey = (generic) => {
    const sliced = action.type.slice(generic.length);

    return `${sliced[0].toLowerCase()}${sliced.slice(1)}`;
  };

  if (action.type.startsWith(ACTIONS.add))
    return { ...state, [getStateKey(ACTIONS.add)]: action.payload };
  else if (action.type.startsWith(ACTIONS.set))
    return { ...state, [getStateKey(ACTIONS.set)]: action.payload };
  else if (action.type.startsWith(ACTIONS.listWanted))
    return { ...state, [getStateKey(ACTIONS.list)]: action.payload };
  else if (action.type === ACTIONS.listMainSearchTechniques)
    return { ...state, mainSearchTechniques: action.payload };
  else if (action.type.startsWith(ACTIONS.list))
    return { ...state, [getStateKey(ACTIONS.list)]: action.payload };
  else if (action.type.startsWith(ACTIONS.edit))
    return { ...state, [getStateKey(ACTIONS.edit)]: action.payload };
  else if (action.type.startsWith(ACTIONS.delete))
    return { ...state, [getStateKey(ACTIONS.delete)]: undefined };

  return state;
};

export default chainMiddlewares(reducer);
export { INITIAL_STATE };
