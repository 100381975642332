import PATHS from '~/constants/paths';

export const BSH_PATH = PATHS.beatboxShare;

export default {
  home: '/',
  record: 'record',
  film: 'film',
  upload: 'upload',
  listen: ':code',
  about: 'about',
};
