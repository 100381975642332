enum VARIANTS {
  neutral = 'neutral',
  primary = 'primary',
  secondary = 'secondary',
  warning = 'warning',
  fail = 'fail',
  success = 'success',
  disabled = 'disabled',
}

export default VARIANTS;
