import React, { ReactElement, useRef } from 'react';
import { Routes, Route } from 'react-router-dom';

import COLORS from '~/constants/colors';
import FramedView from '~/components/FramedView';
import Title from '~/components/titles/Title';
import Divider from '~/components/flow/Divider';
import { useAuth } from '~/providers/Auth';

import PhaseSwitchBoard from '../components/PhaseSwitchBoard';
import NotFound from './NotFound';
import SavedList from '../components/SavedList';
import { STATES } from '../constants/content';
import PATHS, { RECORDS_PATHS } from '../constants/paths';
import ContentCard, { ContentCardRef } from '../components/ContentCard';

function Records(): ReactElement {
  const { state: authState } = useAuth();
  const contentCardRef = useRef<ContentCardRef>(null);
  const phasePathsDict = Object.keys(RECORDS_PATHS).reduce(
    (acc, k) => ({ ...acc, [k]: RECORDS_PATHS[k] }),
    {}
  );

  // Styling
  const recordsStyle = {
    width: 'min(45em, 82.5vw)',
  };
  const frameColors: [string, string] = [
    COLORS.waikawaGray,
    COLORS.waikawaGray,
  ];

  // Reduce repetition
  const SavedListWithRef = ({ state }) => (
    <SavedList state={state} contentCardRef={contentCardRef} />
  );

  return (
    <FramedView accent colors={frameColors} style={recordsStyle}>
      <Title>Records</Title>
      <br />
      <PhaseSwitchBoard
        authState={authState}
        pagePath={PATHS.records}
        phasePathsDict={phasePathsDict}
      />
      <Divider />
      <Routes>
        <Route path="*" element={<NotFound nested />} />
        <Route index element={<SavedListWithRef state={STATES.learning} />} />

        {/* Learning */}
        <Route
          path={RECORDS_PATHS.learning}
          element={<SavedListWithRef state={STATES.learning} />}
        />

        {/* Grinding */}
        <Route
          path={RECORDS_PATHS.grinding}
          element={<SavedListWithRef state={STATES.grinding} />}
        />

        {/* Satisfied */}
        <Route
          path={RECORDS_PATHS.satisfied}
          element={<SavedListWithRef state={STATES.satisfied} />}
        />

        {/* Archived */}
        <Route
          path={RECORDS_PATHS.archived}
          element={<SavedListWithRef state={STATES.archived} />}
        />
      </Routes>
      <ContentCard ref={contentCardRef} />
    </FramedView>
  );
}

export default Records;
