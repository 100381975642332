import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';

import { Song } from '../models';
import { getVideoId } from '../utils';

export const YOUTUBE_OEMBED_URL =
  'https://www.youtube.com/oembed?format=json&url=https%3A//youtube.com/watch%3Fv%3D';

function getVideoMeta(
  link: string,
  bpm: number,
  song: Song,
  setSong: Dispatch<SetStateAction<Song>>
): void {
  axios.get(`${YOUTUBE_OEMBED_URL}${getVideoId(link)}`).then((result) =>
    setSong({
      ...song,
      title: result.data?.title,
      artist: result.data?.author_name,
      source: link,
      bpm,
    })
  );
}

export { getVideoMeta };
