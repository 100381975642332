import Matter from 'matter-js';
import { AnimationControls } from 'framer-motion';

import { Dim, Pos, FeedbackPredicate } from './models';
import { posOf } from '../utils';
import { FEEDBACK_DIMS } from '../constants/layout';

const feedbackPredicate = (
  windowDims: Dim,
  pos: Pos,
  rating: string,
  animate: AnimationControls,
  ratingColor: string,
  statColor: string
): FeedbackPredicate => {
  const feedbackDims = FEEDBACK_DIMS(windowDims);
  const body = Matter.Bodies.rectangle(
    pos.x,
    pos.y,
    feedbackDims.w,
    feedbackDims.h,
    { type: 'feedback' }
  );
  const feedbackStyle = {
    zIndex: 4,
  };

  return {
    dims: feedbackDims,
    getPos: () => posOf(body),
    body,
    rating,
    stat: 0,
    animate,
    ratingColor,
    statColor,
    style: feedbackStyle,
  };
};

export default feedbackPredicate;
