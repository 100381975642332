import React, { ReactElement } from 'react';
import { motion, AnimationControls } from 'framer-motion';

import { FONT_SIZES } from '~/constants/typography';
import COLORS from '~/constants/colors';

interface TargetProps {
  label: string;
  targetControls: AnimationControls;
}

const sideLength = 4;
const targetStyle = {
  width: `${sideLength}em`,
  height: `${sideLength}em`,
  margin: '0.2em',
  fontSize: FONT_SIZES.header,
  textAlign: 'center' as const,
  lineHeight: `${sideLength}em`,
  border: `0.15em solid ${COLORS.kournikova}`,
  boxShadow: `-0.13em 0.13em ${COLORS.black25}`,
};
const cannonStyle = {
  width: 'inherit',
  height: 'inherit',
  backgroundColor: COLORS.eggWhite,
};
const bulletStyle = {
  marginTop: `-${sideLength}em`,
  color: COLORS.black,
  backgroundColor: COLORS.kournikova,
};
const targetLabelStyle = {
  pointerEvents: 'none' as const,
};

// Animation
const slideDistance = `${1.5 * sideLength}em`;
const opacity = [1, 0, 0, 1];
const slideFrames = (sign) => ['0em', `${sign}${slideDistance}`, '0em', '0em'];
const variants = {
  ArrowUp: { y: slideFrames('-'), opacity },
  ArrowRight: { x: slideFrames(''), opacity },
  ArrowDown: { y: slideFrames(''), opacity },
  ArrowLeft: { x: slideFrames('-'), opacity },
};
const transition = { duration: 0.25, times: [0, 0.8, 0.8, 1] };

function Target({ label, targetControls }: TargetProps): ReactElement {
  return (
    <div style={targetStyle}>
      <motion.div style={cannonStyle} />
      <motion.div
        style={bulletStyle}
        animate={targetControls}
        variants={variants}
        transition={transition}
      >
        <b style={targetLabelStyle}>{label}</b>
      </motion.div>
    </div>
  );
}

export default Target;
export { targetStyle, targetLabelStyle };
