import Matter from 'matter-js';
import YouTube from 'react-youtube';

import { RecordSystem } from './models';
import { NOTE_DIMS } from '../constants/layout';
import { getNewY } from '../utils';

const recordHighwaySystem: RecordSystem = (entities) => {
  const newEntities = entities;
  const { notes, receptor } = entities;
  const { engine, windowDims } = entities.physics;
  const { velocity } = entities.settings;
  const { startTime, youTubePlayerState, videoDelta, videoTime } =
    entities.state;

  // Handle timing synchronization
  if (videoDelta <= 0) return newEntities;

  // Make sure start time set and YouTube background is playing
  if (startTime < 0 || youTubePlayerState !== YouTube.PlayerState.PLAYING)
    return newEntities;

  // Delete old or move active notes
  const endY = 0 - NOTE_DIMS(windowDims).h;
  const receptorY = receptor.getPos().y;
  const noteKeys = Object.keys(notes);

  noteKeys.forEach((key) => {
    const note = notes[key];
    const oldY = note.getPos().y;
    const newY = getNewY(receptorY, note.timing, videoTime, velocity, 1);
    const translation = { x: 0, y: newY - oldY };

    if (newY < endY) {
      Matter.Composite.remove(engine.world, note.body);
      delete newEntities.notes[key];
    } else Matter.Body.translate(note.body, translation);
  });

  return newEntities;
};

export default recordHighwaySystem;
