import axios from 'axios';

import { addParams } from '~/helpers/routing';
import { flattenData } from '~/helpers/journal';
import { JournalResponse } from '~/models';
import { DEFAULT_PAGE_SIZE } from '~/components/Paginator';

import urls from '../../urls';
import { Subject, Beatboxer } from './models';

const config = { withCredentials: true };
const beatboxDbSubjectName = 'beatbox';
const pack = (data, subjectId?) => ({
  data: {
    ...data,
    ...(subjectId && { subject: subjectId }),
  },
});

// Profiles
// TODO: remove `any` and fix dependence on `Profile` model
export const readProfiles = async (): Promise<any[]> => {
  const pluralUrl = addParams(urls.profiles, { sort: 'handle:asc' });
  const response = await axios.get(pluralUrl, config);

  return flattenData(response.data.data);
};

export const readBeatboxer = async (handle: string): Promise<Beatboxer> => {
  const response = await axios.get(urls.beatboxer(handle), config);

  return response.data.data.attributes;
};

// Subjects
export const readSubject = async (): Promise<Subject> => {
  const subjectsUrl = addParams(urls.subjects, {
    'filters[name][$eq]': beatboxDbSubjectName,
  });
  const response = await axios.get(subjectsUrl, config);

  return flattenData(response.data.data[0]);
};

// Generic
export const getCreateContent =
  <T, U>(pluralType: string, params?: Record<string, any>) =>
  async (info: T, subjectId: number): Promise<U> => {
    const pluralUrl = addParams(urls[pluralType], params);
    const response = await axios.post(pluralUrl, pack(info, subjectId), config);

    return flattenData(response.data.data);
  };

export const getReadContent =
  <T>(singularType: string, params?: Record<string, any>) =>
  async (id: number): Promise<T> => {
    const singularUrl = addParams(urls[singularType](id), params);
    const response = await axios.get(singularUrl, config);

    return flattenData(response.data.data);
  };

export const getReadContents =
  <T>(pluralType: string) =>
  async (
    subjectId: number,
    page?: number,
    params?: Record<string, any>
  ): Promise<T[] | JournalResponse<T>> => {
    const pluralUrl = addParams(urls[pluralType], {
      'filters[subject][id][$eq]': subjectId,
      sort: 'id:desc',
      ...(page && {
        'pagination[page]': page,
        'pagination[pageSize]': DEFAULT_PAGE_SIZE,
        'pagination[withCount]': true,
      }),
      ...params,
    });
    const response = await axios.get(pluralUrl, config);
    const data = flattenData(response.data.data);

    return page ? { ...response.data, data } : data;
  };

export const getUpdateContent =
  <T, U>(singularType: string, params?: Record<string, any>) =>
  async (id: number, info: T): Promise<U> => {
    const singularUrl = addParams(urls[singularType](id), params);
    const response = await axios.put(singularUrl, pack(info), config);

    return flattenData(response.data.data);
  };

export const getDeleteContent =
  (singularType: string) =>
  async (id: number): Promise<void> => {
    await axios.delete(urls[singularType](id), config);
  };
