import { MutableRefObject } from 'react';
import GameEngine from 'react-game-engine';

import { RecordPadEntity } from './models';
import { Dim } from '../predicates/models';
import RecordPad from '../renderers/RecordPad';
import recordPadPredicate from '../predicates/recordPadPredicate';

const recordPadEntity = (
  windowDims: Dim,
  gameEngineRef: MutableRefObject<GameEngine>
): RecordPadEntity => ({
  ...recordPadPredicate(windowDims, gameEngineRef),
  renderer: RecordPad,
});

export default recordPadEntity;
