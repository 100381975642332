import Matter from 'matter-js';

import { Dim, NotePredicate } from './models';
import { posOf } from '../utils';
import { NOTE_DIMS, NOTE_KEYS } from '../constants/layout';

const notePredicate = (
  windowDims: Dim,
  xOff: number,
  y: number,
  prompt: string,
  answer: string,
  timing: number,
  attemptable: boolean,
  theme = NOTE_KEYS.center,
  state?: string
): NotePredicate => {
  const dims = NOTE_DIMS(windowDims);
  const body = Matter.Bodies.rectangle(
    windowDims.w / 2 + xOff,
    y,
    dims.w,
    dims.h,
    { type: 'note' }
  );
  const noteStyle = {
    zIndex: 4,
  };

  return {
    dims,
    getPos: () => posOf(body),
    timing,
    body,
    prompt,
    answer,
    attemptable,
    garbageTime: -1,
    xOffIdx: Math.sign(xOff) + 1,
    windowDims,
    theme,
    state,
    style: noteStyle,
  };
};

export default notePredicate;
