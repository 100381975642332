import React, { ReactElement } from 'react';

import Subtext from '~/components/titles/Subtext';
import Link from '~/components/flow/Link';
import { FONT_SIZES } from '~/constants/typography';
import COLORS from '~/constants/colors';

interface InlineListProps {
  labels: string[];
  links?: string[];
  color?: string;
}

function InlineList({
  labels,
  links,
  color = COLORS.doveGray,
}: InlineListProps): ReactElement {
  const getKey = (idx) => `BeatboxDbContentCardInlineListSpan${idx}`;

  // Styling
  const labelStyle = {
    fontSize: FONT_SIZES.text,
  };

  return (
    <Subtext color={color}>
      {labels.map((label, idx, { length }) => (
        <span key={getKey(idx)} style={labelStyle}>
          {!links && label}
          {links && (
            <Link href={links[idx]} target="_blank" rel="noopener noreferrer">
              {label}
            </Link>
          )}
          {idx < length - 1 && ', '}
        </span>
      ))}
    </Subtext>
  );
}

export default InlineList;
