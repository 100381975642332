import { BridgeEntity } from './models';
import { Dim } from '../predicates/models';
import Bridge from '../renderers/Bridge';
import bridgePredicate from '../predicates/bridgePredicate';

const bridgeEntity = (
  windowDims: Dim,
  h: number,
  h0: number,
  xOff: number,
  y: number,
  timing: number
): BridgeEntity => ({
  ...bridgePredicate(windowDims, h, h0, xOff, y, timing),
  renderer: Bridge,
});

export default bridgeEntity;
