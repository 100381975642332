import React, { ReactElement, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from '~/providers/Auth';
import Gap from '~/components/flow/Gap';
import Header from '~/components/titles/Header';
import Link from '~/components/flow/Link';
import Code from '~/components/flow/Code';
import COLORS from '~/constants/colors';
import { addParams } from '~/helpers/routing';
import Paginator from '~/components/Paginator';

import CONTENT from '../constants/content';
import { DEFAULT_LABEL_PREFIX } from '../constants/defaults';
import { useBeatboxContext } from '../providers/BeatboxProvider';
import uris from '../uris';
import ContentItem from './ContentItem';
import NoContentItems from './NoContentItems';
import {
  hasRoleAccess,
  hasPermission,
  capitalizeFirstLetter,
  getSingular,
} from '../utils';

interface WantedListProps {
  plural: string;
}

function WantedList({ plural }: WantedListProps): ReactElement {
  const pIsT = plural === CONTENT.techniques;
  const singular = getSingular(plural);
  const defaultLabel = `${DEFAULT_LABEL_PREFIX} ${singular}`;
  const contentItemUriKey = `manage${capitalizeFirstLetter(singular)}`;
  const cappedPlural = capitalizeFirstLetter(plural);
  const stateKey = `wanted${cappedPlural}`;
  const methodKey = `listWanted${cappedPlural}`;
  const paginatorUriKey = `bounties${cappedPlural}`;
  const blurbPredicate = pIsT
    ? "need a description or don't have any demos or tutorials"
    : 'are stranded and need to be associated with techniques';
  const blurb = `The ${plural} below ${blurbPredicate}`;
  const { state: authState } = useAuth();
  const { state: beatboxState, [methodKey]: listMethod } =
    useBeatboxContext() as Record<string, any>;
  const getKey = (idx) => `BeatboxDbWantedListContentItem${idx}`;

  // Processing
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || 1;
  const data = beatboxState[stateKey]?.data;
  const itemTotal = beatboxState[stateKey]?.meta.pagination.total;

  // Rendering
  const hasPlus = (c, content) =>
    c[content]?.length === 0 && hasPermission(authState, content);
  const getGutterWidth = (c) => {
    const hasPlusD = hasPlus(c, CONTENT.demos);
    const hasPlusT = hasPlus(c, CONTENT.tutorials);

    if (hasPlusD && hasPlusT) return '5.9em';
    else if (hasPlusD || hasPlusT) return '3.6em';

    return '0px';
  };

  // Styling
  const contentItemChildrenStyle = {
    float: 'right' as const,
  };
  const manageLinkCodeStyle = {
    display: 'inline-block',
    padding: '0.3em 0.4em',
    margin: '0 0.15em',
    color: COLORS.white,
    backgroundColor: COLORS.dodgerBlue,
  };

  // List content
  useEffect(() => {
    (async () => {
      if (
        ((plural === CONTENT.techniques && hasRoleAccess(authState)) ||
          hasPermission(authState, plural)) &&
        (!beatboxState[stateKey] ||
          page !== beatboxState[stateKey]?.meta?.pagination.page)
      )
        await listMethod(page);
    })();
  }, [beatboxState.subject, plural, page]);

  return (
    <>
      <Header>Wanted {cappedPlural}</Header>
      {blurb}
      <Gap />
      {(data?.length || 0) > 0 ? (
        <>
          {data.map((c, idx) => (
            <ContentItem
              key={getKey(idx)}
              id={c.id}
              label={pIsT ? c.name : defaultLabel}
              gutterWidth={getGutterWidth(c)}
              {...(!(
                pIsT &&
                (c.description || !hasPermission(authState, CONTENT.techniques))
              ) && { link: uris[contentItemUriKey](c.id) })}
            >
              {pIsT && (c.demos?.length === 0 || c.tutorials?.length === 0) && (
                <span style={contentItemChildrenStyle}>
                  {hasPlus(c, CONTENT.demos) && (
                    <Link
                      href={addParams(uris.manageDemos, {
                        techniqueId: c.id,
                      })}
                    >
                      <Code style={manageLinkCodeStyle}>+D</Code>
                    </Link>
                  )}
                  {hasPlus(c, CONTENT.tutorials) && (
                    <Link
                      href={addParams(uris.manageTutorials, {
                        techniqueId: c.id,
                      })}
                    >
                      <Code style={manageLinkCodeStyle}>+T</Code>
                    </Link>
                  )}
                </span>
              )}
            </ContentItem>
          ))}
          <Paginator link={uris[paginatorUriKey]} itemTotal={itemTotal} />
        </>
      ) : (
        <NoContentItems plural={plural} />
      )}
    </>
  );
}

export default WantedList;
