import React, { ReactElement, useState, useEffect, useRef } from 'react';
import GraphemeSplitter from 'grapheme-splitter';
import { isMobile } from 'react-device-detect';

import COLORS from '~/constants/colors';
import urls from '~/urls';
import Announcement from '~/components/Announcement';
import FramedView from '~/components/FramedView';
import Divider from '~/components/flow/Divider';
import TextInput from '~/components/inputs/TextInput';
import Code from '~/components/flow/Code';
import TextArea from '~/components/inputs/TextArea';
import Link from '~/components/flow/Link';
import { stopEventEffects } from '~/utils';
import Checkbox from '~/components/inputs/Checkbox';
import Title from '~/components/titles/Title';
import Header from '~/components/titles/Header';
import Subtext from '~/components/titles/Subtext';

import { HOMEWORKS } from '../constants/alpha';
import PHASES from '../constants/phases';
import { DEFAULT_HOMEWORK } from '../constants/defaults';
import FullPad from '../components/FullPad';
import { DEFAULT_INPUT_PANEL_MEAS } from '../components/InputPanel';
import OptionSelect from '../components/OptionSelect';
import { INPUT_MODES } from '../constants/layout';
import { LABELS } from '../constants/kana';

const graphemeSplitter = new GraphemeSplitter();

interface ClassroomProps {
  phase: string;
}

function Classroom({ phase }: ClassroomProps): ReactElement {
  const [homeworkIdx, setHomeworkIdx] = useState(0);
  const [newHomework, setNewHomework] = useState(DEFAULT_HOMEWORK);
  const [prompts, setPrompts] = useState('');
  const [promptsList, setPromptsList] = useState<string[]>([]);
  const [answersChecked, setAnswersChecked] = useState({});
  const [inputMode, setInputMode] = useState(INPUT_MODES.key);
  const [padInput, setPadInput] = useState('  0');
  const [unique, setUnique] = useState(false);
  const homework = HOMEWORKS[homeworkIdx];
  const classroomProblems = homework.problems;
  const classroomPrompts = classroomProblems.map((p) => p.prompt);
  const classroomPromptsString = classroomPrompts.join('');
  const output = JSON.stringify({
    ...newHomework,
    problems: newHomework.problems.filter((p) => p.answers.length > 0),
  });
  const getKey = (type, idx) => `FlowGoClassroom${type}Option${idx}`;

  // Handle classroom interactions
  const handleInputEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.target.blur();
    }
  };
  const handleHomeworkTitle = (event) =>
    setNewHomework({ ...newHomework, title: event.target.value });
  const handleHomeworkPromoText = (event) => {
    const tempHomework = { ...newHomework };

    stopEventEffects(event);
    if (!event.target.value) delete tempHomework.promoText;
    else tempHomework.promoText = event.target.value;
    setNewHomework(tempHomework);
  };
  const handleHomeworkPromoLink = (event) => {
    const tempHomework = { ...newHomework };

    stopEventEffects(event);
    if (!event.target.value) delete tempHomework.promoLink;
    else tempHomework.promoLink = event.target.value;
    setNewHomework(tempHomework);
  };
  const handleAnswersChecked = (event) => {
    const newAnswersChecked = {
      ...answersChecked,
      [event.target.name]: event.target.checked,
    };
    const checks = Object.values(newAnswersChecked);

    setAnswersChecked(newAnswersChecked);
    setInputMode(
      checks.reduce((acc, v) => v || acc, false)
        ? INPUT_MODES.gym
        : INPUT_MODES.key
    );
  };

  // Answer input generation
  const getProblem = (p, as) => ({ prompt: p, answers: as });
  const collapseToDict = (acc, p) => ({ ...acc, [p.prompt]: p.answers });
  const problemsDict = newHomework.problems.reduce(collapseToDict, {});
  const handleNewPrompts = (s, u) => {
    const splitString = graphemeSplitter.splitGraphemes(s);
    const newPromptsList = u ? [...new Set(splitString)] : splitString;
    const fixChecks = (acc, p) => ({ ...acc, [p]: answersChecked[p] ?? false });
    const newProblems = newPromptsList.map((p) =>
      getProblem(p, problemsDict[p] ?? [])
    );
    const newAnswersChecked = newPromptsList.reduce(fixChecks, {});
    const checks = Object.values(newAnswersChecked);

    setPromptsList(newPromptsList);
    setAnswersChecked(newAnswersChecked);
    setInputMode(
      checks.reduce((acc, v) => v || acc, false)
        ? INPUT_MODES.gym
        : INPUT_MODES.key
    );
    setNewHomework({ ...newHomework, problems: newProblems });
  };
  const handlePrompts = (event) => {
    setPrompts(event.target.value);
    handleNewPrompts(event.target.value, unique);
  };
  const handleAnswersChange = (event) => {
    const newAnswers = graphemeSplitter.splitGraphemes(event.target.value);
    const newProblem = getProblem(event.target.name, newAnswers);
    const newProblems = newHomework.problems.map((p) =>
      p.prompt === event.target.name ? newProblem : p
    );

    setNewHomework({ ...newHomework, problems: newProblems });
  };
  const handleUniqueChecked = (event) => {
    setUnique(event.target.checked);
    handleNewPrompts(prompts, event.target.checked);
  };

  // Full pad management
  const [isSwipe, setIsSwipe] = useState(false);
  const panelMeas = {
    ...DEFAULT_INPUT_PANEL_MEAS,
    width: isMobile ? 'min(17.5vmin, 6.3em)' : 'min(11.6vmin, 6.3em)',
    height: isMobile ? 'min(12.5vmin, 4.5em)' : 'min(8.3vmin, 4.5em)',
  };
  const fullPadRef = useRef<HTMLDivElement>(null);
  const handleTouchMove = () => {
    setIsSwipe(true);
  };
  const handleHideFullPad = (event) => {
    const clickOutside = !fullPadRef.current?.contains(event.target);
    const notCheckbox = event.target.type !== 'checkbox';
    const isDisplayed = fullPadRef.current?.style.display !== 'none';
    const notSwipe = !(isMobile && isSwipe);

    if (clickOutside && isDisplayed && notCheckbox && notSwipe) {
      const keys = Object.keys(answersChecked);
      const allFalse = keys.reduce((acc, p) => ({ ...acc, [p]: false }), {});

      stopEventEffects(event);
      setAnswersChecked(allFalse);
      setInputMode(INPUT_MODES.key);
    }
    setIsSwipe(false);
  };
  const callback = (label) => {
    const labelPart = label.length > 1 ? label : `${label} `;
    const switchPart = padInput[2] === '0' ? '1' : '0';

    setPadInput(`${labelPart}${switchPart}`);
  };

  // Styling
  const classroomStyle = {
    display: phase === PHASES.classroom ? 'block' : 'none',
    ...(inputMode !== INPUT_MODES.key && {
      // Prevent highlighting issues with pad on mobile
      WebkitTouchCallout: 'none' as const,
      WebkitUserSelect: 'none' as const,
      KhtmlUserSelect: 'none' as const,
      MozUserSelect: 'none' as const,
      MsUserSelect: 'none',
      userSelect: 'none' as const,
    }),
  };
  const framedViewStyle = {
    width: 'min(45em, 82.5vw)',
  };
  const frameColors: [string, string] = [
    COLORS.waikawaGray,
    COLORS.waikawaGray,
  ];
  const levelStyle = {
    display: 'block',
    marginTop: '1em',
  };
  const answersLevelStyle = {
    ...levelStyle,
    display: promptsList.length > 0 ? 'block' : 'none',
  };
  const lastLevelStyle = {
    ...levelStyle,
    marginBottom: '0.38em',
  };
  const outputLevelStyle = {
    ...levelStyle,
    marginBottom: '0.2em',
  };
  const optionSelectStyle = {
    width: 'min(20em, 80vw)',
  };
  const textAreaStyle = {
    width: 'min(320px, 75vw)',
    height: '5em',
  };
  const outputStyle = {
    ...textAreaStyle,
    width: 'min(640px, 75vw)',
    height: '7.5em',
  };
  const levelRowSpaceStyle = {
    height: '0.25em',
  };
  const textInputStyle = {
    width: 'min(320px, 75vw)',
  };
  const checkboxStyle = {
    marginLeft: '0.5em',
  };
  const answerStyle = (includeMargin) => ({
    display: 'table',
    marginBottom: includeMargin ? '0.25em' : 0,
  });
  const answerCellStyle = {
    display: 'table-cell',
    verticalAlign: 'middle',
  };
  const answerPromptStyle = {
    display: 'block',
    width: '1.25em',
    height: '1.25em',
    marginRight: '0.5em',
    overflow: 'hidden',
    textAlign: 'center' as const,
  };
  const answerTextInputStyle = {
    width: 'min(240px, 45vw)',
  };
  const answerCheckboxStyle = {
    ...checkboxStyle,
    display: 'block',
  };
  const fullPadStyle = {
    position: 'fixed' as const,
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleHideFullPad);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleHideFullPad);

    return () => {
      document.removeEventListener('mousedown', handleHideFullPad);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleHideFullPad);
    };
  });

  useEffect(() => {
    const newAnswer = padInput.substring(0, 2).replace(/ /g, '');
    const newProblems = newHomework.problems.map((p) =>
      answersChecked[p.prompt]
        ? getProblem(p.prompt, problemsDict[p.prompt]?.concat([newAnswer]))
        : p
    );

    setNewHomework({ ...newHomework, problems: newProblems });
  }, [padInput]);

  return (
    <div style={classroomStyle}>
      <FramedView accent colors={frameColors} style={framedViewStyle}>
        <Announcement header="Warning">
          There is no persistence. If you refresh or change pages, your work
          will be lost!
        </Announcement>
        <Title>Classroom</Title>
        <br />
        Add your new homework problem prompts in the relevant box below. Answer
        inputs will load for each prompt. You can then fill in answers directly
        or click the corresponding checkboxes and use the gym pad. Each answer
        character will show up as a different note. The game recognizes kana
        modifier presses as separate characters. Answers with a diacritic or
        subscripting should be mapped to the base kana followed by the needed
        modifier characters represented by <Code>{LABELS.hiragana[9][0]}</Code>.
        The modifying order is subscript, ten-ten, then maru. If you include
        answers not in the gym pad&apos;s label set, players will be required to
        use key mode.
        <Divider />
        <Header>Homework History</Header>
        <div style={levelStyle}>
          <OptionSelect
            placeholder="Select a homework..."
            value={{
              value: homeworkIdx,
              label: homework.title,
            }}
            isSearchable={false}
            onChange={(option) => setHomeworkIdx(option.value)}
            options={HOMEWORKS.map((h, idx) => ({
              value: idx,
              label: h.title,
            }))}
            style={optionSelectStyle}
          />
        </div>
        <div style={levelStyle}>
          Here are the selected homework&apos;s prompts
          <div style={levelRowSpaceStyle} />
          <TextArea
            readOnly
            value={classroomPromptsString}
            style={textAreaStyle}
          />
        </div>
        <Divider />
        <Header>Arrange Assignment</Header>
        <div style={levelStyle}>
          What should we call your new homework?
          <div style={levelRowSpaceStyle} />
          <TextInput
            onBlur={handleHomeworkTitle}
            onKeyDown={handleInputEnter}
            style={textInputStyle}
          />
        </div>
        <div style={levelStyle}>
          Enter your problem prompts
          <div style={levelRowSpaceStyle} />
          <TextArea
            onChange={handlePrompts}
            onKeyDown={handleInputEnter}
            style={textAreaStyle}
          />
          <div style={levelRowSpaceStyle} />
          <Checkbox
            checked={unique}
            onChange={handleUniqueChecked}
            style={checkboxStyle}
          />{' '}
          Keep prompts unique
        </div>
        <div style={answersLevelStyle}>
          Fill in your answers for each prompt
          <div style={levelRowSpaceStyle} />
          {promptsList.map((p, idx) => (
            <div
              key={getKey('PromptAnswers', idx)}
              style={answerStyle(idx < promptsList.length)}
            >
              <div style={answerCellStyle}>
                <Code style={answerPromptStyle}>{p}</Code>
              </div>
              <div style={answerCellStyle}>
                <TextInput
                  name={p}
                  value={newHomework.problems[idx].answers.join('')}
                  onChange={handleAnswersChange}
                  onKeyDown={handleInputEnter}
                  style={answerTextInputStyle}
                />
              </div>
              <div style={answerCellStyle}>
                <Checkbox
                  name={p}
                  checked={answersChecked[p] ?? false}
                  onChange={handleAnswersChecked}
                  style={answerCheckboxStyle}
                />
              </div>
            </div>
          ))}
          <FullPad
            panelMeas={panelMeas}
            inputMode={inputMode}
            callback={callback}
            showAllHints
            style={fullPadStyle}
            ref={fullPadRef}
          />
        </div>
        <div style={levelStyle}>
          Feel free to add a shoutout!
          <div style={levelRowSpaceStyle} />
          <TextArea
            onBlur={handleHomeworkPromoText}
            onKeyDown={handleInputEnter}
            maxLength={280}
            style={textAreaStyle}
          />
        </div>
        <div style={lastLevelStyle}>
          Do you want your shoutout to also be a link?
          <div style={levelRowSpaceStyle} />
          <TextInput
            onBlur={handleHomeworkPromoLink}
            onKeyDown={handleInputEnter}
            style={textInputStyle}
          />
          <br />
          <Subtext>Please keep things safe for work</Subtext>
        </div>
      </FramedView>
      <br />
      <FramedView accent colors={frameColors} style={framedViewStyle}>
        <Title>Submission</Title>
        <br />
        To add your homework to the game, join my{' '}
        <Link href={urls.discordInvite}>Discord</Link> and post what&apos;s
        below to <Code>#japanese</Code>. Sorry it&apos;s a bit low-tech right
        now. I&apos;ll add a database if enough people are interested.
        <div style={outputLevelStyle}>
          <TextArea readOnly value={output} style={outputStyle} />
        </div>
      </FramedView>
    </div>
  );
}

export default Classroom;
