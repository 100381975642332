import { Body } from 'matter-js';

import { Dim, Pos } from './models';
import { getPx } from '../../../../utils';

export const posOf = (body: Body): Pos => ({
  x: body.position.x,
  y: body.position.y,
});

export const dimsOf = (body: Body): Dim => ({
  w: body.bounds.max.x - body.bounds.min.x,
  h: body.bounds.max.y - body.bounds.min.y,
});

export const getGameDims = (): Dim => ({
  w: document.body.clientWidth,
  h: Math.max(
    window.innerHeight,
    (window.outerWidth / window.innerWidth) * window.innerHeight
  ),
});

export const getNewX = (
  targetX: number,
  timing: number,
  elapsed: number,
  velocity: number,
  scale = 1
): number => {
  const startX = targetX + scale * timing * velocity;
  const progress = elapsed * velocity;

  return startX - scale * progress;
};

export const getCustomEvent = (
  type: string,
  key?: string,
  value?: any
): CustomEvent =>
  new CustomEvent(type, key ? { detail: { [key]: value } } : undefined);

export const playAudio = (url: string): Promise<void> => new Audio(url).play();

export { getPx };
