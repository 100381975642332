import React, { ReactElement, MutableRefObject } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import COLORS from '~/constants/colors';
import Title from '~/components/titles/Title';
import Divider from '~/components/flow/Divider';
import Gap from '~/components/flow/Gap';
import Header from '~/components/titles/Header';
import Paginator, { getPageSlice } from '~/components/Paginator';
import FramedView from '~/components/FramedView';
import { useAuth } from '~/providers/Auth';

import Unauthed from '../../Unauthed';
import { useBeatboxContext } from '../../../providers/BeatboxProvider';
import { MODES, FIELD_NAMES } from '../constants';
import uris from '../../../uris';
import ContentItem from '../../../components/ContentItem';
import NoContentItems from '../../../components/NoContentItems';
import ModeSwitchBoard from './ModeSwitchBoard';
import CONTENT, { KINDS, DIFFICULTIES } from '../../../constants/content';
import { DEFAULT_CATEGORY_LABEL } from '../../../constants/defaults';
import { ContentCardRef } from '../../../components/ContentCard';
import { capitalizeFirstLetter } from '../../../utils';
import LearnButton from '../../../components/LearnButton';

interface FieldListProps {
  mode: string;
  contentCardRef: MutableRefObject<ContentCardRef | null>;
}

function FieldList({ mode, contentCardRef }: FieldListProps): ReactElement {
  const { categoryLabel, techniqueDifficulty } = useParams();
  const { state: authState } = useAuth();
  const { state: beatboxState } = useBeatboxContext();
  const getCallback = (techniqueId) => () =>
    contentCardRef.current?.showTechnique(techniqueId);
  const getKey = (idx) => `BeatboxDbExploreFieldListContentItem${idx}`;

  // Fields
  const combosField = `${capitalizeFirstLetter(KINDS.combo)}s`;
  const isLost = (t) => t.categories?.length === 0 && t.kind === KINDS.sound;
  const isCombo = (t) => t.kind === KINDS.combo;
  const fields =
    mode === MODES.list
      ? (beatboxState.categories ?? [])
          .sort((a, b) => a.position - b.position)
          .map((c) => c.label)
          .concat(
            [
              beatboxState.techniques?.find(isCombo) && combosField,
              beatboxState.techniques?.find(isLost) && DEFAULT_CATEGORY_LABEL,
            ].filter((f) => typeof f === 'string') as string[]
          )
      : Object.values(DIFFICULTIES);
  const currentField =
    (mode === MODES.list ? categoryLabel : techniqueDifficulty) || fields[0];

  // Processing
  const [searchParams] = useSearchParams();
  const isUnique = (t) => t.difficulty === DIFFICULTIES.unique;
  const page = Number(searchParams.get('page')) || 1;
  const source = beatboxState.techniques?.filter((t) => {
    if (mode === MODES.list) {
      if (currentField === DEFAULT_CATEGORY_LABEL) return isLost(t);
      else if (currentField === combosField) return isCombo(t);

      return (
        t.categories?.map((c) => c.label).includes(currentField) && !isUnique(t)
      );
    }

    return t.difficulty === currentField;
  });
  const data = getPageSlice(source, page);
  const itemTotal = source?.length;

  // Rendering
  const header =
    mode === MODES.list ? currentField : capitalizeFirstLetter(currentField);
  const fieldName =
    mode === MODES.list ? FIELD_NAMES.category : FIELD_NAMES.difficulty;
  const techniqueTotal = beatboxState.techniques?.length || 0;

  // Styling
  const fieldListStyle = {
    display: 'inline-block',
    width: 'min(45em, 82.5vw)',
  };
  const totalStyle = {
    color: COLORS.dodgerBlue,
  };
  const learnButtonStyle = {
    float: 'right' as const,
    marginRight: '0.15em',
  };
  const frameColors: [string, string] = [
    COLORS.waikawaGray,
    COLORS.waikawaGray,
  ];

  return (
    <FramedView accent colors={frameColors} style={fieldListStyle}>
      <Title>Explore</Title>
      <br />
      <ModeSwitchBoard
        mode={mode}
        fields={fields}
        currentField={currentField}
      />
      <Divider />
      {authState.isAuthed && (
        <>
          <Header>{header}</Header>
          This {fieldName} contains <b style={totalStyle}>{itemTotal}</b> out of{' '}
          <b style={totalStyle}>{techniqueTotal}</b> known techniques
          <Gap />
          {(data?.length || 0) > 0 ? (
            <>
              {data?.map((t, idx) => (
                <ContentItem
                  key={getKey(idx)}
                  id={t.id}
                  label={t.name}
                  gutterWidth="5.5em"
                  callback={getCallback(t.id)}
                >
                  <LearnButton
                    techniqueId={t.id}
                    orientation="left"
                    style={learnButtonStyle}
                  />
                </ContentItem>
              ))}
              <Paginator
                link={uris.exploreModeField(mode, currentField)}
                itemTotal={itemTotal}
              />
            </>
          ) : (
            <NoContentItems plural={CONTENT.techniques} />
          )}
        </>
      )}
      {!authState.isAuthed && <Unauthed nested />}
    </FramedView>
  );
}

export default FieldList;
