import React, { ReactElement, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'validator';

import VARIANTS from '~/constants/variants';
import COLORS from '~/constants/colors';
import { useAuth } from '~/providers/Auth';
import TextInput from '~/components/inputs/TextInput';
import Subtext from '~/components/titles/Subtext';
import Button from '~/components/buttons/Button';
import Gap from '~/components/flow/Gap';
import { getFormErrorHandler } from '~/helpers/journal';
import Announcement from '~/components/Announcement';
import Prompt from '~/components/titles/Prompt';

import { DEFAULT_ACCOUNT_INFO } from '../constants';

type AccountKeys = 'email' | 'handle';

// TODO: add profile picture field
function Account(): ReactElement {
  const { state: authState, updateAccount } = useAuth();
  const [accountFail, setAccountFail] = useState<string | undefined>();
  const [showAccountSuccess, setShowAccountSuccess] = useState(false);
  const accountInfoKeys = Object.keys(DEFAULT_ACCOUNT_INFO);
  const defaultAccountInfo = accountInfoKeys.reduce(
    (acc, k) => ({
      ...acc,
      ...(authState.user && { [k]: authState.user[k] }),
    }),
    DEFAULT_ACCOUNT_INFO
  );
  const {
    register: accountRegister,
    handleSubmit: handleAccountSubmit,
    setError: setAccountError,
    getValues: getAccountValues,
    reset: accountReset,
    formState: { errors: accountErrors, isSubmitting: isSubmittingAccount },
  } = useForm({ defaultValues: defaultAccountInfo });
  const emailOpts = {
    required: true,
    validate: (email) => validator.isEmail(email),
  };
  const handleOpts = {
    required: true,
    validate: (handle) => validator.isAlphanumeric(handle),
  };
  const accountButtonText = 'Update';
  const accountButtonLoadingText = 'Changing...';
  const resetButtonText = 'Reset';

  // Handlers
  const onAccountUpdate = (accountInfo) => {
    return new Promise<void>((resolve) => {
      const nothingChanged = accountInfoKeys.reduce(
        (acc, k) =>
          acc && defaultAccountInfo[k] === getAccountValues(k as AccountKeys),
        true
      );

      if (nothingChanged) {
        setAccountFail('Please make a change to update');
      } else {
        setAccountFail(undefined);
        setShowAccountSuccess(false);
        updateAccount(accountInfo)
          .then(() => {
            setShowAccountSuccess(true);
          })
          .catch(
            getFormErrorHandler(
              DEFAULT_ACCOUNT_INFO,
              setAccountError,
              setAccountFail
            )
          );
      }
      resolve();
    });
  };
  const handleAccountReset = () => {
    setAccountFail(undefined);
    setShowAccountSuccess(false);
    accountReset();
  };

  // Styling
  const textInputStyle = {
    width: 'min(320px, 75vw)',
  };
  const buttonHolderStyle = {
    textAlign: 'center' as const,
  };
  const primaryButtonStyle = {
    width: '63%',
    marginRight: '3%',
  };
  const resetButtonStyle = {
    width: '33%',
  };
  const announcementStyle = {
    width: 'min(calc(320px - 1.5em), calc(75vw - 1.5em))',
  };

  useEffect(() => accountReset(defaultAccountInfo), [authState.user]);

  return (
    <>
      {showAccountSuccess && (
        <>
          <Announcement variant={VARIANTS.success} style={announcementStyle}>
            Account successfully updated!
          </Announcement>
          <Gap />
        </>
      )}
      {accountFail && (
        <>
          <Announcement style={announcementStyle}>{accountFail}</Announcement>
          <Gap />
        </>
      )}
      <form>
        <Prompt>What&apos;s your email?</Prompt>
        <Gap />
        <div>
          <TextInput
            {...accountRegister('email', emailOpts)}
            placeholder="Email"
            style={textInputStyle}
          />
          {accountErrors.email && (
            <Subtext color={COLORS.mandy}>
              {accountErrors.email.message || 'Valid email required'}
            </Subtext>
          )}
        </div>
        <Gap />
        <Gap />
        <Prompt>How would you like to be known?</Prompt>
        <Gap />
        <div>
          <TextInput
            {...accountRegister('handle', handleOpts)}
            placeholder="Handle"
            style={textInputStyle}
          />
          {accountErrors.handle && (
            <Subtext color={COLORS.mandy}>
              {accountErrors.handle.message ||
                'Handle with only letters and numbers required'}
            </Subtext>
          )}
        </div>
        <Gap />
        <div style={buttonHolderStyle}>
          <Button
            label={accountButtonText}
            loadingLabel={accountButtonLoadingText}
            loading={isSubmittingAccount}
            onClick={handleAccountSubmit(onAccountUpdate)}
            onTouchEnd={handleAccountSubmit(onAccountUpdate)}
            style={primaryButtonStyle}
          />
          <Button
            label={resetButtonText}
            variant={VARIANTS.secondary}
            onClick={handleAccountReset}
            onTouchEnd={handleAccountReset}
            style={resetButtonStyle}
          />
        </div>
      </form>
    </>
  );
}

export default Account;
